import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	position: 'relative',
}));

const getHeight = (
	specialNeedsCount: number,
	withoutSpecialNeedsCount: number
) => {
	if (
		(specialNeedsCount && !withoutSpecialNeedsCount) ||
		(!specialNeedsCount && withoutSpecialNeedsCount)
	) {
		return 100;
	}

	if (specialNeedsCount && withoutSpecialNeedsCount) {
		return 116;
	}

	return 100;
};

export const StationWrapper = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'open' &&
		prop !== 'rideStarted' &&
		prop !== 'specialNeedsCount' &&
		prop !== 'withoutSpecialNeedsCount',
})<{
	open?: boolean;
	rideStarted?: boolean;
	specialNeedsCount?: number;
	withoutSpecialNeedsCount?: number;
}>(
	({
		theme,
		open,
		rideStarted,
		specialNeedsCount,
		withoutSpecialNeedsCount,
	}) => ({
		display: 'grid',
		gridTemplateColumns: '50px max-content 1fr',
		justifyContent: 'start',
		gap: '0 16px',
		color: theme.palette.primary.dark,
		opacity: rideStarted && open ? 0.5 : 1,
		minHeight: getHeight(specialNeedsCount || 0, withoutSpecialNeedsCount || 0),

		svg: {
			marginTop: 2,
		},
	})
);

export const RouteLine = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'rideStarted',
})<{ open: boolean; rideStarted: boolean }>(({ open, rideStarted }) => ({
	width: 0,
	border: '1px dashed #D6DCF7',
	opacity: rideStarted && open ? 0.5 : 1,
}));

export const InfoWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',

	'& > span': {
		...theme.typography.body2,
		color: theme.palette.primary.dark,
		'&:first-of-type': {
			...theme.typography.body2,
			fontWeight: 600,
			marginBottom: 2,
		},
	},

	'& .point_name, & .point_address': {
		whiteSpace: 'pre-wrap',
	},

	'& .point_address': {
		marginTop: 2,
	},
}));

export const PassCount = styled('div', {
	shouldForwardProp: (prop) => prop !== 'entering',
})<{ entering?: boolean }>(({ theme, entering }) => ({
	width: 20,
	height: 20,
	display: 'grid',
	justifyContent: 'center',
	alignItems: 'center',
	border: '2px solid',
	borderRadius: '50%',
	borderColor: entering ? theme.palette.primary.main : theme.palette.error.main,
	marginTop: 8,

	'& > span': {
		...theme.typography.caption,
		fontWeight: 600,
		marginBottom: 2,
		color: entering ? theme.palette.primary.main : theme.palette.error.main,
	},
}));

export const SpecialNeedsInfo = styled('div')(({ theme }) => ({
	width: 'fit-content',
	...theme.typography.caption,
	fontWeight: 500,
	color: theme.palette.primary.main,
	padding: '5px 8px',
	backgroundColor: '#D6DCF7',
	borderRadius: 4,
}));

export const WithSpecialNeeds = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content 1fr',
	alignItems: 'baseline',
	gridGap: 8,
}));

export const WithLineWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	justifyItems: 'center',
}));
