import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Itinerary, PassengerDetails } from '@mopla/data-models';
import {
	formatPrice,
	getDurationStr,
	prepareCancellationData,
} from '@mopla/utils';

export const getCancellationDataPrepared$ =
	(t: TFunction) =>
	(inputs$: Observable<[Itinerary, PassengerDetails[] | undefined]>) => {
		return inputs$.pipe(
			map(([itinerary, passengersList]) =>
				prepareCancellationData(itinerary, passengersList)
			),
			map((cancellationData) =>
				cancellationData.map(({ secondsBeforeRideStart: _seconds, cost }) => {
					const secondsBeforeRideStart = Number(_seconds);
					const duration = dayjs.duration(secondsBeforeRideStart, 'seconds');

					return [
						t('cancellation_rules.text', {
							context: secondsBeforeRideStart !== 0 && 'with_time',
							time: getDurationStr(t, duration, ['seconds']),
						}),
						cost === 0 ? t('cancellation_rules.free') : formatPrice(cost / 100),
					];
				})
			)
		);
	};
