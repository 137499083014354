import { styled } from '@mui/material/styles';

import { getThemeColor, TMoplaColors } from '@mopla/mui';

export const RootMobile = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isNative' && prop !== 'variant',
})<{ isNative: boolean; variant: TMoplaColors }>(
	({ theme, isNative, variant }) => ({
		display: 'grid',
		gridTemplateRows: isNative ? '1fr max-content' : 'max-content 1fr',
		height: '100%',
		width: '100%',
		background: getThemeColor(variant, theme),
	})
);

export const RootDesktop = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
	gridTemplateColumns: '1fr',

	height: '100%',
	width: '100%',
	background: '#F0F0F0',
}));

export const DesktopOverflowWrapper = styled('div')(() => ({
	display: 'grid',
	justifyItems: 'center',
	overflow: 'auto',
	padding: 24,
	boxSizing: 'border-box',

	width: '100%',
	height: '100%',
}));

export const DesktopContentContainer = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr 348px',
	gridGap: 24,
	width: '100%',
	maxWidth: 976,
}));

export const DesktopSearchContainer = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 32,
	height: '100%',
	width: '100%',
}));
