import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	gap: 8,
	alignItems: 'stretch',
	background: 'rgba(255, 255, 255, 0.92)',
	boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.15)',
	borderRadius: 8,
	height: 150,
	overflow: 'hidden',

	'& .content': {
		display: 'flex',
		flexDirection: 'column',
		padding: '19px 24px 19px 0',
		flex: '1 1 auto',
	},

	'& .description': {
		marginTop: 2,
	},
}));

export const SeeMoreButton = styled(Button)(() => ({
	width: 'fit-content',
	padding: 0,
	textTransform: 'initial',
	textDecoration: 'underline',
	alignSelf: 'end',
	marginTop: 'auto',
}));
