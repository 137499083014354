import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';

import { ILatLng } from '@mopla/data-models';

const PLATFORM = Capacitor.getPlatform();

const configs: Record<TOptionKey, IOption> = {
	ios_gmaps: {
		checkLink: 'comgooglemaps-x-callback://',
		buildAppLink: (latLng: ILatLng) =>
			`comgooglemaps-x-callback://?q=${latLng.lat},${latLng.lng}&x-success=sourceapp://?resume=true&x-source=AirApp`,
	},
	android_gmaps: {
		checkLink: 'com.google.android.apps.maps',
		buildAppLink: (latLng: ILatLng) => `geo:0,0?q=${latLng.lat},${latLng.lng}`,
	},
	ios_native_maps: {
		checkLink: 'maps://',
		buildAppLink: (latLng: ILatLng) =>
			`maps://?q=${latLng.lat},${latLng.lng}&x-success=sourceapp://?resume=true&x-source=AirApp`,
	},
	web: {
		checkLink: null,
		buildAppLink: (latLng: ILatLng) =>
			`https://www.google.com/maps/search/?api=1&query=${latLng.lat},${latLng.lng}`,
	},
};

export const getMapsLinkBuilder = async () => {
	const options: IOption[] = [];

	if (PLATFORM === 'android') {
		options.push(configs.android_gmaps);
	} else if (PLATFORM === 'ios') {
		options.push(configs.ios_gmaps, configs.ios_native_maps);
	}

	try {
		for (const option of options) {
			if (!option.checkLink) {
				return option.buildAppLink;
			}

			const { value } = await AppLauncher.canOpenUrl({ url: option.checkLink });

			if (value) {
				return option.buildAppLink;
			}
		}
	} catch (e) {
		Sentry.captureException(e);
	}

	return configs.web.buildAppLink;
};

/** Open provided latLng point in a maps application (native) or google maps (web) */
export const openMapsApp = async (latLng: ILatLng) => {
	const mapLinkBuilder = await getMapsLinkBuilder();

	await AppLauncher.openUrl({ url: mapLinkBuilder(latLng) });
};

interface IOption {
	/** Native application (maps) link to check if an application is able to be opened */
	checkLink: string | null;
	/** Native application (maps) link to open a map and show a certain point */
	buildAppLink: (latLng: ILatLng) => string;
}

type TOptionKey = 'ios_gmaps' | 'android_gmaps' | 'ios_native_maps' | 'web';
