/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTimeValue } from '../models/DateTimeValue';
import type { Itinerary } from '../models/Itinerary';
import type { ItineraryRequestedParams } from '../models/ItineraryRequestedParams';
import type { Province } from '../models/Province';
import type { SpecialNeeds } from '../models/SpecialNeeds';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export interface IApiRouterPlan200 {
	requestedParams: ItineraryRequestedParams;
	itineraries: Array<Itinerary>;
	privateTripAvailable?: boolean;
}

export class RouterService {
	/**
	 * plan a trip
	 * request possible trips from/to at a given time
	 * @param fromPlaceLat
	 * @param fromPlaceLng
	 * @param fromPlaceLabel
	 * @param fromPlaceZip
	 * @param fromPlaceRegion
	 * @param toPlaceLat
	 * @param toPlaceLng
	 * @param toPlaceLabel
	 * @param toPlaceZip
	 * @param toPlaceRegion
	 * @param datetime
	 * @param specialNeeds
	 * @param arriveBy
	 * @returns any OK
	 * @throws ApiError
	 */
	public static getApiRouterPlan(
		fromPlaceLat: string,
		fromPlaceLng: string,
		fromPlaceLabel: string,
		fromPlaceZip: string,
		fromPlaceRegion: Province,
		toPlaceLat: string,
		toPlaceLng: string,
		toPlaceLabel: string,
		toPlaceZip: string,
		toPlaceRegion: Province,
		datetime: DateTimeValue,
		specialNeeds: SpecialNeeds,
		arriveBy?: boolean
	): CancelablePromise<IApiRouterPlan200> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/router/plan',
			query: {
				fromPlaceLat: fromPlaceLat,
				fromPlaceLng: fromPlaceLng,
				fromPlaceLabel: fromPlaceLabel,
				fromPlaceZip: fromPlaceZip,
				fromPlaceRegion: fromPlaceRegion,
				toPlaceLat: toPlaceLat,
				toPlaceLng: toPlaceLng,
				toPlaceLabel: toPlaceLabel,
				toPlaceZip: toPlaceZip,
				toPlaceRegion: toPlaceRegion,
				datetime: datetime,
				arriveBy: arriveBy,
				specialNeeds: specialNeeds,
			},
			errors: {
				403: `forbidden, no access`,
				409: `conflict, i.e. wheelchair type1=true AND type2=true`,
				422: `requested route cant be evaluated, since datetime is in the past`,
			},
		});
	}
}
