import {
	ComponentType,
	FC,
	PropsWithChildren,
	useCallback,
	useMemo,
	useState,
} from 'react';

import { IBooking } from '@mopla/data-models';
import {
	checkIsBookingCancelled,
	checkIsPassedBooking,
	isLBTItinerary,
	wrapDisplayName,
} from '@mopla/utils';

import { CancellationFlow } from './cancellation/CancellationFlow';
import { RemoveBookingFlow } from './remove/RemoveBookingFlow';
import {
	BookingCancellationContextProvider,
	IBookingCancellationContext,
} from './context';
import { TFlowType } from './types';

export const BookingCancellationFlow: FC<PropsWithChildren> = ({
	children,
}) => {
	const [bookedTicket, setBookedTicket] = useState<IBooking | null>(null);

	/** Initiates the cancellation flow */
	const cancelBooking = useCallback((bookedTicket: IBooking) => {
		setBookedTicket(bookedTicket);
	}, []);

	const closeHandler = useCallback(() => {
		setBookedTicket(null);
	}, []);

	const flowType = useMemo<TFlowType | null>(() => {
		if (!bookedTicket) return null;

		const isCancelledRide =
			bookedTicket && checkIsBookingCancelled(bookedTicket);
		const isPassedRide = bookedTicket && checkIsPassedBooking(bookedTicket);
		const isLBT = isLBTItinerary(bookedTicket?.itinerary);

		return isCancelledRide || isPassedRide || isLBT ? 'remove' : 'cancel';
	}, [bookedTicket]);

	const ctx = useMemo<IBookingCancellationContext>(
		() => ({ cancelBooking }),
		[cancelBooking]
	);

	return (
		<BookingCancellationContextProvider value={ctx}>
			{children}
			{flowType === 'cancel' && (
				<CancellationFlow
					bookedItinerary={bookedTicket as IBooking}
					onClose={closeHandler}
				/>
			)}
			{flowType === 'remove' && (
				<RemoveBookingFlow
					bookedItinerary={bookedTicket as IBooking}
					onClose={closeHandler}
				/>
			)}
		</BookingCancellationContextProvider>
	);
};

export const withBookingCancellationFlow = (
	WrappedComponent: ComponentType
) => {
	const Wrapper: FC = () => {
		return (
			<BookingCancellationFlow>
				<WrappedComponent />
			</BookingCancellationFlow>
		);
	};

	Wrapper.displayName = wrapDisplayName(
		WrappedComponent,
		'withBookingCancellationFlow'
	);

	return Wrapper;
};
