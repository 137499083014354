import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from 'observable-hooks';

import {
	PassengerBookingActionTypes,
	removeBooking,
	TRemoveBookingResultAction,
	useBusinessLayer,
} from '@mopla/business-logic';
import { IBooking } from '@mopla/data-models';
import { useTranslation } from '@mopla/i18';

import { CompleteAnim, ErrorModal, InfoModal } from '../../../';

import { ConfirmationModal } from './ConfirmationModal';

interface IRemoveBookingFlowProps {
	bookedItinerary: IBooking;
	onClose: VoidFunction;
}

export const RemoveBookingFlow: FC<IRemoveBookingFlowProps> = ({
	bookedItinerary,
	onClose,
}) => {
	const [isDisposeModalOpened, setIsDisposeModalOpened] = useState(true);
	const [isCancelling, setIsCancelling] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const navigate = useNavigate();
	const bl = useBusinessLayer();
	const { t } = useTranslation('tickets');

	const processRemoveBooking = () => {
		bl.dispatch(removeBooking(bookedItinerary));
		setIsCancelling(true);
	};

	const finishHandler = useCallback(() => {
		navigate('/home/tickets', { replace: true });
		onClose();
	}, [navigate, onClose]);

	useSubscription(
		bl.watchActions$(PassengerBookingActionTypes.RemoveBookingResult),
		(action) => {
			setIsDisposeModalOpened(false);
			setIsCancelling(false);

			if ((action as TRemoveBookingResultAction).payload?.error) {
				setShowErrorModal(true);
			} else {
				setSuccess(true);
			}
		}
	);

	return (
		<>
			{showErrorModal && (
				<ErrorModal
					onSubmit={() => {
						setShowErrorModal(false);
						onClose();
					}}
				/>
			)}
			{isDisposeModalOpened && (
				<ConfirmationModal
					open
					onClose={onClose}
					onConfirm={processRemoveBooking}
					isCanceling={isCancelling}
				/>
			)}
			{isSuccess && (
				<InfoModal
					icon={<CompleteAnim />}
					title={t('cancellation_flow.remove_success.title')}
					submitTitle={t('common:button.ok')}
					infoText=""
					onSubmit={finishHandler}
				/>
			)}
		</>
	);
};
