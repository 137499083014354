import { FC, ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';

import { TMoplaColors } from '@mopla/mui';

import { Root } from './IconMessage.styles';

interface IInfoMessageProps {
	className?: string;
	icon?: ReactNode;
	message: string;
	color?: TMoplaColors;
	variant?: TypographyProps['variant'];
	testId?: string;
}

export const IconMessage: FC<IInfoMessageProps> = ({
	className,
	icon,
	message,
	color = 'neutral.80',
	variant = 'caption',
	testId = 'iconMessage',
}) => {
	return (
		<Root className={className} sx={{ color }} data-testid={testId}>
			{icon}
			<Typography className="label" variant={variant} color="inherit">
				{message}
			</Typography>
		</Root>
	);
};
