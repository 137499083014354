import {
	FC,
	memo,
	MouseEventHandler,
	ReactElement,
	useCallback,
	useMemo,
} from 'react';
import {
	useObservable,
	useObservableEagerState,
	useObservableState,
} from 'observable-hooks';
import { map } from 'rxjs';

import { getCountdownTimer$ } from '@mopla/business-logic';
import { ILeg, IPassengerMapView } from '@mopla/data-models';

import { LiveMapView } from './LiveMapView';
import {
	TBookingMapData$,
	useBookingMapApiPolling$,
} from './useBookingMapApi$';
import { isFullscreenMap$, useLiveMapController } from './useLiveMapController';

type TRenderFn = (params: {
	mapNode: ReactElement;
	isDelayed?: boolean;
}) => ReactElement;

export interface IBookingLegLiveMapProps {
	bookingLeg: ILeg;
	render?: TRenderFn;
}

interface ILiveMapControllerProps extends IBookingLegLiveMapProps {
	bookingMapData$: TBookingMapData$;
}

export const LiveMapController: FC<ILiveMapControllerProps> = ({
	bookingLeg,
	render,
	bookingMapData$,
}) => {
	const isFullViewOpened = useObservableEagerState(isFullscreenMap$);
	const { fullMapRef, minimizedMapRef } = useLiveMapController(
		bookingMapData$,
		bookingLeg
	);

	/** Type-cast makes TS happy. The top-level data check is in the LiveMapWithApiData */
	const bookingMapData = useObservableEagerState(
		bookingMapData$
	) as IPassengerMapView;
	const isDelayed = bookingMapData.isDelayed;

	const miniMapClickHandler = useCallback<MouseEventHandler>((e) => {
		e.stopPropagation();
		isFullscreenMap$.next(true);
	}, []);

	const fullMapCloseHandler = useCallback(
		() => isFullscreenMap$.next(false),
		[]
	);

	const minutesBeforeStart$ = useObservable(() =>
		getCountdownTimer$(bookingLeg.from.departure || '', 60 * 15).pipe(
			map((duration) => Math.max(0, duration.minutes()))
		)
	);

	const minutesBeforeStart = useObservableState(minutesBeforeStart$, null);

	const renderHandler = useMemo(() => {
		if (!render) {
			return undefined;
		}

		return (mapNode: ReactElement) => render({ mapNode, isDelayed });
	}, [render, isDelayed]);

	return (
		<LiveMapView
			isFullViewOpened={isFullViewOpened}
			fullMapRef={fullMapRef}
			miniMapRef={minimizedMapRef}
			onFullMapClose={fullMapCloseHandler}
			onMiniMapClick={miniMapClickHandler}
			minutesBeforeStart={minutesBeforeStart}
			startPointLabel={bookingLeg.from.name}
			transportType={bookingMapData.serviceType}
			licencePlate={bookingMapData.licencePlate}
			stopsLeft={bookingMapData.stopsLeft}
			isDelayed={isDelayed}
			render={renderHandler}
		/>
	);
};

export const LiveMapWithApiData: FC<IBookingLegLiveMapProps> = (props) => {
	const bookingMapData$ = useBookingMapApiPolling$(props.bookingLeg.legId);
	const bookingMapData = useObservableState(bookingMapData$, null);

	if (!bookingMapData) {
		return null;
	}

	return <LiveMapController {...props} bookingMapData$={bookingMapData$} />;
};

export const BookingLegLiveMap =
	memo<IBookingLegLiveMapProps>(LiveMapWithApiData);
