import { Dispatch, FC, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { ISubscriptionOffer, TGermanyTicketType } from '@mopla/data-models';
import { CheckboxWithLink, FlexColumn, IconMessage, WarnIcon } from '@mopla/ui';
import { getIsJobticketByType } from '@mopla/utils';

import { PaymentDetails } from '../../PaymentDetails/PaymentDetails';
import { IInvoiceRecipientFormValues, ISubscriptionFormValues } from '../types';
import { VoucherBlock } from '../Voucher';
import { VoucherForm } from '../VoucherForm';

import { ContentBlock } from './ContentBlock';

type TSummaryStepProps = {
	invoiceData: IInvoiceRecipientFormValues | null;
	showInvoiceRecipientBlock: boolean;
	isAcceptTariffs: boolean;
	setIsAcceptTariffs: Dispatch<SetStateAction<boolean>>;
	isPriceZero?: boolean;
	dTicketType: TGermanyTicketType;
	subscriptionOffer?: ISubscriptionOffer | null;
};

export const SummaryStep: FC<TSummaryStepProps> = ({
	invoiceData,
	showInvoiceRecipientBlock,
	isAcceptTariffs,
	setIsAcceptTariffs,
	isPriceZero = false,
	dTicketType,
	subscriptionOffer,
}) => {
	const { t } = useTranslation(['dticket']);

	const { watch } = useFormContext<ISubscriptionFormValues>();
	const dTicketData = watch();

	const toggleTariffsCheckbox = () => setIsAcceptTariffs((prev) => !prev);
	const isJobticket = getIsJobticketByType(dTicketType);

	return (
		<>
			<SubscriptionInformation
				dTicketData={dTicketData}
				dTicketType={dTicketType}
			/>
			<SubscriberData dTicketData={dTicketData} />
			{invoiceData && showInvoiceRecipientBlock && (
				<InvoiceRecipientBlock invoiceData={invoiceData} />
			)}
			{!isPriceZero && (
				<ContentBlock
					testId="dticket-subscription-payment-method-section"
					headerText={t('header.payment')}
				>
					<PaymentDetails />
					<IconMessage
						icon={<WarnIcon />}
						message={t('text.subscription_payment')}
					/>
				</ContentBlock>
			)}
			{subscriptionOffer?.voucher && (
				<VoucherBlock subscriptionOfferVoucher={subscriptionOffer.voucher} />
			)}
			{!isJobticket && !subscriptionOffer?.voucher && <VoucherForm />}
			<ContentBlock>
				<CheckboxWithLink
					url="https://www.mopla.solutions/de/tarifbestimmung-und-beforderungsbedingungen"
					linkText="dticket:text.accept_tariffs"
					checked={isAcceptTariffs}
					toggleCheckbox={toggleTariffsCheckbox}
					testId="dticket-acceptTariffs-checkbox"
				/>
			</ContentBlock>
		</>
	);
};

const SubscriptionInformation: FC<{
	dTicketData: ISubscriptionFormValues;
	dTicketType: TGermanyTicketType;
}> = ({ dTicketData, dTicketType }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock
			testId="dticket-subscription-subscription-summary-section"
			headerText={t('header.dticket_subscription', { dTicketType })}
		>
			<IconMessage icon={<WarnIcon />} message={t('text.dticket_validation')} />
			<Typography variant="body1" fontWeight={600}>
				{t('dticket:text.subscription_start_date', {
					startDate: dayjs(dTicketData.monthOfStart).format('1. MMMM YYYY'),
				})}
			</Typography>
		</ContentBlock>
	);
};

const SubscriberData: FC<{
	dTicketData: ISubscriptionFormValues;
}> = ({ dTicketData }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock headerText={t('header.subscriber')}>
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Typography variant="body1">
					{dTicketData.firstName} {dTicketData.surname}
				</Typography>
				<Typography variant="body2" color="neutral.80">
					{dTicketData.phoneNumber}
				</Typography>
			</FlexColumn>
		</ContentBlock>
	);
};

const InvoiceRecipientBlock: FC<{
	invoiceData: IInvoiceRecipientFormValues;
}> = ({ invoiceData }) => {
	const { t } = useTranslation(['dticket']);

	return (
		<ContentBlock headerText={t('header.invoice_recipient')}>
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Typography variant="body1">
					{invoiceData.firstName} {invoiceData.surname}
				</Typography>
				<FlexColumn className="space-between">
					{invoiceData.company && (
						<Typography variant="body2" color="neutral.80">
							{t('dticket:text.company', {
								value: invoiceData.company,
							})}
						</Typography>
					)}
					<Typography variant="body2" color="neutral.80">
						{invoiceData.street} {invoiceData.streetNumber}
					</Typography>
					<Typography variant="body2" color="neutral.80">
						{invoiceData.postCode} {invoiceData.location}
					</Typography>
				</FlexColumn>
			</FlexColumn>
		</ContentBlock>
	);
};
