import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { useUser } from '@mopla/business-logic';
import { TGermanyTicketType } from '@mopla/data-models';
import { ArrowRightIcon, FlexColumn, MplButton, Ticket } from '@mopla/ui';
import { getIsJobticketByType } from '@mopla/utils';

import {
	TicketContentBlock,
	TicketSubmitWrapper,
} from './DTicketTicketView.styles';

interface IProps {
	onSubmit: VoidFunction;
	distributor: string;
	dTicketType: TGermanyTicketType;
	monthOfStart: string;
}

export const DTicketTicketView: React.FC<IProps> = (props) => {
	const { onSubmit, distributor, dTicketType, monthOfStart } = props;

	const { t } = useTranslation(['dticket']);
	const { userData: user } = useUser();

	const isJobticket = getIsJobticketByType(dTicketType);

	const TicketHeader = (
		<Typography variant="body1" fontWeight={600}>
			{t('subscription_ticket.header')}
		</Typography>
	);

	const TicketContent = (
		<TicketContentBlock>
			{user === null ? (
				<CircularProgress />
			) : (
				<FlexColumn className="space-between">
					<Typography variant="bodyTitle">{dTicketType}</Typography>
					<Typography variant="body1">
						{t('subscription_ticket.from_date', {
							startDate: monthOfStart
								? dayjs(monthOfStart).format('01.MM.YYYY')
								: '',
						})}
					</Typography>
					<Typography variant="body1">
						{t('subscription_ticket.for_subscriber', {
							subscriber: user
								? isJobticket
									? `${user.metaData?.jobticketFirstName} ${user.metaData?.jobticketLastName}`
									: `${user.firstName} ${user.lastName}`
								: '',
						})}
					</Typography>
				</FlexColumn>
			)}
			<Typography variant="body2" color="neutral.80">
				{t('subscription_ticket.validation')}
			</Typography>
			<Typography variant="body2" color="neutral.80">
				{t('subscription_ticket.source_of_purchase', {
					distributor: distributor,
				})}
			</Typography>
		</TicketContentBlock>
	);

	const TicketFooter = (
		<TicketSubmitWrapper>
			<MplButton
				testId="dTicketTicketView-showButton"
				endIcon={<ArrowRightIcon />}
				variant="text"
				onClick={onSubmit}
			>
				{t('subscription_ticket.submit_button')}
			</MplButton>
		</TicketSubmitWrapper>
	);

	return (
		<Ticket
			header={TicketHeader}
			content={TicketContent}
			footer={TicketFooter}
			testId="dTicketTicketView-ticket"
		/>
	);
};
