import { createTheme } from '@mui/material';

import { theme } from '@mopla/mui';

export const experimentalTheme = createTheme({
	...theme,
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					fontFamily: '"Palanquin", sans-serif',
					fontStyle: 'normal',
					fontSize: 20,
					lineHeight: '24px',
					borderRadius: 8,
					textTransform: 'none',

					...(ownerState.variant === 'outlined' && {
						'&, &:hover': {
							border: `2px solid ${
								theme.palette[ownerState.color || 'primary'].main
							}`,
						},
					}),

					'&.Mui-disabled': {
						...(ownerState.variant === 'contained' && {
							color: theme.palette.neutral[80],
							backgroundColor: theme.palette.neutral[40],
						}),

						...(ownerState.variant === 'outlined' && {
							color: theme.palette.neutral[80],
							border: `2px solid ${theme.palette.neutral[40]}`,
						}),

						...(ownerState.variant === 'text' && {
							color: theme.palette.neutral[80],
						}),
					},
				}),
				sizeLarge: {
					fontWeight: 700,
					padding: '16px 22px',
				},
				sizeSmall: {
					fontWeight: 400,
					padding: '8px 16px',
				},
			},
			defaultProps: {
				type: 'button',
				variant: 'contained',
				color: 'primary',
				size: 'large',
				fullWidth: true, //TODO MC-5190 change it to false and refactor the cases where the true is needed
			},
			variants: [
				{
					props: { variant: 'contained', color: 'secondary' },
					style: ({ theme }) => ({
						':hover': {
							color: theme.palette.neutral[0],
						},
					}),
				},
			],
		},
	},
});
