import { ComponentType } from 'react';

export function getDisplayName<T>(WrappedComponent: ComponentType<T>) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function wrapDisplayName<T>(
	WrappedComponent: ComponentType<T>,
	wrapping: string
) {
	const wrappedDisplayName = getDisplayName(WrappedComponent);
	return `${wrapping}(${wrappedDisplayName})`;
}
