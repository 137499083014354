import {
	FC,
	Fragment,
	ReactElement,
	ReactNode,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { formatTime } from '@mopla/utils';

import { DropdownIcon } from '../../../';

import { useLegDetailsContext } from './context';
import { MiddleCol, RightCol, StopsDropdownHeader, TextStyled } from './styled';
import { ILegDetailsProps } from './types';

interface IRenderParts {
	header: ReactNode;
	content: ReactNode;
}

interface IIntermediateStopsRenderProps extends ILegDetailsProps {
	children(renderParts: IRenderParts): ReactElement;
}

export const IntermediateStopsRender: FC<IIntermediateStopsRenderProps> = ({
	children,
	leg,
}) => {
	const { t } = useTranslation('searchResults');
	const [openStops, setOpenStops] = useState(false);
	const { isStartEndDateFix } = useLegDetailsContext();

	const renderParts = useMemo<IRenderParts>(() => {
		const result: IRenderParts = {
			header: null,
			content: null,
		};

		if (!leg.intermediateStops?.length) {
			return result;
		}

		result.header = (
			<StopsDropdownHeader
				data-testid="search-results-intermediateStops-dropdown"
				onClick={() => {
					setOpenStops((p) => !p);
				}}
			>
				<TextStyled variant="body2" color="inherit" noWrap>
					{t('common:text.stops', {
						count: leg.intermediateStops.length,
					})}
				</TextStyled>
				<DropdownIcon isOpened={openStops} />
			</StopsDropdownHeader>
		);

		if (openStops) {
			result.content = (
				<>
					{leg.intermediateStops.map((stop, index, arr) => {
						const hasMoreRows = index + 1 < arr.length;
						return (
							<Fragment key={index}>
								<MiddleCol sx={{ mt: 1, mb: hasMoreRows ? 1 : undefined }}>
									<Typography variant="body2" color="neutral.80">
										{formatTime(stop.arrival || '')}
									</Typography>
								</MiddleCol>
								<RightCol sx={{ mt: 1, mb: hasMoreRows ? 1 : undefined }}>
									<Typography
										data-testid="search-results-intermediateStop-item"
										variant="body2"
										color="neutral.80"
									>
										{stop?.name}
									</Typography>
								</RightCol>
							</Fragment>
						);
					})}
				</>
			);
		}
		return result;
	}, [isStartEndDateFix, leg.intermediateStops, openStops, t]);

	return children(renderParts);
};
