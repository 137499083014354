import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { NOW } from '@mopla/constants';
import { ESubscriptionStatus, TSubscription } from '@mopla/data-models';

export const useSubscriptionCancellationDate = (
	subscription: TSubscription
) => {
	/**
	 * case {isPausedSubscription} - the end of the month in which the pause also ends. In other words, the pause lasts until the end of the month.
	 * case {isPreorderedSubscription} - the end of the month preceding the subscription start.
	 * in other cases (active subscription) - the end of the current month
	 */
	const result = useMemo(() => {
		const isPausedSubscription =
			subscription?.status === ESubscriptionStatus.PAUSED;
		const isPreorderedSubscription =
			subscription?.status === ESubscriptionStatus.PREORDERED;
		const isActiveSubscription =
			subscription?.status === ESubscriptionStatus.ACTIVE;
		const isInvalidSubscription =
			subscription?.status === ESubscriptionStatus.INVALID;

		const endOfNextMonth = dayjs().add(1, 'month').endOf('month');
		const endOfThisMonth = dayjs().endOf('month');
		const startOfNextMonth = dayjs().add(1, 'month').startOf('month');
		let minDateToCancel: Dayjs | typeof NOW = NOW;

		const isNextMonthPaused = dayjs(subscription?.pauseStart).isSame(
			startOfNextMonth,
			'day'
		);

		const lastDayBeforePreorderStarts =
			subscription && dayjs(subscription.subscriptionStart).subtract(1, 'd');

		const isLastDayBeforePreorderStarts = isTodaySameDateAs(
			lastDayBeforePreorderStarts
		);

		if (isPausedSubscription) {
			if (isTodaySameDateAs(subscription.pauseEnd)) {
				minDateToCancel = endOfNextMonth;
			} else {
				minDateToCancel = NOW;
			}
		} else if (isPreorderedSubscription) {
			if (isTodaySameDateAs(lastDayBeforePreorderStarts)) {
				minDateToCancel = endOfNextMonth;
			} else {
				minDateToCancel = NOW;
			}
		} else if (isActiveSubscription) {
			if (isNextMonthPaused) {
				minDateToCancel = endOfThisMonth;
			} else if (isTodaySameDateAs(endOfThisMonth)) {
				minDateToCancel = endOfNextMonth;
			} else {
				minDateToCancel = endOfThisMonth;
			}
		} else if (isInvalidSubscription) {
			if (isTodaySameDateAs(endOfThisMonth)) {
				minDateToCancel = endOfNextMonth;
			} else {
				minDateToCancel = endOfThisMonth;
			}
		}

		return { minDateToCancel, isLastDayBeforePreorderStarts };
	}, [subscription]);

	return result;
};

function isTodaySameDateAs(date: Dayjs | string | null) {
	if (!date) {
		return null;
	}

	return dayjs().startOf('day').isSame(date, 'day');
}
