import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { deleteVoucher, useBusinessLayer } from '@mopla/business-logic';
import { ISubscriptionOfferVoucher } from '@mopla/data-models';
import { FlexColumn, FlexContainer, TrashIcon } from '@mopla/ui';

import { ContentBlock } from './steps/ContentBlock';
import { CleanVoucherCodeButton, Wrapper } from './DTicketSubscription.styles';

interface IVoucherBlockProps {
	subscriptionOfferVoucher: ISubscriptionOfferVoucher;
}

export const VoucherBlock: FC<IVoucherBlockProps> = ({
	subscriptionOfferVoucher,
}) => {
	const { t } = useTranslation(['dticket']);
	const { voucherShortDescription, voucherCode } = subscriptionOfferVoucher;
	const bl = useBusinessLayer();

	const deleteVoucherHandler = useCallback(() => {
		bl.dispatch(deleteVoucher({ voucherCode }));
	}, [bl, voucherCode]);

	return (
		<ContentBlock headerText={t('header.voucher_title')}>
			<Wrapper>
				<FlexColumn className="space-between" style={{ gap: 4 }}>
					<FlexContainer className="space-between">
						<Typography variant="body1">{t('text.discount_code')}</Typography>
						<Typography variant="body1">{voucherCode}</Typography>
					</FlexContainer>
					<Typography variant="caption" color="neutral.60">
						{voucherShortDescription}
					</Typography>
					<div style={{ alignSelf: 'flex-start' }}>
						<CleanVoucherCodeButton
							data-testid="voucher-deleteButton"
							type="button"
							onClick={deleteVoucherHandler}
						>
							{t('button.delete_voucher')}
							<TrashIcon width={24} height={24} />
						</CleanVoucherCodeButton>
					</div>
				</FlexColumn>
			</Wrapper>
		</ContentBlock>
	);
};
