import React, { ReactElement, useEffect, useState } from 'react';

export const OnlineStatusContext = React.createContext(window.navigator.onLine);

export const NetworkStatusProvider: React.FC<{ children: ReactElement }> = ({
	children,
}) => {
	const [onlineStatus, setOnlineStatus] = useState<boolean>(
		window.navigator.onLine
	);

	useEffect(() => {
		const handleSetOnline = () => {
			setOnlineStatus(true);
		};

		const handleSetOffline = () => {
			setOnlineStatus(false);
		};
		window.addEventListener('offline', handleSetOffline);
		window.addEventListener('online', handleSetOnline);

		return () => {
			window.removeEventListener('offline', handleSetOffline);
			window.removeEventListener('online', handleSetOnline);
		};
	}, []);

	return (
		<OnlineStatusContext.Provider value={onlineStatus}>
			{children}
		</OnlineStatusContext.Provider>
	);
};
