import * as React from 'react';
import {
	createTheme,
	SimplePaletteColorOptions,
	ThemeOptions,
} from '@mui/material/styles';

type CustomNeutralPalette = {
	0: string;
	20: string;
	40: string;
	60: string;
	80: string;
	100: string;
};

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		contained: true;
		outlined: true;
		text: true;
	}

	interface ButtonPropsColorOverrides {
		primaryRevert: true;
		inherit: false;
	}
}

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
		'neutral.0': true;
		'neutral.20': true;
		'neutral.40': true;
		'neutral.60': true;
		'neutral.80': true;
		'neutral.100': true;
	}
}

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xs: false; // removes the initial breakpoints
		sm: false;
		md: false;
		lg: false;
		xl: false;
		mobile: true;
		tabletP: true; //Portrait
		tabletL: true; //Landscape
		desktop: true;
	}

	interface Palette {
		neutral: CustomNeutralPalette;
		primaryRevert: SimplePaletteColorOptions;
	}

	export interface PaletteOptions {
		neutral?: CustomNeutralPalette;
		primaryRevert: SimplePaletteColorOptions;
	}

	interface TypographyVariants {
		desktopHero: React.CSSProperties;
		desktopTitle: React.CSSProperties;
		title: React.CSSProperties;
		subtitle: React.CSSProperties;
		bodyTitle: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		desktopHero?: React.CSSProperties;
		desktopTitle?: React.CSSProperties;
		title?: React.CSSProperties;
		subtitle?: React.CSSProperties;
		bodyTitle?: React.CSSProperties;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		desktopHero: true;
		desktopTitle: true;
		title: true;
		subtitle: true;
		bodyTitle: true;
	}
}

export const PaletteColors = {
	primary: {
		main: '#1C308B',
		dark: '#101D56',
		light: '#DCE3F9',
		contrastText: '#fff',
	},
	secondary: {
		main: '#6FDB89',
		dark: '#208337',
		light: '#C2F0CD',
		contrastText: '#101D56', //TODO check if this is true
	},
	error: {
		main: '#DA1E28',
		dark: '#CA1C25',
		light: '#E95E65',
		contrastText: '#fff',
	},
	warning: {
		main: '#F16E00',
		dark: '#D66200',
		light: '#FF963D',
		contrastText: '#fff',
	},
	neutral: {
		0: '#FFFFFF',
		20: '#FAFAFA',
		40: '#EBEBEB',
		60: '#B8B8B8',
		80: '#666666',
		100: '#000000',
	},
	text: {
		primary: '#030511',
	},
	primaryRevert: {
		main: '#fff',
		dark: '#FAFAFA',
		light: '#fff',
		contrastText: '#1C308B',
	},
};

export const MOPLA_COLORS = [
	'primary.main',
	'primary.dark',
	'primary.light',
	'secondary.main',
	'secondary.dark',
	'secondary.light',
	'error.main',
	'error.dark',
	'error.light',
	'warning.main',
	'warning.dark',
	'warning.light',
	'neutral.0',
	'neutral.20',
	'neutral.40',
	'neutral.60',
	'neutral.80',
	'neutral.100',
	'text.primary',
] as const;

export type TMoplaColors = typeof MOPLA_COLORS[number];

const themeConfig: ThemeOptions = {
	breakpoints: {
		values: {
			mobile: 0,
			tabletP: 768,
			tabletL: 1024,
			desktop: 1440,
		},
	},
	palette: PaletteColors,
	components: {
		MuiIconButton: {
			styleOverrides: {
				root: ({ theme, ownerState: { color } }) => ({
					...(color === 'neutral.0' && { color: theme.palette.neutral[0] }),
					...(color === 'neutral.20' && { color: theme.palette.neutral[20] }),
					...(color === 'neutral.40' && { color: theme.palette.neutral[40] }),
					...(color === 'neutral.60' && { color: theme.palette.neutral[60] }),
					...(color === 'neutral.80' && { color: theme.palette.neutral[80] }),
					...(color === 'neutral.100' && { color: theme.palette.neutral[100] }),
				}),
				sizeLarge: {
					fontSize: '35px',
				},
				sizeMedium: {
					fontSize: '24px',
				},
				sizeSmall: {
					fontSize: '20px',
				},
			},
			defaultProps: {
				color: 'primary',
			},
		},
		MuiSvgIcon: {
			defaultProps: {
				fontSize: 'inherit',
			},
			styleOverrides: {
				fontSizeLarge: {
					fontSize: '35px',
				},
				fontSizeMedium: {
					fontSize: '24px',
				},
				fontSizeSmall: {
					fontSize: '20px',
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&, &.Mui-checked': {
						color: theme.palette.primary.main,
					},
				}),
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&, &.Mui-checked': {
						color: theme.palette.primary.main,
					},
					'&.Mui-disabled': {
						color: theme.palette.neutral[60],
					},
				}),
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 1,
			},
			styleOverrides: {
				rounded: {
					borderRadius: 10,
				},
				/**
				 * This is not specified in our DS, although can possibly be in the future.
				 * For now, lets use elevation1 by default
				 * */
				elevation1: {
					boxShadow: '4px 4px 6px 0px rgba(0, 0, 0, 0.1)',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderColor: theme.palette.neutral[60],
				}),
				light: ({ theme }) => ({
					borderColor: theme.palette.neutral[40],
				}),
			},
		},
		/**
		 * This is a temporary workaround for a problem caused by FocusTrap mui's internal component
		 * https://github.com/mui/material-ui/issues/17497
		 * Problem happens, when those conditions are met:
		 *  * FullscreenOverlay component is opened
		 *  * InfoModal is also opened, but it is mounted outside the FullscreenOverlay children
		 *  * any input is mounted in the InfoModal. This input won't be able to focus
		 * Consider reimplementing InfoModal and using mui's Dialog component inside.
		 * */
		MuiModal: {
			defaultProps: {
				disableEnforceFocus: true,
			},
		},
		MuiSwipeableDrawer: {
			defaultProps: {
				disableSwipeToOpen: true,
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					'& .MuiFormControlLabel-label.Mui-disabled': {
						color: 'unset',
					},
				},
			},
			defaultProps: {
				slotProps: {
					typography: {
						variant: 'body2',
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					...theme.typography.body1,
					fontWeight: 700,
					textTransform: 'unset',
					border: '2px solid',
					borderRadius: 8,
					padding: '16px 11px',
				}),
			},
			variants: [
				{
					props: { color: 'primary' },
					style: ({ theme }) => ({
						borderColor: theme.palette.primary.main,
						color: theme.palette.primary.main,

						'&.Mui-selected, &.Mui-selected:hover': {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.neutral[0],
						},
					}),
				},
			],
		},
	},
	typography: {
		fontFamily: '"Palanquin", sans-serif',
		h1: {
			fontFamily: '"Poppins", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '38px',
			lineHeight: '57px',
		},
		h3: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '24px',
			lineHeight: '24px',
		},
		desktopHero: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '80px',
			lineHeight: '76px',
		},
		desktopTitle: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '32px',
			lineHeight: '40px',
		},
		title: {
			fontFamily: '"Poppins", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '24px',
			lineHeight: '28px',
		},
		subtitle: {
			fontFamily: '"Poppins", sans-serif',
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '20px',
		},
		bodyTitle: {
			fontFamily: '"Poppins", sans-serif',
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '20px',
			lineHeight: '24px',
		},
		body1: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '20px',
			lineHeight: '24px',
		},
		body2: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '20px',
		},
		caption: {
			fontFamily: '"Palanquin", sans-serif',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: '16px',
		},
	},
};

export const theme = createTheme(themeConfig);
export type TTheme = typeof theme;
