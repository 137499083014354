import { actionGenerator } from '@mopla/utils';

export interface PushAction {
	type: PushActionTypes;
}

export enum PushActionTypes {
	InitPassengerPushes = '[Push] Init Passenger Pushes',
	AddPushToken = '[Push] Add Push Token',
	TripDelayed = '[Push] Trip Delayed',
}

export const initPassengerPushes =
	actionGenerator<PushActionTypes.InitPassengerPushes>(
		PushActionTypes.InitPassengerPushes
	);

interface Payload {
	token: string;
}
export const addPushToken = actionGenerator<
	PushActionTypes.AddPushToken,
	Payload
>(PushActionTypes.AddPushToken);

export const tripDelayed = actionGenerator<PushActionTypes.TripDelayed>(
	PushActionTypes.TripDelayed
);

export type InitPassengerPushesAction = ReturnType<typeof initPassengerPushes>;
export type AddPushTokenAction = ReturnType<typeof addPushToken>;
export type TripDelayedAction = ReturnType<typeof tripDelayed>;
