import React, { FC, ReactNode, useState } from 'react';
import { Collapse, IconButton, Typography } from '@mui/material';

import { DropdownIcon } from '../../';

import { Root } from './styled';

export interface ICollapsibleCardProps {
	initialOpen?: boolean;
	title: string;
	icon?: ReactNode;
	content: ReactNode;
}

export const CollapsibleCard: FC<ICollapsibleCardProps> = ({
	initialOpen,
	title,
	content,
	icon,
}) => {
	const [isOpened, setIsOpened] = useState(initialOpen || false);

	return (
		<Root>
			{icon && <div className="icon">{icon}</div>}
			<div className="title">
				<Typography variant="subtitle" color="primary.dark">
					{title}
				</Typography>
			</div>
			<div className="toggle">
				<IconButton size="small" onClick={() => setIsOpened((prev) => !prev)}>
					<DropdownIcon isOpened={isOpened} />
				</IconButton>
			</div>
			<Collapse className="content" unmountOnExit in={isOpened}>
				{content}
			</Collapse>
		</Root>
	);
};
