import { FC, SVGProps, useContext, useEffect, useRef, useState } from 'react';
import { Browser } from '@capacitor/browser';
import * as Sentry from '@sentry/capacitor';

import { BusinessLayerContext } from '@mopla/business-logic';
import { useI18nLanguage } from '@mopla/utils';

import { Loader } from '../../atoms/loader/Loader';
import { ErrorModal } from '../../organisms/ErrorModal/ErrorModal';

import { WalletButton } from './GoogleWalletButton.styles';

interface IProps {
	ticketId: string;
}

interface IGoogleWalletResponse {
	link: string;
}

interface ILazySvg {
	loading: boolean;
	error: Error | undefined;
	SVG: FC<SVGProps<SVGSVGElement>> | undefined;
}

const useLazySvg = (language = 'en'): ILazySvg => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();
	const Svg = useRef<FC<SVGProps<SVGSVGElement>>>();

	useEffect(() => {
		setLoading(true);
		(async () => {
			try {
				switch (language) {
					case 'cs_CZ':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/cz_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'de':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/de_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'es':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/esES_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'fr':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/frFR_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'it':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/it_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'pl_PL':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/pl_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'tr':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/tr_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'uk_UA':
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/uk_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
						break;
					case 'en':
					default:
						Svg.current = (
							await import(
								`../../icons/GoogleWallet/enGB_add_to_google_wallet_add-wallet-badge.svg`
							)
						).default;
				}
			} catch (e) {
				setError(e as Error);
			} finally {
				setLoading(false);
			}
		})();
	}, [language]);

	return {
		loading,
		error,
		SVG: Svg.current,
	};
};

export const GoogleWalletButton: FC<IProps> = ({ ticketId }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const businessLayer = useContext(BusinessLayerContext);
	const language = useI18nLanguage();

	const handleClick = async () => {
		setLoading(true);
		setError(false);
		try {
			const response = await businessLayer.api.get<IGoogleWalletResponse>(
				`/api/passengers/googleWallet?ticketId=${ticketId}`
			);
			Browser.open({ url: response.link });
		} catch (e) {
			Sentry.captureException(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	const {
		SVG: GoogleWallet,
		loading: SVGLoading,
		error: SVGError,
	} = useLazySvg(language);
	if (loading || SVGLoading) {
		return <Loader />;
	}

	return (
		<>
			{(error || SVGError) && <ErrorModal onSubmit={() => setError(false)} />}
			{GoogleWallet && (
				<WalletButton onClick={handleClick}>
					<GoogleWallet></GoogleWallet>
				</WalletButton>
			)}
		</>
	);
};
