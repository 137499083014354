import { FC, ReactNode } from 'react';

import { useBreakpoints } from '@mopla/utils';

import { Footer } from '../../';

import { Root, TopBlockWrapper } from './styled';

export interface IAdaptiveActionsBlockProps {
	topBlock?: ReactNode;
	mainBlock: ReactNode;
	enforceMobile?: boolean;
	enforceAboveMobile?: boolean;
	testId?: string;
}

export const AdaptiveActionsBlock: FC<IAdaptiveActionsBlockProps> = ({
	topBlock,
	mainBlock,
	enforceMobile,
	enforceAboveMobile,
	testId = 'adaptiveActionsBlock',
}) => {
	const { isMobile } = useBreakpoints({ enforceMobile, enforceAboveMobile });

	return (
		<Root data-testid={testId} className={isMobile ? 'mobile' : 'aboveMobile'}>
			{topBlock && <TopBlockWrapper>{topBlock}</TopBlockWrapper>}
			<Footer
				background={isMobile ? 'primary.main' : 'neutral.0'}
				enforceMobile={enforceMobile}
				enforceAboveMobile={enforceAboveMobile}
				borderTop={false}
			>
				{mainBlock}
			</Footer>
		</Root>
	);
};
