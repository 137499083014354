import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { intervalToDuration } from 'date-fns';

import { Itinerary, PassengerDetails, TransportType } from '@mopla/data-models';
import {
	checkTimeMightChange,
	formatTime,
	getItineraryTransportTypes,
	prepareItineraryOperators,
} from '@mopla/utils';

import {
	ArrowDownIcon,
	AttentionIcon,
	CancellationRulesDropdown,
	ItineraryDateTimeInfo,
	PriceBlock,
	RidePointIcon,
	TravelTypeSymbols,
} from '../../';

import {
	CancellationRulesWrapper,
	CollapsibleButton,
	IconWrapper,
	PriceWrapper,
	Root,
	RouteDot,
	RouteLine,
	StationName,
	StationTime,
	StationWrapper,
	Tariff,
	TimeMightChangeMessage,
	TitleWrapper,
	WalkWrapper,
} from './RouteSuggestion.styles';

export interface RouteSuggestionProps {
	passengersList?: PassengerDetails[];
	itinerary: Itinerary;
	onDetailsClick: () => void;
	showAttention?: boolean;
	showPrice?: boolean;
	showOperators?: boolean;
	showCancellationRules?: boolean;
	collapsible?: boolean;
	initiallyCollapsed?: boolean;
	className?: string;
}

const useItineraryDerivedData = (itinerary: Itinerary) => {
	const { t } = useTranslation(['searchResults', 'common', 'booking']);

	return useMemo(() => {
		const timeMightChange = checkTimeMightChange(itinerary);

		// sum of all entry and exit stops of every leg (without duplicates)
		const entryStops =
			itinerary?.legs
				?.filter((l) => l.mode !== TransportType.WALK)
				.map((l) => l.from.name) || [];
		const exitStops =
			itinerary?.legs
				?.filter((l) => l.mode !== TransportType.WALK)
				.map((l) => l.to.name) || [];

		const rideBoundaryStopsCount = new Set([...entryStops, ...exitStops]).size;

		// sum of all intermediate stops of all legs of trip
		const intermediateStopsCount = itinerary?.legs?.reduce(
			(sum, l) => sum + (l.intermediateStops?.length || 0),
			0
		);

		// sum of all stops of all legs of trip
		const stopsCount = rideBoundaryStopsCount + intermediateStopsCount;

		// if stops count is zero we don't display the stops count (this should never be the case)
		const stopsText =
			stopsCount === 0
				? ''
				: `, ${t('common:text.stops', {
						count: stopsCount,
				  })}`;

		const transportTypes = getItineraryTransportTypes(itinerary, [
			TransportType.TRANSFER_WALK,
		]);

		const walkBefore =
			itinerary?.legs?.length && itinerary.legs[0].mode === TransportType.WALK;
		const walkBeforeDistance = walkBefore
			? Math.round(
					Number(itinerary?.legs?.length ? itinerary?.legs[0].distance : 0) /
						100
			  ) / 10
			: 0;
		const walkBeforeDuration = walkBefore
			? Math.round(
					Number(itinerary?.legs?.length ? itinerary.legs[0].duration : 0) / 60
			  )
			: 0;
		const walkBeforeString = `${t(
			'common:text.around'
		)} ${walkBeforeDuration} ${t('common:text.minutes')} ${t(
			'common:text.toWalk'
		)} (${walkBeforeDistance} ${t('common:text.km')})`;
		const walkBeforeStartTime = formatTime(
			new Date(
				itinerary?.legs?.length
					? (itinerary.legs[0].startDateTime as string)
					: ''
			)
		);

		const detailsLegs = itinerary.legs || [];

		const walkAfter =
			detailsLegs[detailsLegs.length - 1].mode === TransportType.WALK;
		const walkAfterDistance = walkAfter
			? Math.round(
					Number(detailsLegs[detailsLegs.length - 1]?.distance || 0) / 100
			  ) / 10
			: 0;
		const walkAfterDuration = walkAfter
			? Math.round(
					Number(detailsLegs[detailsLegs.length - 1]?.duration || 0) / 60
			  )
			: 0;
		const walkAfterString = `${t(
			'common:text.around'
		)} ${walkAfterDuration} ${t('common:text.minutes')} ${t(
			'common:text.toWalk'
		)} (${walkAfterDistance} ${t('common:text.km')})`;
		const walkAfterStartTime = formatTime(
			new Date(detailsLegs[detailsLegs.length - 1].startDateTime || '')
		);

		const legForStartTime = walkBefore
			? itinerary?.legs?.[1]
			: itinerary?.legs?.[0];

		const legForEndTime = walkAfter
			? itinerary?.legs?.[itinerary?.legs?.length - 2]
			: itinerary?.legs?.[itinerary?.legs?.length - 1];

		const tripDuration = intervalToDuration({
			start: new Date(legForStartTime?.startDateTime || '') || 0,
			end: new Date(legForEndTime?.endDateTime || '') || 0,
		});

		const firstStation = walkBefore ? detailsLegs[1] : detailsLegs[0];
		const firstStationDateTime = formatTime(
			new Date(firstStation?.startDateTime || '')
		);
		const firstStationName = firstStation?.from?.name;
		const firstStationLocationType = firstStation?.from?.locationType;

		const lastStation = walkAfter
			? detailsLegs[detailsLegs.length - 2]
			: detailsLegs[detailsLegs.length - 1];
		const lastStationDateTime = formatTime(
			new Date(lastStation?.endDateTime || '')
		);
		const lastStationName = lastStation?.to?.name;
		const lastStationLocationType = lastStation?.to?.locationType;

		const preparedLBODTFlexAgencies = prepareItineraryOperators<string>(
			itinerary,
			(o) => t('tickets:text.operator', { operator: o.join(', ') })
		);

		return {
			tripDuration,
			stopsText,
			transportTypes,
			timeMightChange,
			walkBefore,
			walkBeforeStartTime,
			walkBeforeString,
			firstStationDateTime,
			firstStationName,
			lastStationDateTime,
			lastStationName,
			walkAfter,
			walkAfterStartTime,
			walkAfterString,
			preparedLBODTFlexAgencies,
			firstStationLocationType,
			lastStationLocationType,
		};
	}, [itinerary, t]);
};

export const RouteSuggestion: React.FC<RouteSuggestionProps> = ({
	passengersList,
	itinerary,
	onDetailsClick,
	showAttention = true,
	showPrice = true,
	showOperators,
	showCancellationRules,
	collapsible,
	initiallyCollapsed,
	className,
}) => {
	const { t } = useTranslation(['searchResults', 'common', 'booking']);
	const itineraryData = useItineraryDerivedData(itinerary);
	const [isCollapsed, setIsCollapsed] = useState(!!initiallyCollapsed);

	const toggleCollapsed: React.MouseEventHandler = useCallback((e) => {
		e.stopPropagation();
		setIsCollapsed((p) => !p);
	}, []);

	const priceBlock = <PriceBlock legs={itinerary.legs} />;

	return (
		<Root
			className={className}
			onClick={onDetailsClick}
			data-testid="route-briefDescription-block"
		>
			<TitleWrapper collapsed={isCollapsed}>
				<ItineraryDateTimeInfo
					className="datetime"
					route={itinerary}
					dateTestId="route-routeDate-block"
					timeTestId="route-duration-timeInterval-block"
					durationTestId="route-routeDuration-block"
				/>
				{!collapsible && (
					<IconWrapper>
						<TravelTypeSymbols transportTypes={itineraryData.transportTypes} />
					</IconWrapper>
				)}
				{collapsible && (
					<IconWrapper>
						<CollapsibleButton
							collapsed={isCollapsed}
							onClick={toggleCollapsed}
							type="button"
						>
							<ArrowDownIcon />
						</CollapsibleButton>
					</IconWrapper>
				)}
				{isCollapsed && <PriceWrapper>{priceBlock}</PriceWrapper>}
			</TitleWrapper>
			{!isCollapsed && (
				<>
					{showAttention && itineraryData.timeMightChange && (
						<TimeMightChangeMessage
							icon={<AttentionIcon />}
							message={t('common:text.attentionMessage')}
						/>
					)}
					{itineraryData.walkBefore && (
						<WalkWrapper data-testid="search-results-walkBefore-block">
							<span>{itineraryData.walkBeforeStartTime}</span>
							<span>{itineraryData.walkBeforeString}</span>
						</WalkWrapper>
					)}
					<StationWrapper data-testid="route-firstStation-block">
						<StationTime>{itineraryData.firstStationDateTime}</StationTime>
						<RidePointIcon pointType={itineraryData.firstStationLocationType} />
						<StationName>{itineraryData.firstStationName}</StationName>
					</StationWrapper>
					<RouteLine>
						<RouteDot />
						<RouteDot />
						<RouteDot />
						<RouteDot />
					</RouteLine>
					<StationWrapper data-testid="route-lastStation-block">
						<StationTime>{itineraryData.lastStationDateTime}</StationTime>
						<RidePointIcon pointType={itineraryData.lastStationLocationType} />
						<StationName>{itineraryData.lastStationName}</StationName>
					</StationWrapper>
					{itineraryData.walkAfter && (
						<WalkWrapper
							bottom={true}
							data-testid="search-results-walkAfter-block"
						>
							<span>{itineraryData.walkAfterStartTime}</span>
							<span>{itineraryData.walkAfterString}</span>
						</WalkWrapper>
					)}
				</>
			)}
			{showOperators && (
				<Typography variant="caption" color="neutral.80" sx={{ mt: 2 }}>
					{itineraryData.preparedLBODTFlexAgencies}
				</Typography>
			)}
			{showPrice && !isCollapsed && (
				<Tariff data-testid="search-results-show-details-action">
					{priceBlock}
				</Tariff>
			)}
			{showCancellationRules && (
				<CancellationRulesWrapper>
					<CancellationRulesDropdown
						itinerary={itinerary}
						passengersList={passengersList}
					/>
				</CancellationRulesWrapper>
			)}
		</Root>
	);
};
