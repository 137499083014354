import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'greenLine',
})<{ greenLine: boolean }>(({ theme, greenLine }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	width: '100%',
	boxSizing: 'border-box',
	padding: '0 24px',
	position: 'relative',

	'&:before': {
		content: '" "',
		width: greenLine ? '4px' : 0,
		height: '100%',
		backgroundColor: theme.palette.secondary.main,
		position: 'absolute',
		top: 0,
		left: 0,
		borderRadius: '0 8px 8px 0',
	},
}));

export const HeaderWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridTemplateRows: 'max-content',
	alignItems: 'center',
	marginBottom: '24px',

	'& > div:first-of-type': {
		alignSelf: 'center',
	},
}));

export const TitleWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridTemplateRows: 'max-content',
	marginLeft: 14,
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	maxWidth: '80%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.neutral[80],
}));

export const MainContentWrapper = styled('div')(() => ({
	maxWidth: 'auto',
}));

export const AddressWalkWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const HomeAddressWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: '8px',
	marginLeft: 24,
}));

export const IntermediateStopsWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	...theme.typography.body2,
	color: theme.palette.neutral[80],
	marginBottom: 24,
	marginLeft: 95,
}));

export const AgencyName = styled(Typography)(() => ({
	display: 'block',
	marginLeft: 60,
	marginTop: 24,
	marginBottom: 16,
}));

export const PriceWrapper = styled('div')(() => ({
	marginTop: 24,
	textAlign: 'right',
}));

export const MapBlockWrapper = styled('div')(({ theme }) => ({
	background: theme.palette.neutral[0],
	borderRadius: 8,
	width: '100%',
	display: 'grid',
	gridGap: 16,
	marginTop: 24,
}));
