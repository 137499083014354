import { styled } from '@mui/material/styles';

import { MplButton } from '../../atoms/Button';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',

	'.MuiFormControl-root': {
		marginBottom: '21px',
	},
}));

export const ForgotPasswordButton = styled(MplButton)(() => ({
	alignSelf: 'flex-start',
	marginBottom: 24,
}));

export const SubmitLevel = styled('div', {
	shouldForwardProp: (prop) => prop !== 'browser',
})<{ browser?: boolean }>(({ theme, browser }) => ({
	position: browser ? 'static' : 'relative',
	left: -24,
	bottom: -24,
	width: '100%',
	padding: browser ? '' : '16px 24px',
	marginTop: browser ? '24px' : 'auto',
	backgroundColor: browser ? 'unset' : theme.palette.neutral[0],
	borderRadius: browser ? 'unset' : '8px 8px 0 0',
}));
