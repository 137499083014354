import { memo } from 'react';

import { TransportType } from '@mopla/data-models';

import { TransportCircle } from './TransportCircle';
import { Root } from './TravelTypeSymbols.styles';

export interface TravelTypeSymbolsProps {
	transportTypes: TransportType | TransportType[];
	variant?: 'midGrey' | 'main' | 'disabled';
	large?: boolean;
}

export const TravelTypeSymbols = memo<TravelTypeSymbolsProps>(
	({ transportTypes, variant = 'main', large = false }) => {
		const _transportTypes = Array.isArray(transportTypes)
			? transportTypes
			: [transportTypes];

		return (
			<Root>
				{_transportTypes.map((tt, index) => (
					<TransportCircle
						key={index}
						variant={variant}
						large={large}
						transportType={tt}
					/>
				))}
			</Root>
		);
	}
);
