import { useEffect, useState } from 'react';
import H from '@here/maps-api-for-javascript';

import { ILocationDecoded } from '@mopla/data-models';
import { getLocationCategory } from '@mopla/utils';

import { hereSearch } from '../services/hereapi';

interface IResult {
	items: any[];
}

export const reverseGeocode = (query: string, limit?: number) => {
	return new Promise<ILocationDecoded[]>((resolve, reject) => {
		const parameters: H.service.ServiceParameters = {
			at: query,
			types: ['address'],
		};

		if (limit) {
			parameters['limit'] = limit;
		}

		hereSearch.reverseGeocode(
			parameters,
			(result: any) => {
				resolve(
					(result as IResult).items.map((suggestion: any) => {
						const {
							houseNumber = '',
							street,
							city,
							postalCode,
							stateCode,
							country,
						} = suggestion.address;

						return {
							id: suggestion.id,
							position: suggestion.position,
							title: suggestion.address.label,
							address: {
								postCode: postalCode,
								stateCode,
								street,
								houseNumber,
								city,
								country,
							},
							type: getLocationCategory(suggestion, 'address'),
						};
					})
				);
			},
			(err) => {
				reject(err);
			}
		);
	});
};

export const useConvertLatlong = (query: string) => {
	const [location, setLocation] = useState<ILocationDecoded | null>(null);

	useEffect(() => {
		if (query.length === 0) {
			setLocation(null);
		}
		if (query.length > 0) {
			reverseGeocode(query).then((loc) => {
				setLocation(loc[0]);
			});
		}
	}, [query]);

	return location;
};
