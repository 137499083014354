import { FC } from 'react';

import { TLocationSuggestType } from '@mopla/data-models';

import {
	EmptyStarIcon,
	FilledStartIcon,
	LocationPointIcon,
	LocationStopIcon,
	RailwayPointIcon,
} from '../../icons';

import {
	IconWrapper,
	LocationText,
	Root,
	StarButton,
} from './AddressSuggestion.styles';

export interface AddressSuggestionProps {
	location: string;
	isFavourite?: boolean;
	onFavouriteClick: () => void;
	onSelect: () => void;
	disabled?: boolean;
	type?: TLocationSuggestType;
}

export const AddressSuggestion: FC<AddressSuggestionProps> = ({
	location,
	isFavourite = false,
	onFavouriteClick,
	onSelect,
	disabled = false,
	type = 'address',
}) => {
	return (
		<Root disabled={disabled} data-testid="search-locationForm-suggestion-card">
			<IconWrapper>
				{type === 'address' && <LocationPointIcon />}
				{type === 'railway' && <RailwayPointIcon />}
				{type === 'stop' && <LocationStopIcon />}
			</IconWrapper>
			<LocationText
				disabled={disabled}
				tabIndex={0}
				role="button"
				onClick={!disabled ? onSelect : undefined}
				data-testid="search-locationForm-select-button"
			>
				{location}
			</LocationText>
			<StarButton
				type="button"
				onClick={onFavouriteClick}
				data-testid="search-locationForm-favourite-button"
				data-active={isFavourite}
			>
				{isFavourite ? <FilledStartIcon /> : <EmptyStarIcon />}
			</StarButton>
		</Root>
	);
};
