import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import {
	getAuth,
	isSignInWithEmailLink,
	signInWithEmailLink,
} from 'firebase/auth';

import { personalDetailsManager, useAppLaunched } from '@mopla/business-logic';
import { ErrorModal } from '@mopla/ui';

import passApi from '../../../api/passApi';
import { DTicketSubscription } from '../../organisms/DTicketSubscriptionDrawer/DTicketSubscription';

export const SubscriptionFlowWrapper: FC = () => {
	const [openSubscriptionFlow, setOpenSubscriptionFlow] =
		useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { set } = useAppLaunched();
	const navigate = useNavigate();
	const location = useLocation();
	const auth = getAuth();

	const query = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);

	useEffect(() => {
		const validateUser = async () => {
			const saved_email = localStorage.getItem('emailForSignIn');
			if (!!saved_email && isSignInWithEmailLink(auth, window.location.href)) {
				set({ wasLaunched: true });
				setIsLoading(true);
				const dticketSubscribeFlow = query.has('subscription');
				setOpenSubscriptionFlow(dticketSubscribeFlow);
				try {
					const firebaseUser = await signInWithEmailLink(
						auth,
						saved_email,
						window.location.href
					);
					localStorage.removeItem('emailForSignIn');
					await firebaseUser.user.getIdTokenResult();
					try {
						const whoami = await passApi.get('/api/whoami');
						if (whoami) {
							setIsLoading(false);
							if (!whoami.pleaseComplete) {
								personalDetailsManager.set('completed');
								return !Capacitor.isNativePlatform()
									? navigate({ pathname: '/welcome', search: 'tryOurApp' })
									: navigate('/home/dticket');
							}
							return navigate({
								pathname: '/welcome',
								search: 'personalDetails',
							});
						}
					} catch (err) {
						await passApi.post('/api/passengers/create', {
							authenticationProviderId: firebaseUser.user.uid,
							email: firebaseUser.user.email,
						});
						setIsLoading(false);
					}
				} catch (err) {
					setIsLoading(false);
					setIsError(true);
					setOpenSubscriptionFlow(false);
					console.error('Error on link/passwordless user validation', err);
				}
			}
		};

		validateUser();
	}, [auth, navigate, query]);

	const handleClose = () => {
		setOpenSubscriptionFlow(false);
	};

	if (isError) return <ErrorModal onSubmit={() => setIsError(false)} />;

	if (!openSubscriptionFlow) return null;

	return (
		<DTicketSubscription
			open={openSubscriptionFlow}
			onClose={handleClose}
			loading={isLoading}
		/>
	);
};
