import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { TSearchHeaderVariant } from './SearchHeader';

interface IOwnerState {
	open: boolean;
	variant: TSearchHeaderVariant;
	rounded: boolean;
	transparent?: boolean;
}

export const Root = styled('div')<{ ownerState: IOwnerState }>(
	({ theme, ownerState: { open, variant, rounded, transparent } }) => ({
		background:
			(transparent && 'transparent') ||
			(variant === 'primary' && theme.palette.primary.main) ||
			(variant === 'secondary' && theme.palette.secondary.main) ||
			(variant === 'yellow' && '#FFE66D') ||
			(variant === 'light' && theme.palette.neutral[20]) ||
			(variant === 'white' && theme.palette.neutral[0]) ||
			(variant === 'primary_light' && theme.palette.primary.light) ||
			(variant === 'light_blue' && theme.palette.primary.light) ||
			(variant === 'primary_dark' && theme.palette.primary.dark) ||
			theme.palette.primary.main,
		color:
			(variant === 'primary' && theme.palette.neutral[0]) ||
			(variant === 'secondary' && theme.palette.primary.main) ||
			(variant === 'yellow' && theme.palette.primary.main) ||
			(variant === 'light' && theme.palette.primary.main) ||
			(variant === 'white' && theme.palette.primary.main) ||
			(variant === 'primary_light' && theme.palette.primary.dark) ||
			(variant === 'light_blue' && theme.palette.primary.dark) ||
			(variant === 'primary_dark' && theme.palette.neutral[0]) ||
			theme.palette.neutral[0],
		borderRadius: rounded ? 8 : 0,
		display: 'grid',
		gridTemplateAreas: `
		 "l r"
		 "t t"
		 "c c"
		`,
		gridTemplateColumns: 'min-content min-content',
		gridTemplateRows: 'minmax(56px, max-content) auto auto',
		justifyContent: 'space-between',
		width: '100%',
		height: open ? 'max-content' : '0px',
		overflow: 'hidden',
		'@media(max-width: 900px)': {
			transition: 'height .2s',
		},
		visibility: 'visible',
	})
);

export const TopButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'left',
})<{ left?: boolean }>(({ left }) => ({
	gridArea: left ? 'l' : 'r',
	gridColumnStart: left ? 1 : 2,
	color: 'inherit',
	height: 56,
}));

export const Title = styled('div')(({ theme }) => ({
	...theme.typography.title,
	wordBreak: 'break-word',
	gridArea: 't',
	padding: '8px 24px 24px 24px',
}));

export const ChildrenWrapper = styled('div')(() => ({
	gridArea: 'c',
	padding: '0 24px 27px 24px',
}));
