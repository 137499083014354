import React from 'react';
import { useTranslation } from 'react-i18next';

import { Booking, NamedLocation } from '@mopla/data-models';

import { RidePointIcon } from '../RidePointIcon';

import {
	InfoWrapper,
	Root,
	RouteLine,
	StationWrapper,
	WithLineWrapper,
} from './DriverTripDescription.styles';
import { LegPassCountInfo } from './LegPassCountInfo';

export interface DriverTripDescriptionProps {
	open: boolean;
	rideStarted: boolean;
	startTime: string;
	startPointName: string;
	startAddress?: string;
	startType?: NamedLocation.type;
	endTime: string;
	endPointName: string;
	endAddress?: string;
	endType?: NamedLocation.type;
	enteringBookings: Booking[];
	exitingBookings: Booking[];
}

export const DriverTripDescription: React.FC<DriverTripDescriptionProps> = ({
	open,
	rideStarted,
	startTime,
	startPointName,
	startAddress,
	startType,
	endTime,
	endPointName,
	endAddress,
	endType,
	enteringBookings,
	exitingBookings,
}) => {
	const { t } = useTranslation('checkIn');

	return (
		<Root data-testid="trip-description-block">
			<StationWrapper
				data-testid="trip-description-departure-station"
				open={open}
				rideStarted={rideStarted}
				specialNeedsCount={
					enteringBookings.filter(
						(booking: Booking) => booking.specialNeeds !== 'NONE'
					).length
				}
				withoutSpecialNeedsCount={
					enteringBookings.filter(
						(booking: Booking) => booking.specialNeeds === 'NONE'
					).length
				}
			>
				<InfoWrapper sx={{ pb: 2 }}>
					<span data-testid="trip-description-departure-time">{startTime}</span>
					<span>{t('text.departure')}</span>
				</InfoWrapper>
				<WithLineWrapper>
					<RidePointIcon pointType={startType} />
					<RouteLine open={open} rideStarted={rideStarted} />
				</WithLineWrapper>
				<InfoWrapper
					sx={{ pb: 2 }}
					data-testid="trip-description-departure-address"
				>
					<span className="point_name">{startPointName}</span>
					{startAddress && (
						<span className="point_address">{startAddress}</span>
					)}
					<LegPassCountInfo bookings={enteringBookings} isEntering />
				</InfoWrapper>
			</StationWrapper>
			<StationWrapper data-testid="trip-description-arrival-station">
				<InfoWrapper>
					<span data-testid="trip-description-arrival-time">{endTime}</span>
					<span>{t('text.arrival')}</span>
				</InfoWrapper>
				<RidePointIcon pointType={endType} />
				<InfoWrapper data-testid="trip-description-arrival-address">
					<span className="point_name">{endPointName}</span>
					{endAddress && <span className="point_address">{endAddress}</span>}
					<LegPassCountInfo bookings={exitingBookings} />
				</InfoWrapper>
			</StationWrapper>
		</Root>
	);
};

export default DriverTripDescription;
