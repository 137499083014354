import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Root = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean; index: number; type: string }>(
	({ theme, open, index, type }) => ({
		zIndex: 999,
		position: 'absolute',
		bottom: `${60 + index * (56 + 20)}px`,
		transition: 'bottom .35s',
		left: '50%',
		transform: 'translate(-50%, 0)',
		width: 'calc(100% - 32px)',
		maxWidth: 568,
		display: 'grid',
		gridAutoFlow: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: 16,
		boxSizing: 'border-box',
		...theme.typography.body2,
		fontWeight: 600,
		backgroundColor: theme.palette.neutral[40],
		color:
			(type === 'INFO' && theme.palette.primary.main) ||
			(type === 'ERROR' && theme.palette.error.main) ||
			(type === 'SUCCESS' && theme.palette.secondary.main) ||
			theme.palette.primary.main,
		boxShadow:
			'0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
		borderRadius: 8,

		svg: {
			marginRight: 8,
		},

		span: {
			color: theme.palette.primary.main,
		},
	})
);
