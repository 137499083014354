import { FC } from 'react';

export const WalkingLegSvg: FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="80" fill="none">
			<circle
				cx="7.584"
				cy="3"
				r="2"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="2"
			></circle>
			<circle
				cx="7.584"
				cy="17"
				r="2"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="2"
			></circle>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.54 30.609a2.609 2.609 0 11-5.218 0 2.609 2.609 0 015.217 0zm-4.104 3.926a.995.995 0 01.165-.013h2.786a1 1 0 01.8.4l1.77 2.36 2.454.613a1 1 0 01.728 1.213l-.148.59a1 1 0 01-1.212.728l-3.01-.752a1 1 0 01-.557-.37l-.47-.629L8.54 42.68l1.605 1.338a1 1 0 01.33.526l1.403 5.609a1 1 0 01-.728 1.212l-.59.148a1 1 0 01-1.213-.727l-.074-.295-1.2-4.804h-.001v-.001l-.573-.478L5.4 43.462l-1.057-.882a1 1 0 01-.337-.985l.926-4.163-1.35.225-1.144 2.286a1 1 0 01-1.342.448l-.544-.273a1 1 0 01-.447-1.341l1.51-3.02a1 1 0 01.73-.539l4.09-.682zm.223 11.672a1 1 0 01.273.36l-1.828 4.39a1 1 0 01-1.308.538l-.562-.234a1 1 0 01-.538-1.308l2.18-5.232 1.783 1.486z"
				clipRule="evenodd"
			></path>
			<circle
				cx="7.584"
				cy="62.572"
				r="2"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="2"
			></circle>
			<circle
				cx="7.584"
				cy="76.572"
				r="2"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="2"
			></circle>
		</svg>
	);
};
