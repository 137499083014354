import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';

import {
	Booking as IDriverBooking,
	BookingStatus,
	IBooking as IPassengerBooking,
	IWhoami,
	PassengerDetails,
} from '@mopla/data-models';

export const calculateBookingExtraPassengers = (booking: IDriverBooking) => {
	return (
		booking.passengerList.filter((b) => !b.isMainPassenger).length +
		(booking.toddlerSeats || 0)
	);
};

export const getMainPassenger = (booking: IDriverBooking) => {
	return booking.passengerList.find((b) => b.isMainPassenger);
};

export const checkIfPriceAccordingTarif = (booking: IDriverBooking) => {
	/** This exact condition is discussed and validated by the BE team */
	return booking.paymentForm === 'CASH' && booking.paymentAmount === null;
};

export const checkIsBookingCancelled = (
	booking: IPassengerBooking | Partial<IPassengerBooking>
) => {
	return (
		booking.bookingStatus === 'CANCELLED_BY_PASSENGER' ||
		booking.bookingStatus === 'CANCELLED_NO_SHOW' ||
		booking.bookingStatus === 'CANCELLED_BY_DISTRIBUTOR'
	);
};

/** TODO consider this logic again
 * Which conditions should be met for *passed* and *upcoming* bookings?
 * Those implementations originate from the TicketsList.tsx
 * */
export const checkIsPassedBooking = (booking?: IPassengerBooking) => {
	if (!booking || checkIsBookingCancelled(booking)) return false;

	const diffInHours = dayjs().diff(
		dayjs(booking.itinerary.endDateTime),
		'hours'
	);

	return booking.bookingStatus !== BookingStatus.NEW || diffInHours > 24;
};

export const checkIsUpcomingBooking = (booking: IPassengerBooking) => {
	if (checkIsBookingCancelled(booking)) return false;

	const diffInHours = dayjs().diff(
		dayjs(booking.itinerary.endDateTime),
		'hours'
	);

	return booking.bookingStatus === BookingStatus.NEW && diffInHours <= 24;
};

export const checkIsBookingForCurrentUser = (
	booking: IPassengerBooking | Partial<IPassengerBooking>,
	user: IWhoami
) => {
	/** The main passenger should always exists */
	const mainPassenger = booking.passengerList?.find((p) => p.isMainPassenger);

	if (!mainPassenger) {
		return false;
	}

	return (
		JSON.stringify([
			mainPassenger.email,
			mainPassenger.firstName,
			mainPassenger.lastName,
		]) === JSON.stringify([user.email, user.firstName, user.lastName])
	);
};

export const getBookingAttendeesSummary = (
	booking: IPassengerBooking | Partial<IPassengerBooking>,
	currentUser: IWhoami | null,
	t: TFunction
) => {
	if (!currentUser || !booking.passengerList) {
		return null;
	}

	const mainPassenger = booking.passengerList.find(
		(p) => p.isMainPassenger
	) as PassengerDetails;

	const isTicketForCurrentUser = checkIsBookingForCurrentUser(
		booking,
		currentUser
	);

	const extraPassengersCount = booking.passengerList.length - 1;

	const mainPassengerName = `${mainPassenger.firstName} ${mainPassenger.lastName}`;

	return {
		mainPassengerName,
		title: isTicketForCurrentUser
			? t('tickets:title.your_ticket')
			: mainPassengerName,
		extraPassengers: extraPassengersCount
			? t('tickets:text.adults', { count: extraPassengersCount })
			: null,
		extraChildren: booking.toddlerSeats
			? t('tickets:text.children', {
					count: booking.toddlerSeats,
			  })
			: null,
		luggage: booking.bulkyLuggage
			? t('tickets:text.luggage', {
					count: 1,
			  })
			: null,
	};
};

export const sortBookingsByEndDate = (
	booking1: IPassengerBooking,
	booking2: IPassengerBooking
) => {
	return (
		new Date(booking2.itinerary.startDateTime || 0).getTime() -
		new Date(booking1.itinerary.startDateTime || 0).getTime()
	);
};
