import { FC } from 'react';
import { CircularProgress, ThemeProvider } from '@mui/material';
import cn from 'classnames';

import { experimentalTheme } from './experimentalTheme';
import { Content, LoaderWrapper, MuiButtonStyled } from './styled';
import { TMplButtonProps } from './types';

export const MplButton: FC<TMplButtonProps> = ({
	className,
	children,
	showLoader,
	testId = 'mplButton',
	...muiProps
}) => {
	return (
		<ThemeProvider theme={experimentalTheme}>
			<MuiButtonStyled
				className={cn(className, showLoader && 'loading')}
				disableFocusRipple
				data-testid={testId}
				{...muiProps}
			>
				<Content hide={showLoader}>{children}</Content>
				{showLoader && (
					<LoaderWrapper>
						<CircularProgress size={16} color="inherit" />
					</LoaderWrapper>
				)}
			</MuiButtonStyled>
		</ThemeProvider>
	);
};
