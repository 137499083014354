import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr max-content',
	gridTemplateRows: 'max-content max-content',
	gridTemplateAreas: `
		"icon     title    toggle"
		"content  content  content"
		`,

	'& .icon': {
		gridArea: 'icon',
		alignSelf: 'center',

		'& + .title': {
			marginLeft: 8,
			lineHeight: 1,
		},

		'& svg': {
			display: 'block',
		},
	},

	'& .title': {
		gridArea: 'title',
		alignSelf: 'center',
	},

	'& .toggle': {
		marginLeft: 8,
		gridArea: 'toggle',
		alignSelf: 'center',
		'& .MuiIconButton-root': {
			color: theme.palette.primary.main,
		},
	},

	'& .content': {
		gridArea: 'content',
	},
}));
