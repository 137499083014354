import dayjs from 'dayjs';
import memoizeOne from 'memoize-one';
import invariant from 'tiny-invariant';

import { PRODT_ITINERARY_DEFAULT_DISCOUNT } from '@mopla/constants';
import {
	CurrencyCode,
	DiscountState,
	IBooking,
	IItineraryPayment,
	ILeg,
	ILegPricing,
	Itinerary,
	LegLocation,
	NamedLocation,
	PassengerDetails,
	TransportType,
} from '@mopla/data-models';

import { isMoreThanOneTrue, uniqueFilter } from './array';
import { formatDate, formatTime } from './formatDateTime';

export const checkIsLBODTFlexLeg = (leg: ILeg): boolean =>
	leg.mode === TransportType.LBODTFLEX;
export const checkIsAnyWalkLeg = (leg: ILeg): boolean =>
	[TransportType.WALK, TransportType.TRANSFER_WALK].includes(leg.mode);

/** TODO pay attention how types of rides are checked
 * some of those fns use "every", which answers a question "is a ride *-type only?"
 * some of thise use "some", which is "has a ride *-type legs?" */
export const isLBTItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	return !!itinerary?.legs.every((leg) =>
		[
			TransportType.LBT,
			TransportType.WALK,
			TransportType.TRANSFER_WALK,
		].includes(leg.mode)
	);
});

export const isLBODTFlexItinerary = memoizeOne(
	(itinerary?: Itinerary | null) => {
		return !!itinerary?.legs.some(
			(leg) => leg.mode === TransportType.LBODTFLEX
		);
	}
);

export const isLBODTItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	return !!itinerary?.legs.some((leg) => leg.mode === TransportType.LBODT);
});

/** At the moment, a PRODT ride is a ride with 1 PRODT leg and maybe a WALK legs at the start and end */
export const isPRODTItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	if (!itinerary) {
		return false;
	}

	const tt = getItineraryTransportTypes(itinerary, [TransportType.WALK]);

	return tt.length === 1 && tt[0] === TransportType.PRODT;
});

/** At the moment, a PRODT ride cannot be a part of a mixed ride */
export const isMixedItinerary = memoizeOne((itinerary?: Itinerary | null) => {
	if (!itinerary) {
		return false;
	}

	const hasLBODTFlexLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBODTFLEX
	);

	const hasLBTLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBT
	);

	const hasLBODTLegs = itinerary.legs.some(
		(leg) => leg.mode === TransportType.LBODT
	);

	return isMoreThanOneTrue([hasLBODTFlexLegs, hasLBTLegs, hasLBODTLegs]);
});

/** Returns unique Array of TransportTypes */
export const getItineraryTransportTypes = memoizeOne(
	(itinerary?: Itinerary | null, exclude?: TransportType[]) => {
		const _exclude = exclude || [];
		return itinerary
			? itinerary.legs
					.map((leg: ILeg) => leg.mode)
					.filter((v, i, l) => !_exclude.includes(v) && uniqueFilter(v, i, l))
			: [];
	}
);

/** Checks if an itinerary has any leg with a start or end datetime, which is not fixed (thus can be changed by a transport provider) */
export const checkTimeMightChange = memoizeOne(
	(itinerary?: Itinerary | null) => {
		if (!itinerary) {
			return false;
		}

		return itinerary.legs
			.map((leg: ILeg) => leg.startDateTimeFix || leg.endDateTimeFix)
			.includes(false);
	}
);

/** Checks if an itinerary has any leg with an overwritten time */
export const checkTimeOverwritten = memoizeOne(
	(itinerary?: Itinerary | null) => {
		if (!itinerary) {
			return false;
		}

		return itinerary.legs.some(
			(leg: ILeg) =>
				!!leg.overwrittenStartTimeWindowStart ||
				!!leg.overwrittenEndTimeWindowEnd
		);
	}
);

/** Checks if a Booking price has been reduced */
export const checkBookingPriceReduced = memoizeOne(
	(bookedItinerary: IBooking) => {
		const newPrice = bookedItinerary.payment.overwrittenPaymentAmount;
		const initialPrice = bookedItinerary.payment.paymentAmount;
		return Boolean(newPrice && initialPrice && newPrice < initialPrice);
	}
);

/**
 * For the given discountType and the itinerary,
 * calculates the price value and the flag, if there's any leg with unavailable price info (thus the price according to the tarif)
 * */
export const calcPriceByDiscountType = memoizeOne(
	(itinerary: Itinerary, discountType: DiscountState) => {
		let totalAmount: number | null = null;
		let currency: CurrencyCode | null = null;
		let hasLegsWithPriceAccordingToTarif = false;

		itinerary.legs.forEach((leg) => {
			if (leg.pricings && leg.pricings.length > 0) {
				const suitablePricing = leg.pricings.find(
					(pricing) =>
						pricing.discount === discountType &&
						pricing.paymentInformationAvailable
				);

				if (!suitablePricing) {
					return;
				}

				if (suitablePricing.paymentInformationAvailable) {
					totalAmount = (totalAmount || 0) + (suitablePricing.amount || 0);
					currency = suitablePricing.currency;
				} else {
					/** If "paymentInformationAvailable" is "false" -> "Price according to tariff" */
					hasLegsWithPriceAccordingToTarif = true;
				}
			}
		});

		return {
			totalAmount,
			currency,
			hasLegsWithPriceAccordingToTarif,
		};
	}
);

export function checkHasLegWithoutPaymentInfo(legs: ILeg[]) {
	const legsPrices = getLegsPrices(legs);

	return legsPrices.some((legPrices) =>
		legPrices.some(
			({ paymentInformationAvailable }) => !paymentInformationAvailable
		)
	);
}

export function getLegsPrices(legs: ILeg[]): ILegPricing[][] {
	return legs.map(({ pricings }) => pricings);
}

/** Types are not ideal here */
export function prepareItineraryOperators<T>(
	itinerary?: Itinerary | null,
	mapper?: (operators: string[]) => unknown
): null | T {
	if (!itinerary) {
		return null;
	}

	const operators = itinerary.legs
		.map((leg) => leg.agencyName)
		.filter((v, i, s): v is string => !!v && uniqueFilter(v, i, s));

	if (!operators.length) {
		return null;
	}

	return (mapper ? mapper(operators) : operators) as T;
}

export function calculateItineraryPricingsRange(
	legs: ILeg[] | null
): [number, number] | null {
	if (!legs) {
		return null;
	}

	let totalMin: number | null = null;
	let totalMax: number | null = null;

	const legsPrices = getLegsPrices(legs);

	legsPrices.forEach((el) => {
		const prices = el
			.filter((p) => p.amount != null)
			.map<number>((p) => p.amount as number)
			.sort((a, b) => a - b);

		if (prices.length) {
			totalMin = (totalMin || 0) + prices[0] || 0;
			totalMax = (totalMax || 0) + prices[prices.length - 1] || 0;
		}
	});

	if (totalMin == null || totalMax == null) {
		return null;
	}

	return [totalMin, totalMax];
}

export function getActualPrice(payment: IItineraryPayment) {
	const newPrice = payment.overwrittenPaymentAmount;
	const initialPrice = payment.paymentAmount;

	return newPrice || initialPrice;
}

export function getActualTime(leg: ILeg, type: 'start' | 'end') {
	const newTime =
		type === 'start'
			? leg.overwrittenStartTimeWindowStart
			: leg.overwrittenEndTimeWindowEnd;
	const initialTime = type === 'start' ? leg.startDateTime : leg.endDateTime;
	return new Date(newTime || initialTime);
}

export const getItineraryStopsCount = (itinerary: Itinerary) => {
	// sum of all entry and exit stops of every leg
	// filter out duplicates, the itinerary start and end points
	const uniqueStops = itinerary.legs
		.filter(
			(l, i, arr) =>
				i !== 0 && i !== arr.length - 1 && l.mode !== TransportType.WALK
		)
		.map((l) => [l.from.name, l.to.name])
		.flat()
		.filter(uniqueFilter);

	const intermediateStopsCount = itinerary.legs.reduce(
		(sum, l) => sum + (l.intermediateStops?.length || 0),
		0
	);

	// sum of all stops of all legs of trip
	return uniqueStops.length + intermediateStopsCount;
};

export const getItineraryTiming = memoizeOne((itinerary: Itinerary) => {
	const legForStartTime = itinerary.legs[0];
	const legForEndTime = itinerary.legs[itinerary.legs.length - 1];

	const startTimeFormatted = formatTime(legForStartTime.startDateTime);
	const actualStartTime = getActualTime(legForStartTime, 'start');
	const actualStartTimeFormatted = formatTime(actualStartTime);
	const endTimeFormatted = formatTime(legForEndTime.endDateTime);
	const actualEndTime = getActualTime(legForEndTime, 'end');
	const actualEndTimeFormatted = formatTime(actualEndTime);
	const startDate = formatDate(legForStartTime.startDateTime);
	let isEarlier: boolean | null = null;
	let isDelayed: boolean | null = null;

	if (legForStartTime.overwrittenStartTimeWindowStart) {
		isEarlier = dayjs(legForStartTime.overwrittenStartTimeWindowStart).isBefore(
			legForStartTime.startDateTime
		);
		isDelayed = !isEarlier;
	}

	const duration = dayjs.duration(
		dayjs(actualEndTime).diff(dayjs(actualStartTime))
	);

	return {
		startTime: startTimeFormatted,
		actualStartTime: actualStartTimeFormatted,
		startDate,
		endTime: endTimeFormatted,
		actualEndTime: actualEndTimeFormatted,
		duration,
		isEarlier,
		isDelayed,
	};
});

export function prepareCancellationData(
	itinerary: Itinerary,
	passengersList?: PassengerDetails[]
) {
	/**
	 *  The passengersList can be omitted for PRODT itineraries,
	 *  as the PRODT type charges a single price with NO_DISCOUNT for the entire vehicle rather than per passenger.
	 * */
	if (isPRODTItinerary(itinerary)) {
		passengersList = [{ discountState: PRODT_ITINERARY_DEFAULT_DISCOUNT }];
	}

	const cancellationSummary: { [key: number]: number } = {};

	itinerary.legs.forEach((leg: ILeg) => {
		invariant(
			passengersList,
			'[Itinerary] no passengersList while prepareCancellationData'
		);

		passengersList.forEach((passenger) => {
			const applicableRules = leg.cancellationRules?.filter(
				(rule) => rule.discount === passenger.discountState
			);

			applicableRules?.forEach((rule) => {
				invariant(
					Number.isFinite(rule.secondsBeforeRideStart),
					`[Itinerary] cancellationRules secondsBeforeRideStart is not defined: ${rule.secondsBeforeRideStart}`
				);

				cancellationSummary[rule.secondsBeforeRideStart] =
					(cancellationSummary[rule.secondsBeforeRideStart] || 0) + rule.cost;
			});
		});
	});

	return Object.entries(cancellationSummary)
		.map(([secondsBeforeRideStart, cost]) => ({
			secondsBeforeRideStart: Number(secondsBeforeRideStart),
			cost,
		}))
		.sort((a, b) => b.secondsBeforeRideStart - a.secondsBeforeRideStart);
}

export function calculateCurrentCancellationPrice(itinerary: Itinerary) {
	return itinerary.legs.reduce<number>((accum, leg) => {
		const legCancellationRulesSorted = leg.cancellationRules?.sort(
			(a, b) => b.secondsBeforeRideStart - a.secondsBeforeRideStart
		);

		if (!legCancellationRulesSorted) {
			return accum;
		}

		const actualSecondsBeforeLegStart = Math.max(
			0,
			dayjs(leg.startDateTime).diff(dayjs(), 'seconds')
		);

		let legCancellationPrice = 0;

		legCancellationRulesSorted.forEach((rule) => {
			if (rule.secondsBeforeRideStart >= actualSecondsBeforeLegStart) {
				legCancellationPrice = rule.cost;
			}
		});

		return accum + legCancellationPrice;
	}, 0);
}

export function prepareLegAddress(
	legLocation?: NamedLocation | LegLocation
): string {
	if (!legLocation) {
		return '';
	}

	let prepared = legLocation.name;

	if (legLocation.stopDescription) {
		prepared += `\n${legLocation.stopDescription}`;
	}

	return prepared;
}
