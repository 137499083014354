import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>(({ theme, hidden }) => ({
	backgroundColor: theme.palette.neutral[0],
	width: '100%',
	height: '100%',
	display: 'grid',
	paddingTop: 'var(--mopla-safe-area-top)',
	gridTemplateRows: 'max-content 1fr max-content',
	visibility: hidden ? 'hidden' : 'visible',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
	overflow: 'hidden',
}));

export const ScrollWrapper = styled('div')(() => ({
	display: 'grid',
	height: '100%',
	overflow: 'auto',
}));
