import React from 'react';
import { IconButton, TextFieldProps } from '@mui/material';

import { VisibilityOffIcon } from '../../icons/VisibilityOff';
import { VisibilityOnIcon } from '../../icons/VisibilityOn';
import { Root } from '../input/input.styles';

import { InputAdornmentWrapper } from './PasswordInput.styles';

export const PasswordInput = ({ ...props }: TextFieldProps) => {
	const [showing, setShowing] = React.useState(false);

	const handleClickShowPassword = () => {
		setShowing(!showing);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<Root
			{...props}
			type={showing ? 'text' : 'password'}
			variant="filled"
			color="primary"
			InputProps={{
				disableUnderline: true,
				endAdornment: (
					<InputAdornmentWrapper error={props.error} position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showing ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornmentWrapper>
				),
			}}
		/>
	);
};
