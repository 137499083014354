import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

import { IconMessage } from '../../';

type TVariant = 'default' | 'strike' | 'attention' | 'success' | 'error';

export interface IValueProps {
	className?: string;
	value: string;
	variant?: TVariant;
	icon?: ReactNode;
	caption?: string | null;
	overwritten?: boolean;
	testId?: string;
}

const IconMessageStyled = styled(IconMessage)<{
	ownerState: { type: TVariant };
}>(({ theme, ownerState: { type } }) => ({
	...(type === 'default' && {
		color: theme.palette.neutral[100],
	}),

	...(type === 'strike' && {
		textDecoration: 'line-through',
	}),

	...(type === 'attention' && {
		color: theme.palette.warning.main,
	}),

	...(type === 'success' && {
		color: theme.palette.secondary.dark,
	}),

	...(type === 'error' && {
		color: theme.palette.error.main,
		textDecoration: 'line-through',
	}),
}));

export const Value: FC<IValueProps> = ({
	className,
	value,
	variant = 'default',
	icon,
	testId,
}) => {
	return (
		<IconMessageStyled
			className={className}
			ownerState={{ type: variant }}
			icon={icon}
			message={value}
			variant="bodyTitle"
			testId={testId}
		/>
	);
};
