import { Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: 'calc( 24px + var(--mopla-safe-area-top)) 24px 0 24px',
	backgroundColor: theme.palette.secondary.main,
	overflow: 'auto',
	height: 'calc(100%)',
	boxSizing: 'border-box',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
}));

export const HeaderText = styled('h1')(({ theme }) => ({
	marginTop: '55px',
	marginBottom: '24px',
	fontFamily: theme.typography.fontFamily,
	fontStyle: 'normal',
	fontWeight: 700,
	fontSize: '46px',
	lineHeight: '51px',
	textTransform: 'uppercase',
	color: theme.palette.neutral[0],
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.neutral[80],
	width: '100%',
	borderRadius: '8px',
	alignSelf: 'center',
}));

export const ButtonsWrapper = styled('div')(() => ({
	marginTop: 'auto',
	marginBottom: '40px',

	'button:first-of-type': {
		marginBottom: '16px',
	},
}));

/** TODO delete dead code. This one is used in the dead component only */
export const DrawerRoot = styled(Drawer)(() => ({
	'.MuiPaper-root': {
		maxWidth: 600,
		margin: 'auto',
	},
	'.MuiDrawer-paper': {
		backgroundColor: 'transparent',
		overflowY: 'auto',
		height: '100%',
	},

	'.MuiBackdrop-root': {
		backgroundColor: 'transparent',
	},
}));

export const DrawerContent = styled('div')(() => ({
	padding: 24,
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	position: 'relative',
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	marginBottom: marginBottom || 0,
}));

export const CheckmarkWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',

	svg: {
		marginBottom: '24px',
	},
}));
