import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	background: disabled ? theme.palette.neutral[40] : theme.palette.neutral[0],
	padding: 16,
	boxSizing: 'border-box',
	borderRadius: 8,
}));

export const PassengerInfo = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabledText',
})<{ disabledText?: boolean }>(({ theme, disabledText }) => ({
	...theme.typography.body2,
	fontWeight: 700,
	color: disabledText ? theme.palette.neutral[60] : theme.palette.primary.dark,
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridTemplateRows: 'max-content max-content',
	justifyContent: 'space-between',
	'#more-attendees': {
		...theme.typography.caption,
		color: disabledText
			? theme.palette.neutral[60]
			: theme.palette.primary.dark,
		marginTop: 4,
	},
}));

export const ManualButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'red',
})<{ red?: boolean }>(({ theme, red = false }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	border: `2px solid ${
		red ? theme.palette.error.main : theme.palette.primary.main
	}`,
	color: red ? theme.palette.error.main : theme.palette.primary.main,
	borderRadius: 8,
	marginTop: 16,
	height: 36,

	'&:hover, :active, :focus': {
		border: `2px solid ${
			red ? theme.palette.error.main : theme.palette.primary.main
		}`,
		color: theme.palette.neutral[0],
		background: red ? theme.palette.error.main : theme.palette.primary.main,
	},
}));

export const PaymentFormWrapper = styled(FlexColumn)(({ theme }) => ({
	marginTop: 24,
	gap: 4,

	span: {
		'&:first-of-type': {
			opacity: 0.7,
		},
		'&:last-of-type': {
			display: 'flex',
			alignItems: 'center',
			gap: 4,
		},
	},
	svg: {
		path: {
			fill: theme.palette.primary.main,
		},
	},
}));

export const ExpandIconWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 8,
}));

export const AdditionalNeedsWrapper = styled(FlexColumn)(() => ({
	marginTop: 24,
}));

export const AdditionalNeedText = styled(Typography)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: 8,
}));
