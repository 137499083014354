import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { PaymentForm, SpecialNeeds, TCheckInStatus } from '@mopla/data-models';

import { ArrowDownIcon, CheckmarkIcon } from '../../icons';

import { AdditionalNeeds } from './AdditionalNeeds';
import {
	ExpandIconWrapper,
	ManualButton,
	PassengerInfo,
	PaymentFormWrapper,
	Root,
} from './PassengerCheckCard.styles';

export interface PassengerCheckCardProps {
	name: string;
	id: string;
	attendees: number;
	onManualClick?: VoidFunction;
	onNoShowClick?: (id: string) => void;
	disabled?: boolean;
	disabledText?: boolean;
	paymentForm?: PaymentForm;
	isNoShowGuest?: boolean;
	specialNeeds?: SpecialNeeds;
	bulkyLuggage?: boolean;
	showAdditionalNeeds?: boolean;
	checkInStatus?: TCheckInStatus;
}

export const PassengerCheckCard: FC<PassengerCheckCardProps> = ({
	name,
	id,
	attendees,
	onManualClick,
	onNoShowClick,
	disabled,
	disabledText,
	paymentForm,
	isNoShowGuest = false,
	showAdditionalNeeds = false,
	specialNeeds,
	bulkyLuggage,
	checkInStatus,
}) => {
	const [noShowCollapsed, setNoShowCollapsed] = useState<boolean>(true);

	const handleToggleNoShowBlock = () =>
		setNoShowCollapsed((prevState) => !prevState);
	const collapsedNoShowBlock = isNoShowGuest && noShowCollapsed;

	const { t } = useTranslation('checkIn');
	return (
		<Root disabled={disabled || collapsedNoShowBlock}>
			<PassengerInfo
				data-testid="checkIn-passenger-card-info"
				disabledText={disabledText}
			>
				<span data-testid="checkIn-passenger-card-name">{name}</span>
				<span>{id}</span>
				{!!attendees && (
					<span id="more-attendees">
						{t('text.another_guest', { count: attendees })}
					</span>
				)}
			</PassengerInfo>
			{showAdditionalNeeds && (
				<AdditionalNeeds
					specialNeeds={specialNeeds}
					bulkyLuggage={bulkyLuggage}
				/>
			)}
			{paymentForm && (
				<PaymentFormWrapper className="space-between">
					<Typography variant="body2" color="primary.dark">
						{t('text.payment_method')}
					</Typography>
					<Typography variant="body2" fontWeight={600} color="primary.dark">
						{paymentForm === 'DIGITAL' && <CheckmarkIcon />}
						{t(`text.${paymentForm}`)}
					</Typography>
				</PaymentFormWrapper>
			)}
			{onManualClick &&
				(collapsedNoShowBlock ? (
					<ExpandIconWrapper onClick={handleToggleNoShowBlock}>
						<ArrowDownIcon />
					</ExpandIconWrapper>
				) : (
					<ManualButton
						data-testid="checkIn-passenger-card-button"
						onClick={onManualClick}
						variant="outlined"
					>
						{t('button.check_in')}
					</ManualButton>
				))}
			{onNoShowClick && (
				<ManualButton
					data-testid="checkIn-passenger-card-no-show-button"
					onClick={() => onNoShowClick(id)}
					variant="outlined"
					red
					disabled={checkInStatus && checkInStatus !== 'ready'}
				>
					{t('button.no_show_up')}
				</ManualButton>
			)}
		</Root>
	);
};
