import React from 'react';

import { TMoplaColors } from '@mopla/mui';

import {
	Dash,
	Separator,
	SideCircle,
	TicketWrapper,
	Wrapper,
} from './Ticket.styles';

interface IProps {
	header: React.ReactNode;
	content: React.ReactNode;
	footer: React.ReactNode;
	ticketBg?: TMoplaColors;
	separatorBg?: TMoplaColors;
	circleSize?: number;
	testId?: string;
	disabled?: boolean;
}

export const Ticket: React.FC<IProps> = (props) => {
	const {
		header,
		content,
		footer,
		ticketBg = 'neutral.0',
		separatorBg = 'primary.main',
		circleSize,
		disabled,
		testId,
	} = props;

	if (!header && !content && !footer) return null;

	return (
		<TicketWrapper data-testid={testId} ownerState={{ disabled }}>
			{header && (
				<>
					<Wrapper bgColor={ticketBg} position="top">
						{header}
					</Wrapper>
					<Separator size={circleSize} color={ticketBg}>
						<SideCircle size={circleSize} color={separatorBg} left={true} />
						<Dash color={separatorBg} />
						<SideCircle size={circleSize} color={separatorBg} />
					</Separator>
				</>
			)}
			<Wrapper bgColor={ticketBg} position="center">
				{content}
			</Wrapper>
			{footer && (
				<>
					<Separator size={circleSize} color={ticketBg}>
						<SideCircle size={circleSize} color={separatorBg} left={true} />
						<Dash color={separatorBg} />
						<SideCircle size={circleSize} color={separatorBg} />
					</Separator>
					<Wrapper bgColor={ticketBg} position="bottom">
						{footer}
					</Wrapper>
				</>
			)}
		</TicketWrapper>
	);
};
