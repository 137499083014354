import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import * as Sentry from '@sentry/capacitor';

import {
	fetchSubscriptions,
	fetchTickets,
	paymentFinishSub$,
	setDefaultPaymentMethod,
	useBusinessLayer,
} from '@mopla/business-logic';

export function AppListener() {
	const navigate = useNavigate();
	const businessLayer = useBusinessLayer();
	const [query, setQuery] = useSearchParams();
	const [platform] = useState(Capacitor.getPlatform());

	const handleFinishAddingPaymentMethod = useCallback(
		(searchParams: URLSearchParams) => {
			const setupIntentId = searchParams.get('setup_intent');
			const redirectStatus = searchParams.get('redirect_status');
			const paymentMethodId = searchParams.get('paymentMethodId');
			const paymentClosed = searchParams.get('paymentClosed');

			if (paymentMethodId) {
				businessLayer.dispatch(setDefaultPaymentMethod({ paymentMethodId }));

				Sentry.addBreadcrumb({
					category: 'payment',
					message: 'process payment method on native',
					data: { paymentMethodId },
				});
			} else if (redirectStatus === 'succeeded' && setupIntentId) {
				businessLayer.dispatch(setDefaultPaymentMethod({ setupIntentId }));

				Sentry.addBreadcrumb({
					category: 'payment',
					message: 'process SOFORT payment method',
					data: { setupIntentId },
				});
			} else if (paymentClosed) {
				paymentFinishSub$.next('paymentClosed');
			} else if (redirectStatus === 'failed') {
				paymentFinishSub$.next('redirectStatusFailed');
			}
		},
		[businessLayer]
	);

	useEffect(() => {
		const listener: PluginListenerHandle = CapacitorApp.addListener(
			'appUrlOpen',
			(event: URLOpenListenerEvent) => {
				const url = new URL(event.url);
				const isPaymentRedirect = url.searchParams.has('isPaymentRedirect');

				if (url.pathname && !isPaymentRedirect) {
					navigate(url.pathname + url.search);
				} else if (isPaymentRedirect) {
					// only ios has close method implemented, Android will close the chrome tabs automatically
					if (platform === 'ios') {
						Browser.close();
					}

					handleFinishAddingPaymentMethod(url.searchParams);
				}
				// If no match, do nothing - let regular routing
				// logic take over
			}
		);

		return () => {
			listener.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const listener: PluginListenerHandle = CapacitorApp.addListener(
			'resume',
			() => {
				businessLayer.dispatch(fetchSubscriptions());
				businessLayer.dispatch(fetchTickets());
			}
		);

		return () => {
			listener.remove();
		};
	}, [businessLayer]);

	useEffect(() => {
		// this part handles redirects from stripe, that need a complete redirect flow like SOFORT payments
		const isWeb = platform === 'web';
		const isPaymentRedirect = query.has('isPaymentRedirect');

		if (isWeb && isPaymentRedirect) {
			handleFinishAddingPaymentMethod(query);

			query.delete('setup_intent');
			query.delete('setup_intent_client_secret');
			query.delete('paymentMethodId');
			setQuery(query);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const listeners: PluginListenerHandle[] = [];

		if (platform !== 'web') {
			const l1 = Keyboard.addListener('keyboardWillHide', () => {
				document.body.classList.remove('keyboard-is-open');
			});
			const l2 = Keyboard.addListener('keyboardWillShow', () => {
				document.body.classList.add('keyboard-is-open');
			});
			const l3 = Keyboard.addListener('keyboardDidShow', () => {
				document.activeElement?.scrollIntoView({ behavior: 'smooth' });
			});
			listeners.push(l1, l2, l3);
		}

		return () => {
			listeners.forEach((l) => l.remove());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
}
