import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';

import { Itinerary } from '@mopla/data-models';

import { FlexContainer, RouteDetailsHeader } from '../../../';

import { RouteDetails } from './ui/RouteDetails';
import { CheapestRideIcon, Content } from './ui/styled';

export interface ISearchRouteDetailsProps {
	route: Itinerary;
	cheaperRidesBlock?: ReactNode;
	isCheapestRide?: boolean;
}

export const SearchRouteDetails: FC<ISearchRouteDetailsProps> = ({
	route,
	cheaperRidesBlock,
	isCheapestRide,
}) => {
	const { t } = useTranslation([
		'searchResults',
		'routeDetails',
		'tickets',
		'booking',
	]);

	return (
		<RouteDetails
			route={route}
			routeDetailsHeaderBlock={<RouteDetailsHeader route={route} />}
			cheapestRideBlock={
				isCheapestRide && (
					<Content>
						<FlexContainer gap={8} style={{ flexWrap: 'nowrap' }}>
							<CheapestRideIcon />
							<Typography variant="body1" fontWeight={600} color="primary">
								{t('booking:text.isCheaperRide')}
							</Typography>
						</FlexContainer>
						<Divider sx={{ mt: 3 }} />
					</Content>
				)
			}
			cheaperRidesBlock={cheaperRidesBlock}
		/>
	);
};
