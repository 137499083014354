import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { IconButton, Typography } from '@mui/material';

import {
	useDTicketSubscriptionOffering,
	useUser,
	useUserActions,
} from '@mopla/business-logic';
import {
	DTicketProfileManagerProps,
	IWhoami,
	ProfileSteps,
	TPaymentType,
	TSubscription,
} from '@mopla/data-models';
import { getDTicketType, useBreakpoints, useI18nLanguage } from '@mopla/utils';

import { MplButton } from '../../';
import { Loader } from '../../atoms/loader/Loader';
import { EditIcon, TrashIconSmall } from '../../icons';
import { BottomSlider } from '../../molecules/BottomSlider/BottomSlider';
import SearchHeader from '../../molecules/SearchHeader/SearchHeader';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { useMoplaRideTypesControl } from '../MoplaRideTypesDrawer';
import { useDiscardChangesPrompt } from '../prompt/useDiscardChangesPrompt';
import SideOverlay from '../SideOverlay/SideOverlay';

import { ClosePersonalEditorModal } from './ClosePersonalEditorModal';
import { useProfileManagerContext } from './context';
import { EditUserScreen } from './EditUserScreen';
import { FAQScreen } from './FAQScreen';
import { InitialProfileScreen } from './InititalScreen';
import { LanguageSelectScreen } from './LanguageSelect';
import { PersonalScreen } from './PersonalScreen';
import {
	DataField,
	DesktopDataBlock,
	DesktopDataTitle,
	DesktopRoot,
	ImprintButton,
	MyAccountTitleWithButton,
	Root,
} from './ProfileManager.styles';
import { VouchersScreen } from './VouchersScreen';
import { withProfileManagerContext } from './withProfileManagerContext';

type personalDetails = {
	firstName: string;
	lastName: string;
	phone: string;
	dateOfBirth: string | null;
	streetNumber: string | null;
	zipcode: string | null;
	city: string | null;
};

export interface IProfileManagerProps {
	onClose: () => void;
	onLogout: () => void;
	onDeleteAccount: () => void;
	onEdit: (data: personalDetails) => Promise<any>;
	showGreeting?: boolean;
	subscription?: TSubscription;
	defaultPaymentMethodType?: TPaymentType;
	paymentScreen?: (
		body: Pick<DTicketProfileManagerProps, 'changeStep'>
	) => React.ReactNode;
	getDTicketManager?: (body: DTicketProfileManagerProps) => React.ReactNode;
	showLiveUpdatesVersion?: boolean;
}

const ProfileManagerComponent: React.FC<IProfileManagerProps> = ({
	onEdit,
	onClose,
	onLogout,
	onDeleteAccount,
	showGreeting,
	subscription,
	defaultPaymentMethodType,
	paymentScreen,
	getDTicketManager,
	showLiveUpdatesVersion = false,
}) => {
	const language = useI18nLanguage();
	const { t } = useTranslation(['profile', 'common']);
	const { userData } = useUser();
	const { fetchUser } = useUserActions();
	const { isAboveMobile } = useBreakpoints();
	const [profileStep, setProfileStep] = useState<ProfileSteps>(
		ProfileSteps.INITIAL
	);
	const { backInterceptor, setShowErrorModal, showErrorModal } =
		useProfileManagerContext();

	const [showEditUserNotification, setShowEditUserNotification] =
		useState(false);
	const { isDriverApp } = useProfileManagerContext();

	const [invoiceAddressChanged, setInvoiceAddressChanged] =
		useState<boolean>(false);
	const {
		confirmDiscardChanges: confirmDiscardInvoiceChanges,
		DiscardChangesConfirmationPrompt: DiscardInvoiceChanges,
	} = useDiscardChangesPrompt(() => {
		if (isAboveMobile) return setProfileStep(ProfileSteps.INITIAL);
		setProfileStep(ProfileSteps.DTICKET);
	}, invoiceAddressChanged);

	const location = useLocation();
	const query = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);

	const { subscriptionOffering } = useDTicketSubscriptionOffering();
	const dTicketType = getDTicketType(
		subscription?.type || subscriptionOffering?.subscriptionType
	);

	useEffect(() => {
		const subscriptionPauseStep = query.get('subscription');
		if (subscriptionPauseStep === 'pause') {
			setProfileStep(ProfileSteps.DTICKET_PAUSE);
		}

		const paymentMethod = query.has('payment-method');
		if (paymentMethod && !isAboveMobile) {
			setProfileStep(ProfileSteps.PAYMENT_DETAILS);
		}
	}, [query, isAboveMobile]);

	const onPassengerEdited = () => {
		setProfileStep(ProfileSteps.PASSENGER_PERSONAL_DATA);
		fetchUser();
	};

	const { handleOpen: onTransportTypes } = useMoplaRideTypesControl();

	const onImprint: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		Browser.open({ url: 'https://www.mopla.solutions/de/impressum' });
	};

	const onCookieSettings: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		const windowRef = window as any;
		if (windowRef.CookieScript) {
			windowRef.CookieScript.instance.show();
		}
	};

	const onFAQ = () => setProfileStep(ProfileSteps.FAQ);

	const windowRef = window as any;

	const getDriverStep = () => {
		switch (profileStep) {
			case ProfileSteps.LANGUAGE_SELECT:
				return (
					<LanguageSelectScreen
						onSubmit={() => setProfileStep(ProfileSteps.INITIAL)}
					/>
				);

			/** TODO seems to be unreachable code branch. There's no Personal details for a driver */
			case ProfileSteps.DRIVER_PERSONAL_DATA:
				return (
					<PersonalScreen
						onDeleteAccount={onDeleteAccount}
						onEdit={() => setProfileStep(ProfileSteps.PASSENGER_EDIT_USER)}
					/>
				);

			case ProfileSteps.INITIAL:
			default:
				return (
					<InitialProfileScreen
						onLogout={onLogout}
						onProfile={() => setProfileStep(ProfileSteps.DRIVER_PERSONAL_DATA)}
						onPayment={() => setProfileStep(ProfileSteps.PAYMENT_DETAILS)}
						onLanguage={() => setProfileStep(ProfileSteps.LANGUAGE_SELECT)}
						onGermanyTicket={() => setProfileStep(ProfileSteps.DTICKET)}
						onTransportTypes={onTransportTypes}
						showGreeting={showGreeting}
						showLiveUpdatesVersion={showLiveUpdatesVersion}
					/>
				);
		}
	};

	const getPassengerStep = () => {
		switch (profileStep) {
			case ProfileSteps.FAQ:
				return <FAQScreen />;

			case ProfileSteps.REDEEM_VOUCHER:
				return <VouchersScreen />;

			case ProfileSteps.LANGUAGE_SELECT:
				return (
					<LanguageSelectScreen
						onSubmit={() => setProfileStep(ProfileSteps.INITIAL)}
					/>
				);

			case ProfileSteps.PASSENGER_PERSONAL_DATA:
				return (
					<PersonalScreen
						onDeleteAccount={onDeleteAccount}
						onEdit={() => setProfileStep(ProfileSteps.PASSENGER_EDIT_USER)}
						currentUser={userData as IWhoami}
					/>
				);

			case ProfileSteps.PASSENGER_EDIT_USER:
				return userData ? (
					<EditUserScreen
						onUserEdited={onPassengerEdited}
						onEdit={onEdit}
						currentUser={userData}
						onAbort={(isDirty) => {
							if (isDirty) {
								setShowEditUserNotification(true);
							} else {
								setProfileStep(ProfileSteps.PASSENGER_PERSONAL_DATA);
							}
						}}
					/>
				) : (
					<Loader />
				);

			case ProfileSteps.PAYMENT_DETAILS:
				return (
					paymentScreen?.({
						changeStep: setProfileStep,
					}) || ''
				);

			case ProfileSteps.DTICKET:
			case ProfileSteps.DTICKET_PAUSE:
			case ProfileSteps.DTICKET_CANCEL:
			case ProfileSteps.DTICKET_INVOICE_ADDRESS:
			case ProfileSteps.DTICKET_INVOICE_INQUIRY:
			case ProfileSteps.DTICKET_INVOICE_INQUIRY_SELECT:
			case ProfileSteps.DTICKET_PREORDERED_CANCEL:
				return getDTicketManager?.({
					step: profileStep,
					changeStep: setProfileStep,
					setInvoiceAddressChanged: setInvoiceAddressChanged,
				});

			default:
				return (
					<LanguageSelectScreen
						onSubmit={() => setProfileStep(ProfileSteps.INITIAL)}
					/>
				);
		}
	};

	const getTitle = () => {
		switch (profileStep) {
			case ProfileSteps.INITIAL: {
				return `${userData?.firstName || ' '} ${userData?.lastName || ' '}`;
			}
			case ProfileSteps.LANGUAGE_SELECT:
				return t('header.language');
			case ProfileSteps.PASSENGER_PERSONAL_DATA:
				return t('header.my_details');
			case ProfileSteps.PASSENGER_EDIT_USER:
				return t('header.personal_data');
			case ProfileSteps.DRIVER_PERSONAL_DATA:
				return t('header.my_details');
			case ProfileSteps.PAYMENT_DETAILS:
				return t('header.payment');
			case ProfileSteps.DTICKET:
			case ProfileSteps.DTICKET_INVOICE_INQUIRY:
			case ProfileSteps.DTICKET_INVOICE_INQUIRY_SELECT:
				return t('header.germany_ticket', { dTicketType });
			case ProfileSteps.DTICKET_PAUSE:
				return t('header.pause_subscription');
			case ProfileSteps.DTICKET_CANCEL:
				return t('header.cancel_subscription');
			case ProfileSteps.DTICKET_INVOICE_ADDRESS:
				return t('header.change_billing_address');
			case ProfileSteps.DTICKET_PREORDERED_CANCEL:
				return t('header.cancel_preordered_subscription');
			case ProfileSteps.REDEEM_VOUCHER:
				return t('header.vouchers');
			case ProfileSteps.FAQ:
				return t('header.faq');
			default:
				return t('header.initial');
		}
	};

	const getBackHandler = () => {
		if (backInterceptor) {
			return backInterceptor();
		}

		switch (profileStep) {
			case ProfileSteps.LANGUAGE_SELECT:
				return setProfileStep(ProfileSteps.INITIAL);
			case ProfileSteps.PASSENGER_PERSONAL_DATA:
				return setProfileStep(ProfileSteps.INITIAL);
			case ProfileSteps.DRIVER_PERSONAL_DATA:
				return setProfileStep(ProfileSteps.INITIAL);
			case ProfileSteps.PAYMENT_DETAILS:
				return setProfileStep(ProfileSteps.INITIAL);
			case ProfileSteps.PASSENGER_EDIT_USER:
				return setShowEditUserNotification(true);
			case ProfileSteps.DTICKET:
				return setProfileStep(ProfileSteps.INITIAL);
			case ProfileSteps.DTICKET_CANCEL:
			case ProfileSteps.DTICKET_PAUSE:
			case ProfileSteps.DTICKET_PREORDERED_CANCEL:
				if (isAboveMobile) return setProfileStep(ProfileSteps.INITIAL);
				return setProfileStep(ProfileSteps.DTICKET);
			case ProfileSteps.DTICKET_INVOICE_ADDRESS:
				return confirmDiscardInvoiceChanges();
			case ProfileSteps.DTICKET_INVOICE_INQUIRY:
				if (isAboveMobile) {
					return setProfileStep(ProfileSteps.INITIAL);
				} else {
					return setProfileStep(ProfileSteps.DTICKET);
				}
			case ProfileSteps.DTICKET_INVOICE_INQUIRY_SELECT:
				return setProfileStep(ProfileSteps.DTICKET_INVOICE_INQUIRY);
			default:
				return setProfileStep(ProfileSteps.INITIAL);
		}
	};
	const getVariant = () => {
		return isDriverApp ? 'super_light_blue' : 'secondary_dark';
	};

	const closeUserEditorHandler = () => setShowEditUserNotification(false);

	const userEditorDiscardHandler = () => {
		setShowEditUserNotification(false);
		setProfileStep(ProfileSteps.PASSENGER_PERSONAL_DATA);
	};

	const driverProfile = (
		<Root variant={getVariant()}>
			<SearchHeader
				showBack={profileStep !== ProfileSteps.INITIAL}
				showClose={true}
				onClose={onClose}
				onBack={getBackHandler}
				open={true}
				title={getTitle()}
				variant="primary_dark"
			/>
			{getDriverStep()}
		</Root>
	);

	const mobilePassengerProfile = (
		<Root variant={getVariant()}>
			{DiscardInvoiceChanges}
			<SearchHeader
				showBack={false}
				showClose={false}
				open={true}
				title={getTitle()}
				transparent
			/>

			<InitialProfileScreen
				onLogout={onLogout}
				onProfile={() => setProfileStep(ProfileSteps.PASSENGER_PERSONAL_DATA)}
				onPayment={() => setProfileStep(ProfileSteps.PAYMENT_DETAILS)}
				onLanguage={() => setProfileStep(ProfileSteps.LANGUAGE_SELECT)}
				onVoucher={() => setProfileStep(ProfileSteps.REDEEM_VOUCHER)}
				onTransportTypes={onTransportTypes}
				onFAQ={onFAQ}
				onImprint={onImprint}
				onCookieSettings={onCookieSettings}
				showGreeting={showGreeting}
				onGermanyTicket={() => setProfileStep(ProfileSteps.DTICKET)}
				defaultPaymentMethodType={defaultPaymentMethodType}
				subscription={subscription}
				dTicketType={dTicketType}
				showLiveUpdatesVersion={showLiveUpdatesVersion}
			/>
			<BottomSlider
				open={profileStep !== ProfileSteps.INITIAL}
				onBack={getBackHandler}
				title={getTitle()}
			>
				{getPassengerStep()}
			</BottomSlider>
		</Root>
	);

	const desktopPassengerProfile = (
		<DesktopRoot>
			<DesktopDataBlock>
				<DesktopDataTitle data-testid="desktopProfile-title">
					{t('header.my_details')}
				</DesktopDataTitle>
				{DiscardInvoiceChanges}
				<PersonalScreen
					onDeleteAccount={onDeleteAccount}
					onEdit={() => setProfileStep(ProfileSteps.PASSENGER_EDIT_USER)}
					currentUser={userData as IWhoami}
				/>
				<MyAccountTitleWithButton>
					<Typography
						data-testid="desktopProfile-headerLanguageText"
						variant="bodyTitle"
						color="neutral.100"
					>
						{t('header.language')}
					</Typography>
					<IconButton
						data-testid="desktopProfile-headerLanguageButton"
						onClick={() => setProfileStep(ProfileSteps.LANGUAGE_SELECT)}
					>
						<EditIcon />
					</IconButton>
				</MyAccountTitleWithButton>
				<DataField>{t(`profile:button.${language}`)}</DataField>
			</DesktopDataBlock>
			<DesktopDataBlock data-testid="desktopProfile-dTicketBlock">
				<DesktopDataTitle data-testid="desktopProfile-dTicketTitle">
					{t('header.germany_ticket', { dTicketType })}
				</DesktopDataTitle>
				{getDTicketManager?.({
					step: profileStep,
					changeStep: setProfileStep,
					setInvoiceAddressChanged: setInvoiceAddressChanged,
					staticFirstStep: true,
				})}
			</DesktopDataBlock>
			<DesktopDataBlock data-testid="desktopProfile-voucherBlock">
				<DesktopDataTitle data-testid="desktopProfile-vouchersTitle">
					{t('header.vouchers')}
				</DesktopDataTitle>
				<VouchersScreen />
			</DesktopDataBlock>
			{paymentScreen ? (
				<DesktopDataBlock data-testid="desktopProfile-paymentBlock">
					<DesktopDataTitle data-testid="desktopProfile-paymentTitle">
						{t('header.payment')}
					</DesktopDataTitle>
					{paymentScreen?.({ changeStep: setProfileStep })}
				</DesktopDataBlock>
			) : null}
			<SideOverlay
				open={
					profileStep === ProfileSteps.LANGUAGE_SELECT ||
					profileStep === ProfileSteps.PASSENGER_EDIT_USER ||
					profileStep === ProfileSteps.DTICKET ||
					profileStep === ProfileSteps.DTICKET_PAUSE ||
					profileStep === ProfileSteps.DTICKET_CANCEL ||
					profileStep === ProfileSteps.DTICKET_INVOICE_ADDRESS ||
					profileStep === ProfileSteps.DTICKET_PREORDERED_CANCEL ||
					profileStep === ProfileSteps.DTICKET_INVOICE_INQUIRY ||
					profileStep === ProfileSteps.DTICKET_INVOICE_INQUIRY_SELECT ||
					profileStep === ProfileSteps.FAQ
				}
				title={getTitle()}
				onOpen={() => undefined}
				onClose={getBackHandler}
			>
				{getPassengerStep()}
			</SideOverlay>
			<ImprintButton
				testId="desktopProfile-faqButton"
				variant="text"
				onClick={onFAQ}
			>
				<Typography variant="body2" color="primary">
					{t('profile:button.faq')}
				</Typography>
			</ImprintButton>
			<ImprintButton
				testId="desktopProfile-imprintButton"
				variant="text"
				onClick={onImprint}
			>
				<Typography variant="body2" color="primary">
					{t('profile:button.imprint')}
				</Typography>
			</ImprintButton>
			{windowRef.CookieScript && (
				<ImprintButton
					testId="desktopProfile-cookieSettingsButton"
					variant="text"
					onClick={onCookieSettings}
				>
					<Typography variant="body2" color="primary">
						{t('profile:button.cookie_settings')}
					</Typography>
				</ImprintButton>
			)}
			<ImprintButton
				testId="desktopProfile-transportTypesButton"
				variant="text"
				onClick={onTransportTypes}
			>
				<Typography variant="body2" color="primary">
					{t('profile:button.transport_types')}
				</Typography>
			</ImprintButton>
			<MplButton
				testId="desktopProfile-logOutButton"
				variant="outlined"
				onClick={onLogout}
				sx={{ textTransform: 'capitalize' }}
			>
				{t('profile:button.logout')}
			</MplButton>
			<MplButton
				testId="desktopProfile-deleteAccountButton"
				variant="text"
				color="error"
				onClick={onDeleteAccount}
				endIcon={<TrashIconSmall />}
				fullWidth={false}
				sx={{ justifySelf: 'center' }}
			>
				{t('button.delete_account')}
			</MplButton>
		</DesktopRoot>
	);

	if (isDriverApp) {
		return driverProfile;
	}

	return (
		<>
			{showErrorModal && (
				<ErrorModal
					testId="profileManager-errorModal"
					onSubmit={() => setShowErrorModal(false)}
				/>
			)}
			<ClosePersonalEditorModal
				onClose={closeUserEditorHandler}
				onDiscard={userEditorDiscardHandler}
				isOpen={showEditUserNotification}
			/>
			{isAboveMobile ? desktopPassengerProfile : mobilePassengerProfile}
		</>
	);
};

export const ProfileManager = withProfileManagerContext(
	ProfileManagerComponent
);
