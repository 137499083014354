import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 99999,
	backgroundColor: 'rgba(236,238,248,0.96)',
	width: '100%',
	height: '100%',
}));

export const Content = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
	alignItems: 'center',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	borderRadius: '8px',
	padding: '24px 16px',
	width: '100%',
	maxWidth: 'calc(100vw - 48px)',
	opacity: 1,

	'& > span': {
		marginBottom: 32,
	},
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.typography.title,
	color: theme.palette.primary.dark,
	marginBottom: 32,
}));

export const Text = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	color: theme.palette.primary.dark,

	'&:last-of-type': {
		marginBottom: 40,
	},
}));
