import { styled } from '@mui/material/styles';

export const MiniMapStyled = styled('div')(({ theme }) => ({
	position: 'relative',

	'&, & .map': {
		height: '100%',
	},

	'& .overlay': {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
	},

	'& .arrow': {
		position: 'absolute',
		top: 8,
		right: 8,
		zIndex: 1,
		color: theme.palette.primary.main,
	},
}));

export const FullMapStyled = styled('div')(() => ({
	position: 'relative',

	'&, & .map': {
		height: '100%',

		/** These rules fix map's "right-bottom" alignment for a Control, taking into account .infobox_bottom height */
		'& .H_l_bottom': {
			bottom: 'calc(28px + var(--inbox_top_height, 224px))',
		},

		'& .H_l_right': {
			right: '100%',
		},

		'& .H_icon.H_icon__custom': {
			fill: 'none',
		},
	},

	'& .infobox_bottom': {
		position: 'absolute',
		bottom: 28,
		left: 8,
		right: 8,
		padding: 24,
		zIndex: 2,

		'& .departure_time': {
			marginTop: 8,
		},

		'& .vehicle_details': {
			marginTop: 24,
		},

		'& .delay_msg': {
			marginTop: 4,
		},
	},

	'& .infobox_top': {
		position: 'absolute',
		top: 14,
		left: 8,
		right: 8,
		padding: 16,
		zIndex: 2,
	},
}));
