import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	'&.aboveMobile': {
		position: 'sticky',
		height: 'max-content',
		top: 24,
		borderRadius: 8,
		overflow: 'hidden',
	},
}));

export const TopBlockWrapper = styled('div')(({ theme }) => ({
	lineHeight: 1,
	textAlign: 'right',
	borderRadius: '8px 8px 0 0',
	marginBottom: '-16px',

	'.aboveMobile &': {
		background: theme.palette.neutral[0],
		padding: '16px 24px',
	},

	'.mobile &': {
		background: theme.palette.primary.dark,
		padding: '16px 24px 32px',
	},
}));
