import { ComponentProps, FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import cn from 'classnames';

import { getThemeColor, TMoplaColors } from '@mopla/mui';
import { useBreakpoints } from '@mopla/utils';

interface IOwnerState {
	background?: TMoplaColors;
	borderTop?: boolean;
}

const FooterStyled = styled('div')<{
	ownerState: IOwnerState;
}>(({ theme, ownerState: { background, borderTop = true } }) => ({
	width: '100%',
	padding: '16px 24px 50px',
	boxSizing: 'border-box',

	...(background && { background: getThemeColor(background, theme) }),

	'&.mobile': {
		borderRadius: '8px 8px 0 0',
	},

	'&.aboveMobile': {
		padding: '16px 24px',
		borderTop: borderTop ? `1px solid ${theme.palette.neutral[40]}` : undefined,
	},
}));

interface IFooterProps
	extends Omit<ComponentProps<typeof FooterStyled>, 'ownerState'> {
	enforceMobile?: boolean;
	enforceAboveMobile?: boolean;
	background?: TMoplaColors;
	borderTop?: boolean;
	testId?: string;
}

export const Footer: FC<IFooterProps> = ({
	enforceMobile,
	enforceAboveMobile,
	className,
	background,
	borderTop,
	testId = 'footer',
	...rest
}) => {
	const { isMobile, isAboveMobile } = useBreakpoints({
		enforceMobile,
		enforceAboveMobile,
	});

	const ownerState = useMemo<IOwnerState>(
		() => ({ background, borderTop }),
		[background, borderTop]
	);

	return (
		<FooterStyled
			{...rest}
			data-testid={testId}
			ownerState={ownerState}
			className={cn(
				className,
				isMobile && 'mobile',
				isAboveMobile && 'aboveMobile'
			)}
		/>
	);
};
