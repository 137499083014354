interface IGetOpts {
	safe?: boolean;
}

export const localStorageManager = <T, U = T>(
	key: string,
	storage: Storage = localStorage
) => {
	const _storage = storage;

	function get(options: { safe: false }): T | null;
	function get(options: { safe: true }): T | string | null;
	function get(): T | null;
	function get(options?: IGetOpts): T | null | string {
		const val = _storage.getItem(key);

		if (!val) {
			return null;
		}

		if (options?.safe) {
			try {
				return JSON.parse(val) as T;
			} catch (e) {
				return val;
			}
		} else {
			/** Strict return type as T when we are sure that LocalStorage contains valid JSON value */
			return JSON.parse(val) as T;
		}
	}

	const set = (val: U) => {
		_storage.setItem(key, JSON.stringify(val));
	};

	const clear = () => {
		_storage.removeItem(key);
	};

	return {
		get,
		set,
		clear,
	};
};
