import React, { useState } from 'react';
import { IconButton, Stack, SvgIcon, Typography } from '@mui/material';

import { CheckmarkIcon } from '../../icons';
import { DropdownIcon } from '../DropdownIcon/DropdownIcon';

import {
	HeaderWrapper,
	Root,
	SeparatorSolid,
} from './ConditionsDropdown.styles';

export interface ConditionsDropdownProps {
	title: string;
	conditions: Array<string>;
}

export const ConditionsDropdown: React.FC<ConditionsDropdownProps> = ({
	title,
	conditions,
}) => {
	const [open, setOpen] = useState<boolean>(true);

	return (
		<Root>
			<HeaderWrapper>
				<Typography variant="body1" fontWeight={600}>
					{title}
				</Typography>
				<IconButton
					aria-label="toggle conditions dropdown"
					onClick={() => setOpen((prev) => !prev)}
					onMouseDown={() => setOpen((prev) => !prev)}
				>
					<DropdownIcon isOpened={open} />
				</IconButton>
			</HeaderWrapper>
			{open && (
				<>
					<SeparatorSolid />
					<Stack spacing={1}>
						{conditions.map((condition) => (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								key={condition}
							>
								<SvgIcon fontSize="medium" color="secondary">
									<CheckmarkIcon />
								</SvgIcon>
								<Typography variant="body2">{condition}</Typography>
							</Stack>
						))}
					</Stack>
				</>
			)}
		</Root>
	);
};

export default ConditionsDropdown;
