import { styled } from '@mui/material/styles';
import { Swiper } from 'swiper/react';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridAutoRows: 'max-content',
}));

export const SwiperStyled = styled(Swiper)(() => ({
	width: '100%',
	margin: -8,

	'&.swiper': {
		padding: 8,
	},
})) as typeof Swiper;

export const PaginationWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 22,

	'& .swiper-pagination-bullet': {
		backgroundColor: theme.palette.primary.light,
		opacity: 1,
	},

	'& .swiper-pagination-bullet-active': {
		backgroundColor: theme.palette.primary.main,
	},
}));
