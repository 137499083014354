import { MobileStepper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BlockRoot = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '16px 0 12px 0',
	height: 330,
	width: '100%',
}));

export const MarketingItem = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 8,
	background: theme.palette.neutral[0],
	borderRadius: 8,
	padding: '34px 16px',
	boxSizing: 'border-box',
	'@media(min-width: 601px)': {
		padding: '34px 153px',
	},
}));

export const CustomStepper = styled(MobileStepper)(({ theme }) => ({
	background: 'transparent',
	justifyContent: 'center',
	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.primary.main,
	},
	marginBottom: 32,
}));
