import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import cn from 'classnames';

import { useTranslation } from '@mopla/i18';

import {
	BookingLegLiveMap,
	CurrentGeoPositionProvider,
	ExternalLinkIcon,
	RouteMap,
} from '../../..';

import { useLegDetailsContext } from './context';
import { IntermediateStopsRender } from './IntermediateStopsRender';
import { liveMapRenderer } from './liveMapRenderer';
import {
	ExternalLinkStyled,
	MiddleCol,
	MidRightCol,
	RightCol,
	TextStyled,
	TransportLegRoot,
} from './styled';
import { TimeText } from './TimeText';
import { TransportPath } from './TransportPath';
import { TransportView } from './TransportView';
import { ILegDetailsProps } from './types';

interface ILocationLabelProps {
	onClick: MouseEventHandler;
}

const LocationLabel: FC<PropsWithChildren<ILocationLabelProps>> = ({
	onClick,
	children,
}) => (
	<a onClick={onClick}>
		<TextStyled
			className="maybe-disabled link"
			variant="body1"
			fontWeight={600}
			noWrap
		>
			{children}
		</TextStyled>
	</a>
);

export const TransportLeg: FC<ILegDetailsProps> = (props) => {
	const legData = useLegDetailsContext();
	const { disabled } = legData;
	const { t } = useTranslation('booking');

	return (
		<IntermediateStopsRender {...props}>
			{(intermediateStops) => (
				<TransportLegRoot
					ownerState={{
						isTimeNarrow: legData.isTimeNarrow,
						isDisabled: disabled,
					}}
				>
					<TransportPath className="path" transportType={legData.mode} />
					{!disabled && (
						<MiddleCol className="startTime">
							<TimeText
								variant={legData.isStartEndDateFix ? 'fixed' : 'nonfixed'}
							>
								{legData.startTime}
							</TimeText>
						</MiddleCol>
					)}
					<RightCol className="startTitle">
						<LocationLabel onClick={legData.onStartPointClick}>
							{legData.startPointLabel}
						</LocationLabel>
						{legData.startPointAddress && (
							<TextStyled variant="body2" color="neutral.80">
								{legData.startPointAddress}
							</TextStyled>
						)}
						{legData.startPointUrl && (
							<ExternalLinkStyled url={legData.startPointUrl}>
								{t('text.more_about_location')}
								<ExternalLinkIcon />
							</ExternalLinkStyled>
						)}
						{intermediateStops.header}
					</RightCol>
					{intermediateStops.content}
					<MidRightCol sx={{ height: 16 }} />
					<TransportView {...props} />
					{legData.mapWaypoints && (
						<MidRightCol sx={{ mt: 2, mb: 2 }}>
							<RouteMap waypoints={legData.mapWaypoints} showRoute={false} />
						</MidRightCol>
					)}
					{legData.showLiveMap && (
						<CurrentGeoPositionProvider>
							<BookingLegLiveMap
								bookingLeg={props.leg}
								render={liveMapRenderer}
							/>
						</CurrentGeoPositionProvider>
					)}
					{!disabled && (
						<MiddleCol className="endTime">
							<TimeText
								variant={legData.isStartEndDateFix ? 'fixed' : 'nonfixed'}
							>
								{legData.endTime}
							</TimeText>
						</MiddleCol>
					)}
					<RightCol className="endTitle">
						<LocationLabel onClick={legData.onEndPointClick}>
							{legData.endPointLabel}
						</LocationLabel>
						{legData.endPointAddress && (
							<TextStyled variant="body2" color="neutral.80">
								{legData.endPointAddress}
							</TextStyled>
						)}
						{legData.endPointUrl && (
							<ExternalLinkStyled url={legData.endPointUrl}>
								{t('text.more_about_location')}
								<ExternalLinkIcon />
							</ExternalLinkStyled>
						)}
					</RightCol>
				</TransportLegRoot>
			)}
		</IntermediateStopsRender>
	);
};
