import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal, Typography } from '@mui/material';

import { MplButton } from '../../';
import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { StayInformedAnim } from '../../molecules/Animations';
import { Modal } from '../../molecules/Modal/Modal';

export interface IClosePersonalEditorModalProps {
	isOpen: boolean;
	onClose(): void;
	onDiscard(): void;
}

export const ClosePersonalEditorModal: FC<IClosePersonalEditorModalProps> = ({
	onClose,
	isOpen,
	onDiscard,
}) => {
	const [portalDom] = useState(document.getElementById('portal'));
	const { t } = useTranslation(['profile', 'common']);

	return (
		<Portal container={portalDom}>
			<Modal
				open={isOpen}
				animationElement={<StayInformedAnim />}
				onClose={onClose}
				mainContent={
					<FlexColumn gap={24}>
						<Typography variant="title">{t('title.modal_title')}</Typography>
						<Typography variant="body1">{t('title.modal_message')}</Typography>
					</FlexColumn>
				}
				footerComp={
					<FlexColumn gap={16} sx={{ mt: 4 }}>
						<MplButton variant="text" onClick={onClose}>
							{t('button.return')}
						</MplButton>
						<MplButton onClick={onDiscard}>{t('button.discard')}</MplButton>
					</FlexColumn>
				}
			/>
		</Portal>
	);
};
