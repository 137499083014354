import { FC, Fragment, ReactElement, ReactNode, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { formatPrice } from '@mopla/utils';

import { NoWrap } from './styled';

type TRender = (parts: {
	priceItems: string[];
	previousPrice?: ReactNode;
	separator?: ReactNode;
}) => ReactElement;

export interface IPriceStringProps {
	price?: number | null;
	priceRangePerPerson?: [number, number] | null;
	hasPriceTariff?: boolean | null;
	/** if set, then either the price|priceRangePerPerson or hasPriceTariff will be shown */
	single?: boolean;
	separator?: ReactNode;
	previousPrice?: number;
	render?: TRender;
}

const renderPriceString: TRender = ({ priceItems, separator }) => {
	return (
		<>
			{priceItems.map((item, i, s) => (
				<Fragment key={i}>
					<NoWrap>{item}</NoWrap>
					{!!s[i + 1] && separator}
				</Fragment>
			))}
		</>
	);
};

/** TODO important: cover with tests and fix current behavior */
export const PriceString: FC<IPriceStringProps> = ({
	priceRangePerPerson,
	price,
	hasPriceTariff,
	single,
	separator = ' + ',
	previousPrice,
	render = renderPriceString,
}) => {
	const { t } = useTranslation();

	const priceItems = useMemo(() => {
		if (!priceRangePerPerson && price == null && hasPriceTariff == null) {
			return null;
		}

		const priceAccordingToTariff =
			hasPriceTariff && t('booking:button.price_according_to_tariff');

		const formattedPrice = (
			priceRangePerPerson
				? formatPriceRange(...priceRangePerPerson, t)
				: price != null
				? formatPrice(price / 100)
				: null
		) as string;

		/** If single, pick either formattedPrice (1st priority, if the value is meaningful) or priceAccordingToTariff or null */
		if (single) {
			return priceAccordingToTariff
				? [priceAccordingToTariff]
				: formattedPrice
				? [formattedPrice]
				: null;
		}

		return [formattedPrice, priceAccordingToTariff].filter(
			(v): v is string => !!v
		);
	}, [hasPriceTariff, price, priceRangePerPerson, single, t]);

	if (!priceItems) {
		return null;
	}

	return render({
		priceItems,
		separator,
		previousPrice: previousPrice && formatPrice(previousPrice / 100),
	});
};

function formatPriceRange(min: number, max: number, t: TFunction) {
	return t('booking:button.price_range', {
		range: `${formatPrice(min / 100, false)} - ${formatPrice(max / 100)}`,
	});
}
