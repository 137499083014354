import React from 'react';

import { ArrowRightIcon, MplButton } from '../../';

import { Root } from './CrashMessage.styles';

interface ICrash {
	onCLick?: (r: string) => void;
}

export const CrashMessage: React.FunctionComponent<ICrash> = ({ onCLick }) => {
	return (
		<Root>
			<h1>Something went wrong</h1>
			<MplButton
				onClick={() => onCLick && onCLick('/home')}
				endIcon={<ArrowRightIcon />}
			>
				I'm good
			</MplButton>
		</Root>
	);
};

export default CrashMessage;
