import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ECancellationReasonPassengerBooking } from '@mopla/data-models';

import { ICancellationReasonFormState } from './types';

export const ValidationSchema = yup.object().shape(
	{
		reason: yup.string().when('otherReasonText', {
			is: (v: string) => !v,
			then: (s) => s.required(),
		}),
		otherReasonText: yup.string().when('reason', {
			is: ECancellationReasonPassengerBooking.OtherReason,
			then: (s) => s.required(),
		}),
	},
	[['reason', 'otherReasonText']]
);

export const useCancellationReasonForm = () => {
	return useForm<ICancellationReasonFormState>({
		defaultValues: { reason: '', otherReasonText: '' },
		resolver: yupResolver(ValidationSchema),
		mode: 'onChange',
	});
};
