import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import {
	useDTicketSubscriptionOffering,
	useSpenditToken,
} from '@mopla/business-logic';
import { ESubscriptionType, IWhoami } from '@mopla/data-models';
import { getDTicketType } from '@mopla/utils';

import { ArrowRightIcon, ErrorModal, Input, MplButton } from '../../';

import {
	AccountSubtitleWrapper,
	Root,
	TicketSubtitleWrapper,
} from './PersonalDetailsForm.styles';

interface IProps {
	userData?: IWhoami;
}

export const JobticketDetailsForm: FC<IProps> = ({ userData }) => {
	const { t } = useTranslation(['personalDetails']);
	const [error, setError] = useState<boolean>(false);

	const { subscriptionOffering, refetch } = useDTicketSubscriptionOffering();
	const { deleteSpenditInfo } = useSpenditToken();
	const { isJobticket, subscriptionType } = subscriptionOffering || {};
	const isSpenditType = subscriptionType === ESubscriptionType.SPENDIT;
	const dTicketType = getDTicketType(subscriptionOffering?.subscriptionType);

	if (!isJobticket) return null;

	const handleRemoveSpenditData = async () => {
		try {
			await deleteSpenditInfo();
			await refetch();
		} catch (e) {
			console.error('Error on removing Spendit information: ', e);
			setError(true);
		}
	};
	return (
		<Root withPaddings>
			{error && <ErrorModal onSubmit={() => setError(false)} />}
			<TicketSubtitleWrapper>
				<Typography variant="bodyTitle">
					{t('text.subtitle_jobticket', { dTicketType })}
				</Typography>
			</TicketSubtitleWrapper>
			<Input
				value={userData?.metaData.jobticketFirstName}
				label={t('label.firstName_jobticket', { dTicketType })}
				inputProps={{
					'data-testid': 'jobticket-detailsForm-name-textField',
				}}
				disabled
			/>
			<Input
				value={userData?.metaData.jobticketLastName}
				label={t('label.surname_jobticket', { dTicketType })}
				inputProps={{
					'data-testid': 'jobticket-detailsForm-surname-textField',
				}}
				disabled
			/>
			{isSpenditType && (
				<MplButton
					endIcon={<ArrowRightIcon />}
					data-testid="jobticket-detailsForm-submit-button"
					onClick={handleRemoveSpenditData}
					disabled={!!userData?.isEligibleForJobticket}
				>
					{t('button.remove_jobticket_data')}
				</MplButton>
			)}
			<AccountSubtitleWrapper>
				<Typography variant="bodyTitle">
					{t('text.subtitle_account')}
				</Typography>
			</AccountSubtitleWrapper>
		</Root>
	);
};
