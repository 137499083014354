import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	padding: '0 8px',
	boxSizing: 'border-box',
	display: 'grid',
	gridTemplateColumns: '1fr',
	width: '100%',
	height: '100%',
}));

export const LoaderWrapper = styled('div')(() => ({
	padding: 16,
	boxSizing: 'border-box',
	borderRadius: 8,
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	background:
		'linear-gradient(172.74deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 73.15%);',
	width: '100%',
	height: '100%',
}));

export const TitleBlock = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content',
	gridTemplateColumns: 'max-content 1fr',
	justifyItems: 'end',
	marginBottom: 32,
}));

export const Title = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 6,
}));

export const StopBlock = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: 'max-content',
	gridTemplateColumns: '70px 28px max-content',
	marginBottom: 24,
}));

export const SkeletonBlock = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'width' && prop !== 'height' && prop !== 'radius',
})<{ width: number; height: number; radius: number }>(
	({ theme, width, height, radius }) => ({
		background: theme.palette.neutral[40],
		height: height,
		width: width,
		borderRadius: radius,
		animation: `contentLoaderAnimation 1.5s infinite ease`,

		'@keyframes contentLoaderAnimation': {
			'0%': {
				opacity: 1,
			},
			'50%': {
				opacity: 0.3,
			},
			'100%': {
				opacity: 1,
			},
		},
	})
);
