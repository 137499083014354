import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BrowserLink } from '../../../';

import type { TTimeTextVariant } from './TimeText';
import type { TWalkLegVariant } from './WalkLeg';

interface IRootOwnerState {
	isTimeNarrow?: boolean;
	isDisabled?: boolean;
}

const LegGrid = styled('div')<{ ownerState: IRootOwnerState }>(
	({ ownerState, theme }) => ({
		/** English and German time formats are different: "5:15 PM" vs "17:15". This is workarounded using {isTimeNarrow}*/
		display: 'grid',
		gap: '0 16px',
		gridAutoRows: 'max-content',

		...(!ownerState.isDisabled && {
			gridTemplateColumns: `[a] 32px [b] ${
				ownerState.isTimeNarrow ? '47px' : '80px'
			} [c] 1fr [d]`,
		}),

		...(ownerState.isDisabled && {
			gridTemplateColumns: `[a] 32px [b c] 1fr [d]`,
		}),

		'& .path': {
			justifySelf: 'center',
		},

		'& .startTitle, & .endTitle': {
			display: 'flex',
			flexDirection: 'column',
			gap: 4,
			overflowX: 'hidden',
		},

		'& .startTitle, & .startTime': {
			alignSelf: 'start',
		},

		'& .endTitle, & .endTime': {
			alignSelf: 'start',
		},

		...(ownerState.isDisabled && {
			'& .maybe-disabled': {
				color: theme.palette.neutral[80],
			},
		}),
	})
);

export const TransportLegRoot = styled(LegGrid)(() => ({
	'& .path': {
		/** Height of the grid isn't known and it is impossible to set gridRow: 1 / -1. Big number workarounds it well */
		gridRow: '1 / 500',
	},

	'& .startTime, & .startTitle': {
		marginTop: 3,
	},

	'& .endTime, & .endTitle': {
		marginBottom: 2,
		marginTop: 16,
	},

	'& .routeNumber': {
		placeSelf: 'start',
	},
}));

export const WalkLegRoot = styled(LegGrid)(() => ({
	gridTemplateRows: 'max-content 1fr max-content',

	'& .startTime, & .endTime': {
		alignSelf: 'center',
	},

	'& .path': {
		gridRow: '1 / span 3',
	},

	'& .distance': {
		gridRow: 2,
		alignSelf: 'center',

		'&.middle': {
			gridColumn: 'b / d',
		},
	},

	'& .endTime, & .endTitle': {
		gridRow: 3,
	},
}));

export const MiddleCol = styled('div')(() => ({
	gridColumn: 'b / c',
}));

export const RightCol = styled('div')(() => ({
	gridColumn: 'c / d',
}));

export const MidRightCol = styled('div')(() => ({
	gridColumn: 'b / d',
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
	display: 'block',
	maxWidth: '100%',
	overflow: 'hidden',

	'&.link': {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}));

export const ExternalLinkStyled = styled(BrowserLink)(({ theme }) => ({
	...theme.typography.caption,
	display: 'flex',
	alignItems: 'center',
	gap: 4,
	textDecoration: 'underline',
	cursor: 'pointer',
	overflow: 'hidden',

	'& svg': {
		display: 'block',
	},
}));

export const TimeTextStyled = styled('span')<{
	ownerState: { variant: TTimeTextVariant };
}>(({ ownerState: { variant }, theme }) => ({
	display: 'block',
	maxWidth: '100%',
	whiteSpace: 'nowrap',

	...(variant === 'fixed' && {
		...theme.typography.body1,
		fontWeight: 600,
		color: theme.palette.secondary.dark,
	}),
	...(variant === 'nonfixed' && {
		...theme.typography.body1,
		color: theme.palette.neutral[80],
	}),
	...(variant === 'intermediate' && {
		...theme.typography.body2,
		color: theme.palette.neutral[80],
	}),
}));

export const StopsDropdownHeader = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	color: theme.palette.neutral[80],
	userSelect: 'none',
	cursor: 'pointer',
}));

export const RouteNumber = styled('div')(({ theme }) => ({
	...theme.typography.body2,
	fontWeight: 600,
	padding: '0 12px',
	borderRadius: 8,
	color: theme.palette.primary.main,
	backgroundColor: theme.palette.secondary.main,
}));

export const LegPriceWrapper = styled('div')(() => ({
	marginTop: 4,
}));

export const WalkLegPath = styled('div')<{
	ownerState: { variant: TWalkLegVariant };
}>(({ ownerState: { variant }, theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: 8,
	color: theme.palette.primary.main,

	...(variant === 'disabled' && {
		color: theme.palette.neutral[80],
	}),
}));

export const LiveMapWrapper = styled('div')(() => ({
	height: 150,
	borderRadius: 8,
	overflow: 'hidden',
}));
