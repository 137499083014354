import { ButtonHTMLAttributes, CSSProperties, ReactElement } from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';

import { Content, LoaderWrapper, Root } from './Button.styles';

export enum IconPosition {
	left = 'left',
	right = 'right',
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	iconPosition?: string;
	justify?: CSSProperties['justifyContent'];
	icon?: ReactElement;
	showLoader?: boolean;
	testId?: string;
}

/** TODO MC-5191 remove this deprecated button */
export const DEPRECATED_Button = ({
	iconPosition = IconPosition.right,
	icon,
	children,
	type = 'button',
	variant = 'contained',
	color = 'primary',
	showLoader,
	justify,
	testId,
	...rest
}: IButtonProps & ButtonProps) => {
	return (
		<Root
			data-testid={testId}
			variant={variant}
			color={color}
			type={type}
			disableFocusRipple
			endIcon={iconPosition === IconPosition.right ? icon : null}
			startIcon={iconPosition === IconPosition.left ? icon : null}
			ownerState={{ justify }}
			{...rest}
		>
			<Content hide={showLoader}>{children}</Content>
			{showLoader && (
				<LoaderWrapper>
					<CircularProgress size={24} color="inherit" />
				</LoaderWrapper>
			)}
		</Root>
	);
};
