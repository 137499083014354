import { useTranslation } from 'react-i18next';

import { PushActionTypes, useBusinessLayer } from '@mopla/business-logic';
import { useBooleanState } from '@mopla/utils';

import { FailedAnim } from '../../molecules/Animations';
import { InfoModal } from '../InfoModal/InfoModal';

export const TripDelayModal = () => {
	const { t } = useTranslation();

	const businessLayer = useBusinessLayer();

	const [isNotificationOpened, openNotification, closeNotification] =
		useBooleanState(false);

	businessLayer.watchActions({
		types: PushActionTypes.TripDelayed,
		callback: openNotification,
	});

	return isNotificationOpened ? (
		<InfoModal
			onSubmit={closeNotification}
			icon={<FailedAnim />}
			title={t('notifications:push.delay.title')}
			infoText={t('notifications:push.delay.info')}
			submitTitle={t('common:button.ok')}
			testId="TripDelayModal"
		/>
	) : null;
};
