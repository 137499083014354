import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { TGermanyTicketType } from '@mopla/data-models';
import { FlexColumn } from '@mopla/ui';

import { DTicketTicketView } from '../../DTicketTicketView/DTicketTicketView';
import { ConfirmationWrapper } from '../DTicketSubscription.styles';
import { ISubscriptionFormValues } from '../types';

interface IProps {
	onSubmit: VoidFunction;
	distributor: string;
	dTicketType: TGermanyTicketType;
}

export const ConfirmationStep: React.FC<IProps> = (props) => {
	const { onSubmit, distributor, dTicketType } = props;
	const { t } = useTranslation(['dticket']);
	const { watch } = useFormContext<ISubscriptionFormValues>();
	const monthOfStart = watch('monthOfStart');

	return (
		<ConfirmationWrapper>
			<FlexColumn
				className="space-between"
				style={{ background: 'transparent' }}
			>
				<Typography variant="body1" color="neutral.0">
					{t('text.dticket_stored', { dTicketType })}
				</Typography>
				<Typography variant="body1" color="neutral.0">
					{t('text.dticket_stored2')}
				</Typography>
			</FlexColumn>
			<DTicketTicketView
				onSubmit={onSubmit}
				distributor={distributor}
				dTicketType={dTicketType}
				monthOfStart={monthOfStart}
			/>
		</ConfirmationWrapper>
	);
};
