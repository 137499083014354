import { CSSProperties, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/capacitor';

/**
 * Helps with getting reactive value of the bottom infobox.
 * The height is needed to set correct offset of the map's bottom UI block
 * */
export const useInfoboxHeightCss = () => {
	const [node, setNode] = useState<HTMLDivElement | null>(null);
	const [heightCss, setHeightCss] = useState<CSSProperties>({});

	useEffect(() => {
		if (!node) {
			return;
		}

		const ro = new ResizeObserver((entries) => {
			try {
				const entry = entries[0];
				const blockSize = entry.borderBoxSize[0].blockSize;

				setHeightCss({
					'--inbox_top_height': `${blockSize}px`,
				} as CSSProperties);
			} catch (e) {
				Sentry.captureException(e);
			}
		});

		ro.observe(node);

		return () => ro.unobserve(node);
	}, [node]);

	return useMemo(() => ({ setNode, heightCss }), [setNode, heightCss]);
};
