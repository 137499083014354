import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Itinerary } from '@mopla/data-models';
import {
	checkTimeMightChange,
	checkTimeOverwritten,
	getDurationStr,
	getItineraryStopsCount,
	getItineraryTiming,
} from '@mopla/utils';

import { DepartureIcon, FlexColumn, IValueProps, Value } from '../../index';

import { DepartureWrapper } from './styled';

interface ITimeDisplayOption {
	variant: IValueProps['variant'];
	icon?: ReactNode;
}

interface ITimeDisplay {
	default: ITimeDisplayOption;
	cancelled: ITimeDisplayOption;
	fixed: ITimeDisplayOption;
	overwritten: ITimeDisplayOption;
}

export interface IItineraryDateTimeInfoProps {
	className?: string;
	route: Itinerary;
	isCancelled?: boolean;
	showTimeColors?: boolean;
	dateTestId?: string;
	timeTestId?: string;
	overwrittenTimeTestId?: string;
	durationTestId?: string;
	timeDisplayOverride?: Partial<ITimeDisplay>;
	showDurationInfo?: boolean;
}

const defaultTimeDisplayCfg: ITimeDisplay = {
	default: { variant: 'default' },
	cancelled: { variant: 'error' },
	fixed: { variant: 'success' },
	overwritten: { variant: 'attention' },
};

export const ItineraryDateTimeInfo: FC<IItineraryDateTimeInfoProps> = ({
	className,
	route,
	isCancelled,
	showTimeColors,
	dateTestId,
	timeTestId,
	overwrittenTimeTestId,
	durationTestId,
	timeDisplayOverride,
	showDurationInfo = true,
}) => {
	const { t } = useTranslation('common');
	const timeMightChange = checkTimeMightChange(route);
	const stopsCount = getItineraryStopsCount(route);
	const isTimeOverwritten = checkTimeOverwritten(route);
	const { timeStr, overwrittenTimeStr, startDate, durationStr } =
		useMemo(() => {
			const {
				actualStartTime,
				actualEndTime,
				startTime,
				endTime,
				startDate,
				duration,
			} = getItineraryTiming(route);

			const timeStr = `${actualStartTime} - ${actualEndTime}`;
			const overwrittenTimeStr =
				isTimeOverwritten && `${startTime} - ${endTime}`;

			const durationStr = `${t('text.around')} ${getDurationStr(t, duration, [
				'seconds',
			])}`;

			return {
				timeStr,
				overwrittenTimeStr,
				startDate,
				durationStr,
				duration,
			};
		}, [route, isTimeOverwritten, t]);

	const timeDisplay = useMemo((): ITimeDisplayOption => {
		const timeDisplayConfig = Object.assign(
			{},
			defaultTimeDisplayCfg,
			timeDisplayOverride
		);

		if (!showTimeColors && !timeDisplayOverride) {
			return timeDisplayConfig['default'];
		}

		if (isCancelled) {
			return timeDisplayConfig['cancelled'];
		}

		if (!timeMightChange) {
			return timeDisplayConfig['fixed'];
		}

		if (isTimeOverwritten) {
			return timeDisplayConfig['overwritten'];
		}

		return timeDisplayConfig['default'];
	}, [
		isCancelled,
		isTimeOverwritten,
		showTimeColors,
		timeDisplayOverride,
		timeMightChange,
	]);

	return (
		<FlexColumn className={className}>
			<DepartureWrapper>
				<DepartureIcon />
				<Typography
					data-testid={dateTestId}
					variant="body2"
					color="neutral.100"
				>
					{startDate}
				</Typography>
			</DepartureWrapper>
			{showDurationInfo && (
				<>
					{!isCancelled && timeMightChange && overwrittenTimeStr && (
						<Typography
							variant="body2"
							color="neutral.80"
							sx={{ textDecoration: 'line-through' }}
							data-testid={overwrittenTimeTestId}
						>
							{overwrittenTimeStr}
						</Typography>
					)}
					<Value
						value={timeStr}
						icon={timeDisplay.icon}
						variant={timeDisplay.variant}
						testId={timeTestId}
					/>
					<Typography data-testid={durationTestId} variant="body2">
						{durationStr}
						{stopsCount > 0 &&
							`, ${t('common:text.stops', {
								count: stopsCount,
							})}`}
					</Typography>
				</>
			)}
		</FlexColumn>
	);
};
