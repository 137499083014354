import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import bannerUrl from './banner.svg?url';

export const Root = styled('div')(({ theme }) => ({
	boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.15)',
	borderRadius: 8,
	minHeight: 150,
	overflow: 'hidden',
	background: `${theme.palette.neutral[0]} url(${bannerUrl}) no-repeat`,
	backgroundPosition: 'bottom left',

	'& .content': {
		display: 'flex',
		flexDirection: 'column',
		padding: '16px 24px',
		height: '100%',
		boxSizing: 'border-box',
	},

	'& .description': {
		marginTop: 2,
	},

	'&::before': {
		content: '""',
	},

	'&:hover': {
		cursor: 'pointer',
	},
}));

export const SeeMoreButton = styled(Button)(() => ({
	width: 'fit-content',
	padding: 0,
	textTransform: 'initial',
	textDecoration: 'underline',
	alignSelf: 'end',
	marginTop: 45,

	'&:hover': {
		backgroundColor: 'transparent',
		textDecoration: 'underline',
	},
}));
