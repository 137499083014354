import React from 'react';
import { CircularProgress, Portal, Typography } from '@mui/material';

import { FlexContainer, MplButton } from '../../';

import { ButtonWrapper, Form, IconContainer, Root } from './InfoModal.styles';

export interface InfoModalProps {
	title: string;
	submitTitle: string;
	infoText: string | React.ReactNode;
	onSubmit: VoidFunction;
	onCancel?: VoidFunction;
	cancelTitle?: string;
	isCancelDisabled?: boolean;
	additionalInfo?: React.ReactNode | string;
	icon?: React.ReactNode;
	submitIcon?: React.ReactElement;
	isSubmitDisabled?: boolean;
	isLoading?: boolean;
	isSubmitting?: boolean;
	textAlign?: 'left' | 'right' | 'center';
	isButtonReverse?: boolean;
	outlinedCancelButton?: boolean;
	testId?: string;
	buttonsBlock?: React.ReactNode;
}

export const InfoModal: React.FC<InfoModalProps> = ({
	title,
	submitTitle,
	infoText,
	onSubmit,
	cancelTitle,
	onCancel,
	additionalInfo,
	icon,
	submitIcon,
	isLoading = false,
	textAlign = 'left',
	isButtonReverse = false,
	outlinedCancelButton = true,
	testId = 'infoModal',
	buttonsBlock,
	isSubmitDisabled,
	isSubmitting,
	isCancelDisabled,
}) => (
	<Portal>
		<Root textAlign={textAlign} data-testid={testId}>
			<Form data-testid="infoModal-form">
				{icon && <IconContainer className="center">{icon}</IconContainer>}
				<Typography variant="title" color="neutral.100">
					{title}
				</Typography>
				{isLoading && (
					<FlexContainer className="center">
						<CircularProgress />
					</FlexContainer>
				)}
				{!isLoading && (
					<>
						<Typography variant="body1" color="neutral.100">
							{infoText}
						</Typography>
						{additionalInfo && (
							<Typography variant="body1" color="neutral.100">
								{additionalInfo}
							</Typography>
						)}
					</>
				)}
				<ButtonWrapper
					className="space-between"
					isButtonReverse={isButtonReverse}
				>
					{buttonsBlock}
					{!buttonsBlock && (
						<>
							<MplButton
								onClick={onSubmit}
								endIcon={submitIcon}
								disabled={isLoading || isSubmitDisabled}
								showLoader={isSubmitting}
								data-testid="primary-action-button"
							>
								{submitTitle}
							</MplButton>
							{cancelTitle && (
								<MplButton
									variant={outlinedCancelButton ? 'outlined' : 'text'}
									onClick={onCancel}
									disabled={isLoading || isCancelDisabled}
									data-testid="secondary-action-button"
								>
									{cancelTitle}
								</MplButton>
							)}
						</>
					)}
				</ButtonWrapper>
			</Form>
		</Root>
	</Portal>
);
