import { useCallback, useContext } from 'react';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import * as Sentry from '@sentry/capacitor';

import {
	addPushToken as addPushTokenAction,
	initPassengerPushes,
} from '../actions/pushNotificationActions';
import { BusinessLayerContext } from '../business-logic';

interface IPushesFunctions {
	initPushes: VoidFunction;
	sendMessagingToken: VoidFunction;
}

export const usePassengerPushes = (): IPushesFunctions => {
	const businessLayer = useContext(BusinessLayerContext);

	const initPushes = () => {
		businessLayer.dispatch(initPassengerPushes());
	};

	const sendMessagingToken = useCallback(async () => {
		const messagingPermissions = await FirebaseMessaging.checkPermissions();
		if (messagingPermissions.receive === 'granted') {
			try {
				const result = await FirebaseMessaging.getToken();
				businessLayer.dispatch(addPushTokenAction(result));
			} catch (err) {
				Sentry.captureException(err);
			}
		}
	}, [businessLayer]);

	return {
		initPushes,
		sendMessagingToken,
	};
};
