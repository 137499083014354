import { RxDatabase } from 'rxdb';

import {
	Api,
	IPRODTSearch,
	ProdtSearchPatchParams,
	ProdtSearchPostParams,
} from '@mopla/data-models';

import { IPRODTItinerariesAPI } from './types';

export class PRODTApi implements IPRODTItinerariesAPI {
	constructor(private api: Api, private db: RxDatabase) {}

	async postSearch(payload: ProdtSearchPostParams): Promise<IPRODTSearch> {
		return (await this.api.post(
			'/api/router/prodt/search',
			payload
		)) as IPRODTSearch;
	}

	async getSearch(id: string): Promise<IPRODTSearch> {
		return (await this.api.get(
			`/api/router/prodt/search/${id}`
		)) as IPRODTSearch;
	}

	async patchSearch(
		id: string,
		payload: ProdtSearchPatchParams
	): Promise<void> {
		await this.api.patch(`/api/router/prodt/search/${id}`, payload);
	}

	async deleteSearch(id: string): Promise<void> {
		await this.api.delete(`/api/router/prodt/search/${id}`);
	}
}
