import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { localStorageManager } from '@mopla/utils';

import { deletePRODTSearch, useBusinessLayer } from '../../';

interface IPRODTPrebookingData {
	id: string;
	reservedUntil?: string;
}

export const currentPRODTPrebookingIdLS =
	localStorageManager<IPRODTPrebookingData | null>('currentPRODTPrebookingId');

/**
 * When a user searches for a PRODT ride, the ride is initially reserved (prebooked) for n minutes.
 * This hook ensures that any unnecessary prebooking is cleaned up (http DELETE request) properly
 * This is important because the driver remains frozen for the duration of the prebooking.
 * It covers cases when the app/browser is closed and reopened again right away.
 * Scenario:
 * 1. User is searching for a PRODT ride. A ride is found and reserved for n minutes.
 *    * If the app/browser gets closed accidentally, the prebooking will be lost and forgotten.
 * 2. The user reopens the app/browser.
 * 3. The prebooking must be cancelled (DELETE endpoint).
 * */
export const usePRODTPrebookingCleanup = () => {
	const { dispatch } = useBusinessLayer();
	const [query] = useSearchParams();

	useEffect(() => {
		const prebooking = currentPRODTPrebookingIdLS.get();

		if (prebooking && !query.has('restoreState')) {
			dispatch(deletePRODTSearch({ id: prebooking.id }));
			currentPRODTPrebookingIdLS.clear();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
