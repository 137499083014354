import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
	BusinessLayerContext,
	saveUserLanguageAction,
} from '@mopla/business-logic';
import { SUPPORTED_LNGS, SupportedLngs } from '@mopla/i18';
import { useBreakpoints } from '@mopla/utils';

import { Footer, MainWithFooterTemplate, MplButton } from '../../';

import { LanguageSelectorButton } from './LanguageSelectorButton';
import { ContentWrapper, StepTitle } from './ProfileManager.styles';

export interface LanguageSelectScreenProps {
	onSubmit: () => void;
}

export const LanguageSelectScreen: React.FC<LanguageSelectScreenProps> = ({
	onSubmit,
}) => {
	const { t } = useTranslation(['profile', 'common']);
	const [selectedLanguage, setSelectedLanguage] = useState<SupportedLngs>(
		i18n.language as SupportedLngs
	);
	const businessLayer = useContext(BusinessLayerContext);
	const { isMobile } = useBreakpoints();

	const applyLanguage = () => {
		i18n
			.changeLanguage(selectedLanguage)
			.then(() => {
				businessLayer.dispatch(
					saveUserLanguageAction({ language: selectedLanguage })
				);
			})
			.catch((err) => console.error(err));
		onSubmit();
	};

	return (
		<MainWithFooterTemplate
			content={
				<ContentWrapper>
					<StepTitle data-testid="languageSelect-title">
						{t('title.language')}
					</StepTitle>
					{SUPPORTED_LNGS.map((langugage) => (
						<LanguageSelectorButton
							languageKey={langugage}
							selectedLanguage={selectedLanguage}
							setSelectedLanguage={setSelectedLanguage}
						/>
					))}
				</ContentWrapper>
			}
			footer={
				selectedLanguage !== i18n.language && (
					<Footer background={isMobile ? 'primary.main' : undefined}>
						<MplButton
							testId="languageSelect-confirmButton"
							onClick={applyLanguage}
							color={isMobile ? 'primaryRevert' : 'primary'}
						>
							{t('button.confirm_language')}
						</MplButton>
					</Footer>
				)
			}
		/>
	);
};
