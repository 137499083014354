import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { IBooking } from '@mopla/data-models';
import {
	checkIsBookingCancelled,
	checkTimeMightChange,
	getItineraryTiming,
} from '@mopla/utils';

import { Chip } from '../../';

export interface IBookingStatusBadgeProps {
	booking: Pick<IBooking, 'itinerary'> & Partial<IBooking>;
}

const ChipStyled = styled(Chip)(() => ({
	minWidth: 65,
}));

export const BookingStatusBadge: FC<IBookingStatusBadgeProps> = ({
	booking,
}) => {
	const { t } = useTranslation('tickets');
	const isCancelled = checkIsBookingCancelled(booking);
	const { isDelayed, isEarlier } = getItineraryTiming(booking.itinerary);
	const isFixed = !checkTimeMightChange(booking.itinerary);

	if (isCancelled) {
		return <ChipStyled color="error">{t('text.cancelled_ride')}</ChipStyled>;
	}

	if (isFixed) {
		return <ChipStyled color="secondary">{t('text.fixed_ride')}</ChipStyled>;
	}

	if (isDelayed) {
		return <ChipStyled color="warning">{t('text.delayed_ride')}</ChipStyled>;
	}

	if (isEarlier) {
		return <ChipStyled color="warning">{t('text.earlier_ride')}</ChipStyled>;
	}

	return null;
};
