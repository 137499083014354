import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	marginBottom: '24px',
}));

export const BrowserTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	marginBottom: '16px',
	color: theme.palette.primary.dark,
}));

export const BrowserDescription = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	marginBottom: '16px',
}));

export const LoginBox = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content',
	alignItems: 'center',
	color: theme.palette.primary.dark,
	marginBottom: 16,

	'& > span': {
		...theme.typography.body1,
		fontWeight: 600,
		marginRight: 16,
	},
}));

export const AnimWrapper = styled('div')(({ theme }) => ({
	marginBottom: '24px',
}));

export const SubmitLevel = styled('div')(() => ({
	position: 'absolute',
	bottom: '50px',
	width: 'calc(100% - 48px)',
}));

export const ErrorWrapper = styled('span', {
	shouldForwardProp: (prop) => prop !== 'error',
})<{ error: boolean }>(({ theme, error }) => ({
	display: error ? 'block' : 'none',
	color: theme.palette.error.main,
	...theme.typography.caption,
}));
