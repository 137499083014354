import dayjs from 'dayjs';
import { useObservable } from 'observable-hooks';
import { defer, interval } from 'rxjs';
import { map, scan, startWith, take } from 'rxjs/operators';

/**
 * offsetSeconds helps when the countdown end should be less than 0
 * getCountdownTimer$(Date); //emits until Date is after now
 * getCountdownTimer$(Date, 60*5); //emits until Date is after (now-5min)
 * */
export const getCountdownTimer$ = (
	endDate: Date | string,
	offsetSeconds = 0
) => {
	return defer(() => {
		const initDiffSeconds = dayjs(endDate).diff(dayjs(), 'second');

		return interval(1000).pipe(
			scan((acc) => acc - 1, initDiffSeconds),
			startWith(initDiffSeconds),
			take(initDiffSeconds + 1 + offsetSeconds),
			map((diffSeconds) => dayjs.duration(diffSeconds, 'second'))
		);
	});
};

export const useCountdownTimer$ = (endDate: Date | string) => {
	return useObservable(() => getCountdownTimer$(endDate), [endDate]);
};
