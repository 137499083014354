import { styled } from '@mui/material/styles';

export const NoResultsWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridTemplateRows: 'max-content max-content 1fr',
	width: '100%',
	height: '100%',
	boxSizing: 'border-box',

	[theme.breakpoints.up('tabletP')]: {
		margin: 'auto',
		width: 550,
	},
}));

export const FooterContainer = styled('div')(({ theme }) => ({
	display: 'grid',
	alignSelf: 'end',
	padding: '16px 16px 50px',
	background: theme.palette.secondary.main,
	borderRadius: '8px 8px 0',

	[theme.breakpoints.up('tabletP')]: {
		background: 'none',
	},
}));
export const Content = styled('div')(() => ({
	padding: '0 24px 24px',
}));

export const ErrorContent = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: '1fr max-content max-content',
	gridGap: 16,
	padding: '16px 16px 24px',
	margin: '0 24px',
	borderRadius: 8,
	background: theme.palette.neutral[0],
	textAlign: 'center',
}));
