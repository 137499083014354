import { useContext, useEffect, useState } from 'react';
import { DeepReadonlyObject, RxDocument } from 'rxdb';
import { BehaviorSubject, combineLatest } from 'rxjs';

import {
	Booking,
	DataChange,
	NormalizedScheduledLeg,
} from '@mopla/data-models';

import {
	editBooking as editBookingAction,
	editLeg as editLegAction,
	initEventStream as initEventStreamAction,
	initPushProcessor as initPushProcessorAction,
	resetScheduleDiff,
} from '../actions/scheduleActions';
import { BusinessLayerContext } from '../business-logic';
import { ScheduleDiffEl } from '../entities/scheduleDiff';

type ScheduleValue = {
	legs: NormalizedScheduledLeg[] | null;
	bookings: Booking[] | null;
	editLeg: (leg: NormalizedScheduledLeg) => void;
	editBooking: (book: Booking, legId: string, checkedInLegs?: string[]) => void;
	initEventStream: () => void;
	initPushProcessor: () => void;
	markChangesAsSeen: () => void;
	unseenChanges: ScheduleDiffEl[] | null;
	initialized: boolean;
};

export const useSchedule = (mockedSchedules = false): ScheduleValue => {
	const businessLayer = useContext(BusinessLayerContext);

	const [legs, setLegs] = useState<NormalizedScheduledLeg[] | null>(null);
	const [initialized, setInitialized] = useState(false);
	const [unseenChanges, setUnseenChanges] = useState<ScheduleDiffEl[] | null>(
		null
	);
	const [bookings, setBookings] = useState<Booking[] | null>(null);

	const initEventStream = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(initEventStreamAction());
		}
	};

	const initPushProcessor = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(initPushProcessorAction());
		}
	};

	const editLeg = (leg: NormalizedScheduledLeg) => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(editLegAction(leg));
		}
	};

	const editBooking = (
		booking: Booking,
		legId: string,
		checkedInLegs?: string[]
	) => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(editBookingAction(booking, legId, checkedInLegs));
		}
	};

	const markChangesAsSeen = () => {
		if (businessLayer.dispatch) {
			businessLayer.dispatch(resetScheduleDiff());
		}
	};

	useEffect(() => {
		const legsLiveQuery = businessLayer.db['scheduledLeg'].find().$;
		const bookingsLiveQuery = businessLayer.db['booking'].find().$;
		const dataChangesQuery = businessLayer.db['dataChanges'].find().$;
		const unseenChanges = businessLayer.db['scheduleDiff'].find().$;
		const rxdbSubscr1 = unseenChanges.subscribe(
			(val: RxDocument<ScheduleDiffEl>[]) => {
				setUnseenChanges(val.map((l) => l.toMutableJSON()));
			}
		);

		const changesMergeSource = combineLatest([
			legsLiveQuery as BehaviorSubject<RxDocument<NormalizedScheduledLeg>[]>,
			bookingsLiveQuery as BehaviorSubject<RxDocument<Booking>[]>,
			dataChangesQuery as BehaviorSubject<RxDocument<DataChange>[]>,
		]);

		const rxdbSubscr2 = changesMergeSource.subscribe((val) => {
			const [legsDocs, bookingsDocs, dataChangesDocs] = val;
			setInitialized(true);
			const originalLegs = legsDocs.map((l) => l.toJSON());
			const originalBookings = bookingsDocs.map((l) => l.toJSON());
			const dataChanges = dataChangesDocs.map((l) => l.toJSON());

			const legsChanges = filterLegsDataChanges(dataChanges);
			const bookingsChanges = filterBookingsDataChanges(dataChanges);

			const updatedLegs = mergeLegsWithChanges(originalLegs, legsChanges);
			const updatedBookings = mergeBookingsWithChanges(
				originalBookings,
				bookingsChanges
			);

			setBookings(updatedBookings);
			setLegs(updatedLegs);
		});

		return () => {
			rxdbSubscr1.unsubscribe();
			rxdbSubscr2.unsubscribe();
		};
	}, [businessLayer.db]);

	return {
		legs: legs,
		bookings: bookings,
		editLeg: editLeg,
		editBooking: editBooking,
		initEventStream: initEventStream,
		initPushProcessor,
		unseenChanges,
		markChangesAsSeen,
		initialized,
	};
};

export function filterLegsDataChanges(
	dataChanges: DeepReadonlyObject<DataChange>[]
) {
	return dataChanges.filter((ch) => ch.scheduledLegId && !ch.bookingId);
}

export function filterBookingsDataChanges(
	dataChanges: DeepReadonlyObject<DataChange>[]
) {
	return dataChanges.filter((ch) => ch.bookingId);
}

export function mergeLegsWithChanges(
	legs: DeepReadonlyObject<NormalizedScheduledLeg>[],
	dataChanges: DeepReadonlyObject<DataChange>[]
): NormalizedScheduledLeg[] {
	const result: DeepReadonlyObject<NormalizedScheduledLeg>[] = legs
		.map((l) => {
			const updatedLeg = dataChanges
				.filter((ch) => ch.scheduledLegId === l.scheduledLegId)
				.sort((a, b) => b.insertTime - a.insertTime)[0];

			if (!updatedLeg) {
				return l;
			}

			const vehicleUpdates = updatedLeg.vehicleData
				? {
						checkDone: updatedLeg.checkDone,
						vehicleData: updatedLeg.vehicleData,
				  }
				: {};

			return {
				...vehicleUpdates,
				...l,
				state: updatedLeg.type,
			};
		})
		.filter((l) => l.state !== 'DONE' && l.state !== 'DONE_BREAK')
		.sort(
			(a, b) =>
				new Date(a.start || '').getTime() - new Date(b.start || '').getTime() //TODO sorting can be applied once when saving original data to the collection
		);

	/** DeepReadonlyObject works only in the types layer, thus can be safely casted to a regular (mutable) type */
	return result as NormalizedScheduledLeg[];
}

export function mergeBookingsWithChanges(
	bookings: DeepReadonlyObject<Booking>[],
	dataChanges: DeepReadonlyObject<DataChange>[]
): Booking[] {
	const updatedBookings = bookings.map((b) => {
		const currentBookingChange = dataChanges
			.filter((ch) => ch.bookingId === b.bookingId)
			.sort((a, b) => b.insertTime - a.insertTime)[0];

		if (!currentBookingChange) {
			return b;
		}

		const newBookingStatus = currentBookingChange.type;
		let newCheckedInState = b.checkedInScheduledLegs;
		const bookingCheckedInChange = currentBookingChange.checkedInLegs;

		if (bookingCheckedInChange) {
			newCheckedInState = b.checkedInScheduledLegs.map((data) => {
				if (bookingCheckedInChange.includes(data.scheduledLegId)) {
					return {
						...data,
						isCheckedIn: true,
					};
				}
				return data;
			});
		}

		return {
			...b,
			checkedInScheduledLegs: newCheckedInState,
			bookingState: newBookingStatus,
		};
	});

	/** DeepReadonlyObject works only in the types layer, thus can be safely casted to a regular (mutable) type */
	return updatedBookings as Booking[];
}
