import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Stack,Typography } from '@mui/material';

import {
	ArrowRightIcon,
	FlexColumn,
	FlexContainer,
	LocationFlagIcon,
	MailIcon,
	MplButton,
} from '../../';

import {
	ContentWrapper,
	DescriptionLink,
	MailLink,
	PaperBox,
} from './FAQScreen.styles';

const FAQ_LINK = 'https://www.mopla.solutions/de/faq';
const CONTACTS_FORM_LINK = 'https://www.mopla.solutions/de/unternehmen/kontakt';

const redirectTo = (url: string) => {
	Browser.open({ url });
};

export const FAQScreen: FC = () => {
	const { t } = useTranslation('profile');

	return (
		<ContentWrapper>
			<Typography variant="body1">
				<Trans i18nKey="profile:faq.description">
					Bei Fragen, lese dir unsere{' '}
					<DescriptionLink onClick={() => redirectTo(FAQ_LINK)}>
						FAQ
					</DescriptionLink>{' '}
					auf unserer Homepage! Dort lassen sich oftmals Fragen schnell
					beantworten. Falls du weitere Hilfe benötigst, schreibe uns eine
					E-Mail oder schicke uns über das{' '}
					<DescriptionLink onClick={() => redirectTo(CONTACTS_FORM_LINK)}>
						Kontaktformular
					</DescriptionLink>{' '}
					eine Nachricht.
				</Trans>
			</Typography>
			<PaperBox>
				<FlexContainer gap={16}>
					<LocationFlagIcon />
					<FlexColumn>
						<Typography variant="body1" fontWeight={700} color="inherit">
							Office
						</Typography>
						<Typography variant="body1" color="inherit">
							Welserstraße 11
							<br />
							861368 Gersthofen
						</Typography>
					</FlexColumn>
				</FlexContainer>
				<FlexContainer gap={16}>
					<MailIcon />
					<FlexColumn>
						<MailLink href="mailto:info@mopla.solutions">
							info@mopla.solutions
						</MailLink>
					</FlexColumn>
				</FlexContainer>
			</PaperBox>
			<Stack spacing={1} mt="auto">
				<MplButton
					onClick={() => redirectTo(FAQ_LINK)}
					endIcon={<ArrowRightIcon />}
				>
					{t('faq.to_faq')}
				</MplButton>
				<MplButton
					variant="text"
					onClick={() => redirectTo(CONTACTS_FORM_LINK)}
					endIcon={<ArrowRightIcon />}
				>
					{t('faq.to_contact_form')}
				</MplButton>
			</Stack>
		</ContentWrapper>
	);
};
