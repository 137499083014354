import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Footer } from '@mopla/ui';

export const Root = styled('div')(() => ({
	flex: '1 1 auto',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	marginBottom: marginBottom || 0,
}));

export const Bold = styled('span')(({ theme }) => ({
	fontWeight: '600',
}));

export const FooterStyled = styled(Footer)(() => ({
	paddingInline: 0,
	marginTop: 'auto',
}));
