import {
	Booking,
	NormalizedScheduledLeg,
	ScheduledLeg,
} from '@mopla/data-models';

export interface ScheduleAction {
	type: ScheduleActionTypes;
}

export enum ScheduleActionTypes {
	SetLegs = '[Schedule] Set Legs',
	SetBookings = '[Schedule] Set Bookings',
	EditLeg = '[Schedule] Edit Leg',
	EditBooking = '[Schedule] Edit Booking',
	InitEventStream = '[Schedule] Init Event Stream',
	InitDataChangesCleanup = '[Schedule] Init Data changes Cleanup Stream',
	LoadSchedule = '[Schedule] Load Schedule',
	UpdateSchedule = '[Schedule] Update Schedule',
	InitPushProcessorAction = '[Schedule] Init Push Processor',
	ResetScheduleDiff = '[Schedule] Reset the unseen changes of the last update schedule',
}

// ========= LEGS ACTIONS =========

export interface SetLegsAction extends ScheduleAction {
	type: ScheduleActionTypes.SetLegs;
	payload: ScheduledLeg[];
}

export const setLegs = (legs: ScheduledLeg[]): SetLegsAction => ({
	type: ScheduleActionTypes.SetLegs,
	payload: {
		...legs,
	},
});

export interface EditLegAction extends ScheduleAction {
	type: ScheduleActionTypes.EditLeg;
	payload: NormalizedScheduledLeg;
}

export const editLeg = (leg: NormalizedScheduledLeg): EditLegAction => ({
	type: ScheduleActionTypes.EditLeg,
	payload: {
		...leg,
	},
});

// ========= BOOKINGS ACTIONS =========

export interface SetBookingsAction extends ScheduleAction {
	type: ScheduleActionTypes.SetBookings;
	payload: Booking[];
}

export const setBookings = (bookings: Booking[]): SetBookingsAction => ({
	type: ScheduleActionTypes.SetBookings,
	payload: {
		...bookings,
	},
});

export interface EditBookingAction extends ScheduleAction {
	type: ScheduleActionTypes.EditBooking;
	payload: Booking & { legId: string; checkedInLegs?: string[] };
}

export const editBooking = (
	booking: Booking,
	legId: string,
	checkedInLegs?: string[]
): EditBookingAction => ({
	type: ScheduleActionTypes.EditBooking,
	payload: {
		...booking,
		legId,
		checkedInLegs,
	},
});

// ========= SYNC ACTIONS =========

export interface InitEventStreamAction extends ScheduleAction {
	type: ScheduleActionTypes.InitEventStream;
}

export const initEventStream = (): InitEventStreamAction => ({
	type: ScheduleActionTypes.InitEventStream,
});

export interface InitPushProcessorAction extends ScheduleAction {
	type: ScheduleActionTypes.InitPushProcessorAction;
}

export const initPushProcessor = (): InitPushProcessorAction => ({
	type: ScheduleActionTypes.InitPushProcessorAction,
});

export interface ResetScheduleAction extends ScheduleAction {
	type: ScheduleActionTypes.ResetScheduleDiff;
}

export const resetScheduleDiff = (): ResetScheduleAction => ({
	type: ScheduleActionTypes.ResetScheduleDiff,
});

export interface LoadScheduleAction extends ScheduleAction {
	type: ScheduleActionTypes.LoadSchedule;
}

export const loadSchedule = (): LoadScheduleAction => ({
	type: ScheduleActionTypes.LoadSchedule,
});

export interface UpdateScheduleAction extends ScheduleAction {
	type: ScheduleActionTypes.UpdateSchedule;
}

export const updateSchedule = (): UpdateScheduleAction => ({
	type: ScheduleActionTypes.UpdateSchedule,
});
