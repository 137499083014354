import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Footer } from '../../molecules/MainWithFooterTemplate';

interface InputsWrapperProps {
	firstWidth: string;
	secondWidth: string;
}

export const Form = styled('form')(() => ({
	height: '100%',
}));

export const FormWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridTemplateRows: '1fr max-content',
}));

export const FooterStyled = styled(Footer)(({ theme }) => ({
	[theme.breakpoints.up('tabletP')]: {
		display: 'flex',
		flex: '1 1 50%',
		gap: 24,
	},
}));

export const FooterWrapper = styled('div')(({ theme }) => ({
	[theme.breakpoints.down('tabletP')]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',

		'& .cancel_button': {
			marginLeft: 'auto',
			width: 'auto',
		},
	},
}));

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'withPaddings',
})<{ withPaddings: boolean }>(({ withPaddings }) => ({
	padding: withPaddings ? '0 24px' : 0,
	display: 'grid',

	'.MuiFormControl-root': {
		marginBottom: '24px',
	},
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom: string }>(({ theme, marginBottom }) => ({
	...theme.typography.body1,
	marginBottom: marginBottom || 0,
}));

export const InputsWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'firstWidth' && prop !== 'secondWidth',
})<InputsWrapperProps>(({ firstWidth, secondWidth }) => ({
	display: 'flex',

	'& > div:first-of-type': {
		width: firstWidth,
		marginRight: '16px',
	},

	'& > div:last-of-type': {
		width: secondWidth,
	},
}));

export const SaveAddressWrapper = styled('p')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	margin: 0,
	marginBottom: '30px',
	...theme.typography.body1,
	fontWeight: 600,

	'& > span:last-of-type': {
		marginBottom: '2px',
	},
}));

export const DateOfBirthWrapper = styled('div')(() => ({
	display: 'flex',
	alignItems: 'flex-start',
	marginTop: '8px',

	'& > span': {
		margin: '0 6px',
		marginTop: '12px',
	},

	'& > div': {
		width: '30%',
	},

	'& > div:last-of-type': {
		width: '40%',
	},
}));

export const DateOfBirthError = styled('span', {
	shouldForwardProp: (prop) => prop !== 'error',
})<{ error: boolean }>(({ theme, error }) => ({
	display: error ? 'block' : 'none',
	color: theme.palette.error.main,
	...theme.typography.caption,
	marginBottom: '24px',
	marginTop: '-24px',
}));

export const Description = styled(Typography, {
	shouldForwardProp: (prop) =>
		prop !== 'marginBottom' && prop !== 'withPaddings',
})<{ marginBottom?: string; withPaddings: boolean }>(
	({ theme, marginBottom, withPaddings }) => ({
		...theme.typography.body2,
		padding: withPaddings ? '12px 24px 0' : 0,
		color: theme.palette.neutral[80],
		marginBottom: marginBottom || 0,
	})
);

export const SubmitWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	padding: '16px 24px 50px 24px',
	boxSizing: 'border-box',
	backgroundColor: theme.palette.secondary.main,
	borderTopLeftRadius: '8px',
	borderTopRightRadius: '8px',
}));

export const TicketSubtitleWrapper = styled('div')(() => ({
	margin: '8px 0 14px',
}));

export const AccountSubtitleWrapper = styled('div')(() => ({
	margin: '40px 0 12px',
}));
