import { useEffect, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';

export const useOnAppFocus = (cb: () => void) => {
	const [isWeb] = useState(Capacitor.getPlatform() === 'web');

	useEffect(() => {
		if (isWeb) {
			return;
		}

		const listeners: PluginListenerHandle[] = [];

		(async () => {
			const listener = await CapacitorApp.addListener(
				'appStateChange',
				(state) => {
					if (state.isActive) {
						cb();
					}
				}
			);

			listeners.push(listener);
		})();

		return () => {
			listeners.forEach((listener) => listener.remove());
		};
	}, [cb, isWeb]);
};
