import H from '@here/maps-api-for-javascript';

import type { MapController, TIcons } from './MapController';

export class UserFocusControlButton extends H.ui.Control {
	private readonly controlButton: H.ui.base.Button;

	constructor(
		private mapController: MapController,
		private map: H.Map,
		opt_options?: any
	) {
		super();

		this.controlButton = new H.ui.base.Button({
			label: `<svg class="H_icon H_icon__custom" fill="none" viewBox="0 0 24 24"><path stroke="#1C308B" stroke-width="2" d="M5.28 12.2c-1.02-.15-1.18-1.55-.22-1.92l12.7-4.89a1 1 0 0 1 1.3 1.3l-4.9 12.7c-.36.96-1.77.8-1.91-.22l-.77-5.36a1 1 0 0 0-.85-.85l-5.35-.76Z"/></svg>`,
			onStateChange: this.onButtonClick,
			data: null,
		});

		/** Hide the button until the user geo marker isn't added to the map */
		this.setVisibility(false);
		this.map.addEventListener('marker_draw', this.userMarkerDrawnHandler);

		this.addChild(this.controlButton);
		this.setAlignment(opt_options.alignment || 'top-right');
	}

	private userMarkerDrawnHandler = (e: any) => {
		const markerName: TIcons = e.target.name || '';

		if (markerName === 'user') {
			this.setVisibility(true);
			this.map.removeEventListener('marker_draw', this.userMarkerDrawnHandler);
		}
	};

	private onButtonClick = (evt: any): void => {
		if (evt.currentTarget.getState() === 'down') {
			this.mapController.focusOnUser();
		}
	};
}
