import { createContext, useContext } from 'react';

import { IBooking } from '@mopla/data-models';

export interface IBookingCancellationContext {
	cancelBooking(bookedTicket: IBooking): void;
}

const bookingCancellationContext = createContext(
	{} as IBookingCancellationContext
);

export const BookingCancellationContextProvider =
	bookingCancellationContext.Provider;

export const useBookingCancellation = () =>
	useContext(bookingCancellationContext);
