import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr max-content',
	gridTemplateRows: 'max-content max-content',
	gridTemplateAreas: `
		"icon     title    toggle"
		"content  content  content"
		`,

	alignItems: 'center',
	backgroundColor: theme.palette.neutral[0],
	borderRadius: 8,
	padding: 24,

	'& .icon': {
		gridArea: 'icon',

		'& + .title': {
			marginLeft: 8,
		},
	},

	'& .title': {
		gridArea: 'title',
	},

	'& .toggle': {
		marginLeft: 8,
		gridArea: 'toggle',
		'& .MuiIconButton-root': {
			color: theme.palette.primary.main, //TODO fix when mui's pallete will be configured properly
		},
	},

	'& .content': {
		marginTop: 16,
		gridArea: 'content',
	},
}));
