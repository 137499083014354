import React, { FC } from 'react';

import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import { TextWithLink } from '../../atoms/TextWithLink/TextWithLink';

import { Label } from './CheckboxWithLink.styles';

interface IProps {
	url: string;
	linkText: string;
	checked: boolean;
	toggleCheckbox: VoidFunction;
	testId?: string;
}

export const CheckboxWithLink: FC<IProps> = (props) => {
	const { checked, toggleCheckbox, url, linkText, testId } = props;

	return (
		<Checkbox
			name={linkText}
			checked={checked}
			onChange={toggleCheckbox}
			label={
				<Label>
					<TextWithLink i18nKey={linkText} url={url} />
				</Label>
			}
			checkboxTestId={testId}
		/>
	);
};
