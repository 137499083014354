export interface StripeSetupIntentData {
	stripeCustomerId: string;
	stripeEphemeralKeySecret: string;
	stripeSetupIntentClientSecret: string;
	pkKey: string;
}

export type TPaymentType = 'card' | 'sepa_debit' | 'paypal';

export interface IDefaultCard {
	brand: string;
	last4: string;
	exp_month: number;
	exp_year: number;
	country: string;
}

export interface IDefaultPaymentMethod {
	type: TPaymentType;
	billing_details: {
		address?: {
			city?: string;
			country?: string;
			line1?: string;
			line2?: string;
			postal_code?: string;
			state?: string;
		};
		email?: string;
		name?: string;
		phone?: string;
	};
	card?: IDefaultCard;
	sepa_debit?: {
		last4: string;
		bank_code: string;
		branch_code: string;
		country: string;
	};
	paypal?: {
		payer_email: string;
		payer_id: string;
	};
}
