import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import i18n from 'i18next';

import { useLiveUpdateVersion, useUser } from '@mopla/business-logic';
import {
	ESubscriptionStatus,
	TGermanyTicketType,
	TPaymentType,
	TSubscription,
} from '@mopla/data-models';
import { PaletteColors } from '@mopla/mui';

import {
	DTicketIcon,
	FlexContainer,
	GlobeIcon,
	MplButton,
	PaymentIcon,
	ProfileCircleIcon,
	ProfileGenericButton,
	useProfileManagerContext,
	VoucherIcon,
} from '../../';

import {
	ContentWrapper,
	ImprintButton,
	InitialScreenRoot,
	UserAvatar,
	UserGreeting,
	UserGreetingWrapper,
} from './ProfileManager.styles';

const stringAvatar = (name: string) => {
	return {
		sx: {
			bgcolor: PaletteColors.secondary.main,
		},
		children: `${name.split(' ')[0]?.[0] || ''}${
			name.split(' ')[1]?.[0] || ''
		}`,
	};
};

export interface InitialScreenProps {
	onLogout: VoidFunction;
	onProfile: VoidFunction;
	onPayment: VoidFunction;
	onLanguage: VoidFunction;
	onGermanyTicket: VoidFunction;
	onVoucher?: VoidFunction;
	onImprint?: MouseEventHandler<HTMLButtonElement>;
	onCookieSettings?: MouseEventHandler<HTMLButtonElement>;
	onTransportTypes: VoidFunction;
	onFAQ?: MouseEventHandler<HTMLButtonElement>;
	showGreeting?: boolean;
	subscription?: TSubscription;
	defaultPaymentMethodType?: TPaymentType;
	dTicketType?: TGermanyTicketType;
	showLiveUpdatesVersion?: boolean;
}

export const InitialProfileScreen: React.FC<InitialScreenProps> = ({
	onLogout,
	onProfile,
	onPayment,
	onLanguage,
	onGermanyTicket,
	onVoucher,
	onImprint,
	onCookieSettings,
	onFAQ,
	showGreeting,
	subscription,
	defaultPaymentMethodType,
	dTicketType,
	showLiveUpdatesVersion = false,
	onTransportTypes,
}) => {
	const { t } = useTranslation(['profile', 'common']);
	const { userData } = useUser();

	const { liveUpdateVersion } = useLiveUpdateVersion();

	const { isDriverApp, isPassengerApp } = useProfileManagerContext();
	const isPendingSubscription =
		subscription?.status === ESubscriptionStatus.PENDING;

	const hasDefaultPaymentMethod = !!userData?.hasDefaultStripePaymentMethod;

	const getPaymentHelperText = () => {
		if (!hasDefaultPaymentMethod || !defaultPaymentMethodType) return '';

		return t(`profile:subheader.payment_method.${defaultPaymentMethodType}`);
	};

	const getDTicketHelperText = () => {
		if (!subscription?.status) return '';

		return t(
			`profile:subheader.dticket_status.${subscription.status.toLowerCase()}`
		);
	};

	const windowRef = window as any;

	return (
		<InitialScreenRoot data-testid="initialScreen-root">
			<ContentWrapper>
				{showGreeting ? (
					<UserGreetingWrapper>
						<UserAvatar
							data-testid="initialScreen-userAvatar"
							{...stringAvatar(
								`${userData?.firstName} ${userData?.lastName}` || ''
							)}
						/>
						<UserGreeting data-testid="initialScreen-userGreeting">
							<span>{t('common:text.hello')},</span>
							<span>
								{userData?.firstName} {userData?.lastName}
							</span>
						</UserGreeting>
					</UserGreetingWrapper>
				) : null}
				{!isDriverApp ? (
					<ProfileGenericButton
						testId="initialScreen-myDetailsButton"
						onClick={onProfile}
						mainIcon={<ProfileCircleIcon />}
						label={t('profile:button.my_details')}
						variant="grey"
					/>
				) : null}
				{!isDriverApp ? (
					<ProfileGenericButton
						testId="initialScreen-germanyTicketButton"
						onClick={onGermanyTicket}
						mainIcon={<DTicketIcon />}
						label={t('profile:button.germany_ticket', { dTicketType })}
						helperText={getDTicketHelperText()}
						variant="grey"
					/>
				) : null}
				{!isDriverApp ? (
					<ProfileGenericButton
						testId="initialScreen-vouchersButton"
						onClick={onVoucher!}
						mainIcon={<VoucherIcon />}
						label={t('profile:button.vouchers')}
						variant="grey"
					/>
				) : null}
				{!isDriverApp ? (
					<ProfileGenericButton
						testId="initialScreen-paymentButton"
						onClick={onPayment}
						mainIcon={<PaymentIcon />}
						label={t('profile:button.payment')}
						variant="grey"
						helperText={getPaymentHelperText()}
						disabled={isPendingSubscription}
					/>
				) : null}
				<ProfileGenericButton
					testId="initialScreen-languageButton"
					onClick={onLanguage}
					mainIcon={<GlobeIcon />}
					label={t('profile:button.language')}
					helperText={t(`profile:button.${i18n.language}`)}
					variant={isDriverApp ? 'white' : 'grey'}
				/>
			</ContentWrapper>
			{!isDriverApp && (
				<>
					<FlexContainer className="center" style={{ paddingBottom: 12 }}>
						<ImprintButton
							data-testid="initialScreen-faqButton"
							variant="text"
							onClick={onFAQ}
						>
							<Typography variant="body2" color="neutral.0">
								{t('profile:button.faq')}
							</Typography>
						</ImprintButton>
					</FlexContainer>
					<FlexContainer className="center" style={{ paddingBottom: 12 }}>
						<ImprintButton
							data-testid="initialScreen-button"
							variant="text"
							onClick={onImprint}
						>
							<Typography variant="body2" color="neutral.0">
								{t('profile:button.imprint')}
							</Typography>
						</ImprintButton>
					</FlexContainer>
					{windowRef.CookieScript && (
						<FlexContainer className="center" style={{ paddingBottom: 12 }}>
							<ImprintButton
								data-testid="initialScreen-cookiesButton"
								variant="text"
								onClick={onCookieSettings}
							>
								<Typography variant="body2" color="neutral.0">
									{t('profile:button.cookie_settings')}
								</Typography>
							</ImprintButton>
						</FlexContainer>
					)}
				</>
			)}
			<FlexContainer className="center" style={{ paddingBottom: 12 }}>
				<ImprintButton
					variant="text"
					data-testid="initialScreen-transportTypesButton"
					onClick={onTransportTypes}
				>
					<Typography
						variant="body2"
						color={isPassengerApp ? 'neutral.0' : 'primary'}
					>
						{t('profile:button.transport_types')}
					</Typography>
				</ImprintButton>
			</FlexContainer>
			{showLiveUpdatesVersion && (
				<FlexContainer className="center" style={{ paddingBottom: 12 }}>
					<Typography
						data-testid="initialScreen-liveUpdatesText"
						variant="body2"
						color={isPassengerApp ? 'neutral.0' : 'primary'}
					>
						{`Live Updates build: ${liveUpdateVersion?.buildId}`}
					</Typography>
				</FlexContainer>
			)}
			<Stack alignItems="center" px={2} pb={3}>
				<MplButton
					testId="initialScreen-logoutButton"
					variant="text"
					onClick={onLogout}
					color={isPassengerApp ? 'primaryRevert' : 'primary'}
					fullWidth={false}
				>
					{t('profile:button.logout')}
				</MplButton>
			</Stack>
		</InitialScreenRoot>
	);
};
