import { FC } from 'react';
import { Chip as MuiChip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface IChipProps {
	color?: ChipProps['color'];
	children?: string;
	className?: string;
}

export const ChipStyled = styled(MuiChip)(({ theme }) => ({
	padding: '4px 8px',
	height: 'auto',

	'& .MuiChip-label': {
		...theme.typography.caption,
		lineHeight: '16px',
		fontWeight: 700,
		padding: 0,
	},

	'&.MuiChip-colorError': {
		color: theme.palette.neutral[0],
		backgroundColor: theme.palette.error.main,
	},

	'&.MuiChip-colorPrimary': {
		color: theme.palette.primary.dark,
		backgroundColor: theme.palette.secondary.main,
	},

	'&.MuiChip-colorWarning': {
		color: theme.palette.primary.dark,
		backgroundColor: theme.palette.warning.main,
	},
}));

export const Chip: FC<IChipProps> = ({ className, children, color }) => {
	return <ChipStyled className={className} label={children} color={color} />;
};
