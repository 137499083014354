import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { redeemVoucher, VoucherActionTypes } from '../actions/voucherActions';
import { useBusinessLayer } from '../business-logic';

interface IUseRedeemVoucherFormProps {
	onRedeemSuccess?: VoidFunction;
}

interface IRedeemVoucherFormState {
	code: string;
}

export const RedeemVoucherValidationSchema = yup.object().shape({
	code: yup.string().required('required').max(23, 'max'),
});

export const useRedeemVoucherForm = (
	props: IUseRedeemVoucherFormProps = {}
) => {
	const { onRedeemSuccess } = props;
	const bl = useBusinessLayer();
	const { t } = useTranslation('validation');

	const {
		control,
		handleSubmit,
		setError,
		reset,
		formState: {
			errors,
			isValid,
			isDirty,
			isSubmitted,
			isSubmitting,
			isSubmitSuccessful,
		},
	} = useForm<IRedeemVoucherFormState>({
		defaultValues: { code: '' },
		resolver: yupResolver(RedeemVoucherValidationSchema),
		mode: 'onChange',
	});

	const submitHandler: SubmitHandler<IRedeemVoucherFormState> = useCallback(
		(formState) => {
			return new Promise<void>((resolve) => {
				bl.dispatch(redeemVoucher({ voucherCode: formState.code }));

				bl.watchActions({
					types: VoucherActionTypes.RedeemVoucherResult,
					callback: (action) => {
						if (action.payload?.error) {
							setError(
								'code',
								{ message: action.payload.error.message },
								{ shouldFocus: true }
							);
						} else {
							reset();
							if (onRedeemSuccess) {
								onRedeemSuccess();
							}
						}

						resolve();
					},
				});
			});
		},
		[bl, onRedeemSuccess, reset, setError]
	);

	const validationError = errors.code?.message
		? t(`voucher.${errors.code.message}`, '') || t('voucher.not_eligible')
		: null;

	return {
		onSubmit: handleSubmit(submitHandler),
		control,
		isSubmitting,
		isSubmitDisabled: isSubmitting || ((isDirty || isSubmitted) && !isValid),
		isSubmitSuccessful,
		validationError,
	};
};
