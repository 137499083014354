import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DEPRECATED_Button } from '../../atoms/Button/_deprecated/Button';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string; isDriverWeb?: boolean }>(
	({ theme, variant, isDriverWeb = false }) => ({
		backgroundColor:
			(variant === 'mid_grey' && theme.palette.neutral[40]) ||
			(variant === 'white' && theme.palette.neutral[0]) ||
			(variant === 'super_light_blue' && theme.palette.primary.light) ||
			(variant === 'secondary_dark' && theme.palette.primary.dark) ||
			(variant === 'blue_grey' && '#ECEEF8') ||
			theme.palette.primary.light,
		display: 'grid',
		gridTemplateRows: isDriverWeb ? 'none' : 'max-content 1fr',
		height: '100%',
		overflowY: 'auto',
	})
);

export const InitialScreenRoot = styled('div')(() => ({
	display: 'grid',
	gridTemplateRows: '1fr max-content',
}));

export const ContentWrapper = styled('div')(() => ({
	padding: 24,
	boxSizing: 'border-box',
	height: '100%',
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridGap: 16,
	position: 'relative',
}));

export const EditUserWrapper = styled('div')(() => ({
	padding: '24px 0',
}));

export const UserGreetingWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: 12,
	padding: '8px 0',
}));

export const MyAccountTitleWithButton = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	justifyContent: 'space-between',
	alignItems: 'center',

	[theme.breakpoints.up('tabletP')]: {
		justifyContent: 'start',
	},
}));

export const DataField = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	display: 'block',
}));

export const SeparatorLine = styled('div')(() => ({
	width: '100%',
	height: 0,
	border: '1px solid #E7E7E7',
}));

export const MyAccountWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr',

	gridTemplateRows: 'repeat(6, max-content) 1fr',
	gridGap: 24,

	[theme.breakpoints.down('tabletP')]: {
		padding: 24,
	},
}));

export const UserAvatar = styled(Avatar)(() => ({
	width: 50,
	height: 50,
	borderRadius: '50%',
	objectFit: 'cover',
}));

export const UserGreeting = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	gridGap: 4,

	'& > span:first-of-type': {
		...theme.typography.body1,
		color: theme.palette.primary.dark,
	},

	'& > span:last-of-type': {
		...theme.typography.body1,
		fontWeight: 600,
		color: theme.palette.primary.dark,
	},
}));

export const LanguageAvatar = styled(Avatar)(({ theme }) => ({
	width: 24,
	height: 24,
	borderRadius: '50%',
	objectFit: 'cover',
	color: theme.palette.primary.dark,
	...theme.typography.body1,
	fontSize: 10,
	fontWeight: 'bold',
}));

export const StepTitle = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	color: theme.palette.neutral[100],
}));

export const DesktopRoot = styled('div')(() => ({
	display: 'grid',
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gridTemplateColumns: 'minmax(auto, 632px)',
	justifyContent: 'center',
	gap: 24,
	padding: 24,
}));

export const DesktopDataBlock = styled('div')(({ theme }) => ({
	background: theme.palette.neutral[0],
	borderRadius: 8,
	overflow: 'hidden',

	padding: 24,
	boxSizing: 'border-box',
}));

export const DesktopDataTitle = styled('span')(({ theme }) => ({
	display: 'inline-block',
	...theme.typography.title,
	color: theme.palette.primary.main,
	marginBottom: 40,
}));

//TODO MC-5191 refactor
export const ImprintButton = styled(DEPRECATED_Button)(() => ({
	placeSelf: 'center',
	width: 'fit-content',
	padding: 0,
	textTransform: 'initial',
	textDecoration: 'underline',
}));
