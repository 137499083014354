import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
	marginTop?: string;
	marginBottom?: string;
}
export const Description = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginTop' && prop !== 'marginBottom',
})<Props>(({ theme, marginTop, marginBottom }) => ({
	...theme.typography.body2,
	color: theme.palette.neutral[80],
	marginTop: marginTop || 0,
	marginBottom: marginBottom || 0,
}));
