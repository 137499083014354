import React from 'react';
interface IProps {
	color?: 'white' | 'secondary';
}
export const MoplaLogo: React.FC<IProps> = ({ color = 'primary' }) => {
	const fillLetter = color === 'primary' ? '#1C308B' : '#FFFFFF';

	return (
		<svg
			width="92"
			height="24"
			viewBox="0 0 92 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.9827 6.00329C15.2922 5.6074 14.4912 5.41406 13.5982 5.41406C12.5762 5.41406 11.6648 5.63502 10.8638 6.07694C10.3022 6.38996 9.83267 6.78585 9.44599 7.26459C9.09614 6.73061 8.64502 6.3071 8.11103 6.00329C7.42054 5.6074 6.61956 5.41406 5.72652 5.41406C4.83348 5.41406 3.96805 5.63502 3.24073 6.07694C2.93691 6.26107 2.66071 6.49124 2.41213 6.73061C2.41213 6.38076 2.30166 6.09535 2.0807 5.8836C1.85974 5.66264 1.56513 5.55216 1.20607 5.55216C0.847009 5.55216 0.57081 5.66264 0.340645 5.8836C0.110479 6.10456 0 6.39917 0 6.75823V17.2722C0 17.622 0.110479 17.9074 0.340645 18.1376C0.57081 18.3678 0.856216 18.4783 1.20607 18.4783C1.55592 18.4783 1.85974 18.3678 2.0807 18.1376C2.30166 17.9074 2.41213 17.622 2.41213 17.2722V10.6066C2.41213 9.72276 2.67913 8.99544 3.21311 8.43383C3.74709 7.86302 4.42838 7.58682 5.24777 7.58682C6.06716 7.58682 6.6748 7.8262 7.16275 8.30494C7.6507 8.78369 7.89928 9.52022 7.89928 10.5145V17.2722C7.89928 17.622 8.00976 17.9074 8.23993 18.1376C8.47009 18.3678 8.7555 18.4783 9.10535 18.4783C9.4552 18.4783 9.75902 18.3678 9.97998 18.1376C10.2009 17.9074 10.3114 17.622 10.3114 17.2722V10.6066C10.3114 9.72276 10.5784 8.99544 11.1032 8.43383C11.628 7.86302 12.3093 7.58682 13.1286 7.58682C13.948 7.58682 14.5557 7.8262 15.0436 8.30494C15.5316 8.78369 15.7801 9.52022 15.7801 10.5145V17.2722C15.7801 17.622 15.8906 17.9074 16.1208 18.1376C16.351 18.3678 16.6364 18.4783 16.9862 18.4783C17.3361 18.4783 17.6399 18.3678 17.8608 18.1376C18.0818 17.9074 18.1923 17.622 18.1923 17.2722V10.5145C18.1923 9.42815 17.9989 8.49828 17.6123 7.73413C17.2256 6.96998 16.6824 6.38996 15.9919 5.99408L15.9827 6.00329Z"
				fill={fillLetter}
			/>
			<path
				d="M43.7682 10.3574C43.3263 10.3574 42.9488 10.5231 42.6173 10.8454C42.2951 11.1676 42.1294 11.5543 42.1294 12.0146C42.1294 12.4749 42.2951 12.834 42.6173 13.1654C42.9396 13.4877 43.3263 13.6534 43.7682 13.6534C44.2101 13.6534 44.6152 13.4877 44.9282 13.1654C45.2412 12.8432 45.3977 12.4565 45.3977 12.0146C45.3977 11.5727 45.2412 11.1676 44.9282 10.8454C44.6152 10.5231 44.2285 10.3574 43.7682 10.3574Z"
				fill={fillLetter}
			/>
			<path
				d="M73.4962 16.0932H72.9069C72.5571 16.0932 72.2717 15.8998 72.0599 15.5224C71.839 15.1449 71.7285 14.6661 71.7285 14.0769V1.17845C71.7285 0.828596 71.618 0.552397 71.397 0.331438C71.1761 0.110479 70.8907 0 70.55 0C70.2094 0 69.9148 0.110479 69.703 0.331438C69.4821 0.552397 69.3716 0.837802 69.3716 1.17845V14.0861C69.3716 14.9331 69.5189 15.6881 69.8227 16.3418C70.1173 16.9954 70.5408 17.511 71.0748 17.8885C71.6088 18.2659 72.2256 18.4593 72.9161 18.4593H72.9622C73.4317 18.4593 73.8184 18.3488 74.1222 18.1278C74.426 17.9069 74.5733 17.6215 74.5733 17.2808C74.5733 16.9402 74.4721 16.6548 74.2787 16.4338C74.0854 16.2129 73.8276 16.1024 73.5146 16.1024L73.4962 16.0932Z"
				fill={fillLetter}
			/>
			<path
				d="M88.7795 6.94236C87.6379 5.99408 86.174 5.41406 84.5721 5.41406C80.9447 5.41406 77.9985 8.36018 77.9985 11.9876C77.9985 15.615 80.9447 18.5611 84.5721 18.5611C86.174 18.5611 87.6379 17.9903 88.7795 17.0328V17.3735C88.7795 17.7233 88.89 17.9995 89.1109 18.2205C89.3319 18.4414 89.6173 18.5519 89.9579 18.5519C90.2986 18.5519 90.5932 18.4414 90.8049 18.2205C91.0259 17.9995 91.1364 17.7141 91.1364 17.3735V11.9876C91.1364 9.96213 90.2157 8.14843 88.7703 6.94236H88.7795ZM84.5721 16.1858C82.252 16.1858 80.3738 14.3077 80.3738 11.9876C80.3738 9.66752 82.252 7.78937 84.5721 7.78937C86.8921 7.78937 88.7703 9.66752 88.7703 11.9876C88.7703 14.3077 86.8921 16.1858 84.5721 16.1858Z"
				fill={fillLetter}
			/>
			<path
				d="M50.811 11.9876V22.8146C50.811 23.1644 50.9214 23.4406 51.1424 23.6616C51.3634 23.8825 51.6488 23.993 51.9894 23.993C52.3301 23.993 52.6155 23.8825 52.8364 23.6616C53.0574 23.4406 53.1679 23.1552 53.1679 22.8146V17.0328C54.3095 17.9811 55.7733 18.5611 57.3753 18.5611C61.0027 18.5611 63.9488 15.615 63.9488 11.9876C63.9488 8.36018 61.0027 5.41406 57.3753 5.41406C55.7733 5.41406 54.3095 5.98487 53.1679 6.94236C51.7224 8.14843 50.8018 9.96213 50.8018 11.9876H50.811ZM53.1863 11.9876C53.1863 9.66752 55.0644 7.78937 57.3845 7.78937C59.7046 7.78937 61.5827 9.66752 61.5827 11.9876C61.5827 14.3077 59.7046 16.1858 57.3845 16.1858C55.0644 16.1858 53.1863 14.3077 53.1863 11.9876Z"
				fill={fillLetter}
			/>
			<path
				d="M36.7249 11.9881C36.7249 11.4542 36.6513 10.9386 36.5316 10.4414C35.9516 8.03851 34.055 6.16036 31.6521 5.59875C31.1733 5.48827 30.667 5.42383 30.1514 5.42383C29.6358 5.42383 29.1203 5.48827 28.6323 5.60796C26.2386 6.17877 24.3512 8.05692 23.7712 10.4414C23.6515 10.9386 23.5779 11.4542 23.5779 11.9881C23.5779 12.0526 23.5779 12.1078 23.5779 12.1723C23.5226 12.9548 23.5226 17.2083 29.1295 23.5517C29.6635 24.1501 30.6117 24.1501 31.1457 23.5517C36.7526 17.2083 36.7526 12.9548 36.6973 12.1723C36.6973 12.1078 36.6973 12.0526 36.6973 11.9881H36.7249ZM30.1514 7.78993C32.4715 7.78993 34.3496 9.66808 34.3496 11.9881C34.3496 14.3082 32.4715 16.1864 30.1514 16.1864C27.8313 16.1864 25.9532 14.3082 25.9532 11.9881C25.9532 9.66808 27.8313 7.78993 30.1514 7.78993Z"
				fill="#6FDB89"
			/>
		</svg>
	);
};
