import { FC, PropsWithChildren } from 'react';

import { TimeTextStyled } from './styled';

export type TTimeTextVariant = 'fixed' | 'nonfixed' | 'intermediate';

interface ITimeTextProps {
	variant?: TTimeTextVariant;
}

export const TimeText: FC<PropsWithChildren<ITimeTextProps>> = ({
	children,
	variant = 'fixed',
}) => {
	return (
		<TimeTextStyled className="maybe-disabled" ownerState={{ variant }}>
			{variant === 'nonfixed' && '≈'}
			{children}
		</TimeTextStyled>
	);
};
