import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { MobileStepper, Stack } from '@mui/material';
import { LottieRefCurrentProps } from 'lottie-react';

import { useAppLaunched } from '@mopla/business-logic';
import {
	ArrowRightIcon,
	BookRideAnim,
	MplButton,
	RightRideAnim,
	TravelAnim,
} from '@mopla/ui';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import {
	Footer,
	Image,
	Root,
	Slide,
	Subtitle,
	Title,
} from './Onboarding.styles';

export const OnboardingScreen = () => {
	const { set } = useAppLaunched();
	const navigate = useNavigate();
	const { t } = useTranslation('onboarding');
	const [activeStep, setActiveStep] = useState(0);
	const anim1Ref = useRef<LottieRefCurrentProps>(null);
	const anim2Ref = useRef<LottieRefCurrentProps>(null);
	const anim3Ref = useRef<LottieRefCurrentProps>(null);

	useEffect(() => {
		if (activeStep === 0) {
			anim1Ref.current?.goToAndPlay(0);
		}
		if (activeStep === 1) {
			anim2Ref.current?.goToAndPlay(0);
		}
		if (activeStep === 2) {
			anim3Ref.current?.goToAndPlay(0);
		}
	}, [activeStep]);

	const finishOnboarding = () => {
		set({ wasLaunched: true });
		navigate('/home');
	};

	return (
		<Root>
			<Stack alignItems="flex-end" p={1}>
				<MplButton
					variant="text"
					endIcon={<ArrowRightIcon />}
					onClick={finishOnboarding}
					data-testid="welcomeScreen-skip-onboarding-button"
					fullWidth={false}
				>
					{t('skip')}
				</MplButton>
			</Stack>
			<Carousel
				showThumbs={false}
				showArrows={false}
				showStatus={false}
				showIndicators={false}
				infiniteLoop={false}
				dynamicHeight={false}
				autoPlay={false}
				swipeable={true}
				emulateTouch={true}
				onChange={(e: number) => setActiveStep(e)}
			>
				<Slide>
					<Image>{<TravelAnim autoplay={false} lottieRef={anim1Ref} />}</Image>
					<Title>{t('first_slide.title')}</Title>
					<Subtitle>{t('first_slide.description')}</Subtitle>
				</Slide>
				<Slide>
					<Image>
						{<RightRideAnim autoplay={false} lottieRef={anim2Ref} />}
					</Image>
					<Title>{t('second_slide.title')}</Title>
					<Subtitle>{t('second_slide.description')}</Subtitle>
				</Slide>
				<Slide>
					<Image>
						{<BookRideAnim autoplay={false} lottieRef={anim3Ref} />}
					</Image>
					<Title>{t('third_slide.title')}</Title>
					<Subtitle>{t('third_slide.description')}</Subtitle>
				</Slide>
			</Carousel>
			<Footer>
				{activeStep === 2 ? (
					<MplButton
						onClick={finishOnboarding}
						endIcon={<ArrowRightIcon />}
						data-testid="onboarding-submit-button"
					>
						{t('finish')}
					</MplButton>
				) : (
					<MobileStepper
						variant="dots"
						steps={3}
						position="static"
						activeStep={activeStep}
						nextButton={null}
						backButton={null}
					/>
				)}
			</Footer>
		</Root>
	);
};

export default OnboardingScreen;
