import { useEffect, useMemo, useRef, useState } from 'react';
import {
	pluckFirst,
	useObservable,
	useObservableEagerState,
	useSubscription,
} from 'observable-hooks';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { debounce } from 'throttle-debounce';

import { MapController } from '@mopla/business-logic';
import { GERMANY_CENTER } from '@mopla/constants';
import { ILeg, IPassengerMapView } from '@mopla/data-models';
import { LatLng } from '@mopla/utils';

import { useCurrentGeoPosition } from '../../';

import type { TBookingMapData$ } from './useBookingMapApi$';

type TMapMarkersCoords$ = [
	LatLng,
	IPassengerMapView | null,
	LatLng | null,
	MapController | null
];
type TMapFocusSignal$ = [
	IPassengerMapView | null,
	LatLng | null,
	MapController
];

export const isFullscreenMap$ = new BehaviorSubject(false);

export const useLiveMapController = (
	bookingMapData$: TBookingMapData$,
	leg: ILeg
) => {
	const minimizedMapRef = useRef<HTMLDivElement>(null);
	const fullMapRef = useRef<HTMLDivElement>(null);
	const userPosition = useCurrentGeoPosition();
	const isFullViewOpened = useObservableEagerState(isFullscreenMap$);
	const [mapController, setMapController] = useState<MapController | null>(
		null
	);

	const mapMarkersCoords$: Observable<TMapMarkersCoords$> = useObservable(
		(inputs$) =>
			combineLatest([
				of(
					new LatLng({ lat: Number(leg.from.lat), lng: Number(leg.from.lng) })
				),
				bookingMapData$,
				userPosition.position$,
				pluckFirst(inputs$),
			]),
		[mapController]
	);

	/**
	 *  Listens to changes in mapController and processes it to emit a combined value of coordinates$, userPosition.position$, and mapController.
	 *  When a mapController is emitted (inputs$), emits only when api response or user position are not null
	 *  @example
	 *  1. _map not null + api null + user pos null => no emit
	 *  2. _map not null + api not null + user pos null => emit [apiData, null, map] once
	 *  3. _map not null + api not null + user pos not null => emit [apiData, userPos, map] once
	 *  4. no longer emits
	 *  */
	const mapFocusSignal$: Observable<TMapFocusSignal$> = useObservable(
		(inputs$) =>
			pluckFirst(inputs$).pipe(
				filter((_map): _map is MapController => !!_map),
				switchMap((_map) => {
					const apiWithMap$ = bookingMapData$.pipe(
						filter(Boolean),
						withLatestFrom(
							userPosition.position$,
							(_api, _coords) => [_api, _coords, _map] as TMapFocusSignal$
						),
						first()
					);

					const coordsWithMap$ = userPosition.position$.pipe(
						filter(Boolean),
						withLatestFrom(
							bookingMapData$,
							(_coords, _api) => [_api, _coords, _map] as TMapFocusSignal$
						),
						first()
					);

					return merge(apiWithMap$, coordsWithMap$);
				})
			),
		[mapController]
	);

	/**
	 * When the mapController is ready, focus on the group of vehicle + user markers
	 * The focus will be triggered 2 times.
	 * 1 - when liveMapData or userLatLng is available (whichever comes first)
	 * 2 - when both are available
	 * */
	useSubscription(mapFocusSignal$, ([liveMapData, userLatLng, _map]) => {
		const points = [
			liveMapData &&
				new LatLng({
					lat: Number(liveMapData.currentLocation.lat),
					lng: Number(liveMapData.currentLocation.lng),
				}),
			userLatLng,
		].filter((p): p is LatLng => !!p);

		/** Timeout helps to wait until the map gets initialized and ready to be manipulated */
		setTimeout(() => _map.focusOnPoints(...points), 500);
	});

	/** Draw map objects: departure, vehicle and user markers  */
	useSubscription(
		mapMarkersCoords$,
		([departureLatLng, liveMapData, userLatLng, _map]) => {
			if (!_map) {
				return;
			}

			_map.drawMarker(departureLatLng, 'cursor');

			if (liveMapData) {
				_map.drawMarker(
					new LatLng({
						lat: Number(liveMapData.currentLocation.lat),
						lng: Number(liveMapData.currentLocation.lng),
					}),
					'vehicle',
					true
				);

				_map.updateMarkerIcon(
					'vehicle',
					liveMapData.isDelayed ? 'delayedVehicle' : 'vehicle'
				);
			}

			if (userLatLng) {
				_map.drawMarker(userLatLng, 'user', true);
			}
		}
	);

	/**
	 * Code responsible for creating a MapController instance, which is bound to the DOM node (mini map or fullscreen map)
	 * ResizeObserver is used to adjust map canvas when DOM node gets resized
	 * */
	useEffect(() => {
		const mapNode = isFullViewOpened
			? fullMapRef.current
			: minimizedMapRef.current;

		if (!mapNode) {
			return;
		}

		const newMapController = new MapController(
			mapNode,
			new LatLng(GERMANY_CENTER),
			6,
			isFullViewOpened
				? { top: 150, left: 50, bottom: 260, right: 50 }
				: { top: 24, left: 24, bottom: 32, right: 24 }
		);

		if (isFullViewOpened) {
			newMapController.initUserFocusControlButton();
		}

		setMapController(newMapController);

		const ro = new ResizeObserver(debounce(50, newMapController.resizeHandler));
		ro.observe(mapNode);

		return () => {
			ro.unobserve(mapNode);
			newMapController.destroy();
		};
	}, [isFullViewOpened]);

	return useMemo(
		() => ({ minimizedMapRef, fullMapRef }),
		[minimizedMapRef, fullMapRef]
	);
};
