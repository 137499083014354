import { IBooking } from '@mopla/data-models';
import { actionGenerator } from '@mopla/utils';

import { PassengerBooking } from '../hooks/usePassengerBookings';

export interface PassengerBookingAction {
	type: PassengerBookingActionTypes;
}

export enum PassengerBookingActionTypes {
	LoadBookings = '[Bookins] Load Bookings',
	LoadBookingsResult = '[Bookins] Load Bookings Result',
	SaveBookings = '[Bookings] Save Bookings',
	SaveBookingsResult = '[Bookings] Save Bookings result',
	RemoveBooking = '[Bookings] Remove Booking',
	RemoveBookingResult = '[Bookings] Remove Booking result',
}

// ==================== Save Passenger Bookings ====================

export interface SavePassengerBookingsAction extends PassengerBookingAction {
	type: PassengerBookingActionTypes.SaveBookings;
	payload: PassengerBooking[];
}

export const savePassengerBookings = (
	PassengerBookings: PassengerBooking[]
): SavePassengerBookingsAction => ({
	type: PassengerBookingActionTypes.SaveBookings,
	payload: PassengerBookings,
});

export interface SavePassengerBookingResultAction
	extends PassengerBookingAction {
	type: PassengerBookingActionTypes.SaveBookingsResult;
	error?: Error;
	payload: {
		data?: PassengerBooking[];
	};
}

export const savePassengerBookingResult = (
	data?: PassengerBooking[],
	error?: Error
): SavePassengerBookingResultAction => ({
	type: PassengerBookingActionTypes.SaveBookingsResult,
	error,
	payload: {
		data: data,
	},
});

export interface LoadPassengerBookingsAction extends PassengerBookingAction {
	type: PassengerBookingActionTypes.LoadBookings;
}

export const loadPassengerBookings = (): LoadPassengerBookingsAction => ({
	type: PassengerBookingActionTypes.LoadBookings,
});

export interface LoadPassengerBookingResultAction
	extends PassengerBookingAction {
	type: PassengerBookingActionTypes.LoadBookingsResult;
	error?: Error;
	payload: {
		data?: PassengerBooking[];
	};
}

export const loadPassengerBookingResult = (
	data?: PassengerBooking[],
	error?: Error
): LoadPassengerBookingResultAction => ({
	type: PassengerBookingActionTypes.LoadBookingsResult,
	error,
	payload: {
		data: data,
	},
});

export const removeBooking = actionGenerator<
	PassengerBookingActionTypes.RemoveBooking,
	IBooking
>(PassengerBookingActionTypes.RemoveBooking);

export const removeBookingResult =
	actionGenerator<PassengerBookingActionTypes.RemoveBookingResult>(
		PassengerBookingActionTypes.RemoveBookingResult
	);

export type TRemoveBookingAction = ReturnType<typeof removeBooking>;
export type TRemoveBookingResultAction = ReturnType<typeof removeBookingResult>;
