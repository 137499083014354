export function isMoreThanOneTrue(arg: unknown[]) {
	let trueCount = 0;

	arg.forEach((v) => {
		if (v) {
			trueCount += 1;
		}
	});

	return trueCount > 1;
}

export const uniqueFilter = <T>(value: T, index: number, self: T[]): boolean =>
	self.indexOf(value) === index;

export const uniqueFilterJson = (
	value: object,
	index: number,
	self: object[]
) => {
	const valueString = JSON.stringify(value);
	return index === self.findIndex((obj) => JSON.stringify(obj) === valueString);
};
