import React, { ReactNode } from 'react';
import { IconButton, Typography } from '@mui/material';
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer/SwipeableDrawer';

import { CrossIcon, LeftChevronIcon } from '../../';

import { Content, Header, Root } from './SideOverlay.styles';

interface OverlayProps
	extends Omit<SwipeableDrawerProps, 'anchor' | 'onClose'> {
	children: ReactNode;
	onClose?: () => void;
	onBack?: () => void;
	title: string;
	widthPercentage?: number;
	zIndex?: number;
}

export const SideOverlay: React.FC<OverlayProps> = ({
	children,
	onClose,
	onBack,
	title,
	widthPercentage,
	zIndex,
	...props
}) => {
	return (
		<Root
			data-testid="sideOverlay-root"
			anchor="right"
			onClose={onClose || (() => undefined)}
			widthPercentage={widthPercentage}
			zIndex={zIndex}
			{...props}
		>
			<Header
				data-testid="sideOverlay-header"
				onClose={!!onClose}
				onBack={!!onBack}
			>
				{onBack ? (
					<IconButton data-testid="sideOverlay-backButton" onClick={onBack}>
						<LeftChevronIcon />
					</IconButton>
				) : null}
				<Typography
					data-testid="sideOverlay-title"
					variant="bodyTitle"
					color="neutral.100"
				>
					{title}
				</Typography>
				{onClose ? (
					<IconButton data-testid="sideOverlay-closeButton" onClick={onClose}>
						<CrossIcon />
					</IconButton>
				) : null}
			</Header>
			<Content data-testid="sideOverlay-content">{children}</Content>
		</Root>
	);
};

export default SideOverlay;
