import { User } from '@firebase/auth';

import { localStorageManager } from '@mopla/utils';

interface LocalUser extends User {
	stsTokenManager: {
		accessToken: string;
		expirationTime: number;
		refreshToken: string;
	};
}

export const localUserManager = localStorageManager<
	LocalUser | null,
	User | null
>('localUser');
