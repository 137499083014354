import { FC } from 'react';
import { Typography } from '@mui/material';

import { Root, SeeMoreButton } from './styled';

export interface IPromotionBannerProps {
	onClick(): void;
	img: string;
	title: string;
	description: string;
	action: string;
}

export const PromotionBanner: FC<IPromotionBannerProps> = ({
	onClick,
	img,
	title,
	description,
	action,
}) => {
	return (
		<Root>
			<img src={img} alt={`Promotion: ${title}`} />
			<div className="content">
				<Typography variant="subtitle" color="primary.dark">
					{title}
				</Typography>
				<Typography
					className="description"
					variant="body2"
					color="primary.dark"
				>
					{description}
				</Typography>
				<SeeMoreButton variant="text" onClick={onClick}>
					<Typography variant="body2" fontWeight={600} color="primary">
						{action}
					</Typography>
				</SeeMoreButton>
			</div>
		</Root>
	);
};
