import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { useRedeemVoucherForm } from '@mopla/business-logic';

import { AttentionIcon, Input, MplButton } from '../../';

import {
	VouchersForm,
	VouchersFormInput,
	VouchersFormSubmit,
	VouchersFormText,
} from './Vouchers.styles';

export interface IRedeemVoucherFormProps {
	onRedeemSuccess: VoidFunction;
}

export const RedeemVoucherForm: FC<IRedeemVoucherFormProps> = (props) => {
	const { t } = useTranslation('profile');
	const { onSubmit, control, validationError, isSubmitting, isSubmitDisabled } =
		useRedeemVoucherForm(props);

	return (
		<VouchersForm onSubmit={onSubmit}>
			<VouchersFormInput>
				<Controller
					name="code"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							onChange={(e) => {
								e.target.value = e.target.value.trim();
								field.onChange(e);
							}}
							inputProps={{
								'data-testid': 'voucher_form_input',
							}}
							label={t('vouchers.fieldTitle')}
							error={Boolean(validationError)}
							helperText={
								validationError && (
									<>
										<AttentionIcon width={18} height={18} />
										<Typography
											variant="caption"
											data-testid="voucher_form_validation_error"
										>
											{validationError}
										</Typography>
									</>
								)
							}
							ref={null}
						/>
					)}
				/>
			</VouchersFormInput>
			<VouchersFormText>
				<Typography variant="body2" color="neutral.60">
					{t('vouchers.fieldDescription')}
				</Typography>
			</VouchersFormText>
			<VouchersFormSubmit>
				<MplButton
					type="submit"
					disabled={isSubmitDisabled}
					data-testid="voucher_form_submit"
					showLoader={isSubmitting}
				>
					{t('vouchers.submitVoucher')}
				</MplButton>
			</VouchersFormSubmit>
		</VouchersForm>
	);
};
