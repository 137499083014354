import {
	CurrencyCode,
	DiscountState,
	Itinerary,
	NamedLocation,
	PaymentForm,
	TransportType,
} from '../';

export const itineraryPRODT: Itinerary = {
	startDateTime: '2024-01-24T14:13:27.106Z',
	endDateTime: '2024-01-24T15:38:31.106Z',
	duration: 2102,
	walkTime: 0,
	walkDistance: 0,
	airDistance: 26605,
	legs: [
		{
			legId: 'd83adc3f-eadd-46c3-bbbc-e24e6a42bd12',
			startDateTime: '2024-01-24T14:13:27.106Z',
			startDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
			startDateTimeWindowEnd: '2024-01-24T14:28:27.106817073Z',
			startDateTimeFix: false,
			endDateTime: '2024-01-24T15:38:31.106Z',
			endDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
			endDateTimeWindowEnd: '2024-01-24T15:38:31.106817073Z',
			endDateTimeFix: false,
			cancellationFreeUntil: '2024-01-24T13:13:27.106Z',
			costOfCancellation: 640,
			duration: 2102.1,
			distance: 33464.5,
			mode: TransportType.PRODT,
			modeOrigin: 'CAR',
			route: '',
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.044637,
				lat: 51.855333,
				arrival: '2024-01-24T14:13:27.106Z',
				departure: '2024-01-24T14:13:27.106Z',
				locationType: NamedLocation.type.ADDRESS,
			},
			to: {
				name: 'Wolfen, Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.275519,
				lat: 51.66343,
				arrival: '2024-01-24T15:38:31.106Z',
				departure: '2024-01-24T15:38:31.106Z',
				locationType: NamedLocation.type.ADDRESS,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 2150,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
			],
			cancellationRules: [
				{
					secondsBeforeRideStart: 7200,
					cost: 0,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
				{
					secondsBeforeRideStart: 1800,
					cost: 320,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
				{
					secondsBeforeRideStart: 0,
					cost: 920,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
			],
		},
	],
};
