import { useEffect, useState } from 'react';
import { getI18n } from 'react-i18next';

export const useI18nLanguage = () => {
	const [language, setLanguage] = useState(getI18n().language);

	useEffect(() => {
		const i18n = getI18n();

		const languageChangedHandler = () => {
			setLanguage(i18n.language);
		};

		getI18n().on('languageChanged', languageChangedHandler);

		return () => {
			getI18n().off('languageChanged', languageChangedHandler);
		};
	}, []);

	return language;
};
