import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import {
	AndroidSettings,
	IOSSettings,
	NativeSettings,
} from 'capacitor-native-settings';

import { MplButton } from '../../';
import { ArrowRightIcon } from '../../icons/ArrowRight';

import {
	FirstMessage,
	Form,
	Image,
	Root,
	SecondMessage,
	Title,
} from './PermissionsModal.styles';

export interface PermissionsModalProps {
	title: string;
	fallbackTitle?: string;
	firstMessage: string;
	secondMessage?: string;
	skipTitle?: string;
	acceptTitle: string;
	deniedFallbackMessage1?: string;
	deniedFallbackMessage2?: string;
	deniedFallbackSkip?: string;
	animationElement?: JSX.Element;
	askPermissionsCallback: () => Promise<any>;
	checkPermissionsCallback: () => Promise<any>;
	onPermissionsGranted: (response: any) => void;
	onCancel: () => void;
	dataTestId?: string;
	acceptWithIcon?: boolean;
	cancelWithIcon?: boolean;
	showSkipButton?: boolean;
	deferred?: boolean;
	shouldRender?: boolean;
}

export interface IPermissionsModalRef {
	reprocess(): Promise<void>;
}

export const PermissionsModal = forwardRef<
	IPermissionsModalRef,
	PermissionsModalProps
>(
	(
		{
			title,
			fallbackTitle,
			firstMessage,
			secondMessage,
			skipTitle,
			acceptTitle,
			deniedFallbackMessage1,
			deniedFallbackMessage2,
			deniedFallbackSkip,
			animationElement = null,
			askPermissionsCallback,
			checkPermissionsCallback,
			onPermissionsGranted,
			onCancel,
			dataTestId,
			acceptWithIcon = true,
			cancelWithIcon = true,
			showSkipButton = true,
			deferred = false,
			shouldRender = true,
		},
		ref
	) => {
		const [showModal, setShowModal] = useState<'' | 'request' | 'fallback'>('');
		const [rootNode] = useState(document.getElementById('portal'));
		const { t } = useTranslation();

		const cancelHandler = useCallback(() => {
			setShowModal('');
			onCancel();
		}, [onCancel]);

		const processPermission = useCallback(async () => {
			const permissions = await checkPermissionsCallback();
			const permissionsValues = Object.values(permissions);
			if (
				!permissionsValues.includes('denied') &&
				!permissionsValues.includes('granted')
			) {
				setShowModal('request');
			}

			if (permissionsValues.includes('granted')) {
				onPermissionsGranted(permissions);
			}

			if (
				permissionsValues.includes('denied') &&
				deniedFallbackMessage1 &&
				deniedFallbackMessage2 &&
				Capacitor.getPlatform() !== 'web'
			) {
				//currently the only defined fallback flow is for the apps not for the web
				setShowModal('fallback');
			}
		}, [
			checkPermissionsCallback,
			deniedFallbackMessage1,
			deniedFallbackMessage2,
			onPermissionsGranted,
		]);

		const askPermissions = () => {
			askPermissionsCallback()
				.then((r) => {
					onPermissionsGranted(r);
				})
				.catch((err) => {
					Sentry.captureException(err);
				});
			cancelHandler();
		};

		const goToSettings = useCallback(() => {
			NativeSettings.open({
				optionAndroid: AndroidSettings.ApplicationDetails,
				optionIOS: IOSSettings.App,
			});
			cancelHandler();
		}, [cancelHandler]);

		useEffect(() => {
			if (!deferred) {
				processPermission();
			}
		}, [processPermission, deferred]);

		useImperativeHandle(ref, () => ({ reprocess: processPermission }), [
			processPermission,
		]);

		if (rootNode === null || showModal === '' || !shouldRender) {
			return null;
		}

		return ReactDOM.createPortal(
			<Root data-testid={dataTestId || 'permissions-modal'}>
				<Form>
					<Image>{animationElement}</Image>
					<Title>{showModal === 'request' ? title : fallbackTitle}</Title>
					<FirstMessage>
						{showModal === 'request' ? firstMessage : deniedFallbackMessage1}
					</FirstMessage>
					<SecondMessage>
						{showModal === 'request' ? secondMessage : deniedFallbackMessage2}
					</SecondMessage>
					{showSkipButton && (
						<MplButton
							id="cancelPermissionsModal"
							data-testid="permissions-modal-cancel-button"
							variant="text"
							onClick={cancelHandler}
							endIcon={cancelWithIcon ? <ArrowRightIcon /> : undefined}
						>
							{
								(showModal === 'request'
									? skipTitle
									: deniedFallbackSkip) as string
							}
						</MplButton>
					)}
					<MplButton
						data-testid="permissions-modal-submit-button"
						onClick={showModal === 'request' ? askPermissions : goToSettings}
						endIcon={acceptWithIcon ? <ArrowRightIcon /> : undefined}
					>
						{showModal === 'request'
							? acceptTitle
							: t('permissions:fallback.title')}
					</MplButton>
				</Form>
			</Root>,
			rootNode
		);
	}
);
PermissionsModal.displayName = 'PermissionsModal';

export default PermissionsModal;
