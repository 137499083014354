import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import { ContentWrapper } from '../DTicketSubscription.styles';

interface IContentBlockProps {
	testId?: string;
	headerText?: string;
}
export const ContentBlock: React.FC<PropsWithChildren<IContentBlockProps>> = ({
	headerText,
	children,
	testId,
}) => {
	return (
		<ContentWrapper data-testid={testId}>
			{headerText && <Typography variant="bodyTitle">{headerText}</Typography>}
			{children}
		</ContentWrapper>
	);
};
