import { getI18n } from 'react-i18next';
import { App } from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import * as Sentry from '@sentry/capacitor';
import { AxiosError } from 'axios';
import { RxCollection, RxDocument } from 'rxdb';
import {
	combineLatest,
	concatMap,
	defer,
	distinctUntilChanged,
	EMPTY,
	timer,
} from 'rxjs';

import {
	Booking,
	BookingNotificationType,
	DataChange,
	NormalizedScheduledLeg,
	TDriversScheduleResponse,
} from '@mopla/data-models';
import { safeRemoveDocument } from '@mopla/utils';

import {
	EditBookingAction,
	EditLegAction,
	InitEventStreamAction,
	InitPushProcessorAction,
	loadSchedule as loadScheduleAction,
	LoadScheduleAction,
	ResetScheduleAction,
	ScheduleActionTypes,
	SetBookingsAction,
	SetLegsAction,
	updateSchedule,
	UpdateScheduleAction,
} from '../actions/scheduleActions';
import { Action, Dependencies, Effect } from '../business-logic';
import { ScheduleDiffEl, ScheduleDiffTypes } from '../entities/scheduleDiff';
import { prepareNormalizedScheduledLegs } from '../mappers/prepareNormalizedScheduledLegs';
import { ofType } from '../operators/ofType';
import { networkStatus$ } from '../subjects/network';

let eventStreamInitialized = false;
let pushInitialized = false;

/** Not used */
export const setLegsEffect: Effect<SetLegsAction> = (actions$, dependencies) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.SetLegs),
		concatMap((action: SetLegsAction) =>
			defer(async () => {
				await dependencies.db['scheduledLeg'].bulkUpsert(
					Object.values(action.payload)
				);
			})
		)
	);

export const editLegEffect: Effect<EditLegAction> = (actions$, dependencies) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.EditLeg),
		concatMap((action: EditLegAction) =>
			defer(async () => {
				const lastChange = await dependencies.db['dataChanges']
					.findOne({ selector: {}, sort: [{ changeId: 'desc' }] })
					.exec();
				const lastChangeObj = lastChange?.toJSON();

				await dependencies.db['dataChanges'].upsert({
					changeId: lastChangeObj ? `${+lastChangeObj.changeId + 1}` : '1',
					insertTime: Date.now(),
					synced: false,
					scheduledLegId: action.payload.scheduledLegId,
					type: action.payload.state,
					ref: action.payload.ref,
					checkDone: action.payload.checkDone,
					vehicleData: (action.payload as unknown as any).vehicleData, //this is the placce where we store replies for vehicle checks in serialized form
				});
				//await dependencies.db.scheduledLeg.upsert({ ...action.payload });
			})
		)
	);

/** Not used */
export const setBookingsEffect: Effect<SetBookingsAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.SetBookings),
		concatMap((action: SetBookingsAction) =>
			defer(async () => {
				await dependencies.db['booking'].bulkUpsert(
					Object.values(action.payload)
				);
			})
		)
	);

export const editBookingEffect: Effect<EditBookingAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.EditBooking),
		concatMap((action: EditBookingAction) =>
			defer(async () => {
				const lastChange = await dependencies.db['dataChanges']
					.findOne({ selector: {}, sort: [{ changeId: 'desc' }] })
					.exec();
				const lastChangeObj = lastChange?.toJSON();

				await dependencies.db['dataChanges'].upsert({
					changeId: lastChangeObj ? `${+lastChangeObj.changeId + 1}` : '1',
					insertTime: Date.now(),
					synced: false,
					bookingId: action.payload.bookingId,
					scheduledLegId: action.payload.legId,
					type: action.payload.bookingState,
					checkedInLegs: action.payload.checkedInLegs,
				});

				// await dependencies.db.booking.upsert({ ...action.payload });
			})
		)
	);

export const loadScheduleEffect: Effect<LoadScheduleAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.LoadSchedule),
		concatMap(() =>
			defer(async () => {
				try {
					const newLegs = (await dependencies.api.get(
						'/api/drivers/schedule'
					)) as TDriversScheduleResponse;

					// check, if we need to remove legs
					const existingLegs: RxDocument<NormalizedScheduledLeg>[] =
						await dependencies.db['scheduledLeg'].find().exec();
					const legsToRemove: string[] = [];

					existingLegs.forEach((exLeg) => {
						const exists = newLegs.find((leg) => {
							const breakLegMatch =
								leg.ref === 'BREAK' && exLeg.scheduledLegId === leg.obj.start;
							const vehicleCheckLegMatch =
								leg.ref === 'VEHICLE_CHECK' &&
								exLeg.scheduledLegId === leg.obj.assignmentId;
							const legMatch =
								leg.ref === 'LEG' &&
								exLeg.scheduledLegId === leg.obj.scheduledLegId;

							return breakLegMatch || vehicleCheckLegMatch || legMatch || false;
						});
						if (!exists) {
							legsToRemove.push(exLeg.scheduledLegId as string);
						}
					});

					const normalizedScheduledLegs =
						prepareNormalizedScheduledLegs(newLegs);

					const legsIds = normalizedScheduledLegs.reduce<string[]>((p, c) => {
						if (c.enteringBookings && c.scheduledLegId) {
							p.push(c.scheduledLegId);
						}
						return p;
					}, []);

					const newBookings: Booking[] = legsIds.length
						? await dependencies.api.post('/api/drivers/bookingLegs', legsIds)
						: [];

					await dependencies.db['scheduledLeg'].bulkUpsert(
						normalizedScheduledLegs
					);
					if (legsToRemove.length > 0) {
						await dependencies.db['scheduledLeg'].bulkRemove(legsToRemove);
					}

					const oldBookings = await dependencies.db['booking'].find().exec();
					const bookingsToDelete: string[] = [];
					oldBookings.forEach((booking) => {
						const exists = newBookings.find(
							(doc) => doc.bookingId === booking.bookingId
						);
						if (!exists) {
							bookingsToDelete.push(booking.bookingId);
						}
					});

					await dependencies.db['booking'].bulkUpsert(newBookings);
					if (bookingsToDelete.length > 0) {
						await dependencies.db['booking'].bulkRemove(bookingsToDelete);
					}

					await cleanupDataChanges(dependencies);
				} catch (err) {
					console.log(err);
					dependencies.Sentry.captureException(err);
				}
			})
		)
	);

export const resetScheduleDiffEffect: Effect<ResetScheduleAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.ResetScheduleDiff),
		concatMap((action) =>
			defer(async () => {
				try {
					LocalNotifications.removeAllDeliveredNotifications();
					await dependencies.db['scheduleDiff'].find().remove();
				} catch (err) {
					console.log(err);
					dependencies.Sentry.captureException(err);
				}
			})
		)
	);

export const updateScheduleEffect: Effect<UpdateScheduleAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.UpdateSchedule),
		concatMap(() =>
			defer(async () => {
				try {
					/**
					 * whenever we are doing an update of schedules and bookings keep track of the changed data
					 * so we can report it to the user if needed
					 **/

					const currentLegs: RxDocument<NormalizedScheduledLeg>[] =
						await dependencies.db['scheduledLeg'].find().exec();

					const prevLegs = currentLegs.map((l) => l.toMutableJSON());

					const newLegs = (await dependencies.api.get(
						'/api/drivers/schedule'
					)) as TDriversScheduleResponse;

					const legsToRemove: string[] = [];
					prevLegs.forEach((exLeg) => {
						const exists = newLegs.find((leg) => {
							let isExisting = false;
							if (
								leg.ref === 'LEG' &&
								exLeg.scheduledLegId === leg.obj.scheduledLegId
							) {
								isExisting = true;
							}
							if (
								leg.ref === 'VEHICLE_CHECK' &&
								exLeg.scheduledLegId === leg.obj.assignmentId
							) {
								isExisting = true;
							}
							return isExisting;
						});
						if (!exists) {
							legsToRemove.push(exLeg.scheduledLegId as string);
						}
					});

					const normalizedScheduledLegs =
						prepareNormalizedScheduledLegs(newLegs);

					const legsIds = normalizedScheduledLegs.reduce<string[]>((p, c) => {
						if (c.enteringBookings && c.scheduledLegId) {
							p.push(c.scheduledLegId);
						}
						return p;
					}, []);

					const newBookings: Booking[] = legsIds.length
						? await dependencies.api.post('/api/drivers/bookingLegs', legsIds)
						: [];

					/**
					 * keep track of the diff to the previously known schedule here
					 **/
					if (prevLegs.length) {
						let containsBreak = false;
						let addedRides = 0;
						let cancledRides = 0;
						const diff: ScheduleDiffEl[] = [];

						normalizedScheduledLegs.forEach((l) => {
							if (l.ref === 'LEG') {
								const isNewLeg = !prevLegs?.find(
									(pl) => pl.scheduledLegId === l.scheduledLegId
								);
								if (isNewLeg) {
									addedRides++;
									const newLeg = {
										...l,
										updateState: ScheduleDiffTypes.NEW,
									};
									diff.push(newLeg);
								}
							}

							if (l.ref === 'BREAK') {
								const isNewPause = !prevLegs?.find(
									(pl) => pl.state === 'BREAK'
								);
								const isChangedPause = prevLegs?.find(
									(pl) => pl.state === 'BREAK' && pl.start !== l.start
								);

								if (isNewPause || isChangedPause) {
									containsBreak = true;
									const newLeg = {
										...l,
										updateState: ScheduleDiffTypes.NEW_BREAK,
									};
									diff.push(newLeg);
								}
							}
						});

						prevLegs?.forEach((l) => {
							const isDeletedLeg = !normalizedScheduledLegs.find(
								(nl) => nl.scheduledLegId === l.scheduledLegId
							);
							if (isDeletedLeg) {
								cancledRides++;
								const deletedLeg = {
									...l,
									updateState: ScheduleDiffTypes.DELETED,
								};
								diff.push(deletedLeg);
							}
						});
						const i18n = getI18n();
						if (diff.length > 0) {
							await dependencies.db['scheduleDiff'].bulkInsert(diff);
							const state = await App.getState();
							if (!state.isActive) {
								const textParts = [];
								if (addedRides > 0) {
									textParts.push(
										i18n.t('notifications:push.schedule.added_ride', {
											count: addedRides,
										})
									);
								}
								if (cancledRides > 0) {
									textParts.push(
										i18n.t('notifications:push.schedule.canceled_trip', {
											count: cancledRides,
										})
									);
								}
								if (containsBreak) {
									textParts.push(i18n.t('notifications:push.schedule.pause'));
								}
								LocalNotifications.schedule({
									notifications: [
										{
											body: i18n.t('notifications:push.schedule.text', {
												text: textParts.join(', '),
											}),
											id: 2,
											title: i18n.t('notifications:push.schedule.title'),
										},
									],
								});
							}
						}
					}

					//update data

					//await dependencies.db['scheduledLeg'].find().remove();
					await dependencies.db['scheduledLeg'].bulkUpsert(
						normalizedScheduledLegs
					);
					if (legsToRemove.length > 0) {
						await dependencies.db['scheduledLeg'].bulkRemove(legsToRemove);
					}

					const oldBookings = await dependencies.db['booking'].find().exec();
					const bookingsToDelete: string[] = [];
					oldBookings.forEach((booking) => {
						const exists = newBookings.find(
							(doc) => doc.bookingId === booking.bookingId
						);
						if (!exists) {
							bookingsToDelete.push(booking.bookingId);
						}
					});

					await dependencies.db['booking'].bulkUpsert(newBookings);
					if (bookingsToDelete.length > 0) {
						await dependencies.db['booking'].bulkRemove(bookingsToDelete);
					}

					await cleanupDataChanges(dependencies);
				} catch (err) {
					console.log(err);
					dependencies.Sentry.captureException(err);
				}
			})
		)
	);

interface Payload {
	type: BookingNotificationType;
}

export const initPushProcessorEffect: Effect<InitPushProcessorAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.InitPushProcessorAction),
		concatMap((action: InitPushProcessorAction) =>
			defer(async () => {
				// TODO this should be more sophisticated
				if (pushInitialized) {
					return;
				}
				pushInitialized = true;

				await FirebaseMessaging.addListener('notificationReceived', (event) => {
					const payload = event.notification.data as Payload;
					if (
						[
							'TRIP_IMMUTABLE',
							'BREAK_IMMUTABLE',
							'CHECK_IN_PASSENGER',
						].includes(payload.type)
					) {
						actions$.next(updateSchedule() as Action);
					}
				});

				await FirebaseMessaging.addListener('tokenReceived', async (event) => {
					// send the token to the mopla backend
					await dependencies.api.post('/api/command/createUserPushToken', {
						pushToken: event.token,
						appContext: 'DRIVER',
					});
				});
			})
		)
	);

export const initEventStreamEffect: Effect<InitEventStreamAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(ScheduleActionTypes.InitEventStream),
		concatMap((action: InitEventStreamAction) =>
			defer(() => {
				// TODO this should be more sophisticated
				if (eventStreamInitialized) {
					return EMPTY;
				}
				eventStreamInitialized = true;

				const dataChangesQuery = dependencies.db['dataChanges'].find({
					selector: {
						synced: {
							$eq: false,
						},
					},
				}).$;

				const networkStatusPipe$ = networkStatus$.pipe(distinctUntilChanged());

				return combineLatest([
					networkStatusPipe$,
					dataChangesQuery,
					timer(0, 1000 * 60 * 2),
				]).pipe(
					concatMap(
						([_, changes]: [boolean, RxDocument<DataChange>[], number]) =>
							defer(async () => {
								try {
									if (changes.length === 0) {
										actions$.next(loadScheduleAction() as Action);
										return;
									}

									const currentChange = changes[0];
									const changeType = currentChange.type;

									if (currentChange.ref === 'VEHICLE_CHECK') {
										if (currentChange.checkDone === true) {
											await dependencies.api.post('/api/vehicleCheck', {
												assignmentId: currentChange.scheduledLegId,
												...JSON.parse(currentChange.vehicleData || ''),
											});
										}
										await currentChange.atomicPatch({ synced: true });
									} else if (
										currentChange.scheduledLegId &&
										!currentChange.bookingId &&
										!currentChange.vehicleData
									) {
										//this is either a scheduled leg or a scheduled break
										if (changeType === 'EXECUTING') {
											await dependencies.api.post('/api/command/startDrive', {
												scheduledLegId: currentChange.scheduledLegId,
											});
										}

										if (changeType === 'DONE') {
											await dependencies.api.post('/api/command/endDrive', {
												scheduledLegId: currentChange.scheduledLegId,
											});
										}

										await currentChange.atomicPatch({ synced: true });
									} else if (
										currentChange.bookingId &&
										currentChange.scheduledLegId &&
										!currentChange.vehicleData
									) {
										if (
											changeType === 'EXECUTING' ||
											changeType === 'CANCELLED_NO_SHOW'
										) {
											await dependencies.api.post(
												'/api/command/checkInPassenger',
												{
													bookingId: currentChange.bookingId,
													scheduledLegId: currentChange.scheduledLegId,
													noShow: changeType === 'CANCELLED_NO_SHOW',
												}
											);
										}

										await currentChange.atomicPatch({ synced: true });
									}
								} catch (err: any) {
									const requestError = err as AxiosError;
									const currentChange = changes[0];

									if (
										requestError.response &&
										// in case of network error, we can just wait for the next timer and retry
										requestError.message !== 'Network Error'
									) {
										if (
											requestError.response.status &&
											[400, 422, 404].indexOf(requestError.response.status) !==
												-1
										) {
											// drop doc, because it has conflicts we cannot resolve
											dependencies.Sentry.addBreadcrumb({
												message: 'dropped local change due to server conflict',
												data: currentChange.toJSON(),
											});
											await safeRemoveDocument(currentChange);
										} else {
											const attempt = currentChange.attempts
												? currentChange.attempts + 1
												: 1;
											if (attempt <= 5) {
												await currentChange.atomicPatch({
													attempts: attempt,
												});
											} else {
												dependencies.Sentry.addBreadcrumb({
													message:
														'dropped local change after 5 unsuccessfull retries',
													data: currentChange.toJSON(),
												});
												await safeRemoveDocument(currentChange);
											}
										}
									}
									dependencies.Sentry.captureException(err);
								}
							})
					)
				);
			})
		)
	);

const cleanupDataChanges = async (dependencies: Dependencies) => {
	const dataChangesCol: RxCollection<DataChange> =
		dependencies.db['dataChanges'];

	const docsToRemove = await dataChangesCol
		.find({
			selector: {
				$or: [{ synced: { $eq: true } }, { type: { $eq: 'PRE_FINISHED' } }],
			},
		})
		.exec();

	if (!docsToRemove.length) {
		return;
	}

	try {
		const docsIdsToRemove = docsToRemove.map((doc) => doc.changeId);
		await dataChangesCol.bulkRemove(docsIdsToRemove);
	} catch (e: any) {
		/**
		 * P2 error might occur when no docs were deleted.
		 * This might happen in case those docs were deleted somewhere in parallel (race conditions), which is fine.
		 * */
		if (e.code !== 'P2') {
			Sentry.captureException(e);
		}
	}
};
