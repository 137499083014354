import React, { ReactElement, useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { matchPath, Outlet, Route, Routes } from 'react-router-dom';

import { useUser } from '@mopla/business-logic';

import { Loader } from '../../atoms/loader/Loader';
import { Responsive } from '../../molecules/Responsive';
import FullscreenOverlay from '../../organisms/FullscreenOverlay/FullscreenOverlay';
import PassengerBottomNavigation from '../../organisms/PassengerBottomNavigation/PassengerBottomNavigation';
import PassengerHeader from '../../organisms/PassengerHeader/PassengerHeader';
import PassengerSideNavigation from '../../organisms/PassengerSideNavigation/PassengerSideNavigation';

import {
	DesktopContentContainer,
	DesktopOverflowWrapper,
	DesktopSearchContainer,
	RootDesktop,
	RootMobile,
} from './PassengerHomeLayout.styles';

interface HomeProps {
	dTicketContent: ReactElement;
	searchContent: ReactElement;
	bookingsContent: ReactElement;
	profileContent: ReactElement;
	bookingConfirmationContent: ReactElement;
	isNativePlatform: boolean;
	initialLoading: boolean;
	initialRoute: string;
}

// props are only for the current development purposes.
export const PassengerHomeLayout: React.FC<HomeProps> = ({
	dTicketContent,
	searchContent,
	bookingsContent,
	profileContent,
	bookingConfirmationContent,
	isNativePlatform,
	initialLoading,
	initialRoute,
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const profileIsOpen = matchPath('/home/profile/*', pathname);
	const { userData } = useUser();
	const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);

	const getVariant = useCallback(() => {
		switch (pathname) {
			case '/home/dticket': {
				return 'neutral.0';
			}

			case '/home/tickets': {
				return 'primary.main';
			}

			case '/home/search': {
				return 'secondary.main';
			}

			case '/home/profile': {
				return 'primary.dark';
			}

			default: {
				return 'neutral.0';
			}
		}
	}, [pathname]);

	return (
		<>
			<Responsive aboveMobile>
				<RootDesktop>
					<PassengerHeader
						variant="neutral.0"
						onProfileClick={() => navigate('profile')}
					/>
					<DesktopOverflowWrapper>
						<DesktopContentContainer>
							{bookingConfirmationContent}
							<DesktopSearchContainer>
								{searchContent}
								{bookingsContent}
							</DesktopSearchContainer>
							{dTicketContent}
						</DesktopContentContainer>
					</DesktopOverflowWrapper>
					<FullscreenOverlay
						keepMounted={false}
						anchor="right"
						open={!!profileIsOpen}
						onOpen={() => undefined}
						onClose={() => undefined}
						onBack={() => navigate('/home')}
						title={`${userData?.firstName} ${userData?.lastName}`}
					>
						{profileContent}
					</FullscreenOverlay>
				</RootDesktop>
			</Responsive>
			<Responsive mobile>
				<RootMobile variant={getVariant()} isNative={isNativePlatform}>
					{!isNativePlatform ? (
						<PassengerHeader
							variant={getVariant()}
							onMenuClick={() => setDrawerMenuOpen(true)}
						/>
					) : null}

					{initialLoading ? (
						<Loader />
					) : (
						<Routes>
							<Route path="search" element={searchContent} />
							<Route path="dticket" element={dTicketContent} />
							<Route path="tickets" element={bookingsContent} />
							<Route path="profile" element={profileContent} />
							<Route
								path="*"
								element={<Navigate to={initialRoute} replace={true} />}
							/>
						</Routes>
					)}
					<Outlet />
					{!isNativePlatform ? (
						<PassengerSideNavigation
							open={drawerMenuOpen}
							onClose={() => setDrawerMenuOpen(false)}
							onOpen={() => setDrawerMenuOpen(true)}
						/>
					) : (
						<PassengerBottomNavigation />
					)}
				</RootMobile>
			</Responsive>
		</>
	);
};
