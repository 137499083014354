import {
	CurrencyCode,
	DiscountState,
	ILeg,
	Itinerary,
	NamedLocation,
	PaymentForm,
	PaymentState,
	TransportType,
} from '../lib/api';
import type = NamedLocation.type;

export const itineraryLBODTFlex: Itinerary = {
	startDateTime: '2024-01-24T14:13:27.106Z',
	endDateTime: '2024-01-24T15:38:31.106Z',
	duration: 2102,
	walkTime: 0,
	walkDistance: 0,
	airDistance: 26605,
	price: null,
	legs: [
		{
			legId: 'd83adc3f-eadd-46c3-bbbc-e24e6a42bd12',
			startDateTime: '2024-01-24T14:13:27.106Z',
			startDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
			startDateTimeWindowEnd: '2024-01-24T14:28:27.106817073Z',
			startDateTimeFix: false,
			endDateTime: '2024-01-24T15:38:31.106Z',
			endDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
			endDateTimeWindowEnd: '2024-01-24T15:38:31.106817073Z',
			endDateTimeFix: false,
			cancellationFreeUntil: '2024-01-24T13:13:27.106Z',
			costOfCancellation: 640,
			duration: 2102.1,
			distance: 33464.5,
			mode: TransportType.LBODTFLEX,
			modeOrigin: 'CAR',
			route: '',
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.044637,
				lat: 51.855333,
				arrival: '2024-01-24T14:13:27.106Z',
				departure: '2024-01-24T14:13:27.106Z',
				locationType: type.ADDRESS,
			},
			to: {
				name: 'Wolfen, Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.275519,
				lat: 51.66343,
				arrival: '2024-01-24T15:38:31.106Z',
				departure: '2024-01-24T15:38:31.106Z',
				locationType: type.ADDRESS,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 640,
					currency: CurrencyCode.EUR,
					discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 740,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 1650,
					currency: CurrencyCode.EUR,
					discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 2020,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
			],
			cancellationRules: [
				{
					secondsBeforeRideStart: 7200,
					cost: 10,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
				{
					secondsBeforeRideStart: 1800,
					cost: 320,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
				{
					secondsBeforeRideStart: 0,
					cost: 920,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
				{
					secondsBeforeRideStart: 7200,
					cost: 0,
					currency: CurrencyCode.EUR,
					discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				},
				{
					secondsBeforeRideStart: 1800,
					cost: 50,
					currency: CurrencyCode.EUR,
					discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				},
				{
					secondsBeforeRideStart: 0,
					cost: 100,
					currency: CurrencyCode.EUR,
					discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				},
			],
		},
	],
};

export const legLBODTFlex: ILeg = {
	legId: 'd83adc3f-eadd-46c3-bbbc-e24e6a42bd12',
	startDateTime: '2024-01-24T14:13:27.106Z',
	startDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
	startDateTimeWindowEnd: '2024-01-24T14:28:27.106817073Z',
	startDateTimeFix: false,
	endDateTime: '2024-01-24T15:38:31.106Z',
	endDateTimeWindowStart: '2024-01-24T14:13:27.106817073Z',
	endDateTimeWindowEnd: '2024-01-24T15:38:31.106817073Z',
	endDateTimeFix: false,
	cancellationFreeUntil: '2024-01-24T13:13:27.106Z',
	costOfCancellation: 640,
	duration: 2102.1,
	distance: 33464.5,
	mode: TransportType.LBODTFLEX,
	modeOrigin: 'CAR',
	route: '',
	headsign: null,
	routeId: null,
	agencyId: null,
	agencyName: 'Vetter Verkehrsbetriebe',
	tripShortName: null,
	tripId: null,
	from: {
		name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
		stopId: null,
		lng: 12.044637,
		lat: 51.855333,
		arrival: '2024-01-24T14:13:27.106Z',
		departure: '2024-01-24T14:13:27.106Z',
		locationType: type.ADDRESS,
	},
	to: {
		name: 'Wolfen, Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
		stopId: null,
		lng: 12.275519,
		lat: 51.66343,
		arrival: '2024-01-24T15:38:31.106Z',
		departure: '2024-01-24T15:38:31.106Z',
		locationType: type.ADDRESS,
	},
	payment: {
		currency: CurrencyCode.EUR,
		paymentAmount: 740,
		paymentForm: PaymentForm.DIGITAL,
		paymentInformationAvailable: true,
		paymentState: PaymentState.TO_PAY,
		overwrittenPaymentAmount: 600,
	},
	paymentOptions: [PaymentForm.DIGITAL],
	intermediateStops: [],
	pricings: [
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 640,
			currency: CurrencyCode.EUR,
			discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 740,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 1650,
			currency: CurrencyCode.EUR,
			discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 2020,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NO_DISCOUNT,
		},
	],
};

export const legLBT: ILeg = {
	legId: '39b8d4d1-9677-4002-b467-04b492001367',
	startDateTime: '2024-02-19T10:00:00Z',
	startDateTimeFix: true,
	endDateTime: '2024-02-19T10:26:00Z',
	endDateTimeFix: true,
	duration: 1560,
	distance: 14681.88,
	mode: TransportType.LBT,
	modeOrigin: 'BUS',
	route: '470',
	headsign: 'Gröbzig, Mühlbreite',
	routeId: '1:VETTAB470',
	agencyId: '1:00906',
	agencyName: 'Vetter Verkehrsbetriebe',
	tripShortName: '4700014',
	tripId: '1:78960',
	from: {
		name: 'Aken, Markt',
		stopId: '1:000016812',
		lng: 12.044659,
		lat: 51.85455,
		arrival: '2024-02-19T10:00:00Z',
		departure: '2024-02-19T10:00:00Z',
		locationType: type.STOP,
	},
	to: {
		name: 'Köthen, Bahnhof (Bus)',
		stopId: '1:000004966',
		lng: 11.98812,
		lat: 51.752385,
		arrival: '2024-02-19T10:26:00Z',
		departure: '2024-02-19T10:33:00Z',
		locationType: type.STOP,
	},
	legGeometry: {
		points:
			'}yn{HanohAwE|Y??ff@`S??jh@vx@??~_A|h@??pc@|F??p`@hL??zg@joA??rs@yx@??dj@uE??`Tug@??`Gkp@??tTbh@??rB|iA??~bCdlA??liBhaA',
		length: 30,
	},
	payment: null,
	paymentOptions: [PaymentForm.DIGITAL],
	intermediateStops: [
		{
			arrival: '2024-02-19T10:02:00Z',
			departure: '2024-02-19T10:02:00Z',
			name: 'Aken, Sekundarschule',
		},
		{
			arrival: '2024-02-19T10:03:00Z',
			departure: '2024-02-19T10:03:00Z',
			name: 'Aken, Heidestr.',
		},
		{
			arrival: '2024-02-19T10:05:00Z',
			departure: '2024-02-19T10:05:00Z',
			name: 'Aken, Mennewitzer Weg',
		},
		{
			arrival: '2024-02-19T10:06:00Z',
			departure: '2024-02-19T10:06:00Z',
			name: 'Aken, Heidehof',
		},
		{
			arrival: '2024-02-19T10:07:00Z',
			departure: '2024-02-19T10:07:00Z',
			name: 'Trebbichau (Micheln), Wasserwerk',
		},
		{
			arrival: '2024-02-19T10:07:00Z',
			departure: '2024-02-19T10:07:00Z',
			name: 'Trebbichau (Micheln), Heidekrug',
		},
		{
			arrival: '2024-02-19T10:09:00Z',
			departure: '2024-02-19T10:09:00Z',
			name: 'Trebbichau (Micheln), Ort',
		},
		{
			arrival: '2024-02-19T10:12:00Z',
			departure: '2024-02-19T10:12:00Z',
			name: 'Osternienburg, Ernst-Thälmann-Str.',
		},
		{
			arrival: '2024-02-19T10:13:00Z',
			departure: '2024-02-19T10:13:00Z',
			name: 'Osternienburg, Apotheke',
		},
		{
			arrival: '2024-02-19T10:15:00Z',
			departure: '2024-02-19T10:15:00Z',
			name: 'Osternienburg, Neubau',
		},
		{
			arrival: '2024-02-19T10:16:00Z',
			departure: '2024-02-19T10:16:00Z',
			name: 'Osternienburg, Denkmal',
		},
		{
			arrival: '2024-02-19T10:18:00Z',
			departure: '2024-02-19T10:18:00Z',
			name: 'Osternienburg, Rudolf-Breitscheid-Str.',
		},
		{
			arrival: '2024-02-19T10:19:00Z',
			departure: '2024-02-19T10:19:00Z',
			name: 'Pißdorf (Osternienburg), Ortseingang',
		},
		{
			arrival: '2024-02-19T10:22:00Z',
			departure: '2024-02-19T10:22:00Z',
			name: 'Porst (Köthen), Dessauer Str.',
		},
	],
	pricings: [
		{
			paymentHandlerCompanyName: 'Vetter',
			paymentHandlerCompanyType: 'DISTRIBUTOR',
			paymentInformationAvailable: false,
			amount: null,
			currency: null,
			discount: null,
		},
	],
};

export const legWalk: ILeg = {
	legId: '34d8c33d-9a39-4414-9ab8-42b4d8258e86',
	startDateTime: '2024-02-20T13:58:32Z',
	startDateTimeFix: true,
	endDateTime: '2024-02-20T14:00:00Z',
	endDateTimeFix: true,
	duration: 88,
	distance: 108.69,
	mode: TransportType.WALK,
	modeOrigin: TransportType.WALK,
	route: null,
	headsign: null,
	routeId: null,
	agencyId: null,
	agencyName: null,
	tripShortName: null,
	tripId: null,
	from: {
		name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
		stopId: null,
		lng: 12.04464,
		lat: 51.85535,
		arrival: null,
		departure: '2024-02-20T13:58:32Z',
		locationType: NamedLocation.type.STOP,
	},
	to: {
		name: 'Aken, Markt',
		stopId: '1:000016812',
		lng: 12.044659,
		lat: 51.85455,
		arrival: '2024-02-20T14:00:00Z',
		departure: '2024-02-20T14:00:00Z',
		locationType: NamedLocation.type.STOP,
	},
	legGeometry: {
		points: 'y~n{H}mohAB}@tBd@b@JAF',
		length: 5,
	},
	payment: null,
	paymentOptions: [PaymentForm.DIGITAL],
	intermediateStops: [],
	pricings: [],
};

export const itineraryLBT: Itinerary = {
	startDateTime: '2024-01-16T15:58:32Z',
	endDateTime: '2024-01-16T18:02:19Z',
	duration: 7427,
	walkTime: 287,
	walkDistance: 343.47,
	airDistance: 0,
	price: [],
	legs: [
		{
			legId: '06c44749-017e-416c-adca-e13046b1d0b8',
			startDateTime: '2024-01-16T15:58:32Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:00:00Z',
			endDateTimeFix: true,
			duration: 88,
			distance: 108.69,
			mode: TransportType.WALK,
			modeOrigin: TransportType.WALK,
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: null,
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.04464,
				lat: 51.85535,
				arrival: null,
				departure: '2024-01-16T15:58:32Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Aken, Markt',
				stopId: '1:000016812',
				lng: 12.044659,
				lat: 51.85455,
				arrival: '2024-01-16T16:00:00Z',
				departure: '2024-01-16T16:00:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points: 'y~n{H}mohAB}@tBd@b@JAF',
				length: 5,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [],
		},
		{
			legId: '0bc418d5-1afb-4c59-854d-27d504b6465e',
			startDateTime: '2024-01-16T16:00:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:26:00Z',
			endDateTimeFix: true,
			duration: 1560,
			distance: 14681.88,
			mode: TransportType.LBT,
			modeOrigin: 'BUS',
			route: '470',
			headsign: 'Gröbzig, Mühlbreite',
			routeId: '1:VETTAB470',
			agencyId: '1:00906',
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: '4700026',
			tripId: '1:79003',
			from: {
				name: 'Aken, Markt',
				stopId: '1:000016812',
				lng: 12.044659,
				lat: 51.85455,
				arrival: '2024-01-16T16:00:00Z',
				departure: '2024-01-16T16:00:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Köthen, Bahnhof (Bus)',
				stopId: '1:000004966',
				lng: 11.98812,
				lat: 51.752385,
				arrival: '2024-01-16T16:26:00Z',
				departure: '2024-01-16T16:33:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points:
					'}yn{HanohAwE|Y??ff@`S??jh@vx@??~_A|h@??pc@|F??p`@hL??zg@joA??rs@yx@??dj@uE??`Tug@??`Gkp@??tTbh@??rB|iA??~bCdlA??liBhaA',
				length: 30,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [
				{
					arrival: '2024-01-16T16:02:00Z',
					departure: '2024-01-16T16:02:00Z',
					name: 'Aken, Sekundarschule',
				},
				{
					arrival: '2024-01-16T16:03:00Z',
					departure: '2024-01-16T16:03:00Z',
					name: 'Aken, Heidestr.',
				},
				{
					arrival: '2024-01-16T16:05:00Z',
					departure: '2024-01-16T16:05:00Z',
					name: 'Aken, Mennewitzer Weg',
				},
				{
					arrival: '2024-01-16T16:06:00Z',
					departure: '2024-01-16T16:06:00Z',
					name: 'Aken, Heidehof',
				},
				{
					arrival: '2024-01-16T16:07:00Z',
					departure: '2024-01-16T16:07:00Z',
					name: 'Trebbichau (Micheln), Wasserwerk',
				},
				{
					arrival: '2024-01-16T16:07:00Z',
					departure: '2024-01-16T16:07:00Z',
					name: 'Trebbichau (Micheln), Heidekrug',
				},
				{
					arrival: '2024-01-16T16:09:00Z',
					departure: '2024-01-16T16:09:00Z',
					name: 'Trebbichau (Micheln), Ort',
				},
				{
					arrival: '2024-01-16T16:12:00Z',
					departure: '2024-01-16T16:12:00Z',
					name: 'Osternienburg, Ernst-Thälmann-Str.',
				},
				{
					arrival: '2024-01-16T16:13:00Z',
					departure: '2024-01-16T16:13:00Z',
					name: 'Osternienburg, Apotheke',
				},
				{
					arrival: '2024-01-16T16:15:00Z',
					departure: '2024-01-16T16:15:00Z',
					name: 'Osternienburg, Neubau',
				},
				{
					arrival: '2024-01-16T16:16:00Z',
					departure: '2024-01-16T16:16:00Z',
					name: 'Osternienburg, Denkmal',
				},
				{
					arrival: '2024-01-16T16:18:00Z',
					departure: '2024-01-16T16:18:00Z',
					name: 'Osternienburg, Rudolf-Breitscheid-Str.',
				},
				{
					arrival: '2024-01-16T16:19:00Z',
					departure: '2024-01-16T16:19:00Z',
					name: 'Pißdorf (Osternienburg), Ortseingang',
				},
				{
					arrival: '2024-01-16T16:22:00Z',
					departure: '2024-01-16T16:22:00Z',
					name: 'Porst (Köthen), Dessauer Str.',
				},
			],
			pricings: [
				{
					paymentHandlerCompanyName: 'Vetter',
					paymentHandlerCompanyType: 'DISTRIBUTOR',
					paymentInformationAvailable: false,
					amount: null,
					currency: null,
					discount: null,
				},
			],
		},
		{
			legId: '57a6f5f3-72b0-42e4-8fee-262323405f47',
			startDateTime: '2024-01-16T16:33:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T17:30:00Z',
			endDateTimeFix: true,
			duration: 3420,
			distance: 30919.36,
			mode: TransportType.LBT,
			modeOrigin: 'BUS',
			route: '400',
			headsign: 'Bitterfeld, Bahnhof (Bus)',
			routeId: '1:VETTAB400',
			agencyId: '1:00906',
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: '4000028',
			tripId: '1:77498',
			from: {
				name: 'Köthen, Bahnhof (Bus)',
				stopId: '1:000004966',
				lng: 11.98812,
				lat: 51.752385,
				arrival: '2024-01-16T16:26:00Z',
				departure: '2024-01-16T16:33:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Bitterfeld, Chemiepark',
				stopId: '1:000004727',
				lng: 12.307399,
				lat: 51.625391,
				arrival: '2024-01-16T17:30:00Z',
				departure: '2024-01-16T17:37:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points:
					'k{zzHwldhAgLk[??l@cw@??rFog@??`CgxD??`h@_uP??_I|C??fwCo_D??|WwcE??dI}|E??h`EazG??nOpO??bQoP??Xkd@??lYoR??vd@gF??lStI??fXqG??jKeV??|C~T??rOuK??tNmG??dj@oo@??vb@si@??hh@gY??zY_K??ra@_P??jDg]',
				length: 54,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [
				{
					arrival: '2024-01-16T16:35:00Z',
					departure: '2024-01-16T16:35:00Z',
					name: 'Köthen, Osterköthen',
				},
				{
					arrival: '2024-01-16T16:36:00Z',
					departure: '2024-01-16T16:36:00Z',
					name: 'Köthen, Kaufland',
				},
				{
					arrival: '2024-01-16T16:37:00Z',
					departure: '2024-01-16T16:37:00Z',
					name: 'Köthen, Merziener Str.',
				},
				{
					arrival: '2024-01-16T16:40:00Z',
					departure: '2024-01-16T16:40:00Z',
					name: 'Merzien (Köthen), Str. der DSF',
				},
				{
					arrival: '2024-01-16T16:47:00Z',
					departure: '2024-01-16T16:47:00Z',
					name: 'Quellendorf, Ambulatorium',
				},
				{
					arrival: '2024-01-16T16:49:00Z',
					departure: '2024-01-16T16:49:00Z',
					name: 'Quellendorf, Schule',
				},
				{
					arrival: '2024-01-16T16:54:00Z',
					departure: '2024-01-16T16:54:00Z',
					name: 'Hinsdorf',
				},
				{
					arrival: '2024-01-16T16:57:00Z',
					departure: '2024-01-16T16:57:00Z',
					name: 'Tornau vor der Heide, Thurländer Str.',
				},
				{
					arrival: '2024-01-16T17:00:00Z',
					departure: '2024-01-16T17:00:00Z',
					name: 'Thurland, Hauptstr.',
				},
				{
					arrival: '2024-01-16T17:07:00Z',
					departure: '2024-01-16T17:07:00Z',
					name: 'Wolfen (Bitterfeld)-Nord, Hermann-Fahlke-Str.',
				},
				{
					arrival: '2024-01-16T17:08:00Z',
					departure: '2024-01-16T17:08:00Z',
					name: 'Wolfen (Bitterfeld)-Nord, Dessauer Allee',
				},
				{
					arrival: '2024-01-16T17:09:00Z',
					departure: '2024-01-16T17:09:00Z',
					name: 'Wolfen (Bitterfeld)-Nord, Comeniusstr.',
				},
				{
					arrival: '2024-01-16T17:10:00Z',
					departure: '2024-01-16T17:10:00Z',
					name: 'Wolfen (Bitterfeld)-Nord, Franz-Mehring-Str.',
				},
				{
					arrival: '2024-01-16T17:12:00Z',
					departure: '2024-01-16T17:12:00Z',
					name: 'Steinfurth (Jeßnitz), B184',
				},
				{
					arrival: '2024-01-16T17:13:00Z',
					departure: '2024-01-16T17:13:00Z',
					name: 'Steinfurth (Jeßnitz), Hitschkendorfer Str.',
				},
				{
					arrival: '2024-01-16T17:14:00Z',
					departure: '2024-01-16T17:14:00Z',
					name: 'Wolfen (Bitterfeld), Markt',
				},
				{
					arrival: '2024-01-16T17:15:00Z',
					departure: '2024-01-16T17:15:00Z',
					name: 'Wolfen (Bitterfeld), Bernhard-Göring-Str.',
				},
				{
					arrival: '2024-01-16T17:17:00Z',
					departure: '2024-01-16T17:18:00Z',
					name: 'Wolfen (Bitterfeld), Bahnhof (Bus)',
				},
				{
					arrival: '2024-01-16T17:19:00Z',
					departure: '2024-01-16T17:19:00Z',
					name: 'Wolfen (Bitterfeld), Bahnhofstr.',
				},
				{
					arrival: '2024-01-16T17:21:00Z',
					departure: '2024-01-16T17:21:00Z',
					name: 'Wolfen (Bitterfeld), Filmweg',
				},
				{
					arrival: '2024-01-16T17:22:00Z',
					departure: '2024-01-16T17:22:00Z',
					name: 'Wolfen (Bitterfeld), Greppiner Str.',
				},
				{
					arrival: '2024-01-16T17:23:00Z',
					departure: '2024-01-16T17:23:00Z',
					name: 'Greppin, Farbenstr.',
				},
				{
					arrival: '2024-01-16T17:25:00Z',
					departure: '2024-01-16T17:25:00Z',
					name: 'Bitterfeld, Parsevalstr.',
				},
				{
					arrival: '2024-01-16T17:26:00Z',
					departure: '2024-01-16T17:26:00Z',
					name: 'Bitterfeld, Str. der Wissenschaften',
				},
				{
					arrival: '2024-01-16T17:27:00Z',
					departure: '2024-01-16T17:27:00Z',
					name: 'Bitterfeld, Antonienstr.',
				},
				{
					arrival: '2024-01-16T17:28:00Z',
					departure: '2024-01-16T17:28:00Z',
					name: 'Bitterfeld, Berufsschule',
				},
			],
			pricings: [
				{
					paymentHandlerCompanyName: 'Vetter',
					paymentHandlerCompanyType: 'DISTRIBUTOR',
					paymentInformationAvailable: false,
					amount: null,
					currency: null,
					discount: null,
				},
			],
		},
		{
			legId: '7766df12-39d0-42d2-87e8-caaf1eabcb00',
			startDateTime: '2024-01-16T17:37:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T17:59:00Z',
			endDateTimeFix: true,
			duration: 1320,
			distance: 14191.46,
			mode: TransportType.LBT,
			modeOrigin: 'BUS',
			route: '440',
			headsign: 'Zörbig, Markt',
			routeId: '1:VETTAB440',
			agencyId: '1:00906',
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: '4400030',
			tripId: '1:78402',
			from: {
				name: 'Bitterfeld, Chemiepark',
				stopId: '1:000004727',
				lng: 12.307399,
				lat: 51.625391,
				arrival: '2024-01-16T17:30:00Z',
				departure: '2024-01-16T17:37:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Zörbig, Markt',
				stopId: '1:000004512',
				lng: 12.118125,
				lat: 51.628041,
				arrival: '2024-01-16T17:59:00Z',
				departure: '2024-01-16T17:59:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points:
					'uabzHexbjAfF`i@??oE~{D??gHxg@??pK``K??lHxpC??fN`P??w_@~uG??qHxb@??wK~S??aC~a@??hLxS??No[',
				length: 24,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [
				{
					arrival: '2024-01-16T17:39:00Z',
					departure: '2024-01-16T17:39:00Z',
					name: 'Bitterfeld, Griesheimstr.',
				},
				{
					arrival: '2024-01-16T17:42:00Z',
					departure: '2024-01-16T17:42:00Z',
					name: 'Sandersdorf, Sportzentrum',
				},
				{
					arrival: '2024-01-16T17:44:00Z',
					departure: '2024-01-16T17:44:00Z',
					name: 'Sandersdorf, Rathaus',
				},
				{
					arrival: '2024-01-16T17:47:00Z',
					departure: '2024-01-16T17:47:00Z',
					name: 'Heideloh, Stakendorfer Str.',
				},
				{
					arrival: '2024-01-16T17:49:00Z',
					departure: '2024-01-16T17:49:00Z',
					name: 'Großzöberitz, Gewerbegebiet',
				},
				{
					arrival: '2024-01-16T17:51:00Z',
					departure: '2024-01-16T17:51:00Z',
					name: 'Großzöberitz, Ernst-Thälmann-Str.',
				},
				{
					arrival: '2024-01-16T17:54:00Z',
					departure: '2024-01-16T17:54:00Z',
					name: 'Zörbig, Flutgraben',
				},
				{
					arrival: '2024-01-16T17:55:00Z',
					departure: '2024-01-16T17:55:00Z',
					name: 'Zörbig, Bitterfelder Str.',
				},
				{
					arrival: '2024-01-16T17:56:00Z',
					departure: '2024-01-16T17:56:00Z',
					name: 'Zörbig, Schützenplatz',
				},
				{
					arrival: '2024-01-16T17:56:00Z',
					departure: '2024-01-16T17:56:00Z',
					name: 'Zörbig, Lindenstr.',
				},
				{
					arrival: '2024-01-16T17:58:00Z',
					departure: '2024-01-16T17:58:00Z',
					name: 'Zörbig, Turm',
				},
			],
			pricings: [
				{
					paymentHandlerCompanyName: 'Vetter',
					paymentHandlerCompanyType: 'DISTRIBUTOR',
					paymentInformationAvailable: false,
					amount: null,
					currency: null,
					discount: null,
				},
			],
		},
		{
			legId: '31849a08-ad0c-45f1-949b-64f8349f15aa',
			startDateTime: '2024-01-16T17:59:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T18:02:19Z',
			endDateTimeFix: true,
			duration: 199,
			distance: 234.78,
			mode: TransportType.WALK,
			modeOrigin: TransportType.WALK,
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: null,
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Zörbig, Markt',
				stopId: '1:000004512',
				lng: 12.118125,
				lat: 51.628041,
				arrival: '2024-01-16T17:59:00Z',
				departure: '2024-01-16T17:59:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Zörbig, Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.12023,
				lat: 51.62729,
				arrival: '2024-01-16T18:02:19Z',
				departure: null,
				locationType: type.STOP,
			},
			legGeometry: {
				points: 'grbzHgy}hAK?AmAAkA?_@Aq@Aq@x@Gr@GAM@?TCR?RC?_A?_@',
				length: 16,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [],
		},
	],
};

export const itineraryMixed: Itinerary = {
	startDateTime: '2024-01-16T15:58:32Z',
	endDateTime: '2024-01-16T17:19:30Z',
	duration: 4858,
	walkTime: 1438,
	walkDistance: 12338.35,
	airDistance: 0,
	price: null,
	legs: [
		{
			legId: '42f3ac21-43b4-4801-9f27-5642ed25e8d8',
			startDateTime: '2024-01-16T15:58:32Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:00:00Z',
			endDateTimeFix: true,
			duration: 88,
			distance: 108.72,
			mode: TransportType.WALK,
			modeOrigin: TransportType.WALK,
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: null,
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.044637,
				lat: 51.855333,
				arrival: null,
				departure: '2024-01-16T15:58:32Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Aken, Markt',
				stopId: '1:000016812',
				lng: 12.044659,
				lat: 51.85455,
				arrival: '2024-01-16T16:00:00Z',
				departure: '2024-01-16T16:00:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points: 'y~n{H}mohAB}@tBd@b@JAF',
				length: 5,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [],
		},
		{
			legId: 'cbcd8da7-cb85-47d1-b59d-231de8e0aab4',
			startDateTime: '2024-01-16T16:00:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:26:00Z',
			endDateTimeFix: true,
			duration: 1560,
			distance: 14681.88,
			mode: TransportType.LBT,
			modeOrigin: 'BUS',
			route: '470',
			headsign: 'Gröbzig, Mühlbreite',
			routeId: '1:VETTAB470',
			agencyId: '1:00906',
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: '4700026',
			tripId: '1:79003',
			from: {
				name: 'Aken, Markt',
				stopId: '1:000016812',
				lng: 12.044659,
				lat: 51.85455,
				arrival: '2024-01-16T16:00:00Z',
				departure: '2024-01-16T16:00:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Köthen, Bahnhof (Bus)',
				stopId: '1:000004966',
				lng: 11.98812,
				lat: 51.752385,
				arrival: '2024-01-16T16:26:00Z',
				departure: '2024-01-16T16:33:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points:
					'}yn{HanohAwE|Y??ff@`S??jh@vx@??~_A|h@??pc@|F??p`@hL??zg@joA??rs@yx@??dj@uE??`Tug@??`Gkp@??tTbh@??rB|iA??~bCdlA??liBhaA',
				length: 30,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [
				{
					arrival: '2024-01-16T16:02:00Z',
					departure: '2024-01-16T16:02:00Z',
					name: 'Aken, Sekundarschule',
				},
				{
					arrival: '2024-01-16T16:03:00Z',
					departure: '2024-01-16T16:03:00Z',
					name: 'Aken, Heidestr.',
				},
				{
					arrival: '2024-01-16T16:05:00Z',
					departure: '2024-01-16T16:05:00Z',
					name: 'Aken, Mennewitzer Weg',
				},
				{
					arrival: '2024-01-16T16:06:00Z',
					departure: '2024-01-16T16:06:00Z',
					name: 'Aken, Heidehof',
				},
				{
					arrival: '2024-01-16T16:07:00Z',
					departure: '2024-01-16T16:07:00Z',
					name: 'Trebbichau (Micheln), Wasserwerk',
				},
				{
					arrival: '2024-01-16T16:07:00Z',
					departure: '2024-01-16T16:07:00Z',
					name: 'Trebbichau (Micheln), Heidekrug',
				},
				{
					arrival: '2024-01-16T16:09:00Z',
					departure: '2024-01-16T16:09:00Z',
					name: 'Trebbichau (Micheln), Ort',
				},
				{
					arrival: '2024-01-16T16:12:00Z',
					departure: '2024-01-16T16:12:00Z',
					name: 'Osternienburg, Ernst-Thälmann-Str.',
				},
				{
					arrival: '2024-01-16T16:13:00Z',
					departure: '2024-01-16T16:13:00Z',
					name: 'Osternienburg, Apotheke',
				},
				{
					arrival: '2024-01-16T16:15:00Z',
					departure: '2024-01-16T16:15:00Z',
					name: 'Osternienburg, Neubau',
				},
				{
					arrival: '2024-01-16T16:16:00Z',
					departure: '2024-01-16T16:16:00Z',
					name: 'Osternienburg, Denkmal',
				},
				{
					arrival: '2024-01-16T16:18:00Z',
					departure: '2024-01-16T16:18:00Z',
					name: 'Osternienburg, Rudolf-Breitscheid-Str.',
				},
				{
					arrival: '2024-01-16T16:19:00Z',
					departure: '2024-01-16T16:19:00Z',
					name: 'Pißdorf (Osternienburg), Ortseingang',
				},
				{
					arrival: '2024-01-16T16:22:00Z',
					departure: '2024-01-16T16:22:00Z',
					name: 'Porst (Köthen), Dessauer Str.',
				},
			],
			pricings: [
				{
					paymentHandlerCompanyName: 'Vetter',
					paymentHandlerCompanyType: 'DISTRIBUTOR',
					paymentInformationAvailable: false,
					amount: null,
					currency: null,
					discount: null,
				},
			],
		},
		{
			legId: 'caa90e79-9844-449f-b28c-bcc741176810',
			startDateTime: '2024-01-16T16:33:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:57:00Z',
			endDateTimeFix: true,
			duration: 1440,
			distance: 15428.88,
			mode: TransportType.LBT,
			modeOrigin: 'BUS',
			route: '400',
			headsign: 'Bitterfeld, Bahnhof (Bus)',
			routeId: '1:VETTAB400',
			agencyId: '1:00906',
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: '4000028',
			tripId: '1:77498',
			from: {
				name: 'Köthen, Bahnhof (Bus)',
				stopId: '1:000004966',
				lng: 11.98812,
				lat: 51.752385,
				arrival: '2024-01-16T16:26:00Z',
				departure: '2024-01-16T16:33:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Tornau vor der Heide, Thurländer Str.',
				stopId: '1:000015640',
				lng: 12.184695,
				lat: 51.719085,
				arrival: '2024-01-16T16:57:00Z',
				departure: '2024-01-16T16:57:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points:
					'k{zzHwldhAgLk[??l@cw@??rFog@??`CgxD??`h@_uP??_I|C??fwCo_D??|WwcE',
				length: 16,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [
				{
					arrival: '2024-01-16T16:35:00Z',
					departure: '2024-01-16T16:35:00Z',
					name: 'Köthen, Osterköthen',
				},
				{
					arrival: '2024-01-16T16:36:00Z',
					departure: '2024-01-16T16:36:00Z',
					name: 'Köthen, Kaufland',
				},
				{
					arrival: '2024-01-16T16:37:00Z',
					departure: '2024-01-16T16:37:00Z',
					name: 'Köthen, Merziener Str.',
				},
				{
					arrival: '2024-01-16T16:40:00Z',
					departure: '2024-01-16T16:40:00Z',
					name: 'Merzien (Köthen), Str. der DSF',
				},
				{
					arrival: '2024-01-16T16:47:00Z',
					departure: '2024-01-16T16:47:00Z',
					name: 'Quellendorf, Ambulatorium',
				},
				{
					arrival: '2024-01-16T16:49:00Z',
					departure: '2024-01-16T16:49:00Z',
					name: 'Quellendorf, Schule',
				},
				{
					arrival: '2024-01-16T16:54:00Z',
					departure: '2024-01-16T16:54:00Z',
					name: 'Hinsdorf',
				},
			],
			pricings: [
				{
					paymentHandlerCompanyName: 'Vetter',
					paymentHandlerCompanyType: 'DISTRIBUTOR',
					paymentInformationAvailable: false,
					amount: null,
					currency: null,
					discount: null,
				},
			],
		},
		{
			legId: '3b4b8eef-dd72-48b7-8aa3-9435a3d9f210',
			startDateTime: '2024-01-16T16:57:00Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T16:58:00Z',
			endDateTimeFix: true,
			duration: 60,
			distance: 0,
			mode: TransportType.WALK,
			modeOrigin: TransportType.WALK,
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: null,
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Tornau vor der Heide, Thurländer Str.',
				stopId: '1:000015640',
				lng: 12.184695,
				lat: 51.719085,
				arrival: '2024-01-16T16:57:00Z',
				departure: '2024-01-16T16:57:00Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Am Trappenberg',
				stopId: null,
				lng: 12.1846983,
				lat: 51.7191123,
				arrival: '2024-01-16T16:58:00Z',
				departure: '2024-01-16T16:58:00Z',
				locationType: type.STOP,
			},
			legGeometry: {
				points: 'gktzHiyjiAE?',
				length: 2,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [],
		},
		{
			legId: 'b7da71ff-b0cd-4d9d-9f51-b301868aa5d5',
			startDateTime: '2024-01-16T17:03:30.363Z',
			startDateTimeWindowStart: '2024-01-16T17:03:30.363588914Z',
			startDateTimeWindowEnd: '2024-01-16T17:18:30.363588914Z',
			startDateTimeFix: false,
			endDateTime: '2024-01-16T17:54:14.363Z',
			endDateTimeWindowStart: '2024-01-16T17:03:30.363588914Z',
			endDateTimeWindowEnd: '2024-01-16T17:54:14.363588914Z',
			endDateTimeFix: false,
			cancellationFreeUntil: '2024-01-16T16:03:30.363Z',
			costOfCancellation: 310,
			duration: 1072,
			distance: 11962.14,
			mode: TransportType.LBODTFLEX,
			modeOrigin: 'CAR',
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: 'Vetter Verkehrsbetriebe',
			tripShortName: null,
			tripId: null,
			from: {
				name: 'Am Trappenberg 2, 06779 Raguhn-Jeßnitz, Deutschland',
				stopId: null,
				lng: 12.1846983,
				lat: 51.7191123,
				arrival: '2024-01-16T16:58:00Z',
				departure: '2024-01-16T16:58:00Z',
				locationType: type.ADDRESS,
			},
			to: {
				name: 'Zörbig, Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.122784,
				lat: 51.6284022,
				arrival: '2024-01-16T17:15:52Z',
				departure: '2024-01-16T17:15:52Z',
				locationType: type.ADDRESS,
			},
			legGeometry: {
				points:
					'mktzHiyjiA?VCr@Ab@A\\VJTHRDZDTBh@?fEOlAGpAMbBUzAS|@Kp@GVEpAM~DOnAGvAEl@@b@FlB`@~@Rb@Dp@Ev@OnImBj@Kj@Sh@_@ZSVK^CP?N?VBR?ZC`@IvBc@fH{A~FoAzGyAbR{DbEy@p@O|GuAd@IvCo@`Ci@fCi@|@UnBk@jBo@lBm@lBg@zAWn@I^CVAz@BfAXhAj@`@Vh@j@l@t@h@`Ad@hANj@Rt@n@fCJb@TbATv@Pl@HT\\|@j@tAh@fAb@t@tApBfChCnBnAfGlDj@Zf@Zd@b@nE`E`EtDjF|ElCbCrArA\\TLJ~@t@tAhAhA|@f@`@DBNNv@p@TTlNnMdQhMjChBxChAtGpArFlAfBr@pB~@jDhBtEhCfEtC`ChBp@h@n@d@pAhARPXV|AvA|@v@fAt@n@`@`A`@f@N\\J\\Fj@H|@FrA@fCEfA?vADlBRbBXbBd@b@P`@LfBz@TLTNZThAv@nAfAn@n@r@v@pA`Bt@fAv@lApAhCf@hAf@lAjAjDb@zAVfAXlA\\|AJn@ZfBVlBHt@Jt@NjBP`CFp@JfBLbBLbBN`BNpANnARpAPfATlAn@xCVdAnA`EZx@`@hAP`@Tf@p@tA`AdBXb@^l@^h@dAtAtBzBfCjCtE~EtLpNz_@`e@zBnCV\\NXLJLJNVl@p@jOfRfGtHpDlFp@`APRDDp@v@TRTT|BrBh@d@l@bAd@pAH^^|ACFAF?F?H@FBDBBB@D@BA',
				length: 228,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 160,
					currency: CurrencyCode.EUR,
					discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 210,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 530,
					currency: CurrencyCode.EUR,
					discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
				},
				{
					paymentHandlerCompanyName: 'MoPla Solutions GmbH',
					paymentHandlerCompanyType: 'MOPLA',
					paymentInformationAvailable: true,
					amount: 740,
					currency: CurrencyCode.EUR,
					discount: DiscountState.NO_DISCOUNT,
				},
			],
		},
		{
			legId: '4e72c014-1ccc-4e2c-9167-df3cd7d2fb40',
			startDateTime: '2024-01-16T17:54:14.363Z',
			startDateTimeFix: true,
			endDateTime: '2024-01-16T17:57:52.363Z',
			endDateTimeFix: true,
			duration: 218,
			distance: 267.49,
			mode: TransportType.WALK,
			modeOrigin: TransportType.WALK,
			route: null,
			headsign: null,
			routeId: null,
			agencyId: null,
			agencyName: null,
			tripShortName: null,
			tripId: null,
			from: {
				name: 'corner of L 143 and Lange Straße',
				stopId: null,
				lng: 12.122784,
				lat: 51.6284022,
				arrival: '2024-01-16T17:15:52Z',
				departure: '2024-01-16T17:15:52Z',
				locationType: type.STOP,
			},
			to: {
				name: 'Zörbig, Sachsen-Anhalt, Deutschland',
				stopId: null,
				lng: 12.12023,
				lat: 51.627282,
				arrival: '2024-01-16T17:19:30Z',
				departure: null,
				locationType: type.STOP,
			},
			legGeometry: {
				points: 'otbzHkv~hAAFC`@?L?TB~@BbALANBHHJRPj@Lb@Nl@Pl@N`@\\Cz@K?ZAr@',
				length: 20,
			},
			payment: null,
			paymentOptions: [PaymentForm.DIGITAL],
			intermediateStops: [],
			pricings: [],
		},
	],
};

export const pureLBODTFlex200 = {
	requestedParams: {
		fromPlace: {
			name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
			lat: '51.855333',
			lng: '12.044637',
			type: type.ADDRESS,
			zipcode: '06385',
			province: 'ST',
		},
		toPlace: {
			name: 'Wolfen, Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
			lat: '51.66343',
			lng: '12.275519',
			type: type.ADDRESS,
			zipcode: '06766',
			province: 'ST',
		},
		datetime: '2024-01-24T13:03:26.632Z',
		arriveBy: false,
		specialNeeds: 'NONE',
	},
	itineraries: [itineraryLBODTFlex],
};

export const cheaperRides200 = {
	requestedParams: {
		fromPlace: {
			name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
			lat: '51.855333',
			lng: '12.044637',
			type: type.ADDRESS,
			zipcode: '06385',
			province: 'ST',
		},
		toPlace: {
			name: 'Zörbig, Sachsen-Anhalt, Deutschland',
			lat: '51.627282',
			lng: '12.12023',
			type: type.ADDRESS,
			zipcode: '06780',
			province: 'ST',
		},
		datetime: '2024-01-16T15:53:28.623Z',
		arriveBy: false,
		specialNeeds: 'NONE',
	},
	itineraries: [itineraryLBT, itineraryLBODTFlex, itineraryMixed],
};
