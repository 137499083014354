import { styled } from '@mui/material/styles';

export const ModalTitle = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: 32,
}));

export const SuccessModalContent = styled('div')(({ theme }) => ({
	marginBottom: 32,
}));

export const UploadedDate = styled('div')(({ theme }) => ({
	color: theme.palette.primary.dark,
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content',
	padding: 16,
	borderRadius: 8,
	backgroundColor: theme.palette.neutral[0],
	marginTop: 24,
	marginBottom: 24,

	'& > span': {
		...theme.typography.body2,
		fontWeight: 600,
		color: theme.palette.primary.dark,
		marginLeft: 4,
	},
}));
