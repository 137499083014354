import { ComponentType, FC, useCallback, useMemo, useState } from 'react';

import {
	cancelSubscription,
	SubscriptionActionTypes,
	useBusinessLayer,
} from '@mopla/business-logic';
import { TSubscription } from '@mopla/data-models';

import {
	ICancelSubscriptionHandlerPayload,
	IProfileManagerContext,
	ProfileManagerContext,
} from './context';

export const withProfileManagerContext = <T,>(
	WrappedComponent: ComponentType<{ subscription?: TSubscription } & T>
) => {
	const WithProfileManagerContext: FC<
		{ subscription?: TSubscription; app: 'PASSENGER' | 'DRIVER' } & T
	> = (props) => {
		const { subscription, app } = props;
		const [backInterceptor, _setBackInterceptor] =
			useState<VoidFunction | null>();
		const [proceedInterceptor, _setProceedInterceptor] =
			useState<VoidFunction | null>();
		const [isProceedDisabled, setProceedDisabled] = useState(false);
		const [proceedText, setProceedText] = useState<string | null>(null);
		const [showErrorModal, setShowErrorModal] = useState(false);

		const isPassengerApp = app === 'PASSENGER';
		const isDriverApp = app === 'DRIVER';
		const bl = useBusinessLayer();

		const setBackInterceptor = useCallback(
			(interceptor?: VoidFunction | null) => {
				_setBackInterceptor(() => interceptor);
			},
			[]
		);

		const setProceedInterceptor = useCallback(
			(interceptor?: VoidFunction | null) => {
				_setProceedInterceptor(() => interceptor);
			},
			[]
		);

		const cancelSubscriptionHandler = useCallback(
			(payload: ICancelSubscriptionHandlerPayload) => {
				return new Promise<void>((resolve, reject) => {
					const { cancellationReason, cancellationReasonDescription } = payload;

					bl.dispatch(
						cancelSubscription({
							subscriptionId: subscription?.id as string,
							cancellationReason,
							cancellationReasonDescription,
						})
					);

					bl.watchActions({
						types: SubscriptionActionTypes.CancelSubscriptionResult,
						callback: (action) => {
							if (action.payload?.error) {
								console.error(
									'Error on canceling subscription: ',
									action.payload.error
								);
								setShowErrorModal(true);
								reject();
							} else {
								const windowRef = window as any;
								windowRef.dataLayer = windowRef.dataLayer || [];
								windowRef.dataLayer.push({
									event: 'cancelSubscription',
								});
							}

							resolve();
						},
					});
				});
			},
			[bl, subscription?.id]
		);

		const ctx = useMemo<IProfileManagerContext>(
			() => ({
				setBackInterceptor,
				backInterceptor,
				proceedInterceptor,
				setProceedInterceptor,
				isProceedDisabled,
				setProceedDisabled,
				proceedText,
				setProceedText,
				showErrorModal,
				setShowErrorModal,
				cancelSubscriptionHandler,
				app,
				isPassengerApp,
				isDriverApp,
			}),
			[
				setBackInterceptor,
				backInterceptor,
				proceedInterceptor,
				setProceedInterceptor,
				isProceedDisabled,
				proceedText,
				showErrorModal,
				cancelSubscriptionHandler,
				app,
				isPassengerApp,
				isDriverApp,
			]
		);

		return (
			<ProfileManagerContext.Provider value={ctx}>
				<WrappedComponent {...props} />
			</ProfileManagerContext.Provider>
		);
	};

	return WithProfileManagerContext;
};
