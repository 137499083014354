import React from 'react';
import { Radio as MuiRadio } from '@mui/material';

import { FormControlLabelStyled } from './styled';

export interface IRadioProps {
	checked?: boolean;
	onChange?: VoidFunction;
	label: string | React.ReactNode;
	name: string;
	value: string;
	testId?: string;
	disabled?: boolean;
}

export const Radio: React.FC<IRadioProps> = (props) => {
	const { checked, onChange, label, name, value, disabled, testId } = props;

	return (
		<FormControlLabelStyled
			control={<MuiRadio color="primary" data-testid={testId} />}
			label={label}
			name={name}
			value={value}
			onChange={onChange}
			checked={checked}
			disabled={disabled}
		/>
	);
};
