import {
	BookingStatus,
	CurrencyCode,
	DiscountState,
	IBooking,
	NamedLocation,
	PaymentForm,
	PaymentState,
	SpecialNeeds,
	TransportType,
} from '../';

export const booking_2Adults_3Toddlers_LBODTFLEX: IBooking = {
	passengerList: [
		{
			isMainPassenger: false,
			specialNeeds: SpecialNeeds.NONE,
			discountState: DiscountState.NO_DISCOUNT,
		},
		{
			isMainPassenger: false,
			specialNeeds: SpecialNeeds.NONE,
			discountState: DiscountState.DISCOUNTED_TICKET_PRESENT,
		},
		{
			firstName: 'Smb',
			lastName: 'Another',
			email: 'sewr@sdf.sdf',
			phone: '123',
			isMainPassenger: true,
			specialNeeds: SpecialNeeds.NONE,
			discountState: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
		},
	],
	passengers: 3,
	bookingNumber: '2024ZOTTQTYT',
	specialNeeds: SpecialNeeds.NONE,
	bookingStatus: BookingStatus.NEW,
	itinerary: {
		startDateTime: '2024-03-14T13:05:47.837Z',
		endDateTime: '2024-03-14T14:50:41.837Z',
		duration: 2697,
		walkTime: 0,
		walkDistance: 0,
		airDistance: 32390,
		price: [
			{
				discountState: 'DISCOUNTED_TICKET_PRESENT',
				amountToPay: 760,
				currency: CurrencyCode.EUR,
			},
			{
				discountState: 'NON_DISCOUNTED_TICKET_PRESENT',
				amountToPay: 860,
				currency: CurrencyCode.EUR,
			},
			{
				discountState: 'PERSONAL_DISCOUNT_AVAILABLE',
				amountToPay: 1950,
				currency: CurrencyCode.EUR,
			},
			{
				discountState: 'NO_DISCOUNT',
				amountToPay: 2380,
				currency: CurrencyCode.EUR,
			},
		],
		legs: [
			{
				legId: '39fd7fcd-56dd-49b2-85e4-180f30957986',
				startDateTime: '2024-03-14T13:05:47.837Z',
				startDateTimeWindowStart: '2024-03-14T13:05:47.837883146Z',
				startDateTimeWindowEnd: '2024-03-14T13:20:47.837883146Z',
				startDateTimeFix: false,
				endDateTime: '2024-03-14T14:50:41.837Z',
				endDateTimeWindowStart: '2024-03-14T13:05:47.837883146Z',
				endDateTimeWindowEnd: '2024-03-14T14:50:41.837883146Z',
				endDateTimeFix: false,
				cancellationFreeUntil: '2024-03-14T12:05:47.837Z',
				costOfCancellation: 760,
				duration: 2697,
				distance: 39996.7,
				mode: TransportType.LBODTFLEX,
				modeOrigin: 'CAR',
				route: '',
				headsign: null,
				routeId: null,
				agencyId: null,
				agencyName: 'Vetter Verkehrsbetriebe',
				tripShortName: null,
				tripId: null,
				from: {
					name: 'Aken (Elbe), Sachsen-Anhalt, Deutschland',
					stopId: null,
					lng: 12.044637,
					lat: 51.855333,
					arrival: '2024-03-14T13:05:47.837Z',
					departure: '2024-03-14T13:05:47.837Z',
					locationType: NamedLocation.type.ADDRESS,
				},
				to: {
					name: 'Bitterfeld-Wolfen, Sachsen-Anhalt, Deutschland',
					stopId: null,
					lng: 12.328808,
					lat: 51.623215,
					arrival: '2024-03-14T14:50:41.837Z',
					departure: '2024-03-14T14:50:41.837Z',
					locationType: NamedLocation.type.ADDRESS,
				},
				// legGeometry: null,
				// interlineWithPreviousLeg: null,
				payment: {
					paymentAmount: 5090,
					currency: CurrencyCode.EUR,
					paymentForm: PaymentForm.DIGITAL,
					paymentState: PaymentState.TO_PAY,
					overwrittenPaymentAmount: null,
					paymentInformationAvailable: true,
				},
				paymentOptions: [PaymentForm.DIGITAL],
				intermediateStops: [],
				pricings: [
					{
						paymentHandlerCompanyName: 'MoPla Solutions GmbH',
						paymentHandlerCompanyType: 'MOPLA',
						paymentInformationAvailable: true,
						amount: 760,
						currency: CurrencyCode.EUR,
						discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
					},
					{
						paymentHandlerCompanyName: 'MoPla Solutions GmbH',
						paymentHandlerCompanyType: 'MOPLA',
						paymentInformationAvailable: true,
						amount: 860,
						currency: CurrencyCode.EUR,
						discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
					},
					{
						paymentHandlerCompanyName: 'MoPla Solutions GmbH',
						paymentHandlerCompanyType: 'MOPLA',
						paymentInformationAvailable: true,
						amount: 1950,
						currency: CurrencyCode.EUR,
						discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
					},
					{
						paymentHandlerCompanyName: 'MoPla Solutions GmbH',
						paymentHandlerCompanyType: 'MOPLA',
						paymentInformationAvailable: true,
						amount: 2380,
						currency: CurrencyCode.EUR,
						discount: DiscountState.NO_DISCOUNT,
					},
				],
				lbodtflexLabel: 'AnrufbusFlex',
			},
		],
		luggageDefinition:
			'Das Sperrgepäck darf nicht mehr als einen Sitzplatz einnehmen. Die Mitnahme ist kostenlos QA TEST... QA TEST',
		toddlersDefinition: 'Kinder von 0 - 5 Jahren reisen kostenlos... QATEST',
	},
	id: '0a1291f5-cc6b-4dc8-bfa2-197acf72da7d',
	deleted: false,
	cancellationReason: null,
	payment: {
		paymentAmount: 5090,
		currency: CurrencyCode.EUR,
		paymentForm: PaymentForm.DIGITAL,
		paymentState: PaymentState.TO_PAY,
		overwrittenPaymentAmount: null,
		paymentInformationAvailable: true,
	},
	toddlerSeats: 3,
	bulkyLuggage: true,
};
