import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import {
	BookingStatus,
	ECancellationReasonDispatcherBooking,
	IBooking,
	PaymentState,
} from '@mopla/data-models';

import { Message } from '../../../';

interface IProps {
	bookedItinerary: IBooking;
}

export const FailedStatusBlock: FC<IProps> = (props) => {
	const { bookedItinerary } = props;
	const { t } = useTranslation(['tickets']);

	const isPaymentFailed =
		bookedItinerary.payment.paymentState === PaymentState.FAILED;
	const isCancelledByDistributor =
		bookedItinerary.bookingStatus === BookingStatus.CANCELLED_BY_DISTRIBUTOR;
	const isCancelledByDriver =
		bookedItinerary.bookingStatus === BookingStatus.CANCELLED_NO_SHOW;
	const isCancelledByPassenger =
		bookedItinerary.bookingStatus === BookingStatus.CANCELLED_BY_PASSENGER;

	const cancellationReasonText = getCancellationReasonText(
		t,
		bookedItinerary.cancellationReason
	);

	if (
		!isPaymentFailed &&
		!isCancelledByDistributor &&
		!isCancelledByDriver &&
		!isCancelledByPassenger
	)
		return null;

	if (isCancelledByPassenger) {
		return (
			<Message variant="error" title={t('title.cancelled_by_passenger')}>
				{t('text.cancelled_by_passenger')}
			</Message>
		);
	}

	if (isPaymentFailed) {
		return (
			<Message variant="error" title={t('title.payment_failed')}>
				{t('text.payment_failed')}
			</Message>
		);
	}

	if (isCancelledByDistributor) {
		return (
			<Message variant="error" title={t('title.cancelled_by_distributor')}>
				{t('text.cancelled_by_distributor', {
					reason: cancellationReasonText,
					context: cancellationReasonText && 'reason',
				})}
			</Message>
		);
	}

	return (
		<Message variant="error" title={t('title.cancelled_by_driver')}>
			{t('text.cancelled_by_driver')}
		</Message>
	);
};

function getCancellationReasonText(
	t: TFunction,
	cancellationReason?: ECancellationReasonDispatcherBooking | null
) {
	if (!cancellationReason) {
		return null;
	}

	return t(`cancellation_reasons.${cancellationReason}`);
}
