import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { FailedAnim } from '@mopla/ui';

import { Root, SubmitButton } from './AccountRemoved.styles';

export const AccountRemoved: FC = () => {
	const { t } = useTranslation('personalDetails');
	const navigate = useNavigate();

	const handleClose = () => navigate('/welcome', { replace: true });

	return (
		<Root>
			<Typography variant="title" color="neutral.0">
				{t('header.account_removed')}
			</Typography>
			<FailedAnim />
			<Typography variant="body1" color="neutral.0">
				{t('text.account_removed_description')}
			</Typography>
			<SubmitButton onClick={handleClose}>{t('button.close')}</SubmitButton>
		</Root>
	);
};
