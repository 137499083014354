import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'grid',
	width: 'fit-content',
	gridTemplateColumns: '48px 56px 48px 1fr',
	gridTemplateAreas: `
		"title  title  title  title"
		"dec    count  inc    ."
		"descr  descr  descr  ."
		`,
}));

export const TitleWrapper = styled('div')(() => ({
	gridArea: 'title',
	marginBottom: 8,
	maxWidth: 270,
}));

export const CounterText = styled(Typography)(() => ({
	gridArea: 'count',
	placeSelf: 'center',
}));

export const CounterDescription = styled('div')(() => ({
	gridArea: 'descr',
	marginTop: 4,
	textAlign: 'center',
	'& span': {
		display: 'block',
	},
}));

export const SvgWrapper = styled('div')(() => ({
	display: 'flex',
	width: 24,
	height: 24,
	alignItems: 'center',
	justifyContent: 'center',
}));

export const IconButtonStyled = styled(IconButton)(() => ({
	placeSelf: 'center',
}));
