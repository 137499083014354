import { useEffect } from 'react';
import H from '@here/maps-api-for-javascript';
import { Theme, useTheme } from '@mui/material';
import * as Sentry from '@sentry/capacitor';

import { ILatLng } from '@mopla/data-models';

const platform = new H.service.Platform({
	apikey: 'RJBeP8M9QCqTkJdm0SE-G4kKCoWrWx8am_idQ49z8AY',
});
const router = platform.getRoutingService({}, 8);

const departureIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" id='departureIcon' xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="12" fill="#6FDB89"/>
<path d="M19.1646 9.06728C19.1646 8.49238 19.0853 7.93731 18.9565 7.40206C18.332 4.81502 16.2901 2.79296 13.7031 2.18833C13.1877 2.06938 12.6425 2 12.0874 2C11.5324 2 10.9773 2.06938 10.452 2.19824C7.87483 2.81279 5.84287 4.83484 5.21841 7.40206C5.08955 7.93731 5.01026 8.49238 5.01026 9.06728C5.01026 9.13666 5.01026 9.19613 5.01026 9.26552C4.95078 10.108 4.95078 14.6874 10.9872 21.5168C11.5621 22.1611 12.583 22.1611 13.1579 21.5168C19.1944 14.6874 19.1944 10.108 19.1349 9.26552C19.1349 9.19613 19.1349 9.13666 19.1349 9.06728H19.1646ZM12.0874 4.54739C14.5853 4.54739 16.6073 6.56945 16.6073 9.06728C16.6073 11.5651 14.5853 13.5872 12.0874 13.5872C9.58961 13.5872 7.56756 11.5651 7.56756 9.06728C7.56756 6.56945 9.58961 4.54739 12.0874 4.54739Z" fill="#1C308B"/>
</svg>
`;
const arrivalIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" id='arrivalIcon' xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="12" fill="#6FDB89"/>
<circle cx="12" cy="12" r="6.5" stroke="#1C308B" stroke-width="3"/>
</svg>
`;

function addPolylineToMap(map: H.Map, legs: ILatLng[], theme: Theme) {
	const lineString = new H.geo.LineString();
	const group = new H.map.Group();
	const markers: H.map.DomMarker[] = [];

	legs.forEach((l, i, arr) => {
		lineString.pushPoint({ lat: l.lat, lng: l.lng });
		const marker = new H.map.DomMarker(
			{
				lat: l.lat,
				lng: l.lng,
			},
			{
				data: 'test',
				icon: new H.map.DomIcon(
					i !== arr.length - 1 ? departureIcon : arrivalIcon
				),
			}
		);

		markers.push(marker);
	});

	group.addObjects(markers);

	map.getViewModel().setLookAtData({
		bounds: group.getBoundingBox(),
	});
	map.addObjects(markers);

	map.addObject(
		new H.map.Polyline(lineString, {
			data: 'test',
			style: { lineWidth: 4, strokeColor: theme.palette.primary.main },
		})
	);
}

const addRouteToMap = (map: H.Map, result: any, theme: Theme) => {
	const group = new H.map.Group();
	const markers: H.map.DomMarker[] = [];

	if (result.routes.length) {
		result.routes[0].sections.forEach((section: any, i: number, arr: any[]) => {
			const isLastItem = i === arr.length - 1;
			const linestring = H.geo.LineString.fromFlexiblePolyline(
				section.polyline
			);
			const routeLine = new H.map.Polyline(linestring, {
				data: 'test',
				style: { strokeColor: theme.palette.primary.main, lineWidth: 4 },
			});

			if (!isLastItem) {
				const marker = new H.map.DomMarker(section.departure.place.location, {
					data: 'test',
					icon: new H.map.DomIcon(departureIcon),
				});

				markers.push(marker);
			} else {
				const startMarker = new H.map.DomMarker(
					section.departure.place.location,
					{
						data: 'test',
						icon: new H.map.DomIcon(departureIcon),
					}
				);
				const endMarker = new H.map.DomMarker(section.arrival.place.location, {
					data: 'test',
					icon: new H.map.DomIcon(arrivalIcon),
				});

				markers.push(startMarker);
				markers.push(endMarker);
			}

			map.addObjects([routeLine]);
			// eslint-disable-next-line
			// @ts-ignore
			map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox() });
		});

		group.addObjects(markers);
		map.getViewModel().setLookAtData({
			bounds: group.getBoundingBox(),
		});
		map.addObjects(markers);
	}
};

function latLngToString(point: ILatLng): string {
	return `${point.lat},${point.lng}`;
}

export const useMap = (args: {
	waypoints: ILatLng[];
	ref: any;
	deps: any[];
	showRoute: boolean;
}) => {
	const { waypoints, ref, deps, showRoute } = args;
	const theme = useTheme();
	let map: H.Map;
	const fromPoint = waypoints[0];
	const toPoint = waypoints[waypoints.length - 1];

	const intermediateWaypoints = waypoints
		.slice(1, waypoints.length - 1)
		.map(latLngToString);

	const routingParameters = {
		routingMode: 'fast',
		transportMode: 'car',
		origin: latLngToString(fromPoint),
		via: new H.service.Url.MultiValueQueryParameter(intermediateWaypoints),
		destination: latLngToString(toPoint),
		return: 'polyline',
	};

	useEffect(() => {
		let resizeHandler: () => void;
		if (ref) {
			const defaultLayers = platform.createDefaultLayers();
			const latCenter = (+fromPoint.lat + +toPoint.lat) / 2;
			const longCenter = (+fromPoint.lng + +toPoint.lng) / 2;

			map = new H.Map(ref, defaultLayers.vector.normal.map, {
				center: { lat: latCenter, lng: longCenter },
				zoom: 5,
				pixelRatio: window.devicePixelRatio || 1,
				padding: { top: 30, left: 30, bottom: 30, right: 80 },
			});

			resizeHandler = () => map.getViewPort().resize();
			window.addEventListener('resize', resizeHandler);

			if (showRoute) {
				router.calculateRoute(
					routingParameters,
					(r) => addRouteToMap(map!, r, theme),
					function (error) {
						Sentry.captureException(error);
						console.error(error.message);
					}
				);
			} else {
				addPolylineToMap(map, waypoints, theme);
			}

			const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

			const ui = H.ui.UI.createDefault(map, {});
		}

		return () => {
			window.removeEventListener('resize', resizeHandler);
			if (map) map.dispose();
		};
	}, [ref, ...deps]);
};
