import { RouteDot, RouteDotWrapper } from './TripScheme.styles';

export const StationSplitter = () => (
	<>
		<div />
		<RouteDotWrapper>
			<RouteDot />
			<RouteDot />
			<RouteDot />
			<RouteDot />
		</RouteDotWrapper>
		<div />
	</>
);
