export interface ILatLng {
	lat: number;
	lng: number;
}

interface ILocationBase {
	title: string; //primaryKey in RxDB
	id: string;
	position: ILatLng;
}

/** This type is determined based on the here api response, autosuggest items categories field */
export type TLocationSuggestType = 'stop' | 'address' | 'railway';

export interface ILocationSuggest extends ILocationBase {
	type: TLocationSuggestType;
}

/** Entities of this interface are stored in RxDB */
export interface ILocationSuggestStored extends ILocationSuggest {
	timestamp?: number;
	/** isFavorite */
	saved?: boolean;
}

export interface ILocationDecoded extends ILocationBase {
	address: {
		street?: string;
		houseNumber?: string;
		postCode: string;
		city: string;
		country: string;
		stateCode: string;
	};
	type: TLocationSuggestType;
}
