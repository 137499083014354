import { useState } from 'react';
import * as Sentry from '@sentry/capacitor';
import { pluckFirst, useObservable } from 'observable-hooks';
import { catchError, defer, from, Observable, of, timer } from 'rxjs';
import {
	distinctUntilChanged,
	retry,
	scan,
	shareReplay,
	startWith,
	switchMap,
} from 'rxjs/operators';

import { BusinessLayer, useBusinessLayer } from '@mopla/business-logic';
import { IPassengerMapView } from '@mopla/data-models';

export type TBookingMapData = IPassengerMapView | null;
export type TBookingMapData$ = Observable<TBookingMapData>;

export const getBookingMapData$ = (
	bl: BusinessLayer,
	legId: string
): TBookingMapData$ => {
	return defer(() =>
		from(
			bl.api.get(
				`/api/passengers/bookings/${legId}/map`
			) as Promise<TBookingMapData>
		)
	).pipe(
		catchError((e) => {
			const statusCode = e?.response?.status;

			/**
			 * This is a special case.
			 * When 422, it means that the BE restricts access to the driver's geo.
			 * The geo should be available from 20 minutes before the ride and till the pick up or check in
			 * */
			if (statusCode === 422) {
				return of(null);
			}

			throw e;
		})
	);
};

/**
 * Live-map api polling observable.
 *
 * Initially it polls the api with slow 10s interval, waits for a api to respond with some non-422 data.
 * 422 is returned when the server restricts the data because of the validation rules
 *
 *  Eventually, when the api responds with a data, start more frequent polling (3s).
 *
 * If then api responds with 422 later (prev response with data, current with 422), stop polling. This means the server no longer allows to check the driver's geo.
 * */
export const useBookingMapApiPolling$ = (legId: string): TBookingMapData$ => {
	const bl = useBusinessLayer();
	const [intervalDuration, setIntervalDuration] = useState(10000);

	return useObservable(
		(inputs$) => {
			return pluckFirst(inputs$).pipe(
				switchMap((_intervalDuration) => {
					return timer(0, _intervalDuration).pipe(
						switchMap(() => getBookingMapData$(bl, legId)),
						retry({
							count: 3,
							resetOnSuccess: true,
							delay: (error) => {
								/**
								 * Any unexpected error (except 422) comes here.
								 * Retry 3 times with 5s delay and complete if still fails
								 * */
								Sentry.captureException(error);
								return timer(500);
							},
						})
					);
				}),
				scan((acc, value) => {
					if (acc && !value) {
						/**
						 * The data no longer available, but was available in previous request. Stop polling
						 */
						throw new Error();
					}

					/** A data becomes available. Start more frequent polling. */
					if (value) {
						setTimeout(() => setIntervalDuration(3000), 3000);
						return value;
					}

					return acc;
				}, null as TBookingMapData),
				distinctUntilChanged(compareApiResponses),
				startWith(null as TBookingMapData),
				catchError(() => of(null)),
				shareReplay({ refCount: true, bufferSize: 1 })
			);
		},
		[intervalDuration]
	);
};

function compareApiResponses(prev: TBookingMapData, next: TBookingMapData) {
	return prev?.lastLocationUpdate === next?.lastLocationUpdate;
}
