import { useMemo } from 'react';
import { useSubscription } from 'observable-hooks';
import { RxCollection } from 'rxdb';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { ISubscriptionTicket, TSubscriptionTickets } from '@mopla/data-models';

import { SubscriptionActionTypes } from '../actions/subscriptionActions';
import { useBusinessLayer } from '../business-logic';

import { TSubscriptionTickets$ } from './dataContext';

export interface ISubscriptionTicketsDBValue {
	subscriptionTicketsData: TSubscriptionTickets;
	isLoading: boolean;
}

export const useSubscriptionTickets$ = () => {
	const { db, watchActions$ } = useBusinessLayer();

	const subscriptionTicket$ = useMemo(
		() =>
			new BehaviorSubject<ISubscriptionTicketsDBValue>({
				subscriptionTicketsData: [],
				isLoading: false,
			}),
		[]
	);

	const result$ = useMemo<TSubscriptionTickets$>(() => {
		const isLoading$ = watchActions$([
			SubscriptionActionTypes.FetchTickets,
			SubscriptionActionTypes.FetchTicketsResult,
		]).pipe(
			map(({ type }) => type === SubscriptionActionTypes.FetchTickets),
			startWith(false),
			distinctUntilChanged()
		);

		return combineLatest([
			(db['tickets'] as RxCollection<ISubscriptionTicket>).find().$,
			isLoading$,
		]).pipe(
			map(([docs, isLoading]) => ({
				subscriptionTicketsData: docs.map((doc) => doc.toJSON()),
				isLoading,
			}))
		);
	}, [db, watchActions$]);

	useSubscription(result$, (v) => subscriptionTicket$.next(v));

	return subscriptionTicket$;
};
