import { EntityDescriptor } from '../business-logic';
import { locationSchema } from '../schemas/location';

export const locationEntity: EntityDescriptor = {
	location: {
		migrationStrategies: {
			1: function (oldDoc) {
				oldDoc.id = oldDoc.id ?? '';
				return oldDoc;
			},
			2: function (oldDoc) {
				/**
				 * The 1st strategy caused the bug, when no id was in the location docs
				 * Therefore this one filters those docs without the id field
				 *  */
				return oldDoc.id ? oldDoc : null;
			},
		},
		schema: locationSchema,
	},
};
