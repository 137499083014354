import { styled } from '@mui/material/styles';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'driverApp',
})<{ driverApp?: boolean }>(({ theme, driverApp }) => ({
	backgroundColor: driverApp
		? theme.palette.primary.light
		: theme.palette.neutral[0],
	borderRadius: '9px 9px 0 0',
	marginTop: 'var(--mopla-safe-area-top)',
	height: 'calc(100% - var(--mopla-safe-area-top))',
	display: 'grid',
	gridTemplateRows: 'max-content 1fr',
}));

export const MainContent = styled('main')(() => ({
	padding: '24px',
}));

export const ScrollWrapper = styled('div')(() => ({
	display: 'grid',
	width: '100%',
	overflow: 'auto',
}));
