import { styled } from '@mui/material/styles';

export const Label = styled('span')(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.neutral[100],

	a: {
		color: theme.palette.neutral[100],
	},
}));
