import { FC, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { useUser } from '@mopla/business-logic';
import { IBooking } from '@mopla/data-models';
import { getBookingAttendeesSummary } from '@mopla/utils';

import { FlexColumn } from '../../';

export interface IBookedTicketPassengersSummaryProps {
	booking: Partial<IBooking>;
	render?(p: {
		title: string;
		mainPassengerName: string;
		attendees?: string;
		luggage?: string;
	}): ReactElement;
}

const defaultRenderer: IBookedTicketPassengersSummaryProps['render'] = ({
	title,
	attendees,
	luggage,
}) => (
	<>
		<Typography variant="body1" fontWeight={600}>
			{title}
		</Typography>
		{attendees && <Typography variant="body2">{attendees}</Typography>}
		{luggage && <Typography variant="body2">{luggage}</Typography>}
	</>
);

export const BookedTicketPassengersSummary: FC<
	IBookedTicketPassengersSummaryProps
> = ({ booking, render = defaultRenderer }) => {
	const { userData } = useUser();
	const { t } = useTranslation();

	const bookingAttendeesSummary = useMemo(
		() => getBookingAttendeesSummary(booking, userData, t),
		[booking, userData, t]
	);

	if (!bookingAttendeesSummary) {
		return null;
	}

	const { title, extraPassengers, luggage, extraChildren, mainPassengerName } =
		bookingAttendeesSummary;

	return (
		<FlexColumn>
			{render({
				title,
				mainPassengerName,
				attendees:
					extraPassengers || extraChildren
						? `+ ${[extraPassengers, extraChildren].filter(Boolean).join(', ')}`
						: undefined,
				luggage: luggage ? `+ ${luggage}` : undefined,
			})}
		</FlexColumn>
	);
};
