import React, { ReactElement } from 'react';
import { Capacitor } from '@capacitor/core';

import { Block, Root } from './DriverCommon.styles';

export interface DriverCommonProps {
	webHeaderComponent: ReactElement;
	contentComponent: ReactElement;
	footerComponent?: ReactElement;
	sideNavigationComponent?: ReactElement;
}

export const DriverCommonLayout: React.FC<DriverCommonProps> = ({
	webHeaderComponent,
	contentComponent,
	footerComponent,
	sideNavigationComponent,
}) => {
	return (
		<Root isNativePlatform={Capacitor.isNativePlatform()}>
			{webHeaderComponent}
			<Block>{contentComponent}</Block>
			{footerComponent && <Block>{footerComponent}</Block>}
			{sideNavigationComponent}
		</Root>
	);
};

export default DriverCommonLayout;
