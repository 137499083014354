import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MuiButtonStyled = styled(MuiButton)(({ startIcon, endIcon }) => ({
	justifyContent: startIcon || endIcon ? 'space-between' : 'center',

	'&.loading .MuiButton-endIcon, &.loading .MuiButton-startIcon': {
		opacity: 0,
	},
}));

export const Content = styled('div', {
	shouldForwardProp: (propName) => propName !== 'hide',
})<{ hide?: boolean }>(({ hide }) => ({
	opacity: hide ? 0 : 1,
}));

export const LoaderWrapper = styled('div')(() => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
