import { RxDocument, RxError } from 'rxdb';

/**
 * RxDocument returns rejected promise if the doc has already been removed before
 * This behavior maybe undesired when in a race condition circumstance a document gets deleted twice.
 * Instead, if doc has already been removed, just return false, not throw
 * */
export const safeRemoveDocument = async (doc: RxDocument<any>) => {
	try {
		await doc.remove();
		return true;
	} catch (e) {
		if ((e as RxError).code === 'DOC13') {
			return false;
		} else {
			throw e;
		}
	}
};
