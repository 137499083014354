import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';

import mopla_error_animation from '../../lotties/error.json';
import { InfoModal } from '../InfoModal/InfoModal';

export interface ErrorModalProps {
	title?: string;
	submitTitle?: string;
	infoText?: string | React.ReactNode;
	onSubmit: VoidFunction;
	onCancel?: VoidFunction;
	cancelTitle?: string;
	additionalInfo?: React.ReactNode | string;
	icon?: React.ReactNode;
	submitIcon?: React.ReactElement;
	isLoading?: boolean;
	testId?: string;
	textAlign?: 'left' | 'right' | 'center';
}

export const ErrorModal: React.FC<ErrorModalProps> = (props) => {
	const { t } = useTranslation(['errors']);
	return (
		<InfoModal
			icon={<Lottie animationData={mopla_error_animation} loop />}
			title={t('header.internal')}
			submitTitle={t('button.close')}
			infoText={t('text.internal_description')}
			{...props}
		/>
	);
};
