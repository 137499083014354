import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { FlexColumn } from '@mopla/ui';

import { MarketingItem } from './DTicketMarketingBlock.styles';

interface IProps {
	header: string;
	adText: string;
	image: React.ReactNode;
}
export const DTicketMarketingItem: React.FC<IProps> = (props) => {
	const { header, adText, image } = props;
	const { t } = useTranslation(['dticket']);

	return (
		<MarketingItem>
			{image}
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Typography variant="bodyTitle">{t(header)}</Typography>
				<Typography variant="body2">{t(adText)}</Typography>
			</FlexColumn>
		</MarketingItem>
	);
};
