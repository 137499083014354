import {
	FC,
	MouseEventHandler,
	ReactElement,
	RefObject,
	useState,
} from 'react';
import { Paper, Portal, Typography } from '@mui/material';
import { noop } from 'rxjs';

import { TransportType } from '@mopla/data-models';
import { useTranslation } from '@mopla/i18';

import {
	ArrowRightIcon,
	BottomSlider,
	FlexColumn,
	FlexContainer,
	FullscreenOverlay,
	IconMessage,
	InformationIcon,
	Responsive,
	TransportCircle,
} from '../../';

import { FullMapStyled, MiniMapStyled } from './styled';
import { useInfoboxHeightCss } from './useInfoboxHeightCss';

export interface ILiveMapViewProps {
	miniMapRef: RefObject<HTMLDivElement>;
	fullMapRef: RefObject<HTMLDivElement>;
	isFullViewOpened: boolean;
	onMiniMapClick: MouseEventHandler;
	onFullMapClose: VoidFunction;
	minutesBeforeStart?: number | null;
	startPointLabel: string;
	transportType: TransportType;
	licencePlate: string;
	stopsLeft: number;
	isDelayed: boolean;
	render?(mapNode: ReactElement): ReactElement;
}

const defaultRender: ILiveMapViewProps['render'] = (mapNode) => mapNode;

export const LiveMapView: FC<ILiveMapViewProps> = ({
	miniMapRef,
	fullMapRef,
	isFullViewOpened,
	onMiniMapClick,
	onFullMapClose,
	minutesBeforeStart,
	startPointLabel,
	transportType,
	licencePlate,
	stopsLeft,
	isDelayed,
	render = defaultRender,
}) => {
	const { t } = useTranslation('booking');
	const [rootNode] = useState(document.getElementById('portal'));
	const { setNode, heightCss } = useInfoboxHeightCss();

	const fullMapElement = (
		<FullMapStyled>
			<div style={heightCss} className="map" ref={fullMapRef} />
			<Paper className="infobox_top" elevation={1}>
				<Typography variant="body2">
					{t('live_map.stops_left', {
						amount: stopsLeft,
						context: stopsLeft <= 1 && 'next',
					})}
				</Typography>
			</Paper>
			<Paper ref={setNode} className="infobox_bottom" elevation={1}>
				<FlexColumn>
					{Number.isInteger(minutesBeforeStart) && (
						<>
							<Typography variant="body2" fontWeight={600}>
								{t('live_map.before_start_title')}
							</Typography>
							<Typography
								className="departure_time"
								variant="bodyTitle"
								color={isDelayed ? 'neutral.100' : 'secondary.dark'}
							>
								{t('live_map.minutes_before_start', {
									amount: minutesBeforeStart,
									context: !minutesBeforeStart && 'now',
								})}
							</Typography>
						</>
					)}
					<Typography variant="body2">{startPointLabel}</Typography>
					{isDelayed && (
						<IconMessage
							className="delay_msg"
							color="warning.main"
							variant="body2"
							icon={<InformationIcon />}
							message={t('text.driver_delay')}
						/>
					)}
				</FlexColumn>
				{
					<FlexContainer className="vehicle_details" gap={16}>
						<TransportCircle transportType={transportType} />
						<FlexColumn gap={8}>
							<Typography variant="body2" fontWeight={600}>
								{t(`common:travelTypeName.${transportType}`)}
							</Typography>
							<Typography variant="bodyTitle">{licencePlate}</Typography>
						</FlexColumn>
					</FlexContainer>
				}
			</Paper>
		</FullMapStyled>
	);

	return render(
		<>
			<MiniMapStyled>
				<div className="overlay" onClick={onMiniMapClick} />
				<div className="arrow">
					<ArrowRightIcon />
				</div>
				<div className="map" ref={miniMapRef} />
			</MiniMapStyled>

			<Portal container={rootNode}>
				<Responsive mobile>
					<BottomSlider
						open={isFullViewOpened}
						title={t('live_map.title')}
						onClose={onFullMapClose}
					>
						{fullMapElement}
					</BottomSlider>
				</Responsive>
				<Responsive aboveMobile>
					<FullscreenOverlay
						open={isFullViewOpened}
						onOpen={noop}
						onBack={onFullMapClose}
						onClose={onFullMapClose}
						title={t('live_map.title')}
						anchor="right"
					>
						{fullMapElement}
					</FullscreenOverlay>
				</Responsive>
			</Portal>
		</>
	);
};
