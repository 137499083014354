import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { noop } from 'rxjs';

import { PassengerBooking } from '@mopla/business-logic';
import { MplTrans } from '@mopla/i18';

import { IconMessage, InfoBaseIcon, InfoModal, MplButton } from '../../..';

export interface ConfirmationModalProps {
	onClose: () => void;
	onConfirm: () => void;
	isCanceling: boolean;
	ticket: Partial<PassengerBooking>;
	cancellationPrice: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
	const { onClose, onConfirm, isCanceling, cancellationPrice } = props;
	const { t } = useTranslation('tickets');

	return (
		<InfoModal
			title={t('cancellation_flow.confirmation.title')}
			infoText={
				<>
					<Typography variant="body1" marginBottom={3}>
						{t('cancellation_flow.confirmation.body', {
							cost: cancellationPrice,
						})}
					</Typography>
					<MplTrans i18nKey="tickets:text.note_message" />
				</>
			}
			isLoading={isCanceling}
			additionalInfo={
				<IconMessage icon={<InfoBaseIcon />} message={t('text.info_message')} />
			}
			buttonsBlock={
				<>
					<MplButton
						onClick={onConfirm}
						data-testid="savedTicket-removingConfirmation-cancelBooking-button"
					>
						{t('common:button.continue')}
					</MplButton>
					<MplButton
						variant="outlined"
						onClick={onClose}
						data-testid="savedTicket-removingConfirmation-abort-button"
					>
						{t('button.abort')}
					</MplButton>
				</>
			}
			submitTitle=""
			onSubmit={noop}
		/>
	);
};
