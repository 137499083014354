export const environment = {
	production: true,
	baseUrl: 'https://backend.pullrequest.mopla.solutions',
	sentryDns:
		'https://7d2fefe49cf1488aa225a1c15d716cbd@o1355916.ingest.sentry.io/6643623',
	env: 'pullrequest',
	paymentBaseUrl: 'https://payment.pullrequest.mopla.solutions',
	stripePublicKey:
		'pk_test_51LQ8ugBedPgr0Zv2yhkeDxV6zt8JEySWTSOUB5P7xDwxMeEogDel0crKWVFYOAl9v1Lzbuh3A0use0LRLsa49HqQ009idWlDZA',
	stripeCustomCloseCallback:
		'app.pullrequest.mopla.solutions:/?isPaymentRedirect',
	stripeWebCallback: 'https://app.pullrequest.mopla.solutions',
	stripeNativeCallback: 'https://payment.pullrequest.mopla.solutions',
	firebaseConfig: {
		apiKey: 'AIzaSyBwes4dXu5n5WBi71hErW9LD9BSDmthzdQ',
		authDomain: 'mopla-staging.firebaseapp.com',
		projectId: 'mopla-staging',
		storageBucket: 'mopla-staging.appspot.com',
		messagingSenderId: '704166027041',
		appId: '1:704166027041:web:020d999e262f3749d56860',
	},
	spenditValidationSetSource:
		'https://staging.mybenefits.spendit.de/.well-known/openid-configuration',
	showLiveUpdatesVersion: true,
};
