import { Drawer, FormControlLabel, MobileStepper, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DrawerRoot = styled(Drawer, {
	shouldForwardProp: (prop) => prop !== 'isConfirmationStep',
})<{ isConfirmationStep?: boolean }>(({ theme, isConfirmationStep }) => ({
	zIndex: 1000,
	'.MuiBackdrop-root': {
		backgroundColor: 'transparent',
	},
	'.MuiDrawer-paper': {
		backgroundColor: !isConfirmationStep
			? theme.palette.neutral[40]
			: theme.palette.primary.main,
		borderRadius: '8px 8px 0 0',
		height: 'calc(100% - var(--mopla-safe-area-top) - 15px)',
		display: 'grid',
		gridTemplateRows: 'max-content 1fr',
		overflowY: 'auto',
	},
}));
export const DrawerContentWrapper = styled('div')(() => ({
	height: '100%',
	overflow: 'auto',
}));

export const LoaderWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
}));
export const ContentWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridGap: 16,
	margin: '8px',
	padding: '24px 16px',
	background: theme.palette.neutral[0],
	borderRadius: 8,
	overflow: 'auto',
}));
export const ThirdStepWrapper = styled('div')(() => ({
	'& > div': {
		margin: 0,
		padding: 0,
	},
}));
export const Wrapper = styled('div')(() => ({
	display: 'grid',
	gridGap: 16,
}));

export const CustomStepper = styled(MobileStepper)(({ theme }) => ({
	background: 'transparent',
	justifyContent: 'center',
	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.primary.main,
	},
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	marginLeft: 0,
	marginBottom: 10,
	'>span:last-of-type': {
		fontSize: 18,
	},
}));
export const StyledRadio = styled(Radio)(() => ({
	margin: '0 10px 0',
	padding: 0,
}));

export const ScrollableWrapper = styled('div')(() => ({
	overflow: 'auto',
	height: '100%',
}));

export const CleanVoucherCodeButton = styled('button')(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	color: theme.palette.primary.main,
	display: 'flex',
	gap: 8,
	alignItems: 'center',
	background: 'none',
	border: 'none',
	outline: 'none',
	cursor: 'pointer',
	padding: 0,
	marginTop: 16,
}));

export const ConfirmationWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gap: 24,
	gridAutoRows: 'max-content',
	padding: '0 24px 16px',
	[theme.breakpoints.up('tabletP')]: {
		padding: '16px 24px',
		background: theme.palette.primary.main,
	},
}));
