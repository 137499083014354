import { ScheduledBreak } from './ScheduledBreak';
import { ScheduledLeg } from './ScheduledLeg';
import { ScheduledVehicleCheck } from './ScheduledVehicleCheck';

export type TRef = 'LEG' | 'BREAK' | 'VEHICLE_CHECK';

export enum EDriverLegType {
	LBODTLBODTFLEX = 'LBODTLBODTFLEX',
	MANUAL = 'MANUAL',
	PRODT = 'PRODT',
	EMPTY = 'EMPTY',
}

export type TDriversScheduleLeg =
	| { obj: ScheduledLeg; ref: 'LEG'; driverLegType: EDriverLegType }
	| { obj: ScheduledBreak; ref: 'BREAK'; driverLegType: EDriverLegType.EMPTY }
	| {
			obj: ScheduledVehicleCheck;
			ref: 'VEHICLE_CHECK';
			driverLegType: EDriverLegType.EMPTY;
	  };

export type TDriversScheduleResponse = TDriversScheduleLeg[];
