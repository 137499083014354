import * as yup from 'yup';

const PersonalDetailsValidationSchema = yup.object().shape({
	firstName: yup
		.string()
		.trim()
		.required('firstName.required')
		.min(2, 'firstName.min')
		.max(50, 'firstName.max'),
	surname: yup
		.string()
		.trim()
		.required('surname.required')
		.min(2, 'surname.min')
		.max(50, 'surname.max'),
	phoneNumber: yup
		.string()
		.trim()
		.required('phoneNumber.required')
		.max(20, 'phoneNumber.max'),
	street: yup.string().required('street.required').max(100, 'street.max'),
	streetNumber: yup
		.string()
		.required('streetNumber.required')
		.max(6, 'streetNumber.max'),
	postCode: yup.string().trim().required('postCode.required'),
	location: yup.string().required('location.required').max(100, 'location.max'),
	day: yup.string().required('day.required'),
	month: yup.string().required('month.required'),
	year: yup.string().required('year.required'),
});

export default PersonalDetailsValidationSchema;
