import * as yup from 'yup';

export const SubscriptionFormValidationSchema = yup.object().shape({
	monthOfStart: yup.string().required('month.required'),
	firstName: yup.string().required('firstName.required'),
	surname: yup.string().required('surname.required'),
	phoneNumber: yup.string().required('phoneNumber.required'),
	street: yup.string().required('street.required'),
	streetNumber: yup.string().required('streetNumber.required'),
	postCode: yup.string().required('postCode.required'),
	location: yup.string().required('location.required'),
	day: yup.string().required('day.required'),
	month: yup.string().required('month.required'),
	year: yup.string().required('year.required'),
	companyName: yup.string().required('companyName.required'),
});

export const InvoiceRecipientFormValidationSchema = yup.object().shape({
	firstName: yup.string().required('firstName.required'),
	surname: yup.string().required('surname.required'),
	street: yup.string().required('street.required'),
	streetNumber: yup.string().required('streetNumber.required'),
	postCode: yup.string().required('postCode.required'),
	location: yup.string().required('location.required'),
});

export const VoucherValidationSchema = yup.object().shape({
	code: yup.string().required('value.required'),
});
