import { Itinerary } from './Itinerary';
import { EAlternativesDirection } from './ProdtSearchPatchParams';
import { ProdtSearchPostParams } from './ProdtSearchPostParams';

interface IPRODTSearchResult {
	reservedUntil: string;
	itinerary: Itinerary;
}

export interface IPRODTSearchProgress {
	maxTripDeviationMinutes: number;
	roundIndex: number;
	numberOfRounds: number;
}

type TPRODTSearchRequestedParams = ProdtSearchPostParams & {
	alternativesDirection?: EAlternativesDirection | null;
};

export type IPRODTSearch = {
	id: string;
	requestedParams: TPRODTSearchRequestedParams;
	status: EPRODTSearchProgressStatus;
} & (
	| {
			status:
				| EPRODTSearchProgressStatus.SUCCESS
				| EPRODTSearchProgressStatus.EXPIRED;
			result: IPRODTSearchResult;
			progress: null;
	  }
	| {
			status: EPRODTSearchProgressStatus.CANCELLED;
			result: IPRODTSearchResult | null;
			progress: null;
	  }
	| {
			status:
				| EPRODTSearchProgressStatus.IN_PROGRESS
				| EPRODTSearchProgressStatus.NOTHING_FOUND;
			result: null;
			progress: IPRODTSearchProgress;
	  }
);

export enum EPRODTSearchProgressStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	SUCCESS = 'SUCCESS',
	NOTHING_FOUND = 'NOTHING_FOUND',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
}
