import { FC } from 'react';
import { Typography } from '@mui/material';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { ChevronRightIcon } from '../../icons';

import { Root } from './ProfileGenericButton.styles';
import { IContentProps, IProfileGenericButtonProps } from './types';

const Content: FC<IContentProps> = ({ value, label }) => {
	const labelComponent = (
		<Typography
			data-testid="profileGenericButton-labelComponent"
			variant={value ? 'caption' : 'body1'}
			fontWeight={value ? 700 : 400}
			color="primary.dark"
			className="label"
			noWrap
		>
			{label}
		</Typography>
	);

	if (!value) {
		return labelComponent;
	}

	return (
		<FlexColumn
			testId="profileGenericButton-content"
			gap={4}
			className="flex-start"
		>
			{label}
			<Typography
				data-testid="profileGenericButton-contentTypography"
				variant="body1"
				color="neutral.80"
				className="helper"
			>
				{value}
			</Typography>
		</FlexColumn>
	);
};

export const ProfileGenericButton: FC<IProfileGenericButtonProps> = ({
	mainIcon,
	label,
	helperText,
	helperIcon = <ChevronRightIcon />,
	showChevron = true,
	variant,
	onClick,
	disabled = false,
	testId = 'profileGenericButton',
}) => {
	return (
		<Root
			type="button"
			onClick={onClick}
			variant={variant}
			disabled={disabled}
			spacing={helperText ? 'S' : 'M'}
			data-testid={testId}
		>
			{mainIcon && <div className="main-icon">{mainIcon}</div>}
			<Content label={label} value={helperText} />
			{showChevron && <div className="helper-icon">{helperIcon}</div>}
		</Root>
	);
};
