import React from 'react';

export const PermissionsAttentionIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="133"
			height="108"
			fill="none"
		>
			<path
				fill="#FFE66D"
				fillRule="evenodd"
				d="M63.98 30a5 5 0 015-5h4a5 5 0 015 5v42a5 5 0 01-5 5h-4a5 5 0 01-5-5V30zm5-2h4a2 2 0 012 2v42a2 2 0 01-2 2h-4a2 2 0 01-2-2V30a2 2 0 012-2zm2 69a7 7 0 100-14 7 7 0 000 14zm0-3a4 4 0 10-.001-8 4 4 0 000 8z"
				clipRule="evenodd"
			></path>
			<path
				fill="#FFE66D"
				fillRule="evenodd"
				d="M30.66 69.7l.835-1.447-.006-.003.743-1.29c-1.677.918-3.662-.079-3.47-1.971L63.685 4.5c3.464-6 12.124-6 15.588 0l51.529 89.25c3.464 6-.866 13.5-7.794 13.5H19.951c-6.929 0-11.259-7.5-7.795-13.5l9.996-17.314a1.16 1.16 0 01-.593-2.165l2.225-1.284-2.225-1.285a1.16 1.16 0 011.16-2.01l2.225 1.285V68.16a1.16 1.16 0 012.234-.44l1.586-2.756-.002.025-1.581 2.738c.054.133.084.28.084.433v2.817l2.225-1.284a1.16 1.16 0 011.17.006zM75.81 6.5l51.528 89.25c1.924 3.333-.481 7.5-4.33 7.5H19.951c-3.85 0-6.255-4.167-4.33-7.5l7.818-13.541-.001.008.679-1.177.01.006 1.312-2.282a1.16 1.16 0 001.826-.95V75.59l.257-.446 1.968 1.136a1.16 1.16 0 001.16-2.01l-1.969-1.136.256-.444 1.713-.989a1.16 1.16 0 00.014-2.001l.834-1.446-.003-.001L67.15 6.5c1.924-3.333 6.736-3.333 8.66 0zM28.931 72.694l-.507.293.253.146.254-.439zm-1.414 2.449l-.253-.147v.586l.253-.44zm-5.362 1.293l-2.183 3.78c1.677-.906 3.667.102 3.466 1.993l1.992-3.451a1.159 1.159 0 01-.487-.944v-2.818l-2.224 1.285a1.155 1.155 0 01-.564.155z"
				clipRule="evenodd"
			></path>
			<path
				fill="#FFE66D"
				d="M19.105 62.974a1.16 1.16 0 01-1.16-1.16v-2.818l-2.226 1.285a1.16 1.16 0 11-1.16-2.01l2.225-1.284-2.225-1.285a1.16 1.16 0 011.16-2.01l2.225 1.285V52.16a1.16 1.16 0 112.32 0v2.817l2.226-1.284a1.16 1.16 0 111.16 2.01l-2.225 1.284 2.225 1.284a1.16 1.16 0 11-1.16 2.01l-2.225-1.285v2.817c0 .641-.52 1.16-1.16 1.16zM4.944 81.814a1.16 1.16 0 002.32 0v-2.818l2.226 1.285a1.16 1.16 0 001.16-2.01l-2.225-1.284 2.225-1.285a1.16 1.16 0 00-1.16-2.01l-2.225 1.285V72.16a1.16 1.16 0 10-2.32 0v2.817l-2.226-1.284a1.16 1.16 0 00-1.16 2.01l2.225 1.284L1.56 78.27a1.16 1.16 0 101.16 2.01l2.225-1.285v2.818z"
			></path>
		</svg>
	);
};
