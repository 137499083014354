import { styled } from '@mui/material/styles';

import { DropdownBase } from '../../';

export const DropdownBaseStyled = styled(DropdownBase)(({ theme }) => ({
	'& .icon': {
		color: theme.palette.primary.main,
	},
}));

export const ContentWrapper = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	gap: '0 16px',
	marginLeft: 32,
}));
