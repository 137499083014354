import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IBooking, Itinerary, TransportType } from '@mopla/data-models';
import {
	checkTimeMightChange,
	getItineraryTransportTypes,
	isMixedItinerary,
} from '@mopla/utils';

import {
	BookingStatusBadge,
	CrossCircleIcon,
	IconMessage,
	IItineraryDateTimeInfoProps,
	InformationIcon,
	ItineraryDateTimeInfo,
	TicketDetailsHeader,
} from '../../../';

export interface IRouteDetailsHeaderProps {
	route: Itinerary;
	bookedItinerary?: IBooking;
	isCancelled?: boolean;
	messages?: ReactNode;
}

const TIME_COLORS: IItineraryDateTimeInfoProps['timeDisplayOverride'] = {
	cancelled: { variant: 'error', icon: <CrossCircleIcon /> },
};

export const RouteDetailsHeader: FC<IRouteDetailsHeaderProps> = ({
	route,
	bookedItinerary,
	isCancelled,
	messages,
}) => {
	const { t } = useTranslation([
		'searchResults',
		'routeDetails',
		'tickets',
		'booking',
	]);

	const transportTypes = getItineraryTransportTypes(route, [
		TransportType.TRANSFER_WALK,
	]);
	const timeMightChange = checkTimeMightChange(route);
	const isMixedRoute = isMixedItinerary(route);

	const getMainTitle = () => {
		if (!timeMightChange || isCancelled) {
			return t('common:text.travel_time');
		}

		return t('common:text.estimated_timewindow');
	};

	return (
		<TicketDetailsHeader
			title={getMainTitle()}
			isCancelled={isCancelled}
			transportTypes={transportTypes}
			itineraryDateTime={
				<ItineraryDateTimeInfo
					route={route}
					isCancelled={isCancelled}
					timeTestId="route-duration-timeInterval-block"
					overwrittenTimeTestId="route-duration-initialTimeInterval-block"
					timeDisplayOverride={TIME_COLORS}
				/>
			}
			messages={
				!isCancelled && (
					<>
						{timeMightChange && (
							<IconMessage
								icon={<InformationIcon />}
								message={t('common:text.attentionTimeslot')}
								color="neutral.80"
								variant="body2"
							/>
						)}
						{isMixedRoute && (
							<IconMessage
								icon={<InformationIcon />}
								message={t('common:text.attentionMixedRoutes')}
								color="neutral.80"
								variant="body2"
							/>
						)}
						{messages}
					</>
				)
			}
			badge={
				bookedItinerary && <BookingStatusBadge booking={bookedItinerary} />
			}
		/>
	);
};
