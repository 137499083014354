import { useCallback, useMemo } from 'react';
import { useTranslation as useTranslationLib } from 'react-i18next';

import { useLbodtflexContext } from './lbodtflexLabelContext';

const LBODTFLEX_DEFAULT_LABEL = 'AnrufbusFlex';

export const useTranslation: typeof useTranslationLib = (ns, options) => {
	const result = useTranslationLib(ns, options);
	const lbodtflexLabel = useLbodtflexContext();
	const { t } = result;

	const tFunction = useCallback(
		(...args: Parameters<typeof t>) => {
			const [arg1, arg2, arg3] = args;

			const options =
				typeof arg2 === 'object'
					? arg2
					: typeof arg3 === 'object'
					? arg3
					: ({} as any);

			options.lbodtflexLabel =
				options.lbodtflexLabel || lbodtflexLabel || LBODTFLEX_DEFAULT_LABEL;

			return t(arg1, arg2, options);
		},
		[lbodtflexLabel, t]
	);

	return useMemo(() => ({ ...result, t: tFunction }), [result, tFunction]);
};
