import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TMessageVariant = 'default' | 'error';

export interface IMessageProps {
	className?: string;
	variant: TMessageVariant;
	title: string;
}

const Root = styled('div')<{ ownerState: { variant: TMessageVariant } }>(
	({ ownerState: { variant }, theme }) => ({
		padding: 24,
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		border: '4px solid',
		borderRadius: 8,

		...(variant === 'error' && {
			borderColor: theme.palette.error.main,
			'& .title': {
				color: theme.palette.error.main,
			},
		}),
	})
);

export const Message: FC<PropsWithChildren<IMessageProps>> = ({
	className,
	variant,
	title,
	children,
}) => {
	return (
		<Root className={className} ownerState={{ variant }}>
			<Typography className="title" variant="bodyTitle">
				{title}
			</Typography>
			<Typography variant="body2">{children}</Typography>
		</Root>
	);
};
