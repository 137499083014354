import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content 1fr max-content',
	height: 'calc(100% - var(--mopla-safe-area-top))',
	paddingTop: 'var(--mopla-safe-area-top)',
	paddingBottom: 50,
	boxSizing: 'border-box',
	overflow: 'auto',
	maxWidth: 600,
	margin: 'auto',
	boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

	'.carousel-slider': {
		height: '100%',
		'.slider-wrapper': {
			height: '100%',
			'.slider': {
				height: '100%',
			},
		},
	},

	'.MuiMobileStepper-dot': {
		'&:not(:last-of-type)': {
			marginRight: '8px',
		},
		backgroundColor: theme.palette.primary.light,
	},

	'.MuiMobileStepper-dotActive': {
		backgroundColor: theme.palette.primary.main,
	},

	'.MuiPaper-elevation': {
		alignSelf: 'center',
		justifySelf: 'center',
	},
}));

export const Slide = styled('div')(() => ({
	cursor: 'default',
	display: 'grid',
	gridAutoRows: 'max-content',
	justifyItems: 'start',
	width: '100%',
	height: '100%',
	padding: 24,
	boxSizing: 'border-box',
	wordBreak: 'break-word',
}));

export const Footer = styled('div')(() => ({
	height: '100%',
	width: '100%',
	display: 'grid',
	gridTemplateColumns: '1fr',
	justifyContent: 'center',
	padding: '0 24px',
	boxSizing: 'border-box',
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.typography.title,
	color: theme.palette.neutral[100],
	marginBottom: 24,
	textAlign: 'start',
}));

export const Subtitle = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	whiteSpace: 'break-spaces',
	textAlign: 'start',
}));

export const Image = styled('div')(() => ({
	width: '100%',
	borderRadius: 8,
	marginBottom: 40,
}));
