import { styled } from '@mui/material/styles';

export const ModalText = styled('span')<{ isWarn?: boolean }>(
	({ theme, isWarn }) => ({
		...theme.typography.body1,
		fontWeight: 600,
		marginBottom: 24,
		color: isWarn ? theme.palette.error.main : theme.palette.neutral[100],
	})
);

export const Note = styled('div')(({ theme }) => ({
	...theme.typography.body1,
	display: 'flex',
	flexDirection: 'column',
}));

export const NoteTitle = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
}));

export const NoteText = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	marginBottom: 24,
}));

export const InfoBlock = styled('div')(({ theme }) => ({
	...theme.typography.caption,
	display: 'flex',
	marginBottom: 24,
	color: theme.palette.neutral[80],
	'& svg': {
		marginRight: 8,
		'& > path': {
			fill: theme.palette.neutral[80],
		},
	},
}));

export const ButtonsWrapper = styled('div')(() => ({
	'button:first-of-type': {
		marginBottom: 16,
		marginTop: 38,
	},
}));

export const ModalTitle = styled('span')(({ theme }) => ({
	...theme.typography.title,
	marginTop: 40,
	marginBottom: 24,
}));
