import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import moment from 'moment'; //TODO MC-5210 remove moment, use dayjs
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
	paymentFinishSub$,
	personalDetailsManager,
	useDefaultPaymentMethod,
	useDTicketSubscriptionOffering,
	useSubscription,
	useSubscriptionActions,
	useSubscriptionTicketActions,
	useUser,
} from '@mopla/business-logic';
import {
	AdaptiveActionsBlock,
	CheckmarkIcon,
	ErrorModal,
	Loader,
	MplButton,
} from '@mopla/ui';
import { getDTicketType } from '@mopla/utils';

import passApi from '../../../api/passApi';
import { PaymentPortal } from '../PaymentPortal/PaymentPortal';

import { ConfirmationStep } from './steps/Confirmation';
import { FirstStep } from './steps/First';
import { AnotherRecipientBlock, SecondStep } from './steps/Second';
import { SummaryStep } from './steps/Summary';
import {
	CONFIRMATION_STEP,
	defaultValues,
	invoiceRecipientDefaultValues,
	MONTH_SELECTION_STEP,
	PAYMENT_METHOD_STEP,
	PERSONAL_DATA_STEP,
	SUMMARY_STEP,
} from './constants';
import { DTicketPaymentMethod } from './DTicketPaymentMethod';
import {
	ContentWrapper,
	CustomStepper,
	DrawerContentWrapper,
	LoaderWrapper,
	ScrollableWrapper,
	ThirdStepWrapper,
} from './DTicketSubscription.styles';
import { DTicketSubscriptionWrapper } from './DTicketSubscriptionWrapper';
import { getSavedAlternateInvoiceAddress } from './helpers';
import { PriceInfoBlock } from './PriceInfoBlock';
import {
	InvoiceRecipientFormValidationSchema,
	SubscriptionFormValidationSchema,
} from './SubscriptionValidation';
import { IInvoiceRecipientFormValues, ISubscriptionFormValues } from './types';
import { isAfterMiddleOfMonth, ValidityModal } from './ValidityModal';

interface IProps {
	open: boolean;
	onClose: VoidFunction;
	loading?: boolean;
}

export const DTicketSubscription: React.FC<IProps> = (props) => {
	const { open, onClose, loading = false } = props;
	const { t } = useTranslation(['dticket']);
	const [activeStep, setActiveStep] = useState<string>(MONTH_SELECTION_STEP);
	const [error, setError] = useState<boolean>(false);
	const navigate = useNavigate();
	const { subscriptionData: subscription } = useSubscription();
	const { fetchSubscriptions } = useSubscriptionActions();
	const { fetchTickets } = useSubscriptionTicketActions();
	const [isSubscribing, setIsSubscribing] = useState(false);

	const { userData } = useUser();

	const methods = useForm<ISubscriptionFormValues>({
		defaultValues: {
			...defaultValues,
			firstName: userData?.firstName,
			surname: userData?.lastName,
			phoneNumber: userData?.phone,
			street: userData?.address?.street,
			streetNumber: userData?.address?.streetNumber,
			postCode: userData?.address?.zipCode,
		},
		resolver: yupResolver(SubscriptionFormValidationSchema),
		mode: 'onBlur',
	});
	const { formState, watch, trigger, reset, getValues } = methods;
	const { isDirty, errors } = formState;
	const monthOfStartValue = watch('monthOfStart');

	const { subscriptionOffering, subscriptionOfferByMonth } =
		useDTicketSubscriptionOffering(monthOfStartValue);
	const isJobticket = !!subscriptionOffering?.isJobticket;
	const dTicketType = getDTicketType(subscriptionOffering?.subscriptionType);
	const isPriceZero = subscriptionOffering?.price === 0;

	useEffect(() => {
		if (open && subscription?.id && !isSubscribing) {
			navigate({ pathname: '/home/dticket', search: 'already-exist' });
		}
	}, [subscription?.id, navigate, open, isSubscribing]);

	const isMonthSelectionStep = activeStep === MONTH_SELECTION_STEP;
	const isPersonalDataStep = activeStep === PERSONAL_DATA_STEP;
	const isPaymentStep = activeStep === PAYMENT_METHOD_STEP;
	const isSummaryStep = activeStep === SUMMARY_STEP;
	const isConfirmationStep = activeStep === CONFIRMATION_STEP;

	const [showInvoiceRecipientForm, setShowInvoiceRecipientForm] =
		useState<boolean>(false);
	const [isAcceptTariffs, setIsAcceptTariffs] = useState(false);
	const [forceInitiatePayment, setForceInitiatePayment] =
		useState<boolean>(false);
	const [isPaymentLoading, setIsPaymentLoading] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [isValidityModalVisible, setIsValidityModalVisible] =
		useState<boolean>(false);
	const {
		defaultPaymentMethod,
		isPaymentMethodLoading,
		isPaymentMethodSaving,
	} = useDefaultPaymentMethod();

	const steps = useMemo(() => {
		if (!userData) {
			return [
				MONTH_SELECTION_STEP,
				PERSONAL_DATA_STEP,
				PAYMENT_METHOD_STEP,
				SUMMARY_STEP,
			];
		}
		const flowSteps = [MONTH_SELECTION_STEP];
		const needPersonalData = userData?.pleaseComplete;
		const hasDefaultPaymentMethod = userData?.hasDefaultStripePaymentMethod;

		if (needPersonalData) flowSteps.push(PERSONAL_DATA_STEP);
		if (!hasDefaultPaymentMethod && !isPriceZero)
			flowSteps.push(PAYMENT_METHOD_STEP);
		flowSteps.push(SUMMARY_STEP);

		return flowSteps;
	}, [userData, isPriceZero]);

	const stepsAmount = steps.length;
	const activeStepIndex = steps.findIndex((step) => step === activeStep);

	const savedAlternateInvoiceAddress = getSavedAlternateInvoiceAddress(
		userData?.alternateInvoiceAddress || null
	);
	const invoiceFormMethods = useForm<IInvoiceRecipientFormValues>({
		defaultValues: invoiceRecipientDefaultValues,
		resolver: yupResolver(InvoiceRecipientFormValidationSchema),
		mode: 'onBlur',
	});
	const {
		formState: invoiceFormState,
		watch: watchInvoiceFields,
		trigger: triggerInvoiceForm,
		reset: resetInvoiceForm,
	} = invoiceFormMethods;
	const { isDirty: isInvoiceDirty, errors: invoiceErrors } = invoiceFormState;

	const [query, setQuery] = useSearchParams();

	useEffect(() => {
		if (!userData || !userData.address) return;
		if (!subscription || !subscription.companyName) return;

		const { lastName, dateOfBirth, address, firstName, phone, metaData } =
			userData;
		const name = isJobticket ? metaData.jobticketFirstName : firstName;
		const surname = isJobticket ? metaData.jobticketLastName : lastName;
		const personalData: ISubscriptionFormValues = {
			monthOfStart: getValues('monthOfStart') || '',
			firstName: name || '',
			surname: surname || '',
			phoneNumber: phone || '',
			day: moment(dateOfBirth).get('day').toString(),
			month: moment(dateOfBirth).get('month').toString(),
			year: moment(dateOfBirth).get('year').toString(),
			street: address.street || '',
			streetNumber: address.streetNumber || '',
			postCode: address.zipCode || '',
			location: address.city || '',
			companyName: subscription.companyName || '',
		};
		reset(personalData, { keepDirty: true });
	}, [userData, isJobticket, reset, getValues, subscription]);

	/** TODO remove duplicated code fragment (see BookingFlow.tsx) */
	useEffect(() => {
		const isWeb = Capacitor.getPlatform() === 'web';
		let subscription: Subscription;
		const isPaymentRedirect = query.has('isPaymentRedirect');
		const redirectStatus = query.get('redirect_status');

		if (isWeb) {
			if (isPaymentRedirect) {
				if (redirectStatus !== 'succeeded') {
					setForceInitiatePayment(true);
				} else {
					query.delete('isPaymentRedirect');
					query.delete('redirect_status');
				}
			}
		} else {
			subscription = paymentFinishSub$
				.pipe(filter(Boolean))
				.subscribe((result) => {
					if (result === 'paymentClosed') {
						setForceInitiatePayment(false);
					}
					paymentFinishSub$.next(null);
				});
		}

		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const restoreState = query.get('restoreState');

		/** Restoring the persisted state of the component, if needed */
		if (restoreState) {
			query.delete('restoreState');
			setQuery(query);

			try {
				const { step, month } = JSON.parse(atob(restoreState));

				if (step) {
					setActiveStep(step);
				}

				if (month) {
					methods.setValue('monthOfStart', month, { shouldDirty: true });
				}
			} catch (e) {
				console.log(e);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/** When payment method is being saved or fetched, change the loader state accordingly */
	useEffect(() => {
		setIsPaymentLoading(isPaymentMethodLoading || isPaymentMethodSaving);
	}, [isPaymentMethodLoading, isPaymentMethodSaving]);

	/**
	 * This URL is used to return back to the flow from banking external pages
	 * State is partly persisted
	 * TODO btoa no longer needed, bcs initially it was used to compress long strings
	 * */
	const componentRedirectURL = useMemo(() => {
		const state = btoa(
			JSON.stringify({ step: activeStep, month: monthOfStartValue })
		);

		return `/home/dticket?subscribe&restoreState=${state}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep, monthOfStartValue]);

	const nextStep = () => {
		const nextIndex = activeStepIndex + 1;
		setActiveStep(
			nextIndex > stepsAmount ? steps[stepsAmount - 1] : steps[nextIndex]
		);
	};
	const toggleInvoiceForm = () => setShowInvoiceRecipientForm((prev) => !prev);

	const nextStepHandler = async () => {
		const personalData = watch();
		if (isMonthSelectionStep) {
			const isValid = await trigger('monthOfStart');
			setIsValidityModalVisible(
				isAfterMiddleOfMonth(personalData.monthOfStart)
			);
			if (!isValid) return;

			const windowRef = window as any;
			windowRef.dataLayer = windowRef.dataLayer || [];
			windowRef.dataLayer.push({
				event: 'selectSubscriptionMonth',
				startDate: personalData.monthOfStart,
			});

			return nextStep();
		}
		if (isPersonalDataStep) {
			const isValid = await trigger();
			if (!isValid) return;
			if (showInvoiceRecipientForm) {
				const isInvoiceDataValid = await triggerInvoiceForm();
				if (!isInvoiceDataValid) return;
			}
			try {
				const personalDetails = {
					firstName: personalData.firstName.trim(),
					lastName: personalData.surname.trim(),
					phone: personalData.phoneNumber.trim(),
					dateOfBirth:
						personalData.year && personalData.month && personalData.day
							? new Date(
									`${personalData.year}-${personalData.month}-${personalData.day}`
							  ).toISOString()
							: null,
					street: personalData.street.trim(),
					streetNumber: personalData.streetNumber.trim(),
					zipcode: personalData.postCode.trim(),
					city: personalData.location.trim(),
				};
				await passApi.post('/api/command/addPassengerDetails', personalDetails);
				personalDetailsManager.set('completed');
				try {
					const invoiceFormValues = watchInvoiceFields();
					const invoiceDetails = {
						firstName: invoiceFormValues.firstName.trim(),
						lastName: invoiceFormValues.surname.trim(),
						company: invoiceFormValues.company.trim(),
						street: invoiceFormValues.street.trim(),
						streetNumber: invoiceFormValues.streetNumber.trim(),
						zipcode: invoiceFormValues.postCode.trim(),
						city: invoiceFormValues.location.trim(),
					};
					await passApi.post(
						'/api/command/setAlternateInvoiceAddress',
						invoiceDetails
					);
				} catch (e) {
					setError(true);
					console.error('Error on saving invoice details', e);
				}
				return nextStep();
			} catch (e) {
				setError(true);
				console.error('Error on saving passenger details', e);
			}
		}
		if (isPaymentStep) {
			return setForceInitiatePayment(true);
		}

		try {
			setIsSubscribing(true);
			setIsCreating(true);
			await passApi.post('/api/command/createSubscription', {
				startDate: moment(personalData.monthOfStart).format('YYYY-MM-01'),
			});
			setIsCreating(false);
			fetchSubscriptions();
			fetchTickets();

			const windowRef = window as any;
			windowRef.dataLayer = windowRef.dataLayer || [];
			windowRef.dataLayer.push({
				event: 'purchase',
				ecommerce: {
					currency: 'EUR',
					value: subscriptionOffering?.price,
					shipping: 0.0,
					affiliation: 'Not necessary',
					coupon: subscriptionOfferByMonth?.voucher?.voucherCode,
					items: [
						{
							item_name: 'Deutschlandticket',
							item_id: 'mp1122',
							price: subscriptionOffering?.price,
							item_brand: 'not necessary',
							item_category: 'Deutschlandticket',
							item_variant: 'Deutschlandticket',
							quantity: '1',
						},
					],
				},
			});

			return setActiveStep(CONFIRMATION_STEP);
		} catch (e) {
			setIsCreating(false);
			setError(true);
			console.log('Error on creating subscription: ', e);
		}
	};

	const closeError = () => setError(false);

	const previousStepHandler = () => {
		const prevIndex = activeStepIndex - 1;
		if (prevIndex < 0) return handleClose();
		return setActiveStep(steps[prevIndex]);
	};
	const redirectToHomePage = () =>
		!Capacitor.isNativePlatform()
			? navigate(
					{ pathname: '/welcome', search: 'tryOurApp' },
					{ replace: true }
			  )
			: navigate('/home', { replace: true });

	const handleClose = () => {
		fetchSubscriptions();
		fetchTickets();
		// Condition if subscription flow started by passwordless
		const startedByPasswordless =
			query.has('subscription') && query.has('oobCode');
		startedByPasswordless ? redirectToHomePage() : onClose();

		setIsAcceptTariffs(false);
		setActiveStep(MONTH_SELECTION_STEP);
		reset(defaultValues);
		if (showInvoiceRecipientForm) {
			resetInvoiceForm(invoiceRecipientDefaultValues);
			setShowInvoiceRecipientForm(false);
		}
	};

	const handleSubmitValidityModal = () => {
		setIsValidityModalVisible(false);
	};

	const handleCanceltValidityModal = () => {
		setIsValidityModalVisible(false);
		previousStepHandler();
	};

	const getContent = (): React.ReactNode => {
		if (isMonthSelectionStep)
			return <FirstStep subscriptionOffering={subscriptionOffering} />;
		if (isPersonalDataStep) return <SecondStep />;
		if (isPaymentStep)
			return (
				<ContentWrapper>
					<ThirdStepWrapper>
						<DTicketPaymentMethod isPreparing={isPaymentLoading} />
					</ThirdStepWrapper>
				</ContentWrapper>
			);

		if (isCreating) {
			return (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			);
		}

		return (
			<SummaryStep
				invoiceData={savedAlternateInvoiceAddress || watchInvoiceFields()}
				showInvoiceRecipientBlock={
					!!savedAlternateInvoiceAddress || showInvoiceRecipientForm
				}
				isAcceptTariffs={isAcceptTariffs}
				setIsAcceptTariffs={setIsAcceptTariffs}
				isPriceZero={isPriceZero}
				dTicketType={dTicketType}
				subscriptionOffer={subscriptionOfferByMonth}
			/>
		);
	};
	const getHeader = (): string => {
		if (isMonthSelectionStep) return t('header.book_ticket', { dTicketType });
		if (isPersonalDataStep) return t('header.contact_data');
		if (isPaymentStep) return t('header.payment');
		if (isConfirmationStep) return t('header.thanks_for_subscription');
		return t('header.summary');
	};
	const getButtonText = (): string => {
		if (isMonthSelectionStep || isPersonalDataStep) return t('button.next');
		if (isPaymentStep) return t('button.add_payment_method');

		return t('button.subscribe');
	};

	const getIsDisabled = () => {
		if (isMonthSelectionStep) return !isDirty || !!errors.monthOfStart;

		const invoiceFormHasErrors =
			showInvoiceRecipientForm &&
			(!isInvoiceDirty || Object.keys(invoiceErrors).length > 0);
		const hasErrors = !isDirty || Object.keys(errors).length > 0;

		if (isSummaryStep) return !isAcceptTariffs || isCreating;

		return loading || hasErrors || invoiceFormHasErrors;
	};

	const getSubmitTestId = () => {
		if (isMonthSelectionStep || isPersonalDataStep) {
			return 'dticket-subscription-next-button';
		}

		if (isPaymentStep) {
			return 'dticket-subscription-addPaymentMethod-button';
		}

		return 'dticket-subscription-submit-button';
	};

	const InvoiceRecipientForm = isPersonalDataStep ? (
		<FormProvider {...invoiceFormMethods}>
			<AnotherRecipientBlock
				showInvoiceRecipientForm={showInvoiceRecipientForm}
				toggleInvoiceForm={toggleInvoiceForm}
			/>
		</FormProvider>
	) : null;

	const startLoader = useCallback(() => setIsPaymentLoading(true), []);
	const stopLoader = useCallback(() => setIsPaymentLoading(false), []);
	const handleClosePayment = useCallback(() => {
		setForceInitiatePayment(false);
	}, []);
	const onUserHasDefaultPaymentMethod = useCallback(() => {
		setActiveStep(SUMMARY_STEP);

		const windowRef = window as any;
		windowRef.dataLayer = windowRef.dataLayer || [];
		windowRef.dataLayer.push({
			event: 'paymentMethodAdded',
		});
	}, []);

	useEffect(() => {
		if (isPaymentStep && defaultPaymentMethod) {
			onUserHasDefaultPaymentMethod();
		}
	}, [defaultPaymentMethod, isPaymentStep, onUserHasDefaultPaymentMethod]);

	return (
		<>
			{error && <ErrorModal onSubmit={closeError} />}
			{forceInitiatePayment && (
				<PaymentPortal
					onUserHasDefaultPaymentMethod={onUserHasDefaultPaymentMethod}
					onClose={handleClosePayment}
					startLoader={startLoader}
					stopLoader={stopLoader}
					stripeRedirectUrlWeb={componentRedirectURL}
				/>
			)}
			{isValidityModalVisible && (
				<ValidityModal
					onSubmit={handleSubmitValidityModal}
					onCancel={handleCanceltValidityModal}
				/>
			)}
			<FormProvider {...methods}>
				<DTicketSubscriptionWrapper
					open={open}
					title={getHeader()}
					onClose={handleClose}
					onBack={activeStepIndex < 0 ? undefined : previousStepHandler}
					isConfirmationStep={isConfirmationStep}
					content={
						<DrawerContentWrapper>
							{isConfirmationStep && (
								<ConfirmationStep
									onSubmit={handleClose}
									distributor={subscription?.companyName || ''}
									dTicketType={dTicketType}
								/>
							)}
							{!isConfirmationStep && (
								<ScrollableWrapper>
									{loading && (
										<LoaderWrapper>
											<CircularProgress />
										</LoaderWrapper>
									)}
									{!loading && getContent()}
									{!loading && InvoiceRecipientForm}
								</ScrollableWrapper>
							)}
						</DrawerContentWrapper>
					}
					footer={
						<>
							{!isConfirmationStep && (
								<>
									<CustomStepper
										variant="dots"
										steps={stepsAmount}
										position="static"
										activeStep={activeStepIndex}
										nextButton={null}
										backButton={null}
									/>
									<AdaptiveActionsBlock
										topBlock={
											isSummaryStep && (
												<PriceInfoBlock
													voucher={subscriptionOfferByMonth?.voucher}
													isJobticket={isJobticket}
													regularPrice={subscriptionOffering?.price}
												/>
											)
										}
										mainBlock={
											<MplButton
												color="primaryRevert"
												data-testid={getSubmitTestId()}
												onClick={nextStepHandler}
												disabled={getIsDisabled()}
											>
												{getButtonText()}
											</MplButton>
										}
										enforceMobile
									/>
								</>
							)}
							{isConfirmationStep && (
								<AdaptiveActionsBlock
									mainBlock={
										<MplButton
											testId="dTicketSubscription-concludeButton"
											color="primaryRevert"
											onClick={handleClose}
											endIcon={<CheckmarkIcon />}
										>
											{t('booking:button.conclude')}
										</MplButton>
									}
									enforceMobile
								/>
							)}
						</>
					}
				/>
			</FormProvider>
		</>
	);
};
