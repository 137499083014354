import { FC, useMemo, useState } from 'react';
import { Portal, Typography } from '@mui/material';
import { noop } from 'rxjs';

import { TransportType } from '@mopla/data-models';
import { useTranslation } from '@mopla/i18';

import {
	BottomSlider,
	CollapsibleCard,
	Responsive,
	SideOverlay,
	TransportCircle,
} from '../../';

import { Root } from './styled';
import { useMoplaRideTypesControl } from './useMoplaRideTypesControl';

export const MoplaRideTypesDrawer: FC = () => {
	const { t } = useTranslation('common');
	const [rootNode] = useState(document.getElementById('portal'));
	const { isOpened, handleClose } = useMoplaRideTypesControl();

	const cardsData = useMemo(() => {
		return [
			{
				type: 'PRODT' as const,
				title: t(`ride_types.PRODT.title`),
				description: t(`ride_types.PRODT.description`),
			},
			{
				type: TransportType.LBODTFLEX,
				title: t(`ride_types.${TransportType.LBODTFLEX}.title`),
				description: t(`ride_types.${TransportType.LBODTFLEX}.description`),
			},
			{
				type: TransportType.LBT,
				title: t(`ride_types.${TransportType.LBT}.title`),
				description: t(`ride_types.${TransportType.LBT}.description`),
			},
			{
				type: TransportType.LBODT,
				title: t(`ride_types.${TransportType.LBODT}.title`),
				description: t(`ride_types.${TransportType.LBODT}.description`),
			},
		];
	}, [t]);

	const cardsDataDisplay = (
		<Root>
			{cardsData.map((data, index) => (
				<CollapsibleCard
					key={index}
					icon={<TransportCircle transportType={data.type} />}
					title={data.title}
					content={
						<Typography variant="body2" color="primary.dark">
							{data.description}
						</Typography>
					}
				/>
			))}
		</Root>
	);

	return (
		<Portal key="moplaRideTypesDrawer" container={rootNode}>
			<Responsive mobile>
				<BottomSlider
					open={isOpened}
					onClose={handleClose}
					title={t('ride_types.title')}
				>
					{cardsDataDisplay}
				</BottomSlider>
			</Responsive>
			<Responsive aboveMobile>
				<SideOverlay
					title={t('ride_types.title')}
					open={isOpened}
					onOpen={noop}
					onClose={handleClose}
					disablePortal
					zIndex={1500}
				>
					{cardsDataDisplay}
				</SideOverlay>
			</Responsive>
		</Portal>
	);
};
