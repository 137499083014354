import { createContext, MouseEventHandler, useContext } from 'react';

import { ILatLng, TransportType } from '@mopla/data-models';

export interface ILegDetailsContext {
	mode: TransportType;
	startPointLabel: string;
	startPointAddress?: string;
	startPointUrl?: string;
	startTime: string;
	endTime: string;
	endPointLabel: string;
	endPointAddress?: string;
	endPointUrl?: string;
	walkLegText: string | null;
	isStartEndDateFix: boolean;
	isTimeNarrow?: boolean;
	mapWaypoints: ILatLng[] | null;
	disabled?: boolean;
	showLiveMap?: boolean;
	onStartPointClick: MouseEventHandler;
	onEndPointClick: MouseEventHandler;
}

const legDetailsContext = createContext({} as ILegDetailsContext);

export const LegDetailsContextProvider = legDetailsContext.Provider;

export const useLegDetailsContext = () => useContext(legDetailsContext);
