import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
	marginBottom: 32,
	borderRadius: 8,
	backgroundColor: theme.palette.neutral[0],

	'& > div:first-of-type': {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '10px 16px',

		'& > svg': {
			path: {
				fill: theme.palette.primary.dark,
			},
		},
	},
}));

export const ImageWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: 240,

	'& > img': {
		height: '100%',
		maxWidth: '100%',
		objectFit: 'contain',
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));
