import { useContext, useEffect, useState } from 'react';
import { RxDocument } from 'rxdb';
import { Subscription } from 'rxjs';

import { IBooking } from '@mopla/data-models';

import { savePassengerBookings } from '../actions/passengerBookingActions';
import { BusinessLayerContext } from '../business-logic';

/** TODO fix this. Codebase import the IBooking from here. Shouldn't */
export type PassengerBooking = IBooking;

export const usePassengerBookings = (): {
	bookings: PassengerBooking[];
	loadBookings: () => Promise<PassengerBooking[]>;
	loading: boolean;
	isBookingsLoaded: boolean;
} => {
	const businessLayer = useContext(BusinessLayerContext);
	const [bookings, setBookings] = useState<PassengerBooking[]>([]);
	const [loading, setLoading] = useState(false);
	const [isBookingsLoaded, setIsBookingsLoaded] = useState(false);

	useEffect(() => {
		let subscription: Subscription;
		if (businessLayer.db) {
			const liveQuery = businessLayer.db['passengerBooking'].find().$;

			subscription = liveQuery.subscribe(
				(resultSet: RxDocument<PassengerBooking>[]) => {
					setBookings(resultSet.map((r) => r.toJSON()) as PassengerBooking[]);
					setIsBookingsLoaded(true);
				}
			);
		}

		return () => {
			subscription?.unsubscribe();
		};
	}, [businessLayer.db]);

	//TODO refactor this. Use action instead of api call here.
	const loadBookings = async () => {
		setLoading(true);
		try {
			const bookingsResult = await businessLayer.api.get(
				'/api/passengers/bookings'
			);
			await businessLayer.dispatch(savePassengerBookings(bookingsResult));
			return bookingsResult;
		} finally {
			setLoading(false);
		}
	};
	return { bookings, loadBookings, loading, isBookingsLoaded };
};
