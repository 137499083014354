import { styled } from '@mui/material/styles';

import { HeaderVariants } from './PassengerHeader';

export const RootMobile = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{
	variant: HeaderVariants;
}>(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	placeContent: 'center',
	placeItems: 'center',
	width: '100%',
	height: '100%',
	boxShadow: '0px 3px 40px rgba(0, 0, 0, 0.11)',
	zIndex: 1,
	padding: '26px 20px',
	boxSizing: 'border-box',
}));

export const RootDesktop = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',

	background: theme.palette.neutral[0],

	padding: '16px 24px',
	boxSizing: 'border-box',
}));
