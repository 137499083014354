import React from 'react';

export const ArrowDownIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M11.89 13.42L6.94 8.47 5.53 9.9l4.94 4.95.71.7a1 1 0 001.42 0l.7-.7 4.95-4.95-1.41-1.42-4.95 4.95z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
