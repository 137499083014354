import { styled } from '@mui/material/styles';

export const Title = styled('div')(({ theme }) => ({
	display: 'grid',
	width: '80%',
	...theme.typography.bodyTitle,
	color: theme.palette.primary.dark,
	marginTop: 14,
	marginBottom: 24,
}));

export const Text = styled('div')(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	color: theme.palette.primary.dark,
	marginTop: 12,
	marginBottom: 16,
}));

export const TripCardRoot = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: string }>(({ theme, variant }) => ({
	backgroundColor: theme.palette.neutral[0],
	borderRadius: 8,
	marginBottom: 12,
	padding: variant === 'blue' ? '23px 8px' : '13px 12px',
	borderLeft: '12px solid',
	borderColor:
		(variant === 'secondary_dark' && theme.palette.primary.dark) ||
		(variant === 'yellow' && '#FFE66D') ||
		(variant === 'grey' && theme.palette.neutral[60]) ||
		(variant === 'blue' && theme.palette.primary.light) ||
		theme.palette.neutral[0],

	'&:last-of-type': {
		marginBottom: 32,
	},
}));

export const Row = styled('div', {
	shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string }>(({ theme, variant }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: 'max-content max-content',
	...theme.typography.body2,
	fontWeight: 600,
	color:
		variant === 'grey' ? theme.palette.neutral[60] : theme.palette.neutral[100],

	'&:not(:last-of-type)': {
		marginBottom: 6,
	},

	'& > span:nth-of-type(2)': {
		marginLeft: 16,
	},

	'#city': {
		...theme.typography.body2,
	},
}));
