import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Footer } from '@mopla/ui';

export const Root = styled('div')(() => ({
	flex: '1 1 auto',
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string }>(({ theme, marginBottom }) => ({
	...theme.typography.body1,
	marginBottom: marginBottom || 0,
}));

export const FooterStyled = styled(Footer)(() => ({
	paddingInline: 0,
	marginTop: 'auto',
}));
