import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, User } from 'firebase/auth';

import { personalDetailsManager } from '@mopla/business-logic';
import { Loader } from '@mopla/ui';

import { WelcomeStep } from '../pages/welcome/welcome';

const WelcomeRoute: React.FC<{ children: ReactElement }> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [userData, setUserData] = useState<User | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const emailVerification = location.search.includes(WelcomeStep.verifyEmail);
	const tryOurApp = location.search.includes(WelcomeStep.tryOurApp);
	const shouldVerify = location.search.includes(WelcomeStep.verifyEmail);
	const personalDetails = personalDetailsManager.get({ safe: true });

	useEffect(() => {
		setLoading(true);
		getAuth().onAuthStateChanged((user) => {
			setUserData(user);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		if (
			!loading &&
			userData &&
			userData.emailVerified &&
			personalDetails === 'completed' &&
			!shouldVerify &&
			!tryOurApp
		) {
			navigate('/home', { replace: true });
		}
	}, [loading, userData, navigate, personalDetails, shouldVerify, tryOurApp]);

	if (loading) {
		return <Loader delay={100} />;
	}

	return !loading &&
		(!userData ||
			(userData && !userData.emailVerified) ||
			(userData && emailVerification) ||
			(userData && tryOurApp) ||
			(userData && !personalDetails))
		? children
		: null;
};

export default WelcomeRoute;
