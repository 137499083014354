import {
	FC,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import {
	confirmPasswordReset,
	User,
	verifyPasswordResetCode,
} from '@firebase/auth';
import * as Sentry from '@sentry/capacitor';
import {
	getAuth,
	isSignInWithEmailLink,
	signInWithEmailAndPassword,
	signInWithEmailLink,
} from 'firebase/auth';

import {
	personalDetailsManager,
	useAppLaunched,
	usePasswordlessLogin,
	useResetPassword,
} from '@mopla/business-logic';
import { SuccessStep } from '@mopla/constants';
import { ErrorResponse, IWhoami } from '@mopla/data-models';
import {
	BottomSlider,
	IFormInput,
	Loader,
	MplButton,
	RightRideAnim,
	UserPersonalDetails,
} from '@mopla/ui';
import {
	ChangePasswordForm,
	ForgotPasswordForm,
	PersonalDetailsForm,
} from '@mopla/ui';

import passApi from '../../../api/passApi';
import { environment } from '../../../environments/environment';
import { LoginScreen } from '../../organisms/LoginScreen/LoginScreen';
import { PasswordlessLoginModal } from '../../organisms/LoginScreen/PasswordlessLoginModal';
import OnboardingScreen from '../../organisms/Onboarding/OnboardingScreen';
import { PushToApp } from '../../organisms/PushToAppScreen/PushToApp';
import RegistrationForm from '../../organisms/RegistrationForm/RegistrationForm';
import { VerifyFailureScreen } from '../../organisms/VerifyFailureScreen/VerifyFailureScreen';
import { VerifyReminderScreen } from '../../organisms/VerifyReminderScreen/VerifyReminderScreen';

import { renderActiveUI, verifyEmail } from './helpers';
import { VerificationStatus } from './verification';
import { WelcomeStep } from './welcome';
import {
	ButtonsWrapper,
	DrawerContent,
	HeaderText,
	LogoWrapper,
	Root,
} from './WelcomeScreen.styles';

export const WelcomeScreen: FC = () => {
	const { t } = useTranslation(['logIn', 'registration', 'welcome', 'verify']);
	const navigate = useNavigate();
	const location = useLocation();
	const resetPassword = useResetPassword('PASSENGER', environment.baseUrl);
	const passwordlessLogin = usePasswordlessLogin(environment.baseUrl);
	const query = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);

	const [showSetPasswordModal, setShowSetPasswordModal] =
		useState<boolean>(false);
	const [regEmail, setRegEmail] = useState<string>('');
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
	const [activeRegStep, setActiveRegStep] = useState<number>(0);
	const [authError, setAuthError] = useState<boolean>(false);
	const [successType, setSuccessType] = useState<string>('');
	const [loginIsActive, setLoginIsActive] = useState<boolean>(false);
	const [verifyReminderIsActive, setVerifyReminderIsActive] =
		useState<boolean>(false);
	const [registrationIsActive, setRegistrationIsActive] =
		useState<boolean>(false);
	const [forgotPasswordIsActive, setForgotPasswordIsActive] =
		useState<boolean>(false);
	const [changePasswordIsActive, setChangePasswordIsActive] =
		useState<boolean>(false);
	const [personalDetailsIsActive, setPersonalDetailsIsActive] =
		useState<boolean>(false);
	const [pushToAppIsActive, setPushToAppIsActive] = useState<boolean>(false);
	const [shouldVerify, setShouldVerify] = useState<boolean>(false);
	const [verifyFailureIsActive, setVerifyFailureIsActive] =
		useState<boolean>(false);
	const [onboardingIsActive, setOnboardingIsActive] = useState<boolean>(false);

	const [loginTitleComp, setLoginTitleComp] = useState<ReactElement | null>(
		null
	);
	const [verificationStatus, setVerificationStatus] = useState<string>(
		VerificationStatus.successNotSameDeviceWeb
	);
	const [forgotPasswordError, setForgotPasswordError] =
		useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(true);
	const { data, checking = true } = useAppLaunched();
	const onboardingPassed =
		data.wasLaunched || forgotPasswordIsActive || changePasswordIsActive;

	useEffect(() => {
		if (!onboardingPassed && !checking) {
			setOnboardingIsActive(true);
		}
		if (onboardingPassed) {
			setOnboardingIsActive(false);
		}
		setLoading(false);
	}, [onboardingPassed, checking]);

	useEffect(() => {
		if (!checking) {
			renderActiveUI(
				location,
				setLoginIsActive,
				setVerifyReminderIsActive,
				setRegistrationIsActive,
				setShouldVerify,
				setForgotPasswordIsActive,
				setChangePasswordIsActive,
				setPersonalDetailsIsActive,
				setPushToAppIsActive
			);

			if (!location.search.includes(WelcomeStep.verifyEmail)) {
				setLoading(false);
			}
		}
	}, [location, checking]);

	const auth = getAuth();

	useEffect(() => {
		const saved_email = localStorage.getItem('emailForSignIn');
		if (!!saved_email && isSignInWithEmailLink(auth, window.location.href)) {
			signInWithEmailLink(auth, saved_email, window.location.href)
				.then((res) => {
					res.user.getIdTokenResult().then(() => {
						setShowSetPasswordModal(true);
						navigate('/welcome', { replace: true });
					});
				})
				.catch((error) => {
					navigate('/welcome', { replace: true });
					console.error(error, 'Invalid link or oobCode');
				});
		}
	}, [auth, location.pathname, navigate, query]);
	const submitSetPassword = async () => {
		const email = localStorage.getItem('emailForSignIn');
		if (email) {
			setShowSetPasswordModal(false);
			await onSubmitForgotPassword({ email });
			navigate({
				pathname: '/welcome',
				search: 'forgotPassword',
			});
		}
	};
	const rejectSetPassword = () => {
		setShowSetPasswordModal(false);
		setLoading(true);
		passApi
			.get('/api/whoami')
			.then((user) => {
				navigate(location.pathname, { replace: true });
				if (user.pleaseComplete) {
					return navigate({ pathname: '/welcome', search: 'personalDetails' });
				}

				personalDetailsManager.set('completed');
				return !Capacitor.isNativePlatform()
					? navigate({ pathname: '/welcome', search: 'tryOurApp' })
					: navigate('/home');
			})
			.catch(() => {
				return navigate({ pathname: '/welcome', search: 'personalDetails' });
			})
			.finally(() => {
				localStorage.removeItem('emailForSignIn');
				setLoading(false);
			});
	};

	const onEditUser = (personalDetails: UserPersonalDetails) => {
		return passApi.post('/api/command/addPassengerDetails', personalDetails);
	};
	const onPasswordlessLogin = async (email: string) => {
		setLoading(true);
		try {
			const res = await passwordlessLogin(email, 'NORMAL');

			if (res.status === 204) {
				console.log(
					'successfully login -> open "PW login easy" screen + maybe link '
				);
				localStorage.setItem('emailForSignIn', email);
				localStorage.setItem('emailSendTime', String(new Date().valueOf()));
				setLoading(false);
				setSuccessType(SuccessStep.pwlessLoginEmailSent);
			}
		} catch (e) {
			setLoading(false);
			console.error('Error on passwordless login: ', e);
		}
	};

	const onLogin = async (data: UnpackNestedValue<IFormInput>) => {
		const { email, password } = data;
		try {
			const { user: firebaseUser } = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const userData = await passApi.get('/api/whoami');

			setLoading(true);
			navigate(location.pathname, { replace: true });
			if (
				userData.pleaseComplete &&
				verificationStatus !== VerificationStatus.rejectNoUserData &&
				firebaseUser?.emailVerified
			) {
				return navigate(
					{
						pathname: '/welcome',
						search: 'personalDetails',
					},
					{
						state: {
							title: loginTitleComp ? 'notSameDevice' : 'sameDeviceTitle1',
						},
					}
				);
			}
			if (verificationStatus === VerificationStatus.rejectNoUserData) {
				setSuccessType(SuccessStep.registrationComplete);
				navigate({
					pathname: '/welcome',
					search: 'registration',
				});
				localStorage.setItem('emailSendTime', String(new Date().valueOf()));
				return passApi.post('/api/command/verifyEmail', {
					app: 'PASSENGER',
				});
			} else if (firebaseUser?.emailVerified && !userData.pleaseComplete) {
				personalDetailsManager.set('completed');
				localStorage.removeItem('emailForSignIn');
				return !Capacitor.isNativePlatform()
					? navigate({ pathname: '/welcome', search: 'tryOurApp' })
					: navigate('/home');
			} else {
				return navigate({
					pathname: '/welcome',
					search: 'verifyBeforeStart',
				});
			}
		} catch (err) {
			Sentry.addBreadcrumb({
				category: 'auth',
				message: 'Passenger login flow',
			});
			Sentry.captureException(err);
			setAuthError(true);
		}
	};

	const onSubmitForgotPassword = async (
		data: UnpackNestedValue<{ email: string }>
	) => {
		const { email } = data;
		setLoading(true);
		setForgotPasswordError(false);
		try {
			const res = await resetPassword(email);
			if (res.status === 204) {
				setLoading(false);
				localStorage.setItem('emailSendTime', String(new Date().valueOf()));
				return setSuccessType(SuccessStep.forgotPasswordEmailSent);
			}
			setLoading(false);
			return setForgotPasswordError(true);
		} catch (err) {
			setLoading(false);
			setForgotPasswordError(true);
			console.log('Error on reset password', err);
		}
	};

	useEffect(() => {
		if (shouldVerify) {
			setLoading(true);

			getAuth().onAuthStateChanged((user) => {
				if (user?.emailVerified) {
					user
						.getIdTokenResult()
						.then(() => {
							return passApi.get('/api/whoami').then((res: IWhoami) => {
								if (res.pleaseComplete) {
									return navigate(
										{
											pathname: '/welcome',
											search: 'personalDetails',
										},
										{ state: { title: 'alreadyVerified' } }
									);
								} else {
									return navigate(
										{
											pathname: '/home',
										},
										{ state: { alreadyVerified: 'true' } }
									);
								}
							});
						})
						.catch((err: ErrorResponse) => console.log(err))
						.finally(() => setLoading(false));
				} else {
					verifyEmail(
						location,
						navigate,
						user as User,
						t,
						setLoginIsActive,
						setLoginTitleComp,
						verificationStatus,
						setVerificationStatus,
						setVerifyFailureIsActive,
						loading,
						setLoading
					);
				}
			});
		}
	}, [shouldVerify]);

	const closeDrawer = () => {
		setSuccessType('');
		setActiveRegStep(0);
		navigate({ pathname: '/welcome' });
	};

	const getRegistrationHeader = () => {
		if (successType === SuccessStep.registrationComplete)
			return 'text.header.success';
		if (successType === SuccessStep.verifyEmailResend)
			return 'text.header.verify_email';
		return 'text.header.text';
	};

	const getRegistrationBack = () => {
		if (successType === SuccessStep.verifyEmailResend)
			return () => setSuccessType(SuccessStep.registrationComplete);
		if (activeRegStep) {
			return () => setActiveRegStep(0);
		}
		return () => {
			navigate({ pathname: '/welcome' });
			setActiveRegStep(0);
		};
	};

	const getLoginNamespace = () => {
		if (verificationStatus) return 'verify';
		return 'logIn';
	};

	const getLoginTitle = () => {
		if (
			successType === SuccessStep.pwlessLoginEmailSent ||
			successType === SuccessStep.resendPwlessLoginEmail
		)
			return 'passwordless.header.paswordless_login';
		if (
			verificationStatus === VerificationStatus.successNotSameDeviceApp ||
			verificationStatus === VerificationStatus.successNotSameDeviceWeb
		)
			return 'text.account_confirmed';
		return 'text.header';
	};

	const drawerOpened =
		loginIsActive ||
		registrationIsActive ||
		forgotPasswordIsActive ||
		changePasswordIsActive ||
		personalDetailsIsActive ||
		pushToAppIsActive ||
		verifyReminderIsActive ||
		verifyFailureIsActive;

	const getChangePasswordTitle = () => {
		if (successType === SuccessStep.passwordChanged)
			return 'text.header.success';
		return 'text.header.text';
	};

	const getStepData = () => {
		if (registrationIsActive)
			return {
				namespace: 'registration',
				title: getRegistrationHeader(),
				mainContent: (
					<RegistrationForm
						regEmail={regEmail}
						setRegEmail={setRegEmail}
						activeRegStep={activeRegStep}
						setActiveRegStep={setActiveRegStep}
						onSuccess={setSuccessType}
						showVerifyEmailSent={
							successType === SuccessStep.registrationComplete
						}
						showResendVerifyEmail={
							successType === SuccessStep.verifyEmailResend
						}
					/>
				),
				onBack: getRegistrationBack(),
			};

		if (forgotPasswordIsActive)
			return {
				namespace: 'forgotPassword',
				title:
					successType === SuccessStep.forgotPasswordEmailSent
						? 'text.header.success'
						: 'text.header.text',
				mainContent: (
					<ForgotPasswordForm
						onSubmit={onSubmitForgotPassword}
						onSuccessCallback={() => setSuccessType('')}
						successStep={successType}
						error={forgotPasswordError}
						closeError={() => setForgotPasswordError(false)}
						setForgotPasswordEmail={setForgotPasswordEmail}
						forgotPasswordEmail={forgotPasswordEmail}
					/>
				),
				onBack: loginTitleComp
					? () => {
							setForgotPasswordIsActive(false);
							setShouldVerify(true);
					  }
					: () => navigate(-1),
			};

		if (changePasswordIsActive)
			return {
				namespace: 'changePassword',
				title: getChangePasswordTitle(),
				mainContent: (
					<ChangePasswordForm
						onSuccessCallback={() =>
							setSuccessType(SuccessStep.passwordChanged)
						}
						verifyPasswordResetCode={verifyPasswordResetCode}
						confirmPasswordReset={confirmPasswordReset}
						getAuth={getAuth}
						showSuccess={successType === SuccessStep.passwordChanged}
						onSubmitCallback={() =>
							navigate({ pathname: '/welcome', search: 'login' })
						}
						onErrorCallback={() =>
							navigate({ pathname: '/welcome', search: 'login' })
						}
					/>
				),
				onBack: () => navigate(-1),
			};

		if (personalDetailsIsActive)
			return {
				namespace: 'personalDetails',
				title: 'text.header',
				mainContent: <PersonalDetailsForm onEdit={onEditUser} />,
				onBack: () => navigate(-1),
			};

		if (pushToAppIsActive)
			return {
				namespace: 'pushToApp',
				title: 'text.header',
				mainContent: <PushToApp />,
				onBack: () => navigate(-1),
			};

		if (verifyReminderIsActive)
			return {
				namespace: 'verifyReminder',
				title: 'text.header',
				mainContent: <VerifyReminderScreen setSuccessType={setSuccessType} />,
				onBack: () => navigate(-1),
			};

		if (
			verifyFailureIsActive &&
			verificationStatus !== VerificationStatus.rejectNoUserData
		)
			return {
				namespace: 'verify',
				title: 'text.header2',
				mainContent: <VerifyFailureScreen setSuccessType={setSuccessType} />,
				onBack: () => navigate(-1),
			};

		return {
			namespace: getLoginNamespace(),
			title: getLoginTitle(),
			mainContent: (
				<LoginScreen
					testId={
						[
							VerificationStatus.successNotSameDeviceApp,
							VerificationStatus.successNotSameDeviceWeb,
						].includes(verificationStatus as VerificationStatus)
							? 'successfull-account-verification-content'
							: 'default-login-content'
					}
					onPasswordless={onPasswordlessLogin}
					onLogin={onLogin}
					authError={authError}
					titleComp={loginTitleComp || null}
					successStep={successType}
					changeSuccessType={setSuccessType}
				/>
			),
			onBack: () => {
				setAuthError(false);
				navigate(-1);
				setSuccessType('');
			},
		};
	};

	const showBackButton =
		!changePasswordIsActive &&
		successType !== SuccessStep.registrationComplete &&
		!personalDetailsIsActive &&
		!pushToAppIsActive &&
		!verifyFailureIsActive &&
		verificationStatus !== VerificationStatus.rejectNoUserData;

	if (loading || checking) {
		return <Loader delay={100} />;
	}

	if (onboardingIsActive) {
		return <OnboardingScreen />;
	}

	const stepData = getStepData();

	return (
		<Root>
			{showSetPasswordModal && (
				<PasswordlessLoginModal
					onSubmit={submitSetPassword}
					onCancel={rejectSetPassword}
				/>
			)}
			<HeaderText>{t('welcome:text.title')}</HeaderText>
			<LogoWrapper>
				<RightRideAnim loop />
			</LogoWrapper>
			<ButtonsWrapper>
				<MplButton
					data-testid="welcomeScreen-registration-button"
					onClick={() =>
						navigate({ pathname: '/welcome', search: 'registration' })
					}
				>
					{t('registration:button.registration')}
				</MplButton>
				<MplButton
					data-testid="welcomeScreen-login-button"
					variant="outlined"
					onClick={() => navigate({ pathname: '/welcome', search: 'login' })}
				>
					{t('logIn:button.logIn')}
				</MplButton>
			</ButtonsWrapper>
			<BottomSlider
				open={drawerOpened}
				title={t(`${stepData.namespace}:${stepData.title}`)}
				onClose={closeDrawer}
				onBack={showBackButton ? stepData.onBack : undefined}
			>
				<DrawerContent>{stepData.mainContent}</DrawerContent>
			</BottomSlider>
		</Root>
	);
};
