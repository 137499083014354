import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { FlexColumn } from '../../../../';

import { ContentPaper } from './styled';

export const LBTTicketPrice: FC = () => {
	const { t } = useTranslation('booking');

	return (
		<ContentPaper>
			<FlexColumn className="space-between" style={{ gap: 4 }}>
				<Typography variant="bodyTitle">
					{t('text.price_according_to_tariff')}
				</Typography>
				<Typography variant="body2" color="neutral.80">
					{t('text.buy_ticket_in_bus')}
				</Typography>
			</FlexColumn>
		</ContentPaper>
	);
};
