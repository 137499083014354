import {
	Booking,
	CancelablePromise,
	CurrencyCode,
	NamedLocation,
	PaymentForm,
	PaymentState,
	ScheduledBreak,
	ScheduledLeg,
	ScheduledVehicleCheck,
	SpecialNeeds,
} from '@mopla/data-models';

type leg =
	| { obj: ScheduledLeg; ref: 'LEG' }
	| { obj: ScheduledBreak; ref: 'BREAK' }
	| { obj: ScheduledVehicleCheck; ref: 'VEHICLE_CHECK' };

export const getScheduledLegs = (): CancelablePromise<leg[]> => {
	return new CancelablePromise((resolve) => {
		setTimeout(() => {
			//reject('error');
			resolve([
				{
					obj: {
						assignmentId: 'd4516c96-8611-4006-aba7-737ddde7c7d5',
						licencePlate: 'LOS-PR-888',
						start: '2022-10-05T09:06:47Z',
						end: '2022-10-05T09:21:47Z',
						checkDone: false,
					},
					ref: 'VEHICLE_CHECK',
				},
				{
					obj: {
						scheduledLegId: '316a55fb-aeb1-475e-8265-4fd172472b55',
						from: {
							name: 'Zörbig, Sachsen-Anhalt, Deutschland',
							lat: '51.62729',
							lng: '12.12023',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Gröbzig, Mühlbreite',
							lat: '51.685026',
							lng: '11.877072',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T09:21:47Z',
						end: '2022-10-05T09:51:00Z',
						immutableSince: '2022-10-05T10:00:00.582230Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 0,
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
				{
					obj: {
						scheduledLegId: '7ccf66e0-6e04-4620-a8ac-a9d8a216871e',
						from: {
							name: 'Gröbzig, Mühlbreite',
							lat: '51.685026',
							lng: '11.877072',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Aken, Volksbad',
							lat: '51.851779',
							lng: '12.043737',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T09:53:00Z',
						end: '2022-10-05T10:52:33Z',
						immutableSince: '2022-10-05T10:00:00.582226Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 3,
						enteringBookings: ['2022P7BA9NGC', '2022P3J9O6IC'],
						exitingBookings: ['2022P3J9O6IC'],
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
				{
					obj: {
						scheduledLegId: 'c634204a-ca73-4b29-9a63-f9b103c80ba7',
						from: {
							name: 'Aken, Volksbad',
							lat: '51.851779',
							lng: '12.043737',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Aken, Bahnhof (Bus)',
							lat: '51.853146',
							lng: '12.036454',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T10:59:00Z',
						end: '2022-10-05T11:00:27Z',
						immutableSince: '2022-10-05T10:00:00.582229Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 2,
						exitingBookings: ['2022P7BA9NGC'],
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
			]);
		}, 500);
	});
};

export const getBookings = (): CancelablePromise<Booking[]> => {
	return new CancelablePromise((resolve) => {
		setTimeout(() => {
			resolve([
				{
					bookingId: 'test_booking_id_1',
					isCheckedIn: false,
					bookingState: 'NEW',
					passengerList: [
						{
							firstName: 'John',
							lastName: 'Travolta',
							phone: '+483279236812',
							email: 'john@travolta.io',
							isMainPassenger: true,
							specialNeeds: SpecialNeeds.NONE,
							discountState: 'DISCOUNTED_TICKET_PRESENT',
						},
						{
							firstName: 'Andrew',
							lastName: 'Garfield',
							phone: '+483279236812',
							email: 'andrew@garfield.io',
							isMainPassenger: true,
							specialNeeds: SpecialNeeds.WHEELCHAIR_TYPE_1,
							discountState: 'NON_DISCOUNTED_TICKET_PRESENT',
						},
					],
					checkedInScheduledLegs: [],
					specialNeeds: SpecialNeeds.WHEELCHAIR_TYPE_1,
					paymentAmount: 10,
					currencyCode: CurrencyCode.EUR,
					paymentForm: PaymentForm.CASH,
					paymentState: PaymentState.TO_PAY,
				},
				{
					bookingId: 'test_booking_id_2',
					isCheckedIn: false,
					bookingState: 'NEW',
					passengerList: [
						{
							firstName: 'Emma',
							lastName: 'Watson',
							phone: '+483279236812',
							email: 'emma@watson.io',
							isMainPassenger: true,
							specialNeeds: SpecialNeeds.NONE,
							discountState: 'NO_DISCOUNT',
						},
					],
					checkedInScheduledLegs: [],
					specialNeeds: SpecialNeeds.NONE,
					paymentAmount: 10,
					currencyCode: CurrencyCode.EUR,
					paymentForm: PaymentForm.DIGITAL,
					paymentState: PaymentState.PAID,
				},
				{
					bookingId: 'test_booking_id_3',
					isCheckedIn: false,
					bookingState: 'NEW',
					passengerList: [
						{
							firstName: 'Daniel',
							lastName: 'Radcliffe',
							phone: '+483279236812',
							email: 'daniel@radcliffe.io',
							isMainPassenger: true,
							specialNeeds: SpecialNeeds.NONE,
							discountState: 'PERSONAL_DISCOUNT_AVAILABLE',
						},
					],
					checkedInScheduledLegs: [],
					specialNeeds: SpecialNeeds.NONE,
					paymentAmount: 10,
					currencyCode: CurrencyCode.EUR,
					paymentForm: PaymentForm.CASH,
					paymentState: PaymentState.TO_PAY,
				},
			]);
		}, 2000);
	});
};

export const getUpdatedLegs = (): CancelablePromise<leg[]> => {
	return new CancelablePromise((resolve) => {
		setTimeout(() => {
			resolve([
				{
					obj: {
						assignmentId: 'd4516c96-8611-4006-aba7-737ddde7c7d5',
						licencePlate: 'LOS-PR-888',
						start: '2022-10-05T09:06:47Z',
						end: '2022-10-05T09:21:47Z',
						checkDone: true,
					},
					ref: 'VEHICLE_CHECK',
				},
				{
					obj: {
						scheduledLegId: '316a55fb-aeb1-475e-8265-4fd172472b55',
						from: {
							name: 'Zörbig, Sachsen-Anhalt, Deutschland',
							lat: '51.62729',
							lng: '12.12023',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Gröbzig, Mühlbreite',
							lat: '51.685026',
							lng: '11.877072',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T09:21:47Z',
						end: '2022-10-05T09:51:00Z',
						immutableSince: '2022-10-05T10:00:00.582230Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 0,
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
				{
					obj: {
						scheduledLegId: '316a55fb-aeb1-475e-8265-4fd172472b24',
						from: {
							name: 'Zörbig, Sachsen-Anhalt, Deutschland',
							lat: '51.62729',
							lng: '12.12023',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Gröbzig, Mühlbreite',
							lat: '51.685026',
							lng: '11.877072',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T09:36:47Z',
						end: '2022-10-05T09:51:00Z',
						immutableSince: '2022-10-05T10:00:00.582230Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 0,
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
				{
					obj: {
						assignmentId: 'd4516c96-8611-4006-aba7-737ddde7c7j8',
						licencePlate: 'POR-DM-909',
						start: '2022-10-05T09:55:00Z',
						end: '2022-10-05T10:45:47Z',
						checkDone: false,
					},
					ref: 'VEHICLE_CHECK',
				},
				{
					obj: {
						scheduledLegId: 'c634204a-ca73-4b29-9a63-f9b103c80ba7',
						from: {
							name: 'Aken, Volksbad',
							lat: '51.851779',
							lng: '12.043737',
							type: NamedLocation.type.STOP,
						},
						to: {
							name: 'Aken, Bahnhof (Bus)',
							lat: '51.853146',
							lng: '12.036454',
							type: NamedLocation.type.STOP,
						},
						start: '2022-10-05T10:59:00Z',
						end: '2022-10-05T11:00:27Z',
						immutableSince: '2022-10-05T10:00:00.582229Z',
						// serviceTime: 0,
						distance: 0,
						// manual: false,
						passengers: 2,
						exitingBookings: ['2022P7BA9NGC'],
						state: ScheduledLeg.state.NEW,
					},
					ref: 'LEG',
				},
				{
					ref: 'BREAK',
					obj: {
						location: {
							name: '',
							lat: '51.855626',
							lng: '12.040338',
							type: NamedLocation.type.ADDRESS,
						},
						start: '2023-10-06T11:54:00Z',
						end: '2023-10-06T12:24:00Z',
						immutableSince: '2022-10-06T12:40:01.116421Z',
					},
				},
			]);
		}, 500);
	});
};
