import { FC } from 'react';
import { styled } from '@mui/material/styles';

import { TransportType } from '@mopla/data-models';

import { TransportCircle } from '../../../';

import { useLegDetailsContext } from './context';

export interface ITransportPathProps {
	className?: string;
	transportType: TransportType;
}

interface IOwnerState {
	disabled?: boolean;
}

const Root = styled('div')<{ ownerState: IOwnerState }>(
	({ theme, ownerState }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',

		'& .legPath': {
			width: 10,
			height: 'calc(100% + 10px)',
			margin: '-5px 0',
			backgroundColor: ownerState.disabled
				? theme.palette.neutral[80]
				: theme.palette.primary.main,
		},

		'& .legEndCircle': {
			width: 18,
			height: 18,
			backgroundColor: theme.palette.neutral[0],
			borderRadius: '50%',
			border: `2px solid ${
				ownerState.disabled
					? theme.palette.neutral[80]
					: theme.palette.primary.main
			}`,
			flex: '0 0 auto',
		},

		'& .pathCircle': {
			flex: '0 0 auto',
			position: 'relative',
			zIndex: 1,
		},
	})
);

export const TransportPath: FC<ITransportPathProps> = ({
	className,
	transportType,
}) => {
	const { disabled } = useLegDetailsContext();

	return (
		<Root className={className} ownerState={{ disabled }}>
			<TransportCircle
				className="pathCircle"
				transportType={transportType}
				variant={disabled ? 'disabled' : 'main'}
			/>
			<div className="legPath" />
			<div className="legEndCircle pathCircle" />
		</Root>
	);
};
