import React, { ReactNode } from 'react';
import { IconButton, SwipeableDrawerProps, Typography } from '@mui/material';

import { LeftChevronIcon } from '../../';

import { Content, Header, Root } from './FullscreenOverlay.styles';

interface OverlayProps extends SwipeableDrawerProps {
	children: ReactNode;
	onBack: () => void;
	title: string;
}

export const FullscreenOverlay: React.FC<OverlayProps> = ({
	children,
	onBack,
	title,
	...props
}) => {
	return (
		<Root data-testid="fullscreenOverlay" {...props}>
			<Header>
				<IconButton data-testid="fullscreenOverlay-backButton" onClick={onBack}>
					<LeftChevronIcon />
				</IconButton>
				<Typography
					data-testid="fullscreenOverlay-title"
					variant="bodyTitle"
					color="primary"
				>
					{title}
				</Typography>
			</Header>
			<Content data-testid="fullscreenOverlay-content">
				{props.open ? children : null}
			</Content>
		</Root>
	);
};

export default FullscreenOverlay;
