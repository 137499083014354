import { FC } from 'react';

import { TransportType } from '@mopla/data-models';

import { TransportLeg } from './TransportLeg';
import { ILegDetailsProps } from './types';
import { WalkLeg } from './WalkLeg';
import { withLegDerivedData } from './withLegDerivedData';

const LegDetailsComponent: FC<ILegDetailsProps> = (props) => {
	const { isFirstLeg, isLastLeg, leg } = props;
	const legMode = leg.mode;

	if (
		legMode === TransportType.WALK ||
		legMode === TransportType.TRANSFER_WALK
	) {
		return <WalkLeg isStart={isFirstLeg} isEnd={isLastLeg} />;
	}

	return <TransportLeg {...props} />;
};

export const LegDetailsV2 = withLegDerivedData(LegDetailsComponent);
