import {
	FC,
	PropsWithChildren,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import {
	Geolocation,
	PermissionStatus,
	Position,
} from '@capacitor/geolocation';
import * as Sentry from '@sentry/capacitor';
import { Observable } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';

import { reverseGeocode } from '@mopla/business-logic';
import { Defer, LatLng, useBooleanState } from '@mopla/utils';

import { IPermissionsModalRef, MapAnim, PermissionsModal } from '../../';

import {
	CurrentGeoPositionContextProvider,
	ICurrentGeoPositionContext,
} from './context';

const isWeb = Capacitor.getPlatform() === 'web';
/** Keep reference on the checkPermissions, bcs it always changes (Geolocation.checkPermissions === Geolocation.checkPermissions // false) */
const checkGeolocationPermissions = Geolocation.checkPermissions;

interface ICurrentGeoPositionProviderProps {
	/**
	 * If true, initially the geo won't be fetched, and therefore the geo permissions won't be asked
	 * geo can later be retrieved using either:
	 *  * getCurrentGeoPosition or getCurrentGeoPositionDecoded
	 *    * these open our PermissionsModal, await for permissions to be granted and then return a resolved promise
	 *  * updatePos
	 *    * this does the same, but only updates the {position} state
	 * This prop doesn't affect cases when only position$ is used. In those cases geo permissions will be asked automatically in the custom popup
	 *  */
	deferred?: boolean;
}

export const CurrentGeoPositionProvider: FC<
	PropsWithChildren<ICurrentGeoPositionProviderProps>
> = ({ children, deferred = false }) => {
	const deferredLatLngRef = useRef<Defer<LatLng | null>>();
	const reprocessRef = useRef<IPermissionsModalRef>(null);
	const [position, setPosition] = useState<LatLng | null>(null);
	const [showPermissionsModal, openPermissionsModal, closePermissionsModal] =
		useBooleanState(true);
	const { t } = useTranslation('permissions');

	/**
	 * Fetches the current geo via the PermissionsModal dialog, which in turn opens a native permissions dialog (if weren't granted before)
	 * */
	const _getCurrentGeoPositionDeferred = useCallback(async () => {
		try {
			/** Create a deferred object (Promise), which will later be resolved in the permissionsGrantedHandler */
			deferredLatLngRef.current = new Defer<LatLng | null>();

			/** Trigger permissions reprocess in the PermissionsModal */
			openPermissionsModal();
			reprocessRef.current?.reprocess();

			return deferredLatLngRef.current.promise;
		} catch (e) {
			Sentry.captureException(e);
			return null;
		}
	}, [openPermissionsModal]);

	/**
	 * Fetches the current geo using Geolocation directly, without showing the PermissionsModal dialog
	 * */
	const _getCurrentGeoPositionEager = useCallback(async () => {
		try {
			const r = await Geolocation.getCurrentPosition({
				enableHighAccuracy: true,
				timeout: 10000,
				maximumAge: Infinity,
			});
			return new LatLng({ lat: r.coords.latitude, lng: r.coords.longitude });
		} catch (e) {
			Sentry.captureException(e);
			return null;
		}
	}, []);

	const _permissionsGrantedHandler = useCallback(
		async (arg: PermissionStatus | Position) => {
			let currentLatLng;

			/** This happens when permissions weren't granted initially, but become granted when a user passes the corresponding web/native dialog */
			if ('coords' in arg) {
				currentLatLng = new LatLng({
					lat: arg.coords.latitude,
					lng: arg.coords.longitude,
				});
			} else {
				/**
				 * This happens when permissions were granted prior the mount of the CurrentGeoPositionProvider (Geolocation.checkPermissions returned 'granted'
				 * Therefore it's needed to call the Geolocation.getCurrentPosition
				 * */
				currentLatLng = await _getCurrentGeoPositionEager();
			}

			if (deferredLatLngRef.current) {
				deferredLatLngRef.current.resolve(currentLatLng);
			}

			setPosition(currentLatLng);
			closePermissionsModal();
		},
		[closePermissionsModal, _getCurrentGeoPositionEager]
	);

	const getCurrentGeoPosition = useCallback(async () => {
		try {
			if (deferred) {
				return await _getCurrentGeoPositionDeferred();
			} else {
				return await _getCurrentGeoPositionEager();
			}
		} catch (e) {
			Sentry.captureException(e);
			return null;
		}
	}, [deferred, _getCurrentGeoPositionEager, _getCurrentGeoPositionDeferred]);

	const getCurrentGeoPositionDecoded = useCallback(async () => {
		try {
			const currentLatLng = await getCurrentGeoPosition();

			if (!currentLatLng) {
				return null;
			}

			const decodedPos = await reverseGeocode(currentLatLng.toString(), 1);

			return decodedPos.length ? decodedPos[0] : null;
		} catch (e) {
			Sentry.captureException(e);
			return null;
		}
	}, [getCurrentGeoPosition]);

	const updateGeoPosition = useCallback(() => {
		openPermissionsModal();
		reprocessRef.current?.reprocess();
	}, [openPermissionsModal]);

	const position$ = useMemo(() => {
		return new Observable<LatLng | null>((observer) => {
			let watchId: string;

			const startWatch = async () => {
				/** This would initiate the permissions process, if needed */
				const loc = await _getCurrentGeoPositionDeferred();
				observer.next(loc);
				watchId = await Geolocation.watchPosition(
					{ enableHighAccuracy: true, timeout: 10000, maximumAge: Infinity },
					(position, err) => {
						if (!err) {
							const currentLatLng = position
								? new LatLng({
										lat: position.coords.latitude,
										lng: position.coords.longitude,
								  })
								: null;
							observer.next(currentLatLng);
						}
					}
				);
			};

			startWatch();

			return () => {
				if (watchId) {
					Geolocation.clearWatch({ id: watchId });
				}
			};
		}).pipe(startWith(null), shareReplay({ refCount: true, bufferSize: 1 }));
	}, [_getCurrentGeoPositionDeferred]);

	const ctx = useMemo<ICurrentGeoPositionContext>(
		() => ({
			position$,
			position,
			updatePos: updateGeoPosition,
			getCurrentGeoPosition,
			getCurrentGeoPositionDecoded,
		}),
		[
			position,
			position$,
			updateGeoPosition,
			getCurrentGeoPosition,
			getCurrentGeoPositionDecoded,
		]
	);

	return (
		<>
			<CurrentGeoPositionContextProvider value={ctx}>
				{children}
			</CurrentGeoPositionContextProvider>
			<PermissionsModal
				ref={reprocessRef}
				title={t('location.title')}
				firstMessage={t('location.first_message')}
				secondMessage={t('location.second_message')}
				deniedFallbackMessage1={t('location.first_message_err')}
				deniedFallbackMessage2={t('location.second_message_err')}
				deniedFallbackSkip={t('location.skip_title_err')}
				skipTitle={t('location.skip_title')}
				acceptTitle={t('location.accept_title')}
				animationElement={<MapAnim />}
				checkPermissionsCallback={checkGeolocationPermissions}
				askPermissionsCallback={() => {
					/** The requestPermissions is not implemented for web, so getCurrentPosition will ask for permissions */
					if (isWeb) {
						return Geolocation.getCurrentPosition({
							enableHighAccuracy: true,
							timeout: 10000,
							maximumAge: Infinity,
						});
					}

					/** However for Android the requestPermissions will additionally ask for the Precise location */
					return Geolocation.requestPermissions({
						permissions: ['location', 'coarseLocation'],
					});
				}}
				onPermissionsGranted={_permissionsGrantedHandler}
				onCancel={closePermissionsModal}
				deferred={deferred}
				shouldRender={showPermissionsModal}
			/>
		</>
	);
};
