import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	minHeight: '100%',
	backgroundColor: theme.palette.neutral[40],
	padding: '24px 16px',
	display: 'flex',
	flexDirection: 'column',
	gap: 8,
	boxSizing: 'border-box',
}));
