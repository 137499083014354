import { FC } from 'react';

import { useTranslation } from '@mopla/i18';

import { PromotionBanner, useMoplaRideTypesControl } from '../../';
import bannerImg from '../../icons/promotionRideTypes.svg?url';

export const MoplaRideTypesBanner: FC = () => {
	const { t } = useTranslation('common');
	const { handleOpen } = useMoplaRideTypesControl();

	return (
		<PromotionBanner
			onClick={handleOpen}
			img={bannerImg}
			title={t('mopla_rides_banner.title')}
			description={t('mopla_rides_banner.description')}
			action={t('mopla_rides_banner.action')}
		/>
	);
};
