import React from 'react';

export const UploadIcon = () => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.4856 17.9856L6.4856 19.9856L18.4856 19.9856L18.4856 17.9856L6.4856 17.9856ZM11.7785 4.3074L8.24292 7.84293L9.65713 9.25714L11.4856 7.42868L11.4856 15.9856L13.4856 15.9856L13.4856 7.42869L15.3141 9.25714L16.7283 7.84293L13.1927 4.30739C12.9845 4.09916 12.7073 4.00196 12.4346 4.01579C12.1959 4.02789 11.9608 4.12509 11.7785 4.3074Z"
				fill="currentColor"
			/>
		</svg>
	);
};
