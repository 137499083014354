export const ArrowUpIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M11.89 11.3l4.95 4.95 1.41-1.41-4.95-4.95-.7-.7a1 1 0 00-1.42 0l-.7.7-4.95 4.95 1.4 1.41 4.96-4.95z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
