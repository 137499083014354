import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ArrowRightIcon, MplButton } from '../../';
import { Modal } from '../../molecules/Modal/Modal';

import { Description } from './EmailSentWarningMessage.styles';

interface IProps {
	open: boolean;
	onClose: VoidFunction;
	email?: string | null;
}

export const EmailSentWarningMessage: FC<IProps> = (props) => {
	const { t } = useTranslation(['registration']);
	const { open, onClose, email = '' } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t('resendVerify.text.warning_title')}
			mainContent={
				<>
					<Typography variant="body1">
						<Trans
							i18nKey="registration:resendVerify.text.warning_text_start"
							values={{ regEmail: email }}
							components={{
								bold: <Typography variant="body1" fontWeight={700} />,
							}}
						/>{' '}
						{t('resendVerify.text.warning_text_end')}
					</Typography>
					<Description marginTop="24px" marginBottom="40px">
						{t('resendVerify.text.warning_description')}
					</Description>
				</>
			}
			footerComp={
				<MplButton
					data-testid="registrationForm-verify-email-warning-modal-submit-button"
					endIcon={<ArrowRightIcon />}
					type="button"
					onClick={onClose}
				>
					{t('resendVerify.text.warning_button')}
				</MplButton>
			}
		/>
	);
};
