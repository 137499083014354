import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	ArrowRightIcon,
	BadgeComponent,
	MplButton,
	ProfileIcon,
} from '@mopla/ui';
import {
	AppleButton,
	FacebookButton,
	GoogleButton,
	MicrosoftButton,
} from '@mopla/ui';
import { MoplaLogo } from '@mopla/ui/icons/MoplaLogo';

import '@mobiscroll/react/dist/css/mobiscroll.scss';

import { DTicketMarketingBlock } from '../../organisms/DTicketMarketingBlock/DTicketMarketingBlock';
import { AgreementForm } from '../../organisms/PasswordLessLogin/AgreementForm';
import { PasswordlessForm } from '../../organisms/PasswordLessLogin/PasswordlessForm';

import {
	Content,
	FlexibleWrapper,
	FlexibleWrapperContent,
	GreenDivider,
	HeaderBlock,
	Root,
	SocialWrapper,
} from './DTicket.styles';
import { SubscriptionFlowWrapper } from './SubscriptionFlowWrapper';

export const DTicket = () => {
	const navigate = useNavigate();
	const { t } = useTranslation(['dticket']);
	const [step, setStep] = useState<number>(1);
	const [loginType, setLoginType] = useState<
		'passwordless' | 'google' | 'apple' | 'microsoft' | 'facebook'
	>('passwordless');
	const handleLoginClick = () =>
		navigate({ pathname: '/welcome', search: 'login' });
	const isEmailStep = step === 1;
	const isPasswordless = loginType === 'passwordless';
	const isLoginByGoogle = loginType === 'google';
	const isLoginByApple = loginType === 'apple';
	const isLoginByMicrosoft = loginType === 'microsoft';
	const isLoginByFacebook = loginType === 'facebook';

	const handleNextClick = () => {
		setLoginType('passwordless');
		setStep(2);
	};

	const handleBackClick = () => {
		setLoginType('passwordless');
		setStep(1);
	};

	const handleSubmitAgreementClick = () => {
		console.log('login with social: ', loginType);
	};
	const changeLoginType =
		(type: 'google' | 'apple' | 'microsoft' | 'facebook') => () => {
			setLoginType(type);
			setStep(2);
		};

	const getServiceType = () => {
		if (isLoginByGoogle) return 'Google';
		if (isLoginByApple) return 'Apple';
		if (isLoginByMicrosoft) return 'Microsoft';
		if (isLoginByFacebook) return 'Facebook';
		return '';
	};

	const handleQuickLogin = () => navigate('/welcome');

	return (
		<Root>
			<HeaderBlock>
				<MoplaLogo />
				<div>
					<MplButton
						endIcon={<ProfileIcon />}
						variant="text"
						onClick={handleLoginClick}
					>
						{t('button.login')}
					</MplButton>
				</div>
			</HeaderBlock>
			{/* TODO: this is a temporary quick fix of login; remove it later; */}
			<Content>
				<DTicketMarketingBlock />
				<FlexibleWrapper>
					<FlexibleWrapperContent>
						<BadgeComponent />
						<MplButton
							variant="outlined"
							onClick={handleQuickLogin}
							endIcon={<ArrowRightIcon />}
						>
							{t('button.to_web_app')}
						</MplButton>
					</FlexibleWrapperContent>
				</FlexibleWrapper>
			</Content>
			{/*<Content>*/}
			{/*	{isEmailStep && <DTicketMarketingBlock />}*/}
			{/*	<FlexibleWrapper>*/}
			{/*		<FlexibleWrapperContent>*/}
			{/*			{isPasswordless && (*/}
			{/*				<PasswordlessForm*/}
			{/*					type="GERMANYTICKET"*/}
			{/*					onNextClick={handleNextClick}*/}
			{/*					onBackClick={handleBackClick}*/}
			{/*					formStep={step}*/}
			{/*				/>*/}
			{/*			)}*/}
			{/*			/!*{isEmailStep && (*!/*/}
			{/*			/!*	<>*!/*/}
			{/*			/!*		<GreenDivider> {t('label.login_with')}</GreenDivider>*!/*/}
			{/*			/!*		<SocialWrapper>*!/*/}
			{/*			/!*			<GoogleButton onClick={changeLoginType('google')} />*!/*/}
			{/*			/!*			<MicrosoftButton onClick={changeLoginType('microsoft')} />*!/*/}
			{/*			/!*			<FacebookButton onClick={changeLoginType('facebook')} />*!/*/}
			{/*			/!*			<AppleButton onClick={changeLoginType('apple')} />*!/*/}
			{/*			/!*		</SocialWrapper>*!/*/}
			{/*			/!*	</>*!/*/}
			{/*			/!*)}*!/*/}
			{/*			{!isEmailStep && !isPasswordless && (*/}
			{/*				<AgreementForm*/}
			{/*					buttonText={t('button.login_with', {*/}
			{/*						serviceName: getServiceType(),*/}
			{/*					})}*/}
			{/*					loginTypeText={t('text.login_with', {*/}
			{/*						serviceName: getServiceType(),*/}
			{/*					})}*/}
			{/*					onSubmit={handleSubmitAgreementClick}*/}
			{/*					onBackClick={handleBackClick}*/}
			{/*				/>*/}
			{/*			)}*/}
			{/*		</FlexibleWrapperContent>*/}
			{/*	</FlexibleWrapper>*/}
			{/*</Content>*/}
			<SubscriptionFlowWrapper />
		</Root>
	);
};
