import {
	CurrencyCode,
	EDriverLegType,
	NamedLocation,
	PaymentForm,
	PaymentState,
	SpecialNeeds,
} from '../api';
import { TRef } from '../api/models/driversSchedule';

export type DataChange = {
	changeId: string;
	insertTime: number;
	scheduledLegId?: string;
	bookingId?: string;
	synced: boolean;
	attempts?: number;
	checkDone?: boolean;
	vehicleData?: string;
	ref: string;
	checkedInLegs?: string[];
	type:
		| 'NEW'
		| 'EXECUTING'
		| 'PRE_FINISHED'
		| 'DONE_BREAK'
		| 'DONE'
		| 'CANCELLED_BY_DISTRIBUTOR'
		| 'CANCELLED_BY_PASSENGER'
		| 'CANCELLED_NO_SHOW';
	// "START" or "DONE" leg | "EXECUTING" or "DONE" or "CANCELED_NO_SHOW" booking
};

export type NormalizedScheduledLeg = {
	scheduledLegId?: string;
	from?: NamedLocation;
	to?: NamedLocation;
	start?: string;
	end?: string;
	immutableSince?: string | null;
	serviceTime?: number;
	distance?: number;
	manual?: boolean;
	exitingBookings?: string[];
	enteringBookings?: string[];
	passengers?: number;
	checkDone?: boolean;
	licencePlate?: string;
	state?: string; // TEST field
	ref: TRef;
	note?: string;
	driverLegType: EDriverLegType;
	// enum:  NEW | EXECUTING | PRE_FINISHED | FINISHED
};

interface LegStatus {
	scheduledLegId: string;
	isCheckedIn: boolean;
}

export type PassengerDiscountState =
	| 'NO_DISCOUNT'
	| 'DISCOUNTED_TICKET_PRESENT'
	| 'NON_DISCOUNTED_TICKET_PRESENT'
	| 'PERSONAL_DISCOUNT_AVAILABLE'
	| 'SEVERELY_DISABLED'
	| 'COMPANION_OF_SEVERELY_DISABLED';

export type BookingState =
	| 'NEW'
	| 'EXECUTING'
	| 'FINISHED'
	| 'CANCELLED_BY_DISTRIBUTOR'
	| 'CANCELLED_BY_PASSENGER'
	| 'CANCELLED_NO_SHOW';

export type Passenger = {
	firstName?: string;
	lastName?: string;
	phone?: string;
	email?: string;
	isMainPassenger: boolean;
	specialNeeds: SpecialNeeds;
	discountState: PassengerDiscountState;
};

export type Booking = {
	bookingId: string;
	/**
	 * @deprecated this field is in general not used correctly and will be removed
	 */
	isCheckedIn?: boolean;
	checkedInScheduledLegs: LegStatus[];
	bookingState: BookingState;
	passengerList: Passenger[];
	specialNeeds: SpecialNeeds;
	paymentAmount: number;
	currencyCode: CurrencyCode;
	paymentForm: PaymentForm;
	paymentState: PaymentState;
	toddlerSeats?: number;
	bulkyLuggage?: boolean;
};
export type TCheckInStatus = 'instruction' | 'idle' | 'ready';
