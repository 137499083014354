import * as yup from 'yup';

import { passwordValidator } from '@mopla/ui';

const RegistrationValidationSchema = yup.object().shape({
	newEmail: yup
		.string()
		.email('email.valid')
		.required('email.required')
		.max(254, 'email.length'),
	newPassword: passwordValidator(),
});

export default RegistrationValidationSchema;
