import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ScheduleDiffEl } from '@mopla/business-logic';

import { Modal, MplButton } from '../../';

import { Text, Title } from './DistributorNotificationModal.styles';
import TripCard from './TripCard';

interface DistributorNotificationModalProps {
	onClose: () => void;
	onSubmit: () => void;
	showModal: boolean;
	legsDiff: ScheduleDiffEl[] | null;
}

export const DistributorNotificationModal: React.FC<
	DistributorNotificationModalProps
> = ({ onClose, onSubmit, showModal, legsDiff }) => {
	const { t } = useTranslation('notifications');
	const [rootNode] = React.useState(document.getElementById('portal'));

	if (rootNode === null) {
		return null;
	}

	const addedRides = legsDiff?.filter((leg) => leg.updateState === 'NEW');
	const canceledRides = legsDiff?.filter(
		(leg) => leg.updateState === 'DELETED'
	);
	const breakStop = legsDiff?.filter((leg) => leg.updateState === 'NEW_BREAK');

	return ReactDOM.createPortal(
		<Modal
			variant="secondary_dark"
			backgroundColor="#ECEEF8"
			titleComp={<Title>{t('text.changes_in_roster')}</Title>}
			mainContent={
				<>
					{Boolean(addedRides?.length) && (
						<>
							<Text>{t('text.added_rides')}</Text>
							{addedRides?.map((leg) => (
								<TripCard leg={leg} key={leg.scheduledLegId} />
							))}
						</>
					)}
					{Boolean(canceledRides?.length) && (
						<>
							<Text>{t('text.canceled_trips')}</Text>
							{canceledRides?.map((leg) => (
								<TripCard leg={leg} key={leg.scheduledLegId} />
							))}
						</>
					)}
					{Boolean(breakStop?.length) && (
						<>
							<Text>{t('text.pause')}</Text>
							{breakStop?.map((leg) => (
								<TripCard leg={leg} key={leg.scheduledLegId} />
							))}
						</>
					)}
				</>
			}
			footerComp={
				<MplButton color="secondary" onClick={onSubmit}>
					{t('button.back_to_home')}
				</MplButton>
			}
			open={showModal}
			showClose
			onClose={onClose}
		/>,
		rootNode
	);
};

export default DistributorNotificationModal;
