import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { MoplaRideTypesDrawer, TripDelayModal } from '@mopla/ui';

export const RootPage: FC = () => {
	return (
		<>
			<Outlet />

			{/*MoplaRideTypesDrawer should be able to render under any app's url, both for authenticated and unauthenticated users*/}
			<MoplaRideTypesDrawer />

			{/*TripDelayModal should be here to reach possibility to render it from any page of application  */}
			<TripDelayModal />
		</>
	);
};
