import { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { RxDatabase, RxLocalDocument } from 'rxdb';
import { RxLocalDocumentData } from 'rxdb/dist/types/types/plugins/local-documents';

import { IDefaultPaymentMethod } from '@mopla/data-models';

import {
	fetchDefaultPaymentMethod,
	UserActionTypes,
} from '../actions/userActions';
import { BusinessLayerContext } from '../business-logic';

import { useActionNotification } from './useActionNotification';

export type TDefaultPaymentMethod = IDefaultPaymentMethod | undefined;

export interface IDefaultPaymentMethodData {
	defaultPaymentMethod: TDefaultPaymentMethod;
	isPaymentMethodLoading: boolean;
	isPaymentMethodSaving: boolean;
	isPaymentMethodValid: boolean;
	//refetch(): void;
	remove(): void;
}

export const useDefaultPaymentMethod = (): IDefaultPaymentMethodData => {
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [defaultPaymentMethod, setLocalDefaultPaymentMethod] =
		useState<TDefaultPaymentMethod>();
	const businessLayer = useContext(BusinessLayerContext);
	const waitForPaymentFetchResult = useActionNotification(
		UserActionTypes.FetchDefaultPaymentMethodResult
	);
	const startForFetchPayment = useActionNotification(
		UserActionTypes.FetchDefaultPaymentMethod
	);
	const startForSetPayment = useActionNotification(
		UserActionTypes.SetDefaultPaymentMethod
	);
	const endForSetPayment = useActionNotification(
		UserActionTypes.SetDefaultPaymentMethodResult
	);

	useEffect(() => {
		const liveQuery = businessLayer.db.getLocal$<IDefaultPaymentMethod>(
			'defaultPaymentMethod'
		);

		const sub = liveQuery.subscribe(
			(doc: RxLocalDocument<RxDatabase, IDefaultPaymentMethod> | null) => {
				if (doc) {
					const data: RxLocalDocumentData<IDefaultPaymentMethod> =
						doc.toMutableJSON();
					setLocalDefaultPaymentMethod(data.data);
				} else {
					setLocalDefaultPaymentMethod(undefined);
				}
				setIsLoading(false);
			}
		);

		return () => {
			sub.unsubscribe();
		};
	}, [businessLayer.db]);

	const clearLocalDefaultPaymentMethod = async () => {
		const localDoc = await businessLayer.db.getLocal<IDefaultPaymentMethod>(
			'defaultPaymentMethod'
		);
		await localDoc?.remove();
	};

	// listen to the global action queue, instead of managing a local state
	useEffect(() => {
		let isActive = true;
		startForFetchPayment(
			() => setIsLoading(true),
			() => isActive
		);
		startForSetPayment(
			() => setIsSaving(true),
			() => isActive
		);
		endForSetPayment(
			() => setIsSaving(false),
			() => isActive
		);

		return () => {
			isActive = false;
		};
	}, [waitForPaymentFetchResult, startForFetchPayment, startForSetPayment]);

	const getDefaultPaymentMethod = useCallback(() => {
		businessLayer.dispatch(fetchDefaultPaymentMethod());
	}, [businessLayer]);

	useEffect(() => {
		getDefaultPaymentMethod();
	}, []);

	useEffect(() => {
		const { card, paypal, sepa_debit } = defaultPaymentMethod || {};

		const isCardValid =
			card &&
			dayjs().isBefore(
				dayjs({
					month: card.exp_month - 1,
					year: card.exp_year,
				}).endOf('month')
			);

		const hasAnyValidMethod = Boolean(isCardValid || paypal || sepa_debit);

		setIsValid(hasAnyValidMethod);
	}, [defaultPaymentMethod]);

	return {
		defaultPaymentMethod,
		isPaymentMethodLoading: isLoading,
		isPaymentMethodSaving: isSaving,
		isPaymentMethodValid: isValid,
		//refetch: getDefaultPaymentMethod,
		remove: () => clearLocalDefaultPaymentMethod(),
	};
};
