import { Typography } from '@mui/material';

import { useTranslation } from '@mopla/i18';

import {
	FlexColumn,
	IBookingLegLiveMapProps,
	IconMessage,
	InformationIcon,
} from '../../../';

import { LiveMapWrapper, MidRightCol } from './styled';

export const liveMapRenderer: IBookingLegLiveMapProps['render'] = ({
	mapNode,
	isDelayed,
}) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { t } = useTranslation('booking');

	return (
		<MidRightCol sx={{ mt: 2, mb: 2 }}>
			<FlexColumn gap={8}>
				<Typography variant="body1" fontWeight={600}>
					{t('live_map.title')}
				</Typography>
				<LiveMapWrapper>{mapNode}</LiveMapWrapper>
				{isDelayed && (
					<IconMessage
						color="warning.main"
						variant="body2"
						icon={<InformationIcon />}
						message={t('text.driver_delay')}
					/>
				)}
			</FlexColumn>
		</MidRightCol>
	);
};
