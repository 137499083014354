import { TLocationSuggestType } from '@mopla/data-models';

interface IMaybeWithCategory {
	categories?: Array<{
		id: string;
		name?: string;
		primary?: true;
	}>;
}

/** @see https://www.here.com/docs/bundle/geocoding-and-search-api-developer-guide/page/topics-places/places-category-system-full.html */
const KNOWN_CATEGORIES: { name: TLocationSuggestType; id: string }[] = [
	{
		name: 'railway',
		id: '400-4100-0035',
	},
	{
		name: 'railway',
		id: '400-4100-0037',
	},
	{
		name: 'railway',
		id: '400-4100-0038',
	},
	{
		name: 'railway',
		id: '400-4100-0039',
	},
	{
		name: 'stop',
		id: '400-4100-0042',
	},
	{
		name: 'stop',
		id: '400-4100-0036',
	},
];

export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue: TLocationSuggestType
): TLocationSuggestType;
export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue?: null
): TLocationSuggestType | null;
export function getLocationCategory<T extends IMaybeWithCategory>(
	obj: T,
	defaultValue: TLocationSuggestType | null = null
): TLocationSuggestType | null {
	const mainCategory = obj.categories?.find((cat: any) => cat.primary);

	if (!mainCategory) {
		return defaultValue;
	}

	const knownMainCategory = KNOWN_CATEGORIES.find(
		({ id }) => id === mainCategory.id
	);

	return knownMainCategory?.name || defaultValue;
}
