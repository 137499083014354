import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { OfflineCardAnim } from '../../molecules/Animations';
import { Content, Root } from '../../molecules/Modal/Modal.styles';

export default function OfflineModal() {
	const { t } = useTranslation('logIn');
	const rootNode = document.getElementById('portal');

	if (rootNode === null) {
		return null;
	}

	const modalContent = (
		<Root variant="black">
			<Content>
				<Box mb={2.5}>
					<OfflineCardAnim />
				</Box>
				<Typography variant="h6" sx={{ fontWeight: 700, fontSize: 24, mb: 1 }}>
					{t('modal.offline.title')}
				</Typography>
				<Typography sx={{ fontSize: 20, lineHeight: '20px' }}>
					{t('modal.offline.text')}
				</Typography>
			</Content>
		</Root>
	);

	return createPortal(modalContent, rootNode);
}
