import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'grid',
	placeContent: 'center',
	background: '#00000070',
	position: 'fixed',
	bottom: 0,
	left: 0,
	top: 0,
	right: 0,
	zIndex: 1300,
}));

export const Form = styled('div')(({ theme }) => ({
	background: theme.palette.neutral[0],
	borderRadius: 8,
	width: 'calc(100vw - 16px)',
	maxWidth: 500,
	display: 'grid',
	gridAutoFlow: 'row',
	padding: '24px 16px',
	boxSizing: 'border-box',
	'#cancelPermissionsModal': {
		width: 'max-content',
		justifySelf: 'end',
		marginBottom: 8,
	},
}));

export const Image = styled('div')(() => ({
	width: '100%',
	borderRadius: 8,
	marginBottom: 40,
	'& svg': {
		display: 'block',
		margin: '0 auto',
	},
}));

export const Title = styled('span')(({ theme }) => ({
	...theme.typography.title,
	color: theme.palette.neutral[100],
	marginBottom: 24,
	'&:empty': {
		display: 'none',
	},
}));

export const FirstMessage = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	marginBottom: 24,
	'&:empty': {
		display: 'none',
	},
}));

export const SecondMessage = styled('span')(({ theme }) => ({
	...theme.typography.body1,
	color: theme.palette.neutral[100],
	marginBottom: 40,
	'&:empty': {
		display: 'none',
	},
}));
