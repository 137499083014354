import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.neutral[0],
	borderRadius: 8,
	padding: '16px 18px',
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateColumns: '1fr max-content',
	color: theme.palette.primary.dark,

	'& > button': {
		padding: 0,
		color: theme.palette.primary.dark,
	},
}));

export const SeparatorSolid = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	marginTop: 18,
	marginBottom: 16,
	borderBottom: `1px solid ${theme.palette.primary.light}`,
	alignSelf: 'center',
}));
