import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RxDocument } from 'rxdb';

import { setLaunch as setLaunchAction } from '../actions/launchActions';
import { useBusinessLayer } from '../business-logic';

export interface ILaunch {
	wasLaunched: boolean;
}

const defaultLaunchData = { wasLaunched: false };
export const useAppLaunched = () => {
	const { db, dispatch } = useBusinessLayer();
	const [launch, setLaunch] = useState<ILaunch>(defaultLaunchData);
	const [loading, setLoading] = useState<boolean>(true);
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		const liveQuery = db['launch'].find().$;

		const subscription = liveQuery.subscribe((resultSet: RxDocument[]) => {
			setLaunch((resultSet[0]?.toJSON() as ILaunch) || defaultLaunchData);
			setLoading(false);
		});

		return () => subscription.unsubscribe();
	}, [db]);

	const set = useCallback(
		(item: ILaunch) => {
			dispatch(setLaunchAction(item));
		},
		[dispatch]
	);

	useEffect(() => {
		if (location.search.includes('verifyEmail') && !launch.wasLaunched) {
			set({ wasLaunched: true });
		}
	}, [launch.wasLaunched, location, set]);

	return {
		data: launch,
		set: set,
		checking: loading,
	};
};
