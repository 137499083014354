import { FC, useCallback } from 'react';
import { Browser } from '@capacitor/browser';

import { useTranslation } from '@mopla/i18';

import { PromotionBanner } from '../../';
import germanyMapImg from '../../icons/germanyMap.svg?url';

export const MoplaAreaBanner: FC = () => {
	const { t } = useTranslation('common');

	const handleSeeMore = useCallback(() => {
		Browser.open({ url: 'https://mopla.solutions' });
	}, []);

	return (
		<PromotionBanner
			onClick={handleSeeMore}
			img={germanyMapImg}
			title={t('mopla_area.header')}
			description={t('mopla_area.description')}
			action={t('mopla_area.link')}
		/>
	);
};
