import {
	defer,
	EMPTY,
	from,
	map,
	pipe,
	Subject,
	takeUntil,
	takeWhile,
	timer,
} from 'rxjs';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';

import { EPRODTSearchProgressStatus, IPRODTSearch } from '@mopla/data-models';

import {
	aPRODTResult,
	aPRODTUpdate,
	deletePRODTSearch,
	EPRODTActionTypes,
	initiatePRODTSearch,
	resumePRODTSearch,
} from '../../actions/prodt';
import { Effect } from '../../business-logic';
import { ofType } from '../../operators/ofType';

import { PRODTApi } from './prodtAPI';

const stopPollingSignal$ = new Subject<void>();

export const prodtSearchControllerEffect: Effect = (actions$, deps) => {
	const prodtApi = new PRODTApi(deps.api, deps.db);

	return actions$.pipe(
		ofType(EPRODTActionTypes.InitiateSearch, EPRODTActionTypes.ResumeSearch),
		exhaustMap(
			(
				action:
					| ReturnType<typeof initiatePRODTSearch>
					| ReturnType<typeof resumePRODTSearch>
			) => {
				let searchEffectObs$;

				/** Initiating the search with the POST request and outputting its id */
				if (action.type === EPRODTActionTypes.InitiateSearch) {
					searchEffectObs$ = from(prodtApi.postSearch(action.payload)).pipe(
						sendActionOnPRODTSearchReceived(actions$),
						takeWhileSearchInProgress(),
						map((prodtSearch) => prodtSearch.id)
					);
				} else {
					/** Resuming the search by its id with the PATCH request and setting further search direction (see resumePRODTSearch payload) */
					searchEffectObs$ = from(
						prodtApi.patchSearch(action.payload.id, action.payload)
					).pipe(
						switchMap(() => from(prodtApi.getSearch(action.payload.id))),
						sendActionOnPRODTSearchReceived(actions$),
						takeWhileSearchInProgress(),
						map(() => action.payload.id)
					);
				}

				return searchEffectObs$.pipe(
					switchMap((initialPRODTSearchId) => {
						/** Polling GET */
						return timer(1500, 1000).pipe(
							exhaustMap(() => from(prodtApi.getSearch(initialPRODTSearchId))),
							sendActionOnPRODTSearchReceived(actions$),
							takeWhileSearchInProgress(),
							takeUntil(stopPollingSignal$)
						);
					}),
					catchError((error) => {
						deps.Sentry.captureException(error);
						return EMPTY;
					})
				);
			}
		)
	);
};

export const deletePRODTSearchEffect: Effect = (actions$, deps) => {
	const prodtApi = new PRODTApi(deps.api, deps.db);

	return actions$.pipe(
		ofType(EPRODTActionTypes.DeleteSearch),
		exhaustMap((action: ReturnType<typeof deletePRODTSearch>) =>
			defer(async () => {
				await prodtApi.deleteSearch(action.payload.id);

				stopPollingSignal$.next();
			})
		)
	);
};

function sendActionOnPRODTSearchReceived(actions$: Subject<any>) {
	return pipe(
		tap((response: IPRODTSearch) => {
			if (response.status === EPRODTSearchProgressStatus.IN_PROGRESS) {
				actions$.next(aPRODTUpdate(response));
			} else {
				actions$.next(aPRODTResult(response));
			}
		})
	);
}

function takeWhileSearchInProgress() {
	return pipe(
		takeWhile(
			(res: IPRODTSearch) =>
				res.status === EPRODTSearchProgressStatus.IN_PROGRESS
		)
	);
}
