import { FC } from 'react';
import { Typography } from '@mui/material';

import { useTranslation } from '@mopla/i18';

import { ArrowRightIcon } from '../../';

import { Root, SeeMoreButton } from './styled';

export interface IPRODTSearchBannerProps {
	onClick: VoidFunction;
}

export const PRODTSearchBanner: FC<IPRODTSearchBannerProps> = ({ onClick }) => {
	const { t } = useTranslation('searchResults');

	return (
		<Root onClick={onClick}>
			<div className="content">
				<Typography variant="subtitle" color="primary.dark">
					{t('PRODT.banner.title')}
				</Typography>
				<Typography
					className="description"
					variant="body2"
					color="primary.dark"
				>
					{t('PRODT.banner.description')}
				</Typography>
				<SeeMoreButton
					variant="text"
					endIcon={<ArrowRightIcon />}
					disableRipple
				>
					<Typography variant="body2" fontWeight={600} color="primary">
						{t('PRODT.banner.action')}
					</Typography>
				</SeeMoreButton>
			</div>
		</Root>
	);
};
