import { LatLng } from '@mopla/utils';

export function ease(
	startCoord: LatLng,
	endCoord: LatLng,
	durationMs = 200,
	onStep: (pos: LatLng) => void,
	onComplete?: VoidFunction
) {
	const raf =
			window.requestAnimationFrame ||
			function (f) {
				window.setTimeout(f, 16);
			},
		stepCount = durationMs / 16,
		valueIncrementLat = (endCoord.coord.lat - startCoord.coord.lat) / stepCount,
		valueIncrementLng = (endCoord.coord.lng - startCoord.coord.lng) / stepCount,
		sinValueIncrement = Math.PI / stepCount;

	let currentValueLat = startCoord.coord.lat,
		currentValueLng = startCoord.coord.lng,
		currentSinValue = 0;

	function step() {
		currentSinValue += sinValueIncrement;
		currentValueLat += valueIncrementLat * Math.sin(currentSinValue) ** 2 * 2;
		currentValueLng += valueIncrementLng * Math.sin(currentSinValue) ** 2 * 2;

		if (currentSinValue < Math.PI) {
			onStep(new LatLng({ lat: currentValueLat, lng: currentValueLng }));
			raf(step);
		} else {
			onStep(endCoord);
			onComplete && onComplete();
		}
	}

	raf(step);
}
