import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	marginBottom: '24px',
}));

export const Footer = styled('footer')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const TextFooter = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
}));

export const TextFooterLink = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	fontWeight: 600,
	color: theme.palette.primary.main,
}));

export const Description = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.neutral[80],
	marginTop: '24px',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
	marginTop: '40px',
	backgroundColor: theme.palette.neutral[80],
	width: '100%',
	alignSelf: 'center',
}));

export const SubmitLevel = styled('div')(() => ({
	position: 'absolute',
	bottom: '50px',
	width: 'calc(100% - 48px)',
}));
