import { FC } from 'react';

interface ArrowLongLeftProps {
	className?: string;
}

export const ArrowLongLeftIcon: FC<ArrowLongLeftProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="16"
			viewBox="0 0 50 16"
			fill="none"
		>
			<path
				d="M0.792893 7.29289C0.40237 7.68341 0.40237 8.31658 0.792892 8.7071L7.15685 15.0711C7.54738 15.4616 8.18054 15.4616 8.57107 15.0711C8.96159 14.6805 8.96159 14.0474 8.57107 13.6569L2.91422 8L8.57107 2.34314C8.96159 1.95262 8.96159 1.31945 8.57107 0.928929C8.18054 0.538404 7.54738 0.538404 7.15685 0.928928L0.792893 7.29289ZM49.5 7L1.5 7L1.5 9L49.5 9L49.5 7Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
