import React from 'react';

export const MapIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M11.898.358a5.333 5.333 0 00-1 10.573v7.41h2v-7.41a5.335 5.335 0 00-1-10.573zM0 19.778c0-1.86 4.073-3.412 9.5-3.782v2.005c-2.122.154-3.974.496-5.372.947-.872.28-1.479.575-1.852.83.373.253.98.548 1.852.828 1.924.62 4.708 1.036 7.872 1.036 3.164 0 5.948-.416 7.872-1.036.872-.28 1.479-.575 1.852-.829-.373-.254-.98-.548-1.852-.83-1.398-.45-3.25-.792-5.372-.946v-2.005c5.428.37 9.5 1.923 9.5 3.781 0 2.135-5.373 3.865-12 3.865s-12-1.73-12-3.865zm22.07-.296l-.01.015a.062.062 0 01.01-.015zm-20.14 0c.001 0 .005.005.01.015a.054.054 0 01-.01-.015zm0 .59l.01-.014a.061.061 0 01-.01.014zm20.13-.014l.01.014c-.001 0-.005-.005-.01-.014z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};
