import { useCallback, useState } from 'react';

export const useBooleanState = (
	initialState?: boolean
): [boolean, TSetState, TSetState] => {
	const [state, setState] = useState<boolean>(() =>
		initialState !== undefined ? initialState : false
	);

	const handleSetTrue = useCallback(() => setState(true), []);
	const handleSetFalse = useCallback(() => setState(false), []);

	return [state, handleSetTrue, handleSetFalse];
};

export type TSetState = () => void;
