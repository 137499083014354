import {
	NormalizedScheduledLeg,
	ScheduledLeg,
	TDriversScheduleResponse,
} from '@mopla/data-models';

export const prepareNormalizedScheduledLegs = (
	driversSchedule: TDriversScheduleResponse
): NormalizedScheduledLeg[] => {
	return driversSchedule.map((leg) => {
		if (leg.ref === 'BREAK') {
			return {
				...leg.obj,
				scheduledLegId: leg.obj.start,
				state:
					new Date(leg.obj.end || '').getTime() > Date.now()
						? 'BREAK'
						: 'DONE_BREAK',
				ref: leg.ref,
				driverLegType: leg.driverLegType,
			};
		}

		if (leg.ref === 'VEHICLE_CHECK') {
			return {
				scheduledLegId: leg.obj.assignmentId,
				end: leg.obj.end,
				start: leg.obj.start,
				state: 'VEHICLE_CHECK' as ScheduledLeg.state,
				licencePlate: leg.obj.licencePlate,
				checkDone: leg.obj.checkDone,
				ref: leg.ref,
				driverLegType: leg.driverLegType,
			};
		}

		return {
			...leg.obj,
			ref: leg.ref,
			driverLegType: leg.driverLegType,
		};
	});
};
