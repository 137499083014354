import { TFunction } from 'react-i18next';
import { UnitTypeLongPlural } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

export function getDurationStr(
	t: TFunction,
	duration: Duration,
	exclude?: UnitTypeLongPlural[]
) {
	const _exclude = exclude || [];
	const durationSeconds = duration.seconds();
	const durationMinutes = duration.minutes();
	const durationHours = duration.hours();
	const durationDays = duration.days();

	return [
		durationDays > 0 &&
			!_exclude.includes('days') &&
			durationDays + t('common:time.short.days'),
		durationHours > 0 &&
			!_exclude.includes('hours') &&
			durationHours + t('common:time.short.hours'),
		durationMinutes > 0 &&
			!_exclude.includes('minutes') &&
			durationMinutes + t('common:time.short.minutes'),
		durationSeconds > 0 &&
			!_exclude.includes('seconds') &&
			durationSeconds + t('common:time.short.seconds'),
	]
		.filter(Boolean)
		.join(' ');
}
