import { FC } from 'react';

interface IAlarmBlinkIconProps {
	className?: string;
}

export const AlarmBlinkIcon: FC<IAlarmBlinkIconProps> = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="18"
		viewBox="0 0 24 18"
		fill="currentColor"
	>
		<path
			d="M4.36364 18V15.8824H6.10909L8.26364 8.92059C8.40909 8.46177 8.67727 8.09559 9.06818 7.82206C9.45909 7.54853 9.89091 7.41177 10.3636 7.41177H13.6364C14.1091 7.41177 14.5409 7.54853 14.9318 7.82206C15.3227 8.09559 15.5909 8.46177 15.7364 8.92059L17.8909 15.8824H19.6364V18H4.36364ZM8.4 15.8824H15.6L13.6364 9.52941H10.3636L8.4 15.8824ZM10.9091 5.29412V0H13.0909V5.29412H10.9091ZM17.4 7.91471L15.8455 6.40588L19.7182 2.67353L21.2455 4.15588L17.4 7.91471ZM18.5455 12.7059V10.5882H24V12.7059H18.5455ZM6.6 7.91471L2.75455 4.15588L4.28182 2.67353L8.15455 6.40588L6.6 7.91471ZM0 12.7059V10.5882H5.45455V12.7059H0Z"
			fill="#currentColor"
		/>
		<path className={className} d="M14 9.5H10L8 16H16L14 9.5Z" fill="none" />
	</svg>
);
