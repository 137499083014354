import { FC, ReactNode } from 'react';

import { Itinerary } from '@mopla/data-models';

import { RouteSuggestion } from '../RouteSuggestion/RouteSuggestion';

import { SearchSuggestionsListWrapper } from './styled';

export interface SearchResultProps {
	routes: Array<Itinerary>;
	onRouteClick: (itinerary: Itinerary) => void;
	searchPRODTBanner?: ReactNode;
}

export const SearchSuggestionsList: FC<SearchResultProps> = ({
	routes,
	onRouteClick,
	searchPRODTBanner,
}) => {
	return (
		<SearchSuggestionsListWrapper>
			{routes.map((itinerary) => (
				<RouteSuggestion
					onDetailsClick={() => {
						onRouteClick(itinerary);
					}}
					itinerary={itinerary}
					key={`${itinerary.duration} - ${itinerary.endDateTime}`}
				/>
			))}
			{searchPRODTBanner}
		</SearchSuggestionsListWrapper>
	);
};
