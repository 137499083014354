import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { IBooking } from '@mopla/data-models';

import {
	BookedTicketPassengersSummary,
	IBookedTicketPassengersSummaryProps,
} from '../../../';

import { PassengerInfoView } from './ui/PassengerInfoView';

export interface PassengerInfoBlockProps {
	bookedItinerary: IBooking;
}

const renderFn: IBookedTicketPassengersSummaryProps['render'] = ({
	mainPassengerName,
	attendees,
	luggage,
}) => (
	<>
		<Typography variant="body2">{mainPassengerName}</Typography>
		{attendees && <Typography variant="body2">{attendees}</Typography>}
		{luggage && <Typography variant="body2">{luggage}</Typography>}
	</>
);

export const PassengerInfoBlock: React.FC<PassengerInfoBlockProps> = ({
	bookedItinerary,
}) => {
	const mainPassenger = useMemo(() => {
		return bookedItinerary.passengerList?.find((el) => el.isMainPassenger);
	}, [bookedItinerary?.passengerList]);

	const attendeesCount =
		(bookedItinerary.passengerList?.length || 1) +
		(bookedItinerary.toddlerSeats || 0);

	return (
		<PassengerInfoView
			mainPassenger={mainPassenger}
			attendeesCount={attendeesCount}
			passengersSummary={
				<BookedTicketPassengersSummary
					booking={bookedItinerary}
					render={renderFn}
				/>
			}
		/>
	);
};
