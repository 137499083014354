import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/material';

import { ArrowRightIcon, CrossIcon, MplButton } from '../..';

import { Root, TitleWrapper } from './BookingConfirmation.styles';

interface IBookingConfirmationProps {
	ticketId: string | null;
	onOpenBookedTicket: () => void;
	onCloseBookingConfirmation: () => void;
}

export const BookingConfirmation: FC<IBookingConfirmationProps> = ({
	ticketId,
	onOpenBookedTicket,
	onCloseBookingConfirmation,
}) => {
	const { t } = useTranslation('booking');

	if (!ticketId) {
		return null;
	}

	return (
		<Root>
			<TitleWrapper>
				<Typography variant="title" color="neutral.0">
					{t('text.header.thank_you')}
				</Typography>
				<IconButton color="neutral.0" onClick={onCloseBookingConfirmation}>
					<CrossIcon />
				</IconButton>
			</TitleWrapper>
			<Typography variant="body1" color="neutral.0">
				{t('booking_confirmation.text')}
			</Typography>
			<MplButton
				className="openButton"
				onClick={onOpenBookedTicket}
				endIcon={<ArrowRightIcon />}
			>
				{t('booking_confirmation.show_ticket')}
			</MplButton>
		</Root>
	);
};
