import { useEffect, useState } from 'react';
import { Deploy } from 'cordova-plugin-ionic';
import { ISnapshotInfo } from 'cordova-plugin-ionic/dist/IonicCordova';

export const useLiveUpdateVersion = () => {
	const [liveUpdateVersion, setLiveUpdateVersion] = useState<
		ISnapshotInfo | undefined
	>(undefined);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		Deploy.getCurrentVersion()
			.then((snapshotInfo) => {
				setLiveUpdateVersion(snapshotInfo);
			})
			.catch((e) => {
				console.error('Error on getting live update version', e);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return { liveUpdateVersion, isLoading };
};
