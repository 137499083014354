import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useBreakpoints } from '@mopla/utils';

import { ArrowRightIcon, MplButton, Responsive } from '../..';

import { FooterStyled, FooterWrapper } from './PersonalDetailsForm.styles';

export interface IFormFooterProps {
	isUserProfile: boolean;
	onCancel(): void;
	isSubmitDisabled: boolean;
}

export const FormFooter: FC<IFormFooterProps> = ({
	isUserProfile,
	onCancel,
	isSubmitDisabled,
}) => {
	const { t } = useTranslation('profile');
	const { isMobile } = useBreakpoints();

	if (!isUserProfile) {
		return (
			<MplButton
				endIcon={<ArrowRightIcon />}
				data-testid="personal-detailsForm-submit-button"
				disabled={isSubmitDisabled}
				type="submit"
			>
				{t('common:button.here_we_go')}
			</MplButton>
		);
	}

	/** TODO MC-5177 fixme */
	const cancelBtn = (
		<MplButton className="cancel_button" variant="text" onClick={onCancel}>
			{t('button.abort')}
		</MplButton>
	);

	const submitBtn = (
		<MplButton
			color={isMobile ? 'primaryRevert' : 'primary'}
			data-testid="personal-detailsForm-submit-button"
			disabled={isSubmitDisabled}
			type="submit"
		>
			{t('button.save')}
		</MplButton>
	);

	return (
		<FooterWrapper>
			<Responsive mobile>{cancelBtn}</Responsive>
			<FooterStyled background={isMobile ? 'primary.main' : undefined}>
				<Responsive aboveMobile>{cancelBtn}</Responsive>
				{submitBtn}
			</FooterStyled>
		</FooterWrapper>
	);
};
