import React, { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Typography } from '@mui/material';

import { CheckboxNegative } from '../../icons/CheckboxNegative';
import { CheckboxPositive } from '../../icons/CheckboxPositive';
import { GermanyMapNew } from '../../icons/GermanyMapNew';

import {
	AdditionalContent,
	AdditionalContentText,
	Content,
	Header,
	Root,
	SeeMoreButton,
} from './ServiceStatusBlock.styles';

interface IProps {
	title: string;
	mainText: string;
	additionalText?: string;
	status: boolean;
	testId?: string;
}

export const ServiceStatusBlock: FC<IProps> = ({
	title,
	additionalText,
	mainText,
	status,
	testId = 'serviceStatusBlock',
}) => {
	const { t } = useTranslation(['common']);

	const handleSeeMore: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		Browser.open({ url: 'https://mopla.solutions' });
	};

	return (
		<Root data-testid={testId}>
			<Header>
				<Typography variant="bodyTitle" color="neutral.0">
					{title}
				</Typography>
				{status ? <CheckboxPositive /> : <CheckboxNegative />}
			</Header>
			<Content>
				<Typography variant="body1">{mainText}</Typography>
			</Content>
			{additionalText && (
				<AdditionalContent>
					<GermanyMapNew />
					<AdditionalContentText>
						<Typography variant="body2">{additionalText}</Typography>
						<SeeMoreButton variant="text" onClick={handleSeeMore}>
							<Typography variant="body2" fontWeight={600} color="primary">
								{t('common:mopla_area.link')}
							</Typography>
						</SeeMoreButton>
					</AdditionalContentText>
				</AdditionalContent>
			)}
		</Root>
	);
};
