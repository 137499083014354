import { Dispatch, ReactElement, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
//import { NavigateFunction } from 'react-router/lib/hooks';
import { Location, NavigateFunction } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { User } from '@firebase/auth';
import { applyActionCode, getAuth } from 'firebase/auth';

//import { Location } from 'history';
import { ErrorResponse, IWhoami } from '@mopla/data-models';
import { BadgeComponent, CheckmarkCircleIcon } from '@mopla/ui';

import passApi from '../../../api/passApi';

import { VerificationStatus } from './verification';
import { WelcomeStep } from './welcome';
import { CheckmarkWrapper, Title } from './WelcomeScreen.styles';

export const RejectNoUserDataTitle = (t: TFunction) => {
	return (
		<>
			<Title data-testid="registration-somethingWentWrong-text">
				{t('verify:text.title.something_went_wrong')}
			</Title>
			<Title marginBottom="24px">
				{t('verify:text.title.please_login_again')}
			</Title>
		</>
	);
};

export const SuccessNotSameDeviceAppTitle = (t: TFunction) => {
	return (
		<Title marginBottom="24px" data-testid="success-not-same-device-app-title">
			{t('verify:text.title.sign_up_right_now')}
		</Title>
	);
};

export const SuccessNotSameDeviceWebTitle = (t: TFunction) => {
	return (
		<>
			<CheckmarkWrapper>
				<CheckmarkCircleIcon />
				<Title
					marginBottom="40px"
					data-testid="success-not-same-device-web-title"
				>
					{t('verify:text.title.account_verified')}
				</Title>
				<Title marginBottom="24px">
					{t('verify:text.title.already_have_app')}
				</Title>
			</CheckmarkWrapper>
			<BadgeComponent />
			<Title marginBottom="24px">
				{t('verify:text.title.or_login_in_browser')}
			</Title>
		</>
	);
};

export const renderActiveUI = (
	location: Location,
	setLoginIsActive: Dispatch<SetStateAction<boolean>>,
	setVerifyReminderIsActive: Dispatch<SetStateAction<boolean>>,
	setRegistrationIsActive: Dispatch<SetStateAction<boolean>>,
	setShouldVerify: Dispatch<SetStateAction<boolean>>,
	setForgotPasswordIsActive: Dispatch<SetStateAction<boolean>>,
	setChangePasswordIsActive: Dispatch<SetStateAction<boolean>>,
	setPersonalDetailsIsActive: Dispatch<SetStateAction<boolean>>,
	setPushToAppIsActive: Dispatch<SetStateAction<boolean>>
) => {
	const showLogin = location.search.includes(WelcomeStep.login);
	const showRegistration = location.search.includes(WelcomeStep.registration);
	const showForgotPassword = location.search.includes(
		WelcomeStep.forgotPassword
	);
	const resetPassword = location.search.includes(WelcomeStep.resetPassword);
	const shouldVerify = location.search.includes(WelcomeStep.verifyEmail);
	const showVerifyFirst = location.search.includes(
		WelcomeStep.verifyBeforeStart
	);
	const showPersonalDetails = location.search.includes(
		WelcomeStep.personalDetails
	);
	const showPushToApp = location.search.includes(WelcomeStep.tryOurApp);

	setLoginIsActive(showLogin);
	setVerifyReminderIsActive(showVerifyFirst);
	setRegistrationIsActive(showRegistration);
	setShouldVerify(shouldVerify);
	setForgotPasswordIsActive(showForgotPassword);
	setChangePasswordIsActive(resetPassword);
	setPersonalDetailsIsActive(showPersonalDetails);
	setPushToAppIsActive(showPushToApp);
};

export const verifyEmail = (
	location: Location,
	navigate: NavigateFunction,
	user: User,
	t: TFunction,
	setLoginIsActive: Dispatch<SetStateAction<boolean>>,
	setLoginTitleComp: Dispatch<SetStateAction<ReactElement | null>>,
	verificationStatus: string,
	setVerificationStatus: Dispatch<SetStateAction<string>>,
	setVerifyFailureIsActive: Dispatch<SetStateAction<boolean>>,
	loading: boolean,
	setLoading: Dispatch<SetStateAction<boolean>>
) => {
	const auth = getAuth();
	const oobCode = new URLSearchParams(location.search).get('oobCode');
	return applyActionCode(auth, oobCode as string)
		.then(() => {
			if (auth.currentUser) {
				passApi
					.get('/api/whoami')
					.then((res: IWhoami) => {
						if (res.pleaseComplete) {
							return navigate(
								{
									pathname: '/welcome',
									search: 'personalDetails',
								},
								{ state: { title: 'sameDevice' } }
							);
						} else {
							return navigate('/home');
						}
					})
					.catch((err: ErrorResponse) => console.log(err));
				user?.reload().then(() => getAuth());
			} else {
				if (Capacitor.isNativePlatform()) {
					setLoginTitleComp(SuccessNotSameDeviceAppTitle(t));
					setVerificationStatus(VerificationStatus.successNotSameDeviceApp);
				} else {
					setLoginTitleComp(SuccessNotSameDeviceWebTitle(t));
					setVerificationStatus(VerificationStatus.successNotSameDeviceWeb);
				}
				setLoginIsActive(true);
			}
		})
		.catch(() => {
			if (!auth.currentUser) {
				setVerificationStatus(VerificationStatus.rejectNoUserData);
				setLoginTitleComp(RejectNoUserDataTitle(t));
				setLoginIsActive(true);
			} else if (
				auth.currentUser &&
				verificationStatus !== VerificationStatus.rejectNoUserData &&
				!loading
			) {
				setVerificationStatus(VerificationStatus.rejectWithUserData);
				setVerifyFailureIsActive(true);
			}
		})
		.finally(() => {
			setLoading(false);
		});
};
