import React from 'react';

export const ExternalLinkIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="13"
			fill="none"
			viewBox="0 0 12 13"
		>
			<path
				fill="currentColor"
				d="M2.5 11.07a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7v-7c0-.27.1-.5.3-.7.19-.2.43-.3.7-.3H6v1H2.5v7h7v-3.5h1v3.5c0 .28-.1.51-.3.7-.19.2-.42.3-.7.3h-7Zm2.35-2.65-.7-.7L8.8 3.07H7v-1h3.5v3.5h-1v-1.8L4.85 8.42Z"
			/>
		</svg>
	);
};
