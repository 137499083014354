import { styled, Typography } from '@mui/material';

export const GridWrapper = styled('div')(() => ({
	display: 'grid',
	alignItems: 'center',
	gridTemplateColumns: 'max-content 24px auto',
	columnGap: '8px',
	rowGap: '6px',
}));

export const IconWrapper = styled('div')(() => ({
	height: '24px',
	aspectRatio: '1',
}));

export const StationName = styled(Typography)(() => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export const RouteDotWrapper = styled('div')(() => ({
	display: 'grid',
	rowGap: '2px',
	gridColumn: '2 / 3',
}));

export const RouteDot = styled('div')(({ theme }) => ({
	height: 4,
	width: 2,
	borderRadius: 2,
	backgroundColor: theme.palette.neutral[60],
	justifySelf: 'center',
}));
