import { styled } from '@mui/material/styles';

import { FlexColumn } from '../../atoms/FlexContainer/FlexColumn';
import { FlexContainer } from '../../atoms/FlexContainer/FlexContainer';

export const Root = styled('div', {
	shouldForwardProp: (prop) => prop !== 'textAlign',
})<{
	textAlign: 'left' | 'right' | 'center';
}>(({ textAlign }) => ({
	textAlign,
	display: 'grid',
	placeContent: 'center',
	background: '#00000070',
	position: 'fixed',
	bottom: 0,
	left: 0,
	top: 0,
	right: 0,
	zIndex: 1300 /**above modals */,
}));

export const Form = styled('div')(({ theme }) => ({
	background: theme.palette.neutral[0],
	borderRadius: 8,
	width: 'calc(100vw - 16px)',
	maxWidth: 500,
	display: 'grid',
	gridGap: 24,
	gridAutoFlow: 'row',
	padding: '24px 16px',
	boxSizing: 'border-box',
	overflowY: 'auto',
}));
export const IconContainer = styled(FlexContainer)(() => ({
	'> svg': {
		width: '100%',
		height: 'auto',
	},
}));

export const ButtonWrapper = styled(FlexColumn, {
	shouldForwardProp: (prop) => prop !== 'isButtonReverse',
})<{
	isButtonReverse: boolean;
}>(({ isButtonReverse }) => ({
	gap: '16px',
	flexDirection: isButtonReverse ? 'column-reverse' : 'column',
}));
