import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RadioGroup, Typography } from '@mui/material';

import { ECancellationReasonPassengerBooking } from '@mopla/data-models';

import { InfoModal, Input, Radio } from '../../../';

import { ContentWrapper } from './styled';
import { ICancellationReasonFormState } from './types';

export interface IReasonStepProps {
	onCancel: VoidFunction;
	onSubmit: VoidFunction;
}

export const ReasonFormStep: FC<IReasonStepProps> = ({
	onCancel,
	onSubmit,
}) => {
	const { t } = useTranslation('tickets');
	const {
		watch,
		control,
		setValue,
		formState: { isValid, isSubmitting },
	} = useFormContext<ICancellationReasonFormState>();
	const [reason] = watch(['reason', 'otherReasonText']);

	useEffect(() => {
		if (reason !== ECancellationReasonPassengerBooking.OtherReason) {
			setValue('otherReasonText', '');
		}
	}, [reason, setValue]);

	return (
		<InfoModal
			title={t('cancellation_flow.reason.title')}
			infoText={
				<ContentWrapper data-testid="booking_cancellation_step_reason">
					<Controller
						name="reason"
						control={control}
						render={({ field }) => (
							<RadioGroup {...field}>
								{Object.entries(ECancellationReasonPassengerBooking).map(
									([key, reason]) => (
										<Radio
											key={key}
											label={t(`cancellation_reasons_passenger.${reason}`)}
											name="reason"
											value={reason}
											disabled={isSubmitting}
										/>
									)
								)}
							</RadioGroup>
						)}
					/>
					{reason === ECancellationReasonPassengerBooking.OtherReason && (
						<Controller
							name="otherReasonText"
							control={control}
							render={({ field }) => (
								<Input {...field} ref={null} disabled={isSubmitting} />
							)}
						/>
					)}
					<Typography variant="body1">
						{t('cancellation_flow.reason.description')}
					</Typography>
				</ContentWrapper>
			}
			submitTitle={t('button.cancel')}
			onSubmit={onSubmit}
			isSubmitDisabled={isSubmitting || !isValid}
			isSubmitting={isSubmitting}
			cancelTitle={t('button.abort')}
			onCancel={onCancel}
			isCancelDisabled={isSubmitting}
		/>
	);
};
