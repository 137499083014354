import { RxDocument } from 'rxdb';
import { defer, exhaustMap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ISubscription, ISubscriptionTicket } from '@mopla/data-models';

import {
	cancelSubscriptionResult,
	fetchSubscriptionOfferingResult,
	fetchSubscriptions,
	FetchSubscriptionsAction,
	fetchSubscriptionsResult,
	fetchTickets,
	FetchTicketsAction,
	fetchTicketsResult,
	pauseSubscriptionResult,
	resumeSubscriptionResult,
	SubscriptionActionTypes,
	TCancelSubscriptionAction,
	TPauseSubscriptionAction,
	TResumeSubscriptionAction,
} from '../actions/subscriptionActions';
import { Effect } from '../business-logic';
import { mapSubscriptionOffering } from '../mappers/mapSubscriptionOffering';
import { ofType } from '../operators/ofType';

export const fetchTicketsEffect: Effect<FetchTicketsAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(SubscriptionActionTypes.FetchTickets),
		switchMap(() =>
			defer(async () => {
				try {
					const tickets: ISubscriptionTicket[] = await dependencies.api.get(
						'/api/passengers/tickets'
					);
					const existingTickets: RxDocument<ISubscriptionTicket>[] =
						await dependencies.db['tickets'].find().exec();
					const ticketsToRemove: string[] = [];

					existingTickets.forEach((ticketDoc) => {
						const exists = tickets.find(
							(newTicket) => newTicket.id === ticketDoc.id
						);
						if (!exists) {
							ticketsToRemove.push(ticketDoc.id);
						}
					});
					await dependencies.db['tickets'].bulkUpsert(tickets);
					if (ticketsToRemove.length > 0) {
						await dependencies.db['tickets'].bulkRemove(ticketsToRemove);
					}

					return fetchTicketsResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return fetchTicketsResult({ error });
				}
			})
		)
	);

export const fetchSubscriptionsEffect: Effect<FetchSubscriptionsAction> = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(SubscriptionActionTypes.FetchSubscriptions),
		switchMap(() =>
			defer(async () => {
				try {
					const tickets: ISubscription[] = await dependencies.api.get(
						'/api/passengers/subscriptions'
					);
					const existingTickets: RxDocument<ISubscription>[] =
						await dependencies.db['subscriptions'].find().exec();
					const ticketsToRemove: string[] = [];
					existingTickets.forEach((ticketDoc) => {
						const exists = tickets.find(
							(newTicket) => newTicket.id === ticketDoc.id
						);
						if (!exists) {
							ticketsToRemove.push(ticketDoc.id);
						}
					});
					await dependencies.db['subscriptions'].bulkUpsert(tickets);
					if (ticketsToRemove.length > 0) {
						await dependencies.db['subscriptions'].bulkRemove(ticketsToRemove);
					}

					return fetchSubscriptionsResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return fetchSubscriptionsResult({ error });
				}
			})
		)
	);

export const fetchSubscriptionOfferingEffect: Effect = (
	actions$,
	dependencies
) =>
	actions$.pipe(
		ofType(SubscriptionActionTypes.FetchSubscriptionOffering),
		switchMap(() =>
			defer(async () => {
				try {
					const res = await dependencies.api.get(
						'/api/passengers/germanyTicketSubscriptionOffering'
					);
					const preparedData = mapSubscriptionOffering(res);

					await dependencies.db.upsertLocal(
						'subscriptionOffering',
						preparedData
					);

					return fetchSubscriptionOfferingResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return fetchSubscriptionOfferingResult({ error });
				}
			})
		)
	);

export const pauseSubscriptionEffect: Effect = (actions$, dependencies) => {
	return actions$.pipe(
		ofType(SubscriptionActionTypes.PauseSubscription),
		exhaustMap((action: TPauseSubscriptionAction) => {
			return defer(async () => {
				try {
					const { pauseEnd, pauseStart, subscriptionId } = action.payload;

					await dependencies.api.post('/api/command/pauseSubscription', {
						pauseEnd,
						pauseStart,
						subscriptionId,
					});

					actions$.next(fetchSubscriptions());
					actions$.next(fetchTickets());

					return pauseSubscriptionResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return pauseSubscriptionResult({ error });
				}
			});
		})
	);
};

export const resumeSubscriptionEffect: Effect = (actions$, dependencies) => {
	return actions$.pipe(
		ofType(SubscriptionActionTypes.ResumeSubscription),
		exhaustMap((action: TResumeSubscriptionAction) => {
			return defer(async () => {
				try {
					const { startDate, subscriptionId } = action.payload;

					await dependencies.api.post('/api/command/resumeSubscription', {
						subscriptionId,
						startDate,
					});

					actions$.next(fetchSubscriptions());
					actions$.next(fetchTickets());

					return resumeSubscriptionResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return resumeSubscriptionResult({ error });
				}
			});
		})
	);
};

export const cancelSubscriptionEffect: Effect = (actions$, dependencies) => {
	return actions$.pipe(
		ofType(SubscriptionActionTypes.CancelSubscription),
		exhaustMap((action: TCancelSubscriptionAction) => {
			return defer(async () => {
				try {
					const {
						subscriptionId,
						cancellationReason,
						cancellationReasonDescription,
					} = action.payload;

					await dependencies.api.post('/api/command/cancelSubscription', {
						subscriptionId,
						cancellationReason,
						cancellationReasonDescription,
					});

					actions$.next(fetchSubscriptions());
					actions$.next(fetchTickets());

					return cancelSubscriptionResult();
				} catch (error: any) {
					dependencies.Sentry.captureException(error);
					return cancelSubscriptionResult({ error });
				}
			});
		})
	);
};
