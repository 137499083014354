/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

export enum TransportType {
	WALK = 'WALK',
	TRANSFER_WALK = 'TRANSFER_WALK',
	LBT = 'LBT',
	LBODT = 'LBODT',
	LBODTFLEX = 'LBODTFLEX',
	PRODT = 'PRODT',
}
