import { styled } from '@mui/material/styles';

import { getThemeColor, TMoplaColors } from '@mopla/mui';

const excludedProps = ['solid', 'color', 'left', 'position', 'bgColor'];

export const Separator = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as string),
})<{ solid?: boolean; color?: TMoplaColors; size?: number }>(
	({ theme, solid, color, size = 20 }) => ({
		background: color
			? getThemeColor(color, theme)
			: theme.palette.primary.main,
		display: 'grid',
		gridTemplateColumns: solid ? '1fr' : 'max-content 1fr max-content',
		height: size,
		width: '100%',
	})
);
export const Dash = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as string),
})<{ color?: TMoplaColors }>(({ theme, color }) => ({
	height: 0,
	width: '100%',
	border: `1px dashed ${
		color ? getThemeColor(color, theme) : theme.palette.primary.main
	}`,
	alignSelf: 'center',
}));
export const SideCircle = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as string),
})<{
	left?: boolean;
	color?: TMoplaColors;
	size?: number;
}>(({ theme, left, color, size = 20 }) => ({
	background: color ? getThemeColor(color, theme) : theme.palette.primary.main,
	height: size,
	width: size,
	borderRadius: '50%',
	margin: left ? `0 0 0 -${size / 2}px` : `0 -${size / 2}px 0 0`,
}));
export const Wrapper = styled('div', {
	shouldForwardProp: (prop) => !excludedProps.includes(prop as string),
})<{
	bgColor: TMoplaColors;
	position: 'top' | 'center' | 'bottom';
}>(({ theme, bgColor, position }) => ({
	padding: position === 'center' ? '16px 16px 24px' : 16,
	borderRadius:
		position === 'top'
			? '8px 8px 0 0'
			: position === 'bottom'
			? '0 0 8px 8px '
			: 0,
	background: bgColor
		? getThemeColor(bgColor, theme)
		: theme.palette.neutral[0],

	'&:last-of-type': {
		borderRadius: '0 0 8px 8px ',
	},
}));

export const TicketWrapper = styled('div')<{
	ownerState?: { disabled?: boolean };
}>(({ ownerState }) => ({
	display: 'grid',
	flexDirection: 'column',
	alignItems: 'flex-end',
	width: '100%',
	boxSizing: 'border-box',
	borderRadius: 8,
	...(ownerState?.disabled && { opacity: 0.8 }),
}));
