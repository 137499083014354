import React, { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppListener } from '../../app/appListener';
import { NotificationListener } from '../../app/notificationListener';
import { AccountRemoved } from '../pages/accountRemoved/AccountRemoved';
import { DTicket } from '../pages/dticket/DTicket';
import { RootPage } from '../pages/root/RootPage';
import { SpenditPage } from '../pages/spendit/SpenditPage';
import { WelcomeScreen } from '../pages/welcome/WelcomeScreen';

import LazyRoute from './LazyRoute';
import PrivateRoute from './PrivateRoute';
import WelcomeRoute from './WelcomeRoute';

const HomeScreen = lazy(
	() => import(/*webpackChunkName: "Home"*/ '../pages/home/Home')
);

const AppRoutes: React.FunctionComponent = () => {
	return (
		<React.Suspense fallback={null}>
			<BrowserRouter>
				<AppListener />
				<NotificationListener />
				<Routes>
					<Route element={<RootPage />}>
						<Route
							path="home/*"
							element={
								<PrivateRoute>
									<LazyRoute>
										<HomeScreen />
									</LazyRoute>
								</PrivateRoute>
							}
						/>
						<Route
							path="welcome"
							element={
								<WelcomeRoute>
									<WelcomeScreen />
								</WelcomeRoute>
							}
						/>
						<Route
							path="spendit"
							element={
								<PrivateRoute>
									<SpenditPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="account-deleted"
							element={
								<WelcomeRoute>
									<AccountRemoved />
								</WelcomeRoute>
							}
						/>
						<Route
							path="deutschlandticket"
							element={
								<WelcomeRoute>
									<DTicket />
								</WelcomeRoute>
							}
						/>

						{/*<Route path="*" element={<Navigate to="/home" />} />*/}

						<Route index element={<Navigate to="/home" replace={true} />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</React.Suspense>
	);
};

export default AppRoutes;
