import { Theme } from '@mui/material';

import { MOPLA_COLORS, TMoplaColors } from './configureTheme';

export const getThemeColor = (color: TMoplaColors, theme: Theme) => {
	if (MOPLA_COLORS.includes(color as TMoplaColors)) {
		const [category, shade] = color.split('.') as [
			keyof Theme['palette'],
			string
		];
		// Ensure that 'shade' exists on 'category'
		const paletteCategory = theme.palette[category];
		if (
			paletteCategory &&
			typeof paletteCategory === 'object' &&
			shade in paletteCategory
		) {
			return paletteCategory[shade as keyof typeof paletteCategory];
		}
	}
	return theme.palette.primary.main;
};
