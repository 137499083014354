import React, { CSSProperties } from 'react';

import { Container } from './FlexContainer.styles';

interface Props {
	children: React.ReactNode;
	className?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'stretch'
		| string;
	style?: CSSProperties | undefined;
	gap?: CSSProperties['gap'];
	testId?: string;
}

export const FlexContainer: React.FC<Props> = ({
	children,
	className = 'stretch',
	style,
	gap,
	testId = 'flexContainer',
}) => {
	return (
		<Container
			data-testid={testId}
			className={className}
			style={style}
			gap={gap}
		>
			{children}
		</Container>
	);
};
