import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Controller,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { MobileStepper, Typography } from '@mui/material';
import * as Sentry from '@sentry/capacitor';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';

import {
	checkBannedEmail,
	emailSendTimeLSManager,
	TCheckBannedEmailResultAction,
	useBusinessLayer,
	UserActionTypes,
	useSpenditToken,
} from '@mopla/business-logic';
import {
	FIREBASE_EMAIL_EXISTS_ERR,
	RegistrationFields,
	SuccessStep,
} from '@mopla/constants';
import {
	ArrowRightIcon,
	AttentionIcon,
	ErrorModal,
	Input,
	Loader,
	MplButton,
	PasswordInput,
} from '@mopla/ui';
import PasswordValidationConditions from '@mopla/ui/validation/PasswordValidationConditions';

import passApi from '../../../api/passApi';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';
import { ResendEmail } from '../ResendEmailScreen/ResendEmail';
import { TermsAndConditions } from '../TermsOfUse/TermsOfUse';

import {
	CheckboxesWrapper,
	FormStyled,
	Root,
	SubmitLevel,
	TextWithLinkWrapper,
	Title,
} from './RegistrationForm.styles';
import RegistrationValidationSchema from './RegistrationValidation';
import { VerifyEmailSentView } from './VerifyEmailSentView';

/** TODO enums don't work really good inside interfaces for react-hook-form (types might be broken). Better avoid using enums here */
interface IFormInput {
	[RegistrationFields.email]: string;
	[RegistrationFields.password]: string;
	emailBanned?: string;
	serverError?: string;
}

interface RegistrationFormProps {
	onSuccess: Dispatch<SetStateAction<string>>;
	showVerifyEmailSent: boolean;
	showResendVerifyEmail: boolean;
	regEmail: string;
	setRegEmail: Dispatch<SetStateAction<string>>;
	activeRegStep: number; //TODO better to use enum and give names to the steps
	setActiveRegStep: Dispatch<SetStateAction<number>>;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
	regEmail,
	setRegEmail,
	onSuccess,
	showVerifyEmailSent,
	showResendVerifyEmail,
	activeRegStep,
	setActiveRegStep,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const bl = useBusinessLayer();
	const [showWarning, setShowWarning] = useState(false);
	const [loading, setLoading] = useState(false);
	const [checkPasswordErrors, setCheckPasswordErrors] = useState(false);
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const [termsOfUse, setTermsOfUse] = useState(false);
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const { t } = useTranslation([
		'registration',
		'logIn',
		'verify',
		'validation',
	]);
	const auth = getAuth();
	const { connectTokenToUser } = useSpenditToken();

	useEffect(() => {
		const { prevPath = '' } = (location.state as Record<string, string>) || {};
		const emailSendTime = emailSendTimeLSManager.get() || 0;
		const now = new Date().getTime();

		if (
			showVerifyEmailSent &&
			prevPath === 'verifyBeforeStart' &&
			now - emailSendTime <= 60000
		) {
			setShowWarning(true);
		}
	}, [showVerifyEmailSent, location.state]);

	const methods = useForm<IFormInput>({
		defaultValues: {
			[RegistrationFields.email]: '',
			[RegistrationFields.password]: '',
		},
		resolver: yupResolver(RegistrationValidationSchema),
		mode: 'onBlur',
	});
	const {
		formState,
		handleSubmit,
		getValues,
		watch,
		trigger,
		resetField,
		clearErrors,
		setError,
	} = methods;

	useEffect(() => {
		clearErrors(RegistrationFields.password);
	}, [activeRegStep, clearErrors]);

	const onSubmitRegistration: SubmitHandler<IFormInput> = async (data) => {
		setLoading(true);
		const { newEmail, newPassword } = data;

		try {
			const userCredentials = await createUserWithEmailAndPassword(
				auth,
				newEmail,
				newPassword
			);

			const { user } = userCredentials;
			await user.getIdTokenResult();

			await passApi.post('/api/passengers/create', {
				authenticationProviderId: user.uid,
				email: user.email,
			});

			const isJobticket = !!localStorage.getItem('spenditToken');
			if (isJobticket) {
				connectTokenToUser();
			}

			emailSendTimeLSManager.set(new Date().getTime());
			setRegEmail(newEmail);
			setActiveRegStep(0);
			onSuccess(SuccessStep.registrationComplete);

			/** TODO Augment window type */
			const windowRef = window as any;
			windowRef.dataLayer = windowRef.dataLayer || [];
			windowRef.dataLayer.push({ event: 'registerSuccess' });
		} catch (err) {
			setActiveRegStep(0);
			let errorText = 'email.common';

			if ((err as FirebaseError).code === FIREBASE_EMAIL_EXISTS_ERR) {
				errorText = 'email.exist';
			}

			setError(RegistrationFields.email, { message: errorText });

			Sentry.captureException(err);
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const onSubmitResendEmail = () => {
		const user = auth.currentUser;
		if (user) {
			setLoading(true);
			passApi
				.post('/api/command/verifyEmail', { app: 'PASSENGER' })
				.then(() => {
					emailSendTimeLSManager.set(new Date().getTime());
					onSuccess(SuccessStep.registrationComplete);
					setLoading(false);
					return navigate(
						{ pathname: '/welcome', search: 'registration' },
						{ state: { prevPath: 'null' } }
					);
				});
		}
	};

	const handleCheckBannedEmail = (email: string) => {
		return new Promise<boolean>((resolve, reject) => {
			setLoading(true);
			bl.dispatch(checkBannedEmail({ email }));

			bl.watchActions({
				types: UserActionTypes.CheckBannedEmailResult,
				callback: (_action) => {
					const action = _action as TCheckBannedEmailResultAction;

					setLoading(false);

					if (action.payload?.error) {
						setError('serverError', {});
						return reject();
					}

					resolve(!action.payload.isValid);
				},
			});
		});
	};

	const handleBannedEmailOkClick = useCallback(() => {
		clearErrors('emailBanned');
		resetField(RegistrationFields.email);
		resetField(RegistrationFields.password);
		setActiveRegStep(0);
	}, [clearErrors, resetField, setActiveRegStep]);

	const handleSubmitForm = async () => {
		const email = getValues(RegistrationFields.email);

		if (activeRegStep === 0) {
			const isEmailValid = await trigger(RegistrationFields.email);
			const isEmailBanned = await handleCheckBannedEmail(email);

			if (isEmailBanned) {
				setError('emailBanned', {});
				return;
			}

			if (!isEmailValid) {
				return;
			}

			setActiveRegStep(1);
		}

		if (activeRegStep === 1) {
			handleSubmit(onSubmitRegistration)();
		}
	};

	const handleSkipServerError = useCallback(() => {
		clearErrors('serverError');
	}, [clearErrors]);

	if (showVerifyEmailSent) {
		return (
			<VerifyEmailSentView
				email={regEmail}
				onResend={() => onSuccess(SuccessStep.verifyEmailResend)}
				showWarning={showWarning}
			/>
		);
	}

	if (showResendVerifyEmail) {
		return (
			<ResendEmail
				onSubmit={onSubmitResendEmail}
				email={regEmail || String(auth?.currentUser?.email)}
				loading={loading}
			/>
		);
	}

	if (loading) {
		return <Loader delay={100} />;
	}

	return (
		<>
			{activeRegStep === 0 && (
				<Title data-testid="registrationForm-email-title">
					{t('text.title1')}
				</Title>
			)}
			{activeRegStep === 1 && (
				<Title data-testid="registrationForm-password-title">
					{t('text.title2')}
				</Title>
			)}
			{formState.errors.emailBanned && (
				<ErrorModal
					onSubmit={handleBannedEmailOkClick}
					title={t('validation:email.banned.title')}
					infoText={t('validation:email.banned.text')}
					submitTitle={t('validation:email.banned.button')}
				/>
			)}
			{formState.errors.serverError && (
				<ErrorModal onSubmit={handleSkipServerError} />
			)}
			<FormProvider {...methods}>
				<FormStyled
					autoComplete="off"
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmitForm();
					}}
				>
					<Root marginTop={activeRegStep === 0 ? '32px' : 'auto'}>
						{activeRegStep === 0 && (
							<Controller
								name="newEmail"
								render={({ field }) => (
									<Input
										{...field}
										onChange={(e) => {
											e.target.value = e.target.value.trim();
											field.onChange(e);
										}}
										error={Boolean(formState.errors.newEmail?.message)}
										label={t('label.email')}
										ref={null}
										helperText={
											formState.errors.newEmail && (
												<>
													<AttentionIcon width={15} height={15} />
													<span data-testid="registrationForm-email-validation-error">
														{t(
															`validation:${formState.errors.newEmail?.message}`
														)}
													</span>
												</>
											)
										}
										inputProps={{
											'data-testid': 'registrationForm-email-textField',
										}}
									/>
								)}
							/>
						)}
						{activeRegStep === 1 && (
							<>
								<Controller
									name="newPassword"
									render={({ field }) => (
										<PasswordInput
											{...field}
											autoComplete="new-password"
											error={Boolean(formState.errors.newPassword?.message)}
											onChange={(e) => {
												e.target.value = e.target.value.trim();
												field.onChange(e);
											}}
											onBlur={() => {
												field.onBlur();
												setCheckPasswordErrors(true);
											}}
											label={t('label.password')}
											ref={null}
											helperText={
												formState.errors.newPassword?.message && (
													<>
														<AttentionIcon width={15} height={15} />
														<span data-testid="registrationForm-password-validation-error">
															{t(
																`validation:${formState.errors.newPassword.message}`
															)}
														</span>
													</>
												)
											}
											inputProps={{
												'data-testid': 'registrationForm-password-textField',
											}}
										/>
									)}
								/>
								<PasswordValidationConditions
									password={watch('newPassword')}
									checkPasswordErrors={checkPasswordErrors}
									setCheckPasswordErrors={setCheckPasswordErrors}
									setPasswordIsValid={setPasswordIsValid}
								/>
							</>
						)}
						<TextWithLinkWrapper
							marginTop={activeRegStep === 1 ? '32px' : 'auto'}
						>
							{activeRegStep === 0 && (
								<>
									<Typography variant="body1">
										{t('text.already_have_account')}
									</Typography>
									<MplButton
										variant="text"
										fullWidth={false}
										onClick={() =>
											navigate({ pathname: '/welcome', search: 'login' })
										}
									>
										{t('logIn:button.logIn')}
									</MplButton>
								</>
							)}
							{activeRegStep === 1 && (
								<CheckboxesWrapper>
									<TermsAndConditions
										checked={termsOfUse}
										toggleCheckbox={() => setTermsOfUse((prev) => !prev)}
									/>
									<PrivacyPolicy
										checked={privacyPolicy}
										toggleCheckbox={() => setPrivacyPolicy((prev) => !prev)}
									/>
								</CheckboxesWrapper>
							)}
						</TextWithLinkWrapper>
						<MobileStepper
							variant="dots"
							steps={2}
							position="static"
							activeStep={activeRegStep}
							nextButton={null}
							backButton={null}
						/>
						<SubmitLevel>
							<MplButton
								endIcon={<ArrowRightIcon />}
								data-testid="registration-create-account-submit-button"
								disabled={
									activeRegStep === 0
										? !watch('newEmail') || Boolean(formState.errors.newEmail)
										: !passwordIsValid || !termsOfUse || !privacyPolicy
								}
								type="submit"
							>
								{activeRegStep === 0
									? t('button.continue')
									: t('button.create_account')}
							</MplButton>
						</SubmitLevel>
					</Root>
				</FormStyled>
			</FormProvider>
		</>
	);
};

export default RegistrationForm;
