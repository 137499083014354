import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@mui/material';
import dayjs from 'dayjs';

import { useUser } from '@mopla/business-logic';
import { localStorageManager } from '@mopla/utils';

import { ImpairedCardAnim, Modal, MplButton } from '../../';

import { ItemWrapper, ModalText, ModalTitle, StyledCheckbox } from './styled';

export const impairedCardExpireReminderStorage = localStorageManager<boolean>(
	'ImpairedCardExpirationReminder__showed'
);

export const wasShownSessionStorage = localStorageManager<boolean>(
	'ImpairedCardExpirationReminder__was_showed',
	window.sessionStorage
);

export const ImpairedCardExpirationReminder: FC = () => {
	const { t } = useTranslation('booking');
	const { userData } = useUser();
	const [showModal, setShowModal] = useState(false);
	const [notShowAgain, setNotShowAgain] = useState(false);
	const [daysBeforeStatusExpired, setDaysBeforeStatusExpired] = useState<
		number | null
	>(null);

	const toggleNotShownAgain = useCallback(() => setNotShowAgain((p) => !p), []);

	const onConfirm = useCallback(() => {
		setShowModal(false);

		if (notShowAgain) {
			impairedCardExpireReminderStorage.set(true);
		}
	}, [notShowAgain]);

	useEffect(() => {
		if (
			!wasShownSessionStorage.get() &&
			!impairedCardExpireReminderStorage.get() &&
			userData?.disabilityStatusValidTo &&
			dayjs().isBefore(userData.disabilityStatusValidTo)
		) {
			const daysBeforeStatusExpired =
				dayjs(userData.disabilityStatusValidTo).diff(dayjs(), 'day') + 1;

			if (daysBeforeStatusExpired <= 30) {
				wasShownSessionStorage.set(true);
				setShowModal(true);
				setDaysBeforeStatusExpired(daysBeforeStatusExpired);
			}
		}
	}, [userData]);

	if (!showModal || !Number.isInteger(daysBeforeStatusExpired)) {
		return null;
	}

	return (
		<Portal
			key={'ImpairedCardExpirationReminder_portal'}
			container={document.getElementById('portal')}
		>
			<Modal
				key={'ImpairedCardExpirationReminder_modal'}
				open={true}
				onClose={() => null}
				animationElement={<ImpairedCardAnim />}
				mainContent={
					<>
						<ModalTitle>
							{t('modal.impaired_card_expire_reminder.title', {
								count: daysBeforeStatusExpired as number,
							})}
						</ModalTitle>
						<ModalText>
							{t('modal.impaired_card_expire_reminder.text')}
						</ModalText>
						<ItemWrapper marginBottom="24px">
							<StyledCheckbox
								color="primary"
								checked={notShowAgain}
								id="ImpairedCardExpirationReminder_check"
								onChange={toggleNotShownAgain}
							/>
							<label htmlFor="ImpairedCardExpirationReminder_check">
								{t('modal.impaired_card_expire_reminder.not_show_again')}
							</label>
						</ItemWrapper>
					</>
				}
				footerComp={
					<MplButton onClick={onConfirm}>
						{t('modal.impaired_card_expire_reminder.ok')}
					</MplButton>
				}
			/>
		</Portal>
	);
};
