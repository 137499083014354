import { ReactNode } from 'react';

import { IItineraryPayment } from '@mopla/data-models';
import { getActualPrice } from '@mopla/utils';

import { defaultRenderer } from './defaultRenderer';
import { IPriceStringProps, PriceString } from './PriceString';

export interface IPaymentBlockProps<
	T extends { payment: IItineraryPayment | null }
> {
	entity: T | null;
	single?: boolean;
	render?: (paymentString: ReactNode) => ReactNode;
	renderPaymentParts?: IPriceStringProps['render'];
}

export const PaymentBlock = <T extends { payment: IItineraryPayment | null }>({
	entity,
	single,
	render = defaultRenderer,
	renderPaymentParts,
}: IPaymentBlockProps<T>) => {
	if (!entity?.payment) {
		return null;
	}

	const currentPrice = getActualPrice(entity.payment);
	const showPreviousPrice = !!entity.payment.overwrittenPaymentAmount;
	const previousPrice = showPreviousPrice
		? entity.payment.paymentAmount
		: undefined;

	return (
		<>
			{render(
				<PriceString
					price={currentPrice}
					hasPriceTariff={!entity.payment.paymentInformationAvailable}
					single={single}
					previousPrice={previousPrice}
					render={renderPaymentParts}
				/>
			)}
		</>
	);
};
