import {
	IPRODTSearch,
	ProdtSearchPatchParams,
	ProdtSearchPostParams,
} from '@mopla/data-models';
import { actionGenerator } from '@mopla/utils';

export enum EPRODTActionTypes {
	InitiateSearch = '[PRODT] Initiate search',
	ResumeSearch = '[PRODT] Resume search',
	Result = '[PRODT] Result',
	Update = '[PRODT] Update',
	DeleteSearch = '[PRODT] Delete search',
	SearchError = '[PRODT] Search Error',
}

export const initiatePRODTSearch = actionGenerator<
	EPRODTActionTypes.InitiateSearch,
	ProdtSearchPostParams
>(EPRODTActionTypes.InitiateSearch);

export const resumePRODTSearch = actionGenerator<
	EPRODTActionTypes.ResumeSearch,
	ProdtSearchPatchParams & IPRODTSearchId
>(EPRODTActionTypes.ResumeSearch);

export const deletePRODTSearch = actionGenerator<
	EPRODTActionTypes.DeleteSearch,
	IPRODTSearchId
>(EPRODTActionTypes.DeleteSearch);

export const searchPRODTError = actionGenerator<EPRODTActionTypes.SearchError>(
	EPRODTActionTypes.SearchError
);

export const aPRODTUpdate = actionGenerator<
	EPRODTActionTypes.Update,
	IPRODTSearch
>(EPRODTActionTypes.Update);

export const aPRODTResult = actionGenerator<
	EPRODTActionTypes.Result,
	IPRODTSearch
>(EPRODTActionTypes.Result);

interface IPRODTSearchId {
	id: string;
}

export type TInitiatePRODTSearchAction = ReturnType<typeof initiatePRODTSearch>;
export type TResumePRODTSearchAction = ReturnType<typeof resumePRODTSearch>;
export type TDeletePRODTSearchAction = ReturnType<typeof deletePRODTSearch>;
export type TPRODTUpdateAction = ReturnType<typeof aPRODTUpdate>;
export type TPRODTResultAction = ReturnType<typeof aPRODTResult>;
export type TSearchPRODTErrorAction = ReturnType<typeof searchPRODTError>;

export type TPRODTActions =
	| TInitiatePRODTSearchAction
	| TResumePRODTSearchAction
	| TDeletePRODTSearchAction
	| TPRODTUpdateAction
	| TPRODTResultAction
	| TSearchPRODTErrorAction;
