import {
	CurrencyCode,
	DiscountState,
	ILeg,
	NamedLocation,
	PaymentForm,
	PaymentState,
	TransportType,
} from '../lib/api';

export const oneLeg: ILeg = {
	legId: 'cea2113c-c8ab-43b5-a65a-43e9e732301d',
	startDateTime: '2024-09-12T12:25:18.034Z',
	startDateTimeWindowStart: '2024-09-12T12:25:18.034Z',
	startDateTimeWindowEnd: '2024-09-12T12:40:18.034Z',
	startDateTimeFix: false,
	endDateTime: '2024-09-12T13:03:20.034Z',
	endDateTimeWindowStart: '2024-09-12T12:25:18.034Z',
	endDateTimeWindowEnd: '2024-09-12T13:03:20.034Z',
	endDateTimeFix: false,
	cancellationFreeUntil: '2024-09-12T11:25:18.034Z',
	costOfCancellation: 1761,
	duration: 2282.0,
	distance: 6751.5,
	mode: TransportType.LBODTFLEX,
	modeOrigin: 'CAR',
	route: '',
	headsign: null,
	routeId: null,
	agencyId: null,
	agencyName: 'Vetter',
	tripShortName: null,
	tripId: null,
	from: {
		name: 'Saale-Center, Rennbahnring 9, 06124 Halle (Saale), Deutschland',
		stopId: null,
		lng: 11.941506,
		lat: 51.480104,
		arrival: '2024-09-12T12:25:18.034Z',
		departure: '2024-09-12T12:25:18.034Z',
		locationType: NamedLocation.type.ADDRESS,
	},
	to: {
		name: '06116, Halle (Saale), Sachsen-Anhalt, Deutschland',
		stopId: null,
		lng: 12.030904,
		lat: 51.476498,
		arrival: '2024-09-12T13:03:20.034Z',
		departure: '2024-09-12T13:03:20.034Z',
		locationType: NamedLocation.type.ADDRESS,
	},
	payment: {
		paymentAmount: 1761,
		currency: CurrencyCode.EUR,
		paymentForm: PaymentForm.DIGITAL,
		paymentState: PaymentState.TO_PAY,
		overwrittenPaymentAmount: null,
		paymentInformationAvailable: true,
	},
	intermediateStops: [],
	pricings: [
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 1761,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NO_DISCOUNT,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 3537,
			currency: CurrencyCode.EUR,
			discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 5313,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
		},
		{
			paymentHandlerCompanyName: 'MoPla Solutions GmbH',
			paymentHandlerCompanyType: 'MOPLA',
			paymentInformationAvailable: true,
			amount: 7019,
			currency: CurrencyCode.EUR,
			discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
		},
	],
	paymentOptions: [PaymentForm.DIGITAL],
	cancellationRules: [
		{
			secondsBeforeRideStart: 3600,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NO_DISCOUNT,
			cost: 0,
		},
		{
			secondsBeforeRideStart: 0,
			currency: CurrencyCode.EUR,
			discount: DiscountState.NO_DISCOUNT,
			cost: 1761,
		},
	],
	lbodtflexLabel: 'AnrufbusFlex',
};

export const multipleLegs: ILeg[] = [
	{
		legId: 'cea2113c-c8ab-43b5-a65a-43e9e732301d',
		startDateTime: '2024-09-12T12:25:18.034Z',
		startDateTimeWindowStart: '2024-09-12T12:25:18.034Z',
		startDateTimeWindowEnd: '2024-09-12T12:40:18.034Z',
		startDateTimeFix: false,
		endDateTime: '2024-09-12T13:03:20.034Z',
		endDateTimeWindowStart: '2024-09-12T12:25:18.034Z',
		endDateTimeWindowEnd: '2024-09-12T13:03:20.034Z',
		endDateTimeFix: false,
		cancellationFreeUntil: '2024-09-12T11:25:18.034Z',
		costOfCancellation: 1761,
		duration: 2282.0,
		distance: 6751.5,
		mode: TransportType.LBODTFLEX,
		modeOrigin: 'CAR',
		route: '',
		headsign: null,
		routeId: null,
		agencyId: null,
		agencyName: 'Vetter',
		tripShortName: null,
		tripId: null,
		from: {
			name: 'Saale-Center, Rennbahnring 9, 06124 Halle (Saale), Deutschland',
			stopId: null,
			lng: 11.941506,
			lat: 51.480104,
			arrival: '2024-09-12T12:25:18.034Z',
			departure: '2024-09-12T12:25:18.034Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		to: {
			name: '06116, Halle (Saale), Sachsen-Anhalt, Deutschland',
			stopId: null,
			lng: 12.030904,
			lat: 51.476498,
			arrival: '2024-09-12T13:03:20.034Z',
			departure: '2024-09-12T13:03:20.034Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		payment: {
			paymentAmount: 1761,
			currency: CurrencyCode.EUR,
			paymentForm: PaymentForm.DIGITAL,
			paymentState: PaymentState.TO_PAY,
			overwrittenPaymentAmount: null,
			paymentInformationAvailable: true,
		},
		paymentOptions: [PaymentForm.DIGITAL],
		intermediateStops: [],
		pricings: [
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 1761,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 3537,
				currency: CurrencyCode.EUR,
				discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 5313,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 7019,
				currency: CurrencyCode.EUR,
				discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
			},
		],
		cancellationRules: [
			{
				secondsBeforeRideStart: 3600,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 0,
			},
			{
				secondsBeforeRideStart: 0,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 1761,
			},
		],
		lbodtflexLabel: 'AnrufbusFlex',
	},
	{
		legId: '6db7f367-9d81-4c9c-868c-1fba9f2a9aea',
		startDateTime: '2024-09-09T13:50:44.141Z',
		startDateTimeWindowStart: '2024-09-09T13:50:44.141Z',
		startDateTimeWindowEnd: '2024-09-09T14:05:44.141Z',
		overwrittenStartTimeWindowStart: '2024-09-09T12:00:44.141Z',
		overwrittenStartTimeWindowEnd: '2024-09-09T12:15:44.141Z',
		startDateTimeFix: false,
		endDateTime: '2024-09-09T14:21:14.141Z',
		endDateTimeWindowStart: '2024-09-09T13:50:44.141Z',
		endDateTimeWindowEnd: '2024-09-09T14:21:14.141Z',
		overwrittenEndTimeWindowStart: '2024-09-09T12:00:44.141Z',
		overwrittenEndTimeWindowEnd: '2024-09-09T12:31:14.141Z',
		endDateTimeFix: false,
		cancellationFreeUntil: '2024-09-09T12:50:44.141Z',
		costOfCancellation: 900,
		duration: 1830.0,
		distance: 4729.5,
		mode: TransportType.LBODTFLEX,
		modeOrigin: 'CAR',
		route: '',
		headsign: null,
		routeId: null,
		agencyId: null,
		agencyName: 'Vetter',
		tripShortName: null,
		tripId: null,
		from: {
			name: 'Halle (Saale), Sachsen-Anhalt, Deutschland',
			stopId: null,
			lng: 11.974929,
			lat: 51.482197,
			arrival: '2024-09-09T13:50:44.141Z',
			departure: '2024-09-09T13:50:44.141Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		to: {
			name: '06116, Halle (Saale), Sachsen-Anhalt, Deutschland',
			stopId: null,
			lng: 12.030904,
			lat: 51.476498,
			arrival: '2024-09-09T14:21:14.141Z',
			departure: '2024-09-09T14:21:14.141Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		payment: {
			paymentAmount: 900,
			currency: CurrencyCode.EUR,
			paymentForm: PaymentForm.DIGITAL,
			paymentState: PaymentState.TO_PAY,
			overwrittenPaymentAmount: null,
			paymentInformationAvailable: true,
		},
		paymentOptions: [PaymentForm.DIGITAL],
		intermediateStops: [],
		pricings: [
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 900,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 1900,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NON_DISCOUNTED_TICKET_PRESENT,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 2900,
				currency: CurrencyCode.EUR,
				discount: DiscountState.PERSONAL_DISCOUNT_AVAILABLE,
			},
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 3900,
				currency: CurrencyCode.EUR,
				discount: DiscountState.DISCOUNTED_TICKET_PRESENT,
			},
		],
		cancellationRules: [
			{
				secondsBeforeRideStart: 3600,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 0,
			},
			{
				secondsBeforeRideStart: 0,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 900,
			},
		],
		lbodtflexLabel: 'AnrufbusFlex',
	},
	{
		legId: '8ffe6114-c24a-4fc1-bea6-11bde39aa459',
		startDateTime: '2024-09-09T13:10:28Z',
		startDateTimeWindowStart: '2024-09-09T13:08:58Z',
		startDateTimeWindowEnd: '2024-09-09T13:08:58Z',
		startDateTimeFix: true,
		endDateTime: '2024-09-09T13:17:32Z',
		endDateTimeWindowStart: '2024-09-09T13:16:48Z',
		endDateTimeWindowEnd: '2024-09-09T13:16:48Z',
		endDateTimeFix: true,
		duration: 470.0,
		distance: 4729.5,
		mode: TransportType.PRODT,
		modeOrigin: '',
		route: '',
		headsign: null,
		routeId: null,
		agencyId: null,
		agencyName: 'Vetter',
		tripShortName: null,
		tripId: null,
		from: {
			name: 'Hansering 11, 06108 Halle (Saale), Deutschland',
			stopId: null,
			lng: 11.97494,
			lat: 51.4822,
			arrival: '2024-09-09T13:08:58Z',
			departure: '2024-09-09T13:08:58Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		to: {
			name: 'Käthe-Kollwitz-Straße 21, 06116 Halle (Saale), Deutschland',
			stopId: null,
			lng: 12.0309,
			lat: 51.4765,
			arrival: '2024-09-09T13:16:48Z',
			departure: '2024-09-09T13:16:48Z',
			locationType: NamedLocation.type.ADDRESS,
		},
		payment: {
			paymentAmount: 105,
			currency: CurrencyCode.EUR,
			paymentForm: PaymentForm.DIGITAL,
			paymentState: PaymentState.PAID,
			overwrittenPaymentAmount: null,
			paymentInformationAvailable: true,
		},
		paymentOptions: [PaymentForm.DIGITAL],

		intermediateStops: [],
		pricings: [
			{
				paymentHandlerCompanyName: 'MoPla Solutions GmbH',
				paymentHandlerCompanyType: 'MOPLA',
				paymentInformationAvailable: true,
				amount: 105,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
			},
		],
		cancellationRules: [
			{
				secondsBeforeRideStart: 0,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 105,
			},
			{
				secondsBeforeRideStart: 5400,
				currency: CurrencyCode.EUR,
				discount: DiscountState.NO_DISCOUNT,
				cost: 32,
			},
		],
		lbodtflexLabel: null,
	},
];
