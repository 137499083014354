import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/capacitor';

import { IApiRouterPlan200 } from '@mopla/data-models';

import { BusinessLayerContext } from '../business-logic';
import { parseItinerarySearchParams } from '../mappers/parseItinerarySearchParams';

export const useRoutesSuggestions = (
	searchParams: URLSearchParams
): [IApiRouterPlan200 | null, boolean] => {
	const businessLayer = useContext(BusinessLayerContext);
	const [routes, setRoutes] = useState<IApiRouterPlan200 | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getRoutesSuggestions = async () => {
			try {
				const itinerarySearchParams = parseItinerarySearchParams(searchParams);

				/** TODO add encodeURIComponent or smth like this */
				const routesResult = (await businessLayer.api.get(`/api/router/plan
					?fromPlaceLat=${itinerarySearchParams.fromPlaceLat}
					&fromPlaceLng=${itinerarySearchParams.fromPlaceLng}
					&fromPlaceLabel=${itinerarySearchParams.fromPlaceLabel}
					&fromPlaceZip=${itinerarySearchParams.fromPlaceZip}
					&fromPlaceRegion=${itinerarySearchParams.fromPlaceRegion}
					&toPlaceLat=${itinerarySearchParams.toPlaceLat}
					&toPlaceLng=${itinerarySearchParams.toPlaceLng}
					&toPlaceLabel=${itinerarySearchParams.toPlaceLabel}
					&toPlaceZip=${itinerarySearchParams.toPlaceZip}
					&toPlaceRegion=${itinerarySearchParams.toPlaceRegion}
					&datetime=${itinerarySearchParams.datetime}
					&arriveBy=${itinerarySearchParams.arriveBy}
					&specialNeeds=${itinerarySearchParams.specialNeeds}`)) as IApiRouterPlan200;

				setRoutes(routesResult);
			} catch (e) {
				Sentry.captureException(e);
			} finally {
				setLoading(false);
			}
		};

		getRoutesSuggestions();
	}, []);

	return [routes, loading];
};
