import { useCallback } from 'react';

type TAppType = 'PASSENGER' | 'DRIVER';

export const useResetPassword = (app: TAppType, baseUrl: string) => {
	return useCallback(
		(email: string) => {
			return fetch(`${baseUrl}/api/reset-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				mode: 'cors',
				body: JSON.stringify({ email, app }),
			});
		},
		[app, baseUrl]
	);
};
