import { RefObject, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/capacitor';

import { ILocationDecoded } from '@mopla/data-models';
import { LatLng } from '@mopla/utils';

import { lookupById, reverseGeocode } from '../../';
import { MapController } from '../services/hereapi';

interface IInitialGeoFieldValue {
	id: string;
	position: LatLng;
}

interface IParameters {
	userPosition?: LatLng | null;
	mapCenter: LatLng;
	ref: RefObject<HTMLDivElement>;
	initialGeoFieldValue?: IInitialGeoFieldValue | null;
}

export const useMapAddressPicker = (args: IParameters) => {
	const { mapCenter, ref, userPosition, initialGeoFieldValue } = args;
	const [pos, setPos] = useState<LatLng | null>(null);
	const [map, setMap] = useState<MapController | null>(null);
	const [locationDecoded, setLocationDecoded] =
		useState<ILocationDecoded | null>(null);
	const [isLoading, setLoading] = useState(false);
	const lastUserPositionRef = useRef(userPosition);

	useEffect(() => {
		const refNode = ref.current;

		if (refNode && !map) {
			const map = new MapController(refNode, mapCenter, 17);
			map.initCenterPointer();
			setMap(map);

			if (initialGeoFieldValue) {
				map.drawMarker(initialGeoFieldValue.position, 'cursor');
			}

			map.onDragDebounced = setPos;
			map.onDrag = () => setLoading(true);

			const ro = new ResizeObserver(map.resizeHandler);
			ro.observe(refNode);

			return () => {
				ro.unobserve(refNode);
				map.destroy();
			};
		}

		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (userPosition && map) {
			map.drawMarker(userPosition, 'user', true);

			/**
			 * Focus on the user's position. Do it only once and if the initial target point wasn't set before
			 * */
			if (!initialGeoFieldValue?.position && !lastUserPositionRef.current) {
				map.focusOnPos(userPosition);
			}
		}

		lastUserPositionRef.current = userPosition;
	}, [initialGeoFieldValue, map, userPosition]);

	/** When user's location become known AND no location was inputed or picked by map, set user's location as a target point */
	useEffect(() => {
		if (userPosition && !initialGeoFieldValue?.position && !pos) {
			setPos(userPosition);
		}
	}, [initialGeoFieldValue, pos, userPosition]);

	/**
	 * If a user has earlier typed and selected a location using the input and the autosuggest, then reuse that location id and lookup for its IlocationDecoded
	 * It is needed to set the correct initial value of ILocationDecoded for this hook state
	 * */
	useEffect(() => {
		(async () => {
			if (initialGeoFieldValue?.id) {
				const loc = await lookupById(initialGeoFieldValue.id);
				setLocationDecoded(loc);
			}
		})();
	}, [initialGeoFieldValue]);

	/** If a user tapped on the map, then reverseGeocode the lat,lng pair (convert it to the ILocationDecoded) */
	useEffect(() => {
		(async () => {
			if (pos) {
				try {
					const found = await reverseGeocode(pos.toString(), 1);
					setLocationDecoded(found[0] || null);
				} catch (e) {
					Sentry.captureException(e);
				} finally {
					setLoading(false);
				}
			}
		})();
	}, [pos]);

	return { locationDecoded, isLoading };
};
