import { styled } from '@mui/material/styles';

import { MplButton } from '@mopla/ui';

export const Root = styled('div')(({ theme }) => ({
	height: 'calc(100% - var(--mopla-safe-area-top))',
	width: '100%',
	maxWidth: 600,
	margin: 'auto',
	padding:
		'calc( 24px + var(--mopla-safe-area-top)) 24px calc(24px + var(--mopla-safe-area-bottom)) 24px',
	boxSizing: 'border-box',
	display: 'grid',
	gridGap: 24,
	gridTemplateRows: 'max-content max-content 1fr max-content',
	'span:first-of-type': {
		marginTop: 40,
		marginBottom: 8,
	},
	backgroundColor: theme.palette.primary.main,
	borderRadius: '8px 8px 0 0',
	overflow: 'auto',
}));

/** TODO MC-5176 fix this */
export const SubmitButton = styled(MplButton)(({ theme }) => ({
	marginTop: 12,
	background: theme.palette.neutral[0],
	color: theme.palette.primary.main,
}));
