import { styled } from '@mui/material/styles';

export const Root = styled('button')(({ theme }) => ({
	...theme.typography.body2,
	fontWeight: 600,
	background: 'transparent',
	border: 'none',
	outline: 'none',
	height: 24,
	cursor: 'pointer',
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridTemplateRows: '1fr',
	gridGap: 8,
	alignItems: 'center',
	color: theme.palette.primary.main,
	width: 'max-content',
	padding: 0,
	position: 'relative',
	span: {
		...theme.typography.caption,
		position: 'absolute',
		right: 0,
		bottom: '-10px',
		color: theme.palette.error.main,
	},
	'&:focus-visible': {
		outline: `2px solid ${theme.palette.primary.main}`,
	},
}));
