import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import { TMoplaColors } from '@mopla/mui';

import { Root } from './styled';

export interface IStatusPaymentProps {
	status: ReactNode;
	payment: ReactNode;
	color?: TMoplaColors;
	testId?: string;
	className?: string;
}

export const StatusPayment: FC<IStatusPaymentProps> = ({
	status,
	payment,
	color = 'neutral.0',
	testId,
	className,
}) => {
	return (
		<Root className={className}>
			<Typography
				className="status"
				variant="body1"
				fontWeight={600}
				color={color}
			>
				{status}
			</Typography>
			<Typography
				className="payment"
				variant="body1"
				fontWeight={600}
				color={color}
				data-testid={testId}
			>
				{payment}
			</Typography>
		</Root>
	);
};
