import { actionGenerator } from '@mopla/utils';

export enum UserActionTypes {
	FetchDefaultPaymentMethod = '[User] FetchDefaultPaymentMethod',
	FetchDefaultPaymentMethodResult = '[User] FetchDefaultPaymentMethodResult',
	FetchUser = '[User] Fetch User',
	FetchUserResult = '[User] Fetch User Result',
	SetDefaultPaymentMethod = '[User] Set Default Payment Method',
	SetDefaultPaymentMethodResult = '[User] Set Default Payment Method Result',
	SaveUserLanguage = '[User] Save user language',
	SaveUserLanguageResult = '[User] Save user language result',
	InquiryInvoice = '[User] Inquiry Invoice',
	InquiryInvoiceResult = '[User] Inquiry Invoice Result',
	CheckBannedEmail = '[User] Check Banned Email',
	CheckBannedEmailResult = '[User] Check Banned Email Result',

	ClearCurrentUser = '[User] Clear Current User',
	ClearCurrentUserResult = '[User] Clear Current User Result',

	SignOut = '[User] Sign Out',
	SignOutResult = '[User] Sign Out Result',

	DeleteCurrentUser = '[User] Delete Current User',
	DeleteCurrentUserResult = '[User] Delete Current User Result',
}

export const fetchDefaultPaymentMethod =
	actionGenerator<UserActionTypes.FetchDefaultPaymentMethod>(
		UserActionTypes.FetchDefaultPaymentMethod
	);

export const fetchDefaultPaymentMethodResult =
	actionGenerator<UserActionTypes.FetchDefaultPaymentMethodResult>(
		UserActionTypes.FetchDefaultPaymentMethodResult
	);

export const fetchUser = actionGenerator<UserActionTypes.FetchUser>(
	UserActionTypes.FetchUser
);

interface ISaveUserLanguageActionPayload {
	language: string;
}

export const saveUserLanguageAction = actionGenerator<
	UserActionTypes.SaveUserLanguage,
	ISaveUserLanguageActionPayload
>(UserActionTypes.SaveUserLanguage);

export const saveUserLanguageResult =
	actionGenerator<UserActionTypes.SaveUserLanguageResult>(
		UserActionTypes.SaveUserLanguageResult
	);

export const fetchUserResult = actionGenerator<UserActionTypes.FetchUserResult>(
	UserActionTypes.FetchUserResult
);

export interface ISetDefaultPaymentMethodPayload {
	paymentMethodId?: string | null;
	setupIntentId?: string | null;
}

export const setDefaultPaymentMethod = actionGenerator<
	UserActionTypes.SetDefaultPaymentMethod,
	ISetDefaultPaymentMethodPayload
>(UserActionTypes.SetDefaultPaymentMethod);

export const setDefaultPaymentMethodResult =
	actionGenerator<UserActionTypes.SetDefaultPaymentMethodResult>(
		UserActionTypes.SetDefaultPaymentMethodResult
	);

interface IInquiryInvoicePayload {
	subscriptionId: string | null;
	month: number | null;
	year: number | null;
}

export const inquiryInvoice = actionGenerator<
	UserActionTypes.InquiryInvoice,
	IInquiryInvoicePayload
>(UserActionTypes.InquiryInvoice);

export const inquiryInvoiceResult =
	actionGenerator<UserActionTypes.InquiryInvoiceResult>(
		UserActionTypes.InquiryInvoiceResult
	);

export interface ICheckBannedEmailPayload {
	email: string;
}

export interface ICheckBannedEmailResultPayload {
	isValid: boolean;
}

export const checkBannedEmail = actionGenerator<
	UserActionTypes.CheckBannedEmail,
	ICheckBannedEmailPayload
>(UserActionTypes.CheckBannedEmail);

export const checkBannedEmailResult = actionGenerator<
	UserActionTypes.CheckBannedEmailResult,
	ICheckBannedEmailResultPayload
>(UserActionTypes.CheckBannedEmailResult);

export const clearCurrentUser =
	actionGenerator<UserActionTypes.ClearCurrentUser>(
		UserActionTypes.ClearCurrentUser
	);

export const clearCurrentUserResult =
	actionGenerator<UserActionTypes.ClearCurrentUserResult>(
		UserActionTypes.ClearCurrentUserResult
	);

export const signOut = actionGenerator<UserActionTypes.SignOut>(
	UserActionTypes.SignOut
);

export const signOutResult = actionGenerator<UserActionTypes.SignOutResult>(
	UserActionTypes.SignOutResult
);

export const deleteCurrentUser =
	actionGenerator<UserActionTypes.DeleteCurrentUser>(
		UserActionTypes.DeleteCurrentUser
	);

export const deleteCurrentUserResult =
	actionGenerator<UserActionTypes.DeleteCurrentUserResult>(
		UserActionTypes.DeleteCurrentUserResult
	);

export type TSaveUserLanguageAction = ReturnType<typeof saveUserLanguageAction>;
export type FetchUserAction = ReturnType<typeof fetchUser>;
export type TInquiryInvoiceAction = ReturnType<typeof inquiryInvoice>;
export type FetchDefaultPaymentMethodAction = ReturnType<
	typeof fetchDefaultPaymentMethod
>;
export type SetDefaultPaymentMethodAction = ReturnType<
	typeof setDefaultPaymentMethod
>;
export type SetDefaultPaymentMethodResultAction = ReturnType<
	typeof setDefaultPaymentMethodResult
>;
export type TCheckBannedEmailAction = ReturnType<typeof checkBannedEmail>;
export type TCheckBannedEmailResultAction = ReturnType<
	typeof checkBannedEmailResult
>;
