import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@mui/material';

import { Modal, MplButton } from '../../../';
import { ButtonsWrapper, ModalText, ModalTitle } from '../common/styled';

export interface ConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	isCanceling: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
	const { open, onClose, onConfirm, isCanceling } = props;
	const { t } = useTranslation('tickets');

	return (
		<Portal container={document.getElementById('portal')}>
			<Modal
				open={open}
				onClose={onClose}
				mainContent={
					<>
						<ModalTitle>{t('text.remove_ride')}</ModalTitle>
						<ModalText>{t('text.remove_lbt_text')}</ModalText>
					</>
				}
				footerComp={
					<ButtonsWrapper>
						<MplButton
							variant="outlined"
							onClick={onClose}
							disabled={isCanceling}
						>
							{t('button.abort')}
						</MplButton>
						<MplButton
							onClick={onConfirm}
							showLoader={isCanceling}
							disabled={isCanceling}
						>
							{t('button.remove')}
						</MplButton>
					</ButtonsWrapper>
				}
			/>
		</Portal>
	);
};
