import { FC, ReactNode, useState } from 'react';
import { Collapse, IconButton } from '@mui/material';

import { DropdownIcon } from '../../';

import { Root } from './styled';

export interface IDropdownBaseProps {
	className?: string;
	icon?: ReactNode;
	content: ReactNode;
	title: ReactNode;
	initialOpen?: boolean;
}

export const DropdownBase: FC<IDropdownBaseProps> = ({
	className,
	icon,
	title,
	content,
	initialOpen = true,
}) => {
	const [open, setOpen] = useState(initialOpen);

	return (
		<Root className={className}>
			{icon && <div className="icon">{icon}</div>}
			<div className="title">{title}</div>
			<div className="toggle">
				<IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
					<DropdownIcon isOpened={open} />
				</IconButton>
			</div>
			<Collapse className="content" unmountOnExit in={open}>
				{content}
			</Collapse>
		</Root>
	);
};
