import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

import { TMoplaColors } from '@mopla/mui';

import { LogoIcon, MenuIcon, ProfileCircleIcon } from '../../';
import { Responsive } from '../../molecules/Responsive';

import { RootDesktop, RootMobile } from './passengerHeader.styles';

export type HeaderVariants = Extract<
	TMoplaColors,
	'neutral.0' | 'primary.main' | 'secondary.main' | 'primary.dark'
>;

interface HeaderProps {
	onMenuClick?: () => void;
	onProfileClick?: () => void;
	variant: HeaderVariants;
}

const PassengerHeader: React.FC<HeaderProps> = ({
	onMenuClick,
	onProfileClick,
	variant,
}) => {
	const getMobileMenuButtonColor = (): IconButtonProps['color'] => {
		if (variant === 'neutral.0' || variant === 'secondary.main') {
			return 'primary';
		}

		return 'neutral.0';
	};

	return (
		<>
			<Responsive aboveMobile>
				<RootDesktop>
					<LogoIcon variant={variant} />
					<IconButton
						data-testid="passengerHeader-desktopProfileButton"
						onClick={onProfileClick}
						color="primary"
					>
						<ProfileCircleIcon />
					</IconButton>
				</RootDesktop>
			</Responsive>
			<Responsive mobile>
				<RootMobile variant={variant}>
					<IconButton
						data-testid="passengerHeader-mobileMenuButton"
						onClick={onMenuClick}
						color={getMobileMenuButtonColor()}
					>
						<MenuIcon />
					</IconButton>
					<LogoIcon variant={variant} />
				</RootMobile>
			</Responsive>
		</>
	);
};

export default PassengerHeader;
