import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { DTicketIcon, ProfileIcon, SearchIcon, TicketIcon } from '../../';

import { NavButton, Root } from './PassengerSideNavigation.styles';

interface SideNavigationProps {
	open: boolean;
	onClose: () => void;
	onOpen: () => void;
}

const PassengerSideNavigation: React.FC<SideNavigationProps> = ({
	open,
	onClose,
	onOpen,
}) => {
	const { t } = useTranslation('navigation');
	const navigate = useNavigate();
	const location = useLocation();

	const onNavigate = useCallback(
		(to: string) => {
			onClose();
			navigate(to);
		},
		[onClose, navigate]
	);

	return (
		<Root anchor="left" open={open} onClose={onClose} onOpen={onOpen}>
			<NavButton
				onClick={() => onNavigate('dticket')}
				variant={location.pathname === '/home/dticket' ? 'contained' : 'text'}
			>
				<DTicketIcon />
				<span>{t('dticket')}</span>
			</NavButton>
			<NavButton
				onClick={() => onNavigate('tickets')}
				variant={location.pathname === '/home/tickets' ? 'contained' : 'text'}
			>
				<TicketIcon />
				<span>{t('tickets')}</span>
			</NavButton>
			<NavButton
				onClick={() => onNavigate('search')}
				variant={location.pathname === '/home/search' ? 'contained' : 'text'}
			>
				<SearchIcon />
				<span>{t('search')}</span>
			</NavButton>
			<NavButton
				onClick={() => onNavigate('profile')}
				variant={location.pathname === '/home/profile' ? 'contained' : 'text'}
			>
				<ProfileIcon />
				<span>{t('profile')}</span>
			</NavButton>
		</Root>
	);
};

export default PassengerSideNavigation;
