import { FC } from 'react';

import { SupportedLngs, useTranslation } from '@mopla/i18';

import { CheckmarkIcon } from '../../icons';
import { ProfileGenericButton } from '../../molecules/ProfileGenericButton/ProfileGenericButton';

import { useProfileManagerContext } from './context';
import { LanguageAvatar } from './ProfileManager.styles';

export interface ILanguageSelectorButtonProps {
	languageKey: SupportedLngs;
	selectedLanguage: SupportedLngs;
	setSelectedLanguage: (language: SupportedLngs) => void;
	testId?: string;
}

export const LanguageSelectorButton: FC<ILanguageSelectorButtonProps> = ({
	languageKey,
	selectedLanguage,
	setSelectedLanguage,
	testId = 'languageSelectorButton',
}) => {
	const { t } = useTranslation('common');
	const { isDriverApp } = useProfileManagerContext();
	const language = languageKey.split('_')[0];
	return (
		<ProfileGenericButton
			testId={testId}
			onClick={() => setSelectedLanguage(languageKey)}
			mainIcon={
				<LanguageAvatar
					sx={{ bgcolor: isDriverApp ? '#ECEEF8' : '#FFFFFF' }}
					children={language.toUpperCase()}
				/>
			}
			label={t(`common:language.${language.toLowerCase()}`)}
			helperIcon={<CheckmarkIcon />}
			showChevron={selectedLanguage === languageKey}
			variant={isDriverApp ? 'white' : 'grey'}
		/>
	);
};
