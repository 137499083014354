import { styled } from '@mui/material/styles';

import { MplButton } from '../../atoms/Button/MplButton';

/** TODO @deprecated */
export const WalletButton = styled(MplButton)(({ theme }) => ({
	...theme.typography.body1,
	background: theme.palette.neutral[100],
	fontWeight: 400,
	fontSize: 16,
	justifyContent: 'center',

	'& .MuiButton-startIcon': {
		marginRight: 12,
	},
}));
