import React from 'react';
import ReactDOM from 'react-dom';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { CheckmarkIcon, CompleteAnim, Modal, MplButton } from '../../';

import {
	ModalTitle,
	SuccessModalContent,
	UploadedDate,
} from './SuccessModalWithDate.styles';

export interface UploadSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	label: string;
	description: string;
	btnName: string;
	dataTestid?: string;
}

export const SuccessModalWithDate: React.FC<UploadSuccessModalProps> = ({
	isOpen,
	onClose,
	title,
	label,
	description,
	btnName,
	dataTestid = 'success-modal',
}) => {
	const [rootNode] = React.useState(document.getElementById('portal'));

	if (rootNode === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<Modal
			dataTestid={dataTestid}
			showClose
			backgroundColor="#ECEEF8"
			open={isOpen}
			animationElement={<CompleteAnim />}
			onClose={onClose}
			titleComp={
				<ModalTitle>
					<Typography variant="title" color="primary.dark">
						{title}
					</Typography>
				</ModalTitle>
			}
			mainContent={
				<SuccessModalContent>
					<Typography variant="body1" fontWeight={600} color="primary.dark">
						{label}:
					</Typography>
					<UploadedDate>
						<CheckmarkIcon />
						<span>{dayjs().format('DD.MM.YYYY')}</span>
					</UploadedDate>
					<Typography variant="body2" color="primary.dark">
						{description}
					</Typography>
				</SuccessModalContent>
			}
			footerComp={
				<MplButton color="secondary" onClick={onClose}>
					{btnName}
				</MplButton>
			}
		/>,
		rootNode
	);
};

export default SuccessModalWithDate;
