import { lazy } from 'react';
import Lottie from 'lottie-react';

import book_ride_animation from '../../lotties/book_ride.json';
import break_animation from '../../lotties/break.json';
import completed_animation from '../../lotties/completed.json';
import delete_ticket_animation from '../../lotties/delete_ticket.json';
import documents_upload_animation from '../../lotties/documents_upload.json';
import error_animation from '../../lotties/error.json';
import failed_animation from '../../lotties/failed.json';
import impaired_card_animation from '../../lotties/impaired_card.json';
import license_animation from '../../lotties/license.json';
import map_animation from '../../lotties/map.json';
import no_internet_animation from '../../lotties/no_internet.json';
import picture_upload_animation from '../../lotties/picture_upload.json';
import ride_notification_animation from '../../lotties/ride_notification.json';
import right_ride_animation from '../../lotties/right_ride.json';
import stay_informed_animation from '../../lotties/stay_informed.json';
import travel_animation from '../../lotties/travel.json';
import vehicle_check_animation from '../../lotties/vehicle_check.json';
import verification_animation from '../../lotties/verification.json';

import { AspectRatioGuardStyled } from './Anim.styles';
import type { LC } from './types';

export const BookRideAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={book_ride_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const BreakAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={break_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const CompleteAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={completed_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const DelteTicketAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={delete_ticket_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const DocumentsUploadAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie
			animationData={documents_upload_animation}
			loop={false}
			{...props}
		/>
	</AspectRatioGuardStyled>
);
export const ErrorAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={error_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const FailedAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={failed_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const ImpairedCardAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={impaired_card_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const LicenceAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={license_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const MapAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={map_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const PicUploadAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={picture_upload_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const RideNotificationAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie
			animationData={ride_notification_animation}
			loop={false}
			{...props}
		/>
	</AspectRatioGuardStyled>
);
export const RightRideAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={right_ride_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const StayInformedAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={stay_informed_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const TravelAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={travel_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const VehicleCheckAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={vehicle_check_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const VerficationAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={verification_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const OfflineCardAnim = (props: LC) => (
	<AspectRatioGuardStyled>
		<Lottie animationData={no_internet_animation} loop={false} {...props} />
	</AspectRatioGuardStyled>
);
export const PRODTAwaitAnim = lazy(() => import('./PRODTAwaitAnim'));
