import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, styled, Typography } from '@mui/material';

import { FlexColumn, IconMessage, InfoBaseIcon, MplPaper } from '@mopla/ui';

interface IDTicketPaymentMethodProps {
	isPreparing?: boolean;
}

const LoaderWrapper = styled('div')(() => ({
	width: '100%',
	display: 'grid',
	justifyContent: 'center',
}));

export const DTicketPaymentMethod: React.FC<IDTicketPaymentMethodProps> = ({
	isPreparing,
}) => {
	const { t } = useTranslation('booking');

	return (
		<MplPaper>
			<Typography variant="bodyTitle">
				{t('payment.no_payment_method_subtitle')}
			</Typography>
			<FlexColumn gap={16} sx={{ mt: 2 }}>
				{isPreparing ? (
					<LoaderWrapper>
						<CircularProgress />
					</LoaderWrapper>
				) : (
					<>
						<span data-testid="booking-purchaseSummary-paymentMethod-text">
							{t('payment.no_payment_method_body')}
						</span>
						<IconMessage
							icon={<InfoBaseIcon />}
							message={t('payment.no_payment_method_info')}
						/>
					</>
				)}
			</FlexColumn>
		</MplPaper>
	);
};
