import React from 'react';
import { useTranslation } from 'react-i18next';

import { Booking } from '@mopla/data-models';
import { calculateBookingExtraPassengers } from '@mopla/utils';

import { PassengerInfo, Root } from './PassengerCheckedList.styles';

export interface IPassengerCheckedListProps {
	bookings: Booking[];
	highlighted?: boolean;
}

export const PassengerCheckedList: React.FC<IPassengerCheckedListProps> = ({
	bookings,
	highlighted = true,
}) => {
	const { t } = useTranslation('checkIn');
	return (
		<Root>
			{bookings.map((booking: Booking) => {
				const mainPassenger = booking.passengerList.find(
					(pass) => pass.isMainPassenger
				);
				const otherPassengers = calculateBookingExtraPassengers(booking);
				return (
					<PassengerInfo key={booking.bookingId} highlighted={highlighted}>
						<span>{`${mainPassenger?.firstName || ''} ${
							mainPassenger?.lastName || ''
						}`}</span>
						<span>{booking.bookingId}</span>
						{!!otherPassengers && (
							<span id="more-attendees">
								{t('text.another_guest', { count: otherPassengers })}
							</span>
						)}
					</PassengerInfo>
				);
			})}
		</Root>
	);
};
