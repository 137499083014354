import { useEffect, useState } from 'react';

import { GERMANY_CENTER } from '@mopla/constants';
import { ILocationSuggest } from '@mopla/data-models';
import { getLocationCategory, LatLng } from '@mopla/utils';

import { hereSearch } from '../services/hereapi';

interface IResult {
	items: any[];
}

const germanyCenter = new LatLng(GERMANY_CENTER);

const getLocations = (query: string, position?: string | null) => {
	return new Promise<ILocationSuggest[]>((resolve, reject) => {
		const uniqueLabels = new Set<string>();
		hereSearch.autosuggest(
			{
				q: query,
				at: position || germanyCenter.toString(),
				result_types: ['address', 'place'],
			},
			(result: any) => {
				// Add a marker for each location found
				const filteredSuggestions = (result as IResult).items
					.filter(
						(suggestion: any) => !!suggestion.position && !!suggestion.address
					)
					.filter((suggestion: any) => {
						const label = suggestion.address.label;
						if (!uniqueLabels.has(label)) {
							uniqueLabels.add(label);
							return true;
						}
						return false;
					})
					.map((suggestion: any) => {
						const type = getLocationCategory(suggestion, 'address');

						return {
							id: suggestion.id,
							position: suggestion.position,
							title: suggestion.address.label,
							type,
						};
					});

				resolve(filteredSuggestions);
			},
			(err) => {
				reject(err);
			}
		);
	});
};

export const useLocationSuggestions = (
	query: string,
	position?: string | null
): { suggestions: ILocationSuggest[] } => {
	const [locations, setLocations] = useState<ILocationSuggest[]>([]);

	useEffect(() => {
		if (query.length === 0) {
			setLocations([]);
		}
		if (query.length > 0) {
			getLocations(query, position).then((loc) => {
				setLocations(loc);
			});
		}
	}, [position, query]);

	return { suggestions: locations };
};
