import { ComponentType, FC, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable, useObservableState } from 'observable-hooks';
import { map } from 'rxjs';

import { getCountdownTimer$ } from '@mopla/business-logic';
import { ILatLng, LegLocation, TransportType } from '@mopla/data-models';
import {
	checkIsLBODTFlexLeg,
	formatTime,
	getActualTime,
	openMapsApp,
	prepareLegAddress,
	useI18nLanguage,
	wrapDisplayName,
} from '@mopla/utils';

import { ILegDetailsContext, LegDetailsContextProvider } from './context';
import { ILegDetailsProps } from './types';

export const withLegDerivedData = (
	WrappedComponent: ComponentType<ILegDetailsProps>
) => {
	const Wrapper: FC<ILegDetailsProps> = (props) => {
		const { leg, isBookedItinerary, disabled } = props;
		const { t } = useTranslation('searchResults');
		const language = useI18nLanguage();
		const legActualStartTime = getActualTime(leg, 'start');

		/** Countdown until -15minutes from the startDateTime */
		const minutesBeforeStart$ = useObservable(() =>
			getCountdownTimer$(legActualStartTime).pipe(
				map((duration) => duration.minutes())
			)
		);

		const minutesBeforeStart = useObservableState(minutesBeforeStart$, null);

		const getLocationLabelClickHandler = useMemo(
			() => (location: LegLocation) => (e: MouseEvent) => {
				e.preventDefault();

				openMapsApp({ lat: Number(location.lat), lng: Number(location.lng) });
			},
			[]
		);

		const ctx = useMemo<ILegDetailsContext>(() => {
			const startTime = formatTime(leg.startDateTime);
			const endTime = formatTime(leg.endDateTime);
			const isStartEndDateFix = leg.endDateTimeFix && leg.startDateTimeFix;
			const isLBODTFlexLeg = checkIsLBODTFlexLeg(leg);
			const distanceKm = (Math.round((leg.distance || 0) / 100) / 10)
				.toFixed(1)
				.toString()
				.replace('.', ',');

			let mapWaypoints: ILatLng[] | null = null;
			let showLiveMap = false;
			let walkLegText = null;

			if (leg.mode === TransportType.WALK) {
				walkLegText = t('text.walkTime', {
					minutes: Math.round((leg.duration || 0) / 60),
					kilometers: distanceKm,
				});
			}

			if (leg.mode === TransportType.TRANSFER_WALK) {
				walkLegText = t('text.transfer', {
					kilometers: distanceKm,
				});
			}

			if (isLBODTFlexLeg && !isBookedItinerary && !disabled) {
				mapWaypoints = [
					{ lat: +leg.from.lat, lng: +leg.from.lng },
					{ lat: +leg.to.lat, lng: +leg.to.lng },
				];
			}

			if (
				[
					TransportType.PRODT,
					TransportType.LBODTFLEX,
					TransportType.LBODT,
				].includes(leg.mode)
			) {
				/**
				 * Show the livemap when it is less than 20 minutes before the leg start
				 * In case the leg start time is passed (minutesBeforeStart===null),
				 * the BE decides whether to show the livemap or not (422 error).
				 * The BE should restrict access to the livemap since the passenger has checked in
				 * */
				showLiveMap =
					!!isBookedItinerary &&
					(!minutesBeforeStart || minutesBeforeStart <= 20);
			}

			const isTimeNarrow = language !== 'en';

			return {
				mode: leg.mode,
				startPointLabel: prepareLegAddress(leg.from),
				startPointAddress: leg.from.address,
				startPointUrl: leg.from.stopLink,
				startTime,
				endTime,
				endPointLabel: prepareLegAddress(leg.to),
				endPointAddress: leg.to.address,
				endPointUrl: leg.to.stopLink,
				isStartEndDateFix,
				walkLegText,
				isTimeNarrow,
				mapWaypoints,
				disabled,
				showLiveMap,
				onStartPointClick: getLocationLabelClickHandler(leg.from),
				onEndPointClick: getLocationLabelClickHandler(leg.to),
			};
		}, [
			leg,
			isBookedItinerary,
			language,
			t,
			disabled,
			minutesBeforeStart,
			getLocationLabelClickHandler,
		]);

		return (
			<LegDetailsContextProvider value={ctx}>
				<WrappedComponent {...props} />
			</LegDetailsContextProvider>
		);
	};

	Wrapper.displayName = wrapDisplayName(WrappedComponent, 'withLegDerivedData');

	return Wrapper;
};
