import { ComponentType, FC } from 'react';

import { TransportType } from '@mopla/data-models';

import BusIcon from '../../icons/bus.svg';
import BusWithPeopleIcon from '../../icons/busWithPeople.svg';
import PRODTIcon from '../../icons/prodt.svg';
import RufbusIcon from '../../icons/rufbus.svg';
import WalkIcon from '../../icons/walk.svg';

import { SymbolCircle } from './TravelTypeSymbols.styles';

interface IConfig {
	TransportIcon: ComponentType;
	testId: string;
}

//TODO fix this when PRODT will be added to the TransportType
const mapTransportTypeToConfig: Record<TransportType | 'PRODT', IConfig> = {
	[TransportType.WALK]: {
		TransportIcon: WalkIcon,
		testId: 'travelType-symbols-walk',
	},
	[TransportType.TRANSFER_WALK]: {
		TransportIcon: WalkIcon,
		testId: 'travelType-symbols-walk',
	},
	[TransportType.LBT]: {
		TransportIcon: BusIcon,
		testId: 'travelType-symbols-lbt',
	},
	[TransportType.LBODT]: {
		TransportIcon: RufbusIcon,
		testId: 'travelType-symbols-lbodt',
	},
	[TransportType.LBODTFLEX]: {
		TransportIcon: BusWithPeopleIcon,
		testId: 'travelType-symbols-lbodtFlex',
	},
	//TODO fix this when PRODT will be added to the TransportType
	PRODT: {
		TransportIcon: PRODTIcon,
		testId: 'travelType-symbols-prodt',
	},
};

export interface ITransportCircleProps {
	className?: string;
	transportType: TransportType | 'PRODT'; //TODO fix this when PRODT will be added to the TransportType
	variant?: 'midGrey' | 'main' | 'disabled';
	large?: boolean;
}

export const TransportCircle: FC<ITransportCircleProps> = ({
	className,
	transportType,
	variant = 'main',
	large = false,
}) => {
	const { TransportIcon, testId } = mapTransportTypeToConfig[transportType];

	return (
		<SymbolCircle
			className={className}
			variant={variant}
			large={large}
			data-testid={testId}
		>
			<TransportIcon />
		</SymbolCircle>
	);
};
