import { FC } from 'react';

interface ArrowLongRightProps {
	className?: string;
}

export const ArrowLongRightIcon: FC<ArrowLongRightProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="16"
			viewBox="0 0 50 16"
			fill="none"
		>
			<path
				d="M49.2071 8.70711C49.5976 8.31658 49.5976 7.68342 49.2071 7.29289L42.8431 0.928932C42.4526 0.538408 41.8195 0.538408 41.4289 0.928932C41.0384 1.31946 41.0384 1.95262 41.4289 2.34315L47.0858 8L41.4289 13.6569C41.0384 14.0474 41.0384 14.6805 41.4289 15.0711C41.8195 15.4616 42.4526 15.4616 42.8431 15.0711L49.2071 8.70711ZM0.5 9L48.5 9V7L0.5 7L0.5 9Z"
				fill="#1C308B"
			/>
		</svg>
	);
};
