import { FC } from 'react';
import { Typography } from '@mui/material';

import { useTranslation } from '@mopla/i18';

import {
	FlexColumn,
	FlexContainer,
	IPriceStringProps,
	PaymentBlock,
	PriceBlock,
} from '../../..';

import { useLegDetailsContext } from './context';
import { LegPriceWrapper, MiddleCol, RightCol, RouteNumber } from './styled';
import { ILegDetailsProps } from './types';

const renderPaymentParts: IPriceStringProps['render'] = ({
	priceItems,
	previousPrice,
}) => {
	return (
		<FlexContainer gap={4}>
			<Typography
				className="maybe-disabled"
				variant="body2"
				fontWeight={600}
				color="secondary.dark"
			>
				{priceItems[0]}
			</Typography>
			{previousPrice && (
				<Typography
					variant="body2"
					fontWeight={600}
					color="neutral.80"
					sx={{ textDecoration: 'line-through' }}
				>
					{previousPrice}
				</Typography>
			)}
		</FlexContainer>
	);
};

export const TransportView: FC<ILegDetailsProps> = ({
	leg,
	isBookedItinerary,
}) => {
	const { t } = useTranslation('common');
	const { disabled } = useLegDetailsContext();

	return (
		<>
			{!disabled && leg.route && (
				<MiddleCol className="routeNumber">
					<RouteNumber>{leg.route}</RouteNumber>
				</MiddleCol>
			)}
			<RightCol>
				<FlexColumn>
					<Typography
						className="maybe-disabled"
						variant="body1"
						fontWeight={600}
					>
						{t(`travelTypeName.${leg.mode}`, '')}
					</Typography>
					{leg.headsign && (
						<Typography variant="body2" color="neutral.80">
							{leg.headsign}
						</Typography>
					)}
					{leg.agencyName && (
						<Typography variant="caption" color="neutral.80">
							{t('tickets:text.operator', { operator: leg.agencyName })}
						</Typography>
					)}
					<LegPriceWrapper>
						{isBookedItinerary ? (
							<PaymentBlock
								entity={leg}
								single
								renderPaymentParts={renderPaymentParts}
							/>
						) : (
							<PriceBlock legs={leg} />
						)}
					</LegPriceWrapper>
				</FlexColumn>
			</RightCol>
		</>
	);
};
