import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';

export { useMediaQuery };

interface IConfig {
	enforceMobile?: boolean;
	enforceAboveMobile?: boolean;
}

export const useBreakpoints = (config?: IConfig) => {
	const { enforceMobile, enforceAboveMobile } = config || ({} as IConfig);

	const isMobile = useMediaQuery((t) => t.breakpoints.only('mobile'));
	const isAboveMobile = useMediaQuery((t) => t.breakpoints.up('tabletP'));

	return useMemo(() => {
		const result = {
			isMobile: false,
			isAboveMobile: false,
		};

		if (enforceMobile) {
			return { ...result, isMobile: true };
		} else if (enforceAboveMobile) {
			return { ...result, isAboveMobile: true };
		} else {
			return { isMobile, isAboveMobile };
		}
	}, [enforceAboveMobile, enforceMobile, isAboveMobile, isMobile]);
};
