import { styled } from '@mui/material/styles';

export const VouchersWrapper = styled('div')(({ theme }) => ({
	display: 'grid',
	gridAutoRows: 'max-content',
	gap: 40,
	boxSizing: 'border-box',
	[theme.breakpoints.only('mobile')]: {
		padding: 24,
		backgroundColor: theme.palette.neutral[40],
		minHeight: '100%',
		gap: 16,
	},
}));

export const VouchersForm = styled('form')(({ theme }) => ({
	display: 'grid',
	gridAutoRows: 'max-content',
	[theme.breakpoints.up('tabletP')]: {
		gridTemplateColumns: 'auto 234px',
		gridTemplateAreas: `
		"VouchersFormInput  VouchersFormSubmit"
		"VouchersFormText   VouchersFormText"
		`,
		gap: '16px 8px',
	},
	[theme.breakpoints.only('mobile')]: {
		gap: 12,
		gridTemplateAreas: `
		"VouchersFormInput"
		"VouchersFormText"
		"VouchersFormSubmit"
		`,
	},
}));

export const VouchersFormInput = styled('div')(() => ({
	gridArea: 'VouchersFormInput',
	'& > div': {
		width: '100%',
	},
}));

export const VouchersFormText = styled('div')(() => ({
	gridArea: 'VouchersFormText',
	lineHeight: 1,
}));

export const VouchersFormSubmit = styled('div')(() => ({
	gridArea: 'VouchersFormSubmit',
}));

export const ActiveVouchers = styled('div')(() => ({
	display: 'grid',
	gridAutoRows: 'max-content',
	gridGap: 16,
}));

export const RedeemSuccessAnimWrapper = styled('div')(() => ({
	width: 327,
	margin: '0 auto',
}));

export const ModalContent = styled('div')(() => ({
	marginTop: 24,
}));

export const ModalTitle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('tabletP')]: {
		textAlign: 'center',
	},
}));

export const ModalFooter = styled('div')(() => ({
	marginTop: 24,
	display: 'flex',
	flexDirection: 'column',
	gap: 8,
}));

export const TicketContent = styled('div')(() => ({
	'& > span': {
		display: 'block',
	},
}));
