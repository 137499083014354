import { FC, PropsWithChildren } from 'react';

import { Root } from './styled';

interface IAspectRatioGuardProps {
	className?: string;
}

export const AspectRatioGuard: FC<
	PropsWithChildren<IAspectRatioGuardProps>
> = ({ children, className }) => {
	return (
		<Root className={className}>
			<div className="inner">{children}</div>
		</Root>
	);
};
