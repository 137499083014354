import { FC } from 'react';
import { styled, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { IDefaultCard } from '@mopla/data-models';

interface ICardExpiryDateProps {
	card: IDefaultCard;
	className?: string;
}

interface ICardExpiryDateStyledProps {
	isExpired: boolean;
	className?: string;
}

const CardExpiryDateStyled = styled('span')<ICardExpiryDateStyledProps>(
	({ theme, isExpired }) => ({
		backgroundColor: isExpired
			? theme.palette.neutral[20]
			: theme.palette.neutral[40],
		color: isExpired ? theme.palette.error.light : theme.palette.neutral[100],
		display: 'inline-flex',
		width: 'max-content',
		borderRadius: '4px',
		padding: '2px 4px',
	})
);

export const CardExpiryDate: FC<ICardExpiryDateProps> = ({
	card,
	className,
}) => {
	const { exp_month: month, exp_year: year } = card;

	const cardDate = dayjs({ month: month - 1, year });
	const isExpired = dayjs().isAfter(cardDate.endOf('month'));
	const expiryDate = cardDate.format('MM/YY');

	return (
		<CardExpiryDateStyled isExpired={isExpired} className={className}>
			<Typography variant="caption">{expiryDate}</Typography>
		</CardExpiryDateStyled>
	);
};
