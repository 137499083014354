import { FC, ReactNode } from 'react';

import { ContentWrapper, Root } from './styled';

export interface ILayoutProps {
	content: ReactNode;
	footer?: ReactNode;
	testId?: string;
}

export const MainWithFooterTemplate: FC<ILayoutProps> = ({
	content,
	footer,
	testId = 'mainWithFooterTemplate',
}) => {
	return (
		<Root data-testid={testId}>
			<ContentWrapper>{content}</ContentWrapper>
			{footer && <div>{footer}</div>}
		</Root>
	);
};
