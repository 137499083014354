import { styled } from '@mui/material';

interface IMplPaperProps {
	hasTransparentBackground?: boolean;
}

export const MplPaper = styled('div')<IMplPaperProps>(
	({ theme, hasTransparentBackground }) => ({
		width: '100%',
		background: hasTransparentBackground
			? 'transparent'
			: theme.palette.neutral[0],
		padding: '24px 16px',
		boxSizing: 'border-box',
		borderRadius: 8,
		marginTop: 8,
	})
);
