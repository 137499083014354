import { Button, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(SwipeableDrawer)(() => ({
	'.MuiDrawer-paper': {
		display: 'grid',
		gridAutoFlow: 'row',
		gridAutoRows: 'max-content',
		gridGap: 2,
		width: 250,

		padding: 12,
		boxSizing: 'border-box',
	},
}));

/** TODO MC-5199 refactoring needed. Consider using another component for the Nav buttons */
export const NavButton = styled(Button)(() => ({
	padding: 16,
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridGap: 12,
	alignItems: 'center',
	justifyItems: 'start',
	textTransform: 'capitalize',
}));
