import { styled } from '@mui/material';

export const Root = styled('div')(() => ({
	width: '100%',
	paddingTop: '61%',
	position: 'relative',

	'.inner': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	},
}));
