import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ isNativePlatform?: boolean }>(
	({ theme, isNativePlatform }) => ({
		backgroundColor: theme.palette.neutral[0],
		width: '100%',
		height: 'calc(100% - var(--mopla-safe-area-top))',
		display: 'grid',
		paddingTop: 'var(--mopla-safe-area-top)',
		gridTemplateRows: isNativePlatform
			? 'max-content 1fr max-content'
			: 'max-content 1fr',
		maxWidth: 600,
		margin: 'auto',
		boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
	})
);

export const Block = styled('div')(() => ({
	width: '100%',
	height: '100%',
	overflowY: 'auto',
}));
