import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ArrowRightIcon, MplButton } from '@mopla/ui';

import { Bold } from '../RegistrationForm/RegistrationForm.styles';

import { Description, Root, SubmitLevel, Title } from './ResendEmail.styles';

interface ResendEmailProps {
	onSubmit: () => void;
	email: string;
	loading?: boolean;
}

export const ResendEmail: React.FC<ResendEmailProps> = ({
	onSubmit,
	email,
	loading = false,
}) => {
	const { t } = useTranslation('registration');

	return (
		<Root>
			<Title data-testid="registration-letsDoThatAgain-text">
				{t('resendVerify.text.title1')}
			</Title>
			<Title>
				<Trans
					i18nKey="registration:resendVerify.text.title2"
					values={{ regEmail: email }}
					components={{ bold: <Bold /> }}
				/>
			</Title>
			<Description marginTop="24px">
				{t('resendVerify.text.description2')}
			</Description>
			<SubmitLevel>
				<MplButton
					endIcon={<ArrowRightIcon />}
					onClick={onSubmit}
					disabled={loading}
				>
					{t('resendVerify.button.send_again')}
				</MplButton>
			</SubmitLevel>
		</Root>
	);
};
