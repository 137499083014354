import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Passenger } from '@mopla/data-models';

import { FlexContainer } from '../../atoms/FlexContainer/FlexContainer';

export type IPassengerRowProps = {
	index?: number;
} & (
	| {
			passenger: Passenger;
			toddler?: never | false;
	  }
	| {
			passenger?: never;
			toddler: true;
	  }
);

export const PassengerRow: FC<IPassengerRowProps> = ({
	passenger,
	index = 0,
	toddler,
}) => {
	const { t } = useTranslation('booking');

	const getRowTitle = () => {
		if (passenger?.isMainPassenger) {
			return `${passenger.firstName} ${passenger.lastName}`;
		}

		return t('text.attendee_order', { count: index + 1 });
	};

	const getDiscountState = () => {
		if (toddler) {
			return t('text.toddlers_tariff');
		}

		return t(`text.${passenger.discountState}`);
	};

	return (
		<FlexContainer className="space-between" key={`row-${index}`}>
			<Typography variant="body2" fontWeight={600} color="primary.dark">
				{getRowTitle()}
			</Typography>
			<Typography variant="caption" color="primary.dark">
				{getDiscountState()}
			</Typography>
		</FlexContainer>
	);
};
