import { FC, Fragment } from 'react';
import { Box, Typography } from '@mui/material';

import { ILeg, NamedLocation } from '@mopla/data-models';
import { formatTime } from '@mopla/utils';

import { RidePointIcon } from '../RidePointIcon';

import { StationSplitter } from './StationSplitter';
import { GridWrapper, IconWrapper, StationName } from './TripScheme.styles';

interface ITripSchemeProps {
	bookingItineraryLegs: Array<ILeg>;
}

export const TripScheme: FC<ITripSchemeProps> = ({ bookingItineraryLegs }) => (
	<GridWrapper>
		{bookingItineraryLegs.map((leg, index) => (
			<Fragment key={leg.legId}>
				<Box mr={1}>
					<Typography variant="body1" fontWeight={600}>
						{formatTime(leg.startDateTime)}
					</Typography>
				</Box>
				<IconWrapper>
					<RidePointIcon pointType={NamedLocation.type.STOP} />
				</IconWrapper>
				<StationName
					variant="body1"
					fontWeight={600}
					title={leg.to.name}
					noWrap
				>
					{leg.from.name}
				</StationName>
				<StationSplitter />
				<Box mr={1}>
					<Typography variant="body1" fontWeight={600}>
						{formatTime(leg.endDateTime)}
					</Typography>
				</Box>
				<IconWrapper>
					<RidePointIcon pointType={NamedLocation.type.STOP} />
				</IconWrapper>
				<StationName
					variant="body1"
					fontWeight={600}
					title={leg.to.name}
					noWrap
				>
					{leg.to.name}
				</StationName>
				{bookingItineraryLegs.length - 1 > index && <StationSplitter />}
			</Fragment>
		))}
	</GridWrapper>
);
