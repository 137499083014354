import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MOPLA_RIDES_TYPES_SEARCH_PARAM } from '@mopla/constants';

export const useMoplaRideTypesControl = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const isOpened = searchParams.has(MOPLA_RIDES_TYPES_SEARCH_PARAM);

	const handleClose = useCallback(() => {
		setSearchParams((prevSearchParams) => {
			prevSearchParams.delete(MOPLA_RIDES_TYPES_SEARCH_PARAM);
			return prevSearchParams;
		});
	}, [setSearchParams]);

	const handleOpen = useCallback(() => {
		setSearchParams((prevSearchParams) => {
			prevSearchParams.set(MOPLA_RIDES_TYPES_SEARCH_PARAM, '1');
			return prevSearchParams;
		});
	}, [setSearchParams]);

	return { isOpened, handleOpen, handleClose };
};
