import { styled } from '@mui/material';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr max-content',
	gridTemplateAreas: `
	  "title      badge"
		"mainValue  transports"
		"messages   messages"
		`,
	boxSizing: 'border-box',

	'& .badge': {
		gridArea: 'badge',
		marginBottom: 12,
		alignSelf: 'center',
		lineHeight: 1,
	},

	'& .title': {
		marginBottom: 12,
		gridArea: 'title',
		lineHeight: 1,
		alignSelf: 'center',
	},

	'& .transportIcons': {
		gridArea: 'transports',
		justifySelf: 'end',
	},

	'& .mainValue': {
		gridArea: 'mainValue',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
	},

	'& .messages': {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		gridArea: 'messages',
	},
}));
