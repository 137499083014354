import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import { TransportType } from '@mopla/data-models';

import { TravelTypeSymbols } from '../TravelTypeSymbols';

import { Root } from './TicketDetailsHeader.styles';

export interface ITicketDetailsHeaderProps {
	transportTypes: TransportType[];
	messages?: ReactNode;
	itineraryDateTime?: ReactNode;
	badge?: ReactNode;
	isCancelled?: boolean;
	title: string;
}

export const TicketDetailsHeader: FC<ITicketDetailsHeaderProps> = ({
	title,
	transportTypes,
	messages,
	isCancelled,
	itineraryDateTime,
	badge,
}) => (
	<Root>
		<div className="title">
			<Typography variant="body2" fontWeight={600}>
				{title}
			</Typography>
		</div>
		<div className="badge">{badge}</div>
		<div className="mainValue">{itineraryDateTime}</div>
		<div className="transportIcons">
			<TravelTypeSymbols
				transportTypes={transportTypes}
				variant={isCancelled ? 'disabled' : 'main'}
			/>
		</div>
		{messages && <div className="messages">{messages}</div>}
	</Root>
);
