import { ECancellationReasons } from '@mopla/data-models';
import { actionGenerator } from '@mopla/utils';

export interface SubscriptionAction {
	type: SubscriptionActionTypes;
}

export enum SubscriptionActionTypes {
	FetchTickets = '[Subscription] Fetch Tickets',
	FetchTicketsResult = '[Subscription] Fetch Tickets Result',
	FetchSubscriptions = '[Subscription] Fetch Subscriptions',
	FetchSubscriptionsResult = '[Subscription] Fetch Subscriptions Result',
	FetchSubscriptionOffering = '[Subscription] Fetch Subscription Offering',
	FetchSubscriptionOfferingResult = '[Subscription] Fetch Subscription Offering Result',
	PauseSubscription = '[Subscription] Pause Subscription',
	PauseSubscriptionResult = '[Subscription] Pause Subscription Result',
	ResumeSubscription = '[Subscription] Resume Subscription',
	ResumeSubscriptionResult = '[Subscription] Resume Subscription Result',
	CancelSubscription = '[Subscription] Cancel Subscription',
	CancelSubscriptionResult = '[Subscription] Cancel Subscription Result',
}

// ==================== Add Location ====================

export interface FetchTicketsAction extends SubscriptionAction {
	type: SubscriptionActionTypes.FetchTickets;
	payload: Record<string, unknown>;
}

export const fetchTickets = (): FetchTicketsAction => ({
	type: SubscriptionActionTypes.FetchTickets,
	payload: {},
});

export const fetchTicketsResult =
	actionGenerator<SubscriptionActionTypes.FetchTicketsResult>(
		SubscriptionActionTypes.FetchTicketsResult
	);

export interface FetchSubscriptionsAction extends SubscriptionAction {
	type: SubscriptionActionTypes.FetchSubscriptions;
	payload: Record<string, unknown>;
}

export const fetchSubscriptions = (): FetchSubscriptionsAction => ({
	type: SubscriptionActionTypes.FetchSubscriptions,
	payload: {},
});

export const fetchSubscriptionsResult =
	actionGenerator<SubscriptionActionTypes.FetchSubscriptionsResult>(
		SubscriptionActionTypes.FetchSubscriptionsResult
	);

export const fetchSubscriptionOffering =
	actionGenerator<SubscriptionActionTypes.FetchSubscriptionOffering>(
		SubscriptionActionTypes.FetchSubscriptionOffering
	);

export const fetchSubscriptionOfferingResult =
	actionGenerator<SubscriptionActionTypes.FetchSubscriptionOfferingResult>(
		SubscriptionActionTypes.FetchSubscriptionOfferingResult
	);

interface IPauseSubscriptionPayload {
	pauseStart: string;
	pauseEnd: string;
	subscriptionId: string;
}

export const pauseSubscription = actionGenerator<
	SubscriptionActionTypes.PauseSubscription,
	IPauseSubscriptionPayload
>(SubscriptionActionTypes.PauseSubscription);

export const pauseSubscriptionResult =
	actionGenerator<SubscriptionActionTypes.PauseSubscriptionResult>(
		SubscriptionActionTypes.PauseSubscriptionResult
	);

interface IResumeSubscriptionPayload {
	subscriptionId: string;
	startDate: string;
}

export const resumeSubscription = actionGenerator<
	SubscriptionActionTypes.ResumeSubscription,
	IResumeSubscriptionPayload
>(SubscriptionActionTypes.ResumeSubscription);

export const resumeSubscriptionResult =
	actionGenerator<SubscriptionActionTypes.ResumeSubscriptionResult>(
		SubscriptionActionTypes.ResumeSubscriptionResult
	);

interface ICancelSubscriptionPayload {
	subscriptionId: string;
	cancellationReason: ECancellationReasons;
	cancellationReasonDescription?: string;
}

export const cancelSubscription = actionGenerator<
	SubscriptionActionTypes.CancelSubscription,
	ICancelSubscriptionPayload
>(SubscriptionActionTypes.CancelSubscription);

export const cancelSubscriptionResult =
	actionGenerator<SubscriptionActionTypes.CancelSubscriptionResult>(
		SubscriptionActionTypes.CancelSubscriptionResult
	);

export type TPauseSubscriptionAction = ReturnType<typeof pauseSubscription>;
export type TResumeSubscriptionAction = ReturnType<typeof resumeSubscription>;
export type TCancelSubscriptionAction = ReturnType<typeof cancelSubscription>;
