import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ILeg, Itinerary } from '@mopla/data-models';
import { LbodtflexContextProvider } from '@mopla/i18';
import { checkTimeMightChange } from '@mopla/utils';

import { FlexColumn, IconMessage } from '../../../..';
import { LegDetailsV2 } from '../../../LegDetails/v2/LegDetailsV2';

import { Content, ContentPaper, LegsWrapper, Root, TildaIcon } from './styled';

export interface IRouteDetailsProps {
	route: Itinerary;
	isTicket?: boolean;
	cheaperRidesBlock?: ReactNode;
	routeDetailsHeaderBlock: ReactNode;
	cheapestRideBlock?: ReactNode;
	failedStatusBlock?: ReactNode;
	passengerInfoBlock?: ReactNode;
	cancelButtonBlock?: ReactNode;
	isCancelled?: boolean;
}

export const RouteDetails: FC<IRouteDetailsProps> = ({
	route,
	isTicket,
	cheaperRidesBlock,
	cheapestRideBlock,
	failedStatusBlock,
	passengerInfoBlock,
	cancelButtonBlock,
	routeDetailsHeaderBlock,
	isCancelled,
}) => {
	const { t } = useTranslation('common');
	const timeMightChange = checkTimeMightChange(route);

	return (
		<Root data-testid="savedTicket-routeDetails-block">
			{cheapestRideBlock}
			<Content>{routeDetailsHeaderBlock}</Content>
			{failedStatusBlock}
			{cheaperRidesBlock && <Content>{cheaperRidesBlock}</Content>}
			<ContentPaper>
				<FlexColumn gap={48}>
					{timeMightChange && (
						<IconMessage
							icon={<TildaIcon />}
							message={t('text.attentionTimeMayChange')}
							variant="body2"
						/>
					)}
					<LegsWrapper gap={16}>
						{route?.legs &&
							route.legs.map((leg: ILeg, index, legs) => (
								<LbodtflexContextProvider
									key={leg.startDateTime}
									value={leg.lbodtflexLabel}
								>
									<LegDetailsV2
										leg={leg}
										isBookedItinerary={isTicket}
										isFirstLeg={index === 0}
										isLastLeg={index === legs.length - 1}
										disabled={isCancelled}
									/>
								</LbodtflexContextProvider>
							))}
					</LegsWrapper>
				</FlexColumn>
			</ContentPaper>
			{passengerInfoBlock}
			{cancelButtonBlock && <Content>{cancelButtonBlock}</Content>}
		</Root>
	);
};
