import React, { ReactNode, useMemo } from 'react';

import SearchHeader from '../../molecules/SearchHeader/SearchHeader';

import {
	BaseSurfaceBlock,
	ChildrenWrapper,
	ContentWrapper,
	Root,
} from './homeBanner.styles';

interface BottomSliderProps {
	open: boolean;
	children: ReactNode;
	title?: string;
	onBack?: () => void;
	onClose?: () => void;
	withHeader?: boolean;
}

export const BottomSlider: React.FC<BottomSliderProps> = ({
	children,
	open,
	onBack,
	onClose,
	title,
	withHeader = true,
}) => {
	const ownerState = useMemo(() => ({ open, withHeader }), [open, withHeader]);

	return (
		<>
			<BaseSurfaceBlock
				data-testid="bottomSlider-surfaceBlock"
				ownerState={ownerState}
			/>
			<Root data-testid="bottomSlider-root" ownerState={ownerState}>
				<ContentWrapper ownerState={ownerState} elevation={16} square>
					{withHeader ? (
						<SearchHeader
							showBack={!!onBack}
							showClose={!!onClose}
							onClose={onClose}
							onBack={onBack}
							title={title}
							open={true}
						/>
					) : null}
					{open ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
				</ContentWrapper>
			</Root>
		</>
	);
};
