import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

import { PassengerBooking } from '@mopla/business-logic';
import { BookingStatus } from '@mopla/data-models';
import {
	checkBookingPriceReduced,
	checkIsBookingCancelled,
	checkTimeMightChange,
	isLBTItinerary,
	useBreakpoints,
} from '@mopla/utils';

import {
	CheckmarkCircleIcon,
	IconMessage,
	InformationIcon,
	RouteDetailsHeader,
} from '../../../';

import { RouteDetails } from './ui/RouteDetails';
import { CancelButton } from './CancelButton';
import { FailedStatusBlock } from './FailedStatusBlock';
import { PassengerInfoBlock } from './PassengerInfoBlock';

export interface IBookedRouteDetailsProps {
	cancelBooking?(): void;
	bookedItinerary: PassengerBooking;
}

export const BookedRouteDetails: FC<IBookedRouteDetailsProps> = ({
	cancelBooking,
	bookedItinerary,
}) => {
	const theme = useTheme();
	const { t } = useTranslation([
		'searchResults',
		'routeDetails',
		'tickets',
		'booking',
	]);
	const { itinerary } = bookedItinerary;
	const { isMobile } = useBreakpoints();
	const timeMightChange = checkTimeMightChange(itinerary);
	const isLBT = isLBTItinerary(itinerary);
	const isPriceReduction = checkBookingPriceReduced(bookedItinerary);
	const isExecuting = bookedItinerary.bookingStatus === BookingStatus.EXECUTING;
	const isCancelled = checkIsBookingCancelled(bookedItinerary);

	return (
		<RouteDetails
			route={itinerary}
			routeDetailsHeaderBlock={
				<RouteDetailsHeader
					route={itinerary}
					bookedItinerary={bookedItinerary}
					messages={
						<>
							{!timeMightChange && !isExecuting && (
								<IconMessage
									icon={
										<CheckmarkCircleIcon color={theme.palette.secondary.dark} />
									}
									message={t('common:text.time_fixed')}
									color="neutral.80"
									variant="body2"
								/>
							)}
							{isExecuting && (
								<IconMessage
									icon={
										<CheckmarkCircleIcon color={theme.palette.secondary.dark} />
									}
									message={t('common:text.checked_in_done')}
									color="neutral.80"
									variant="body2"
								/>
							)}
							{isPriceReduction && !isExecuting && (
								<IconMessage
									icon={<InformationIcon />}
									message={t('common:text.ticket_cheaper')}
									color="neutral.80"
									variant="body2"
								/>
							)}
						</>
					}
					isCancelled={isCancelled}
				/>
			}
			failedStatusBlock={
				<FailedStatusBlock bookedItinerary={bookedItinerary} />
			}
			passengerInfoBlock={
				!isLBT &&
				isMobile && <PassengerInfoBlock bookedItinerary={bookedItinerary} />
			}
			cancelButtonBlock={
				cancelBooking && (
					<CancelButton
						bookedItinerary={bookedItinerary}
						onClick={cancelBooking}
					/>
				)
			}
			isTicket
			isCancelled={isCancelled}
		/>
	);
};
