import { styled } from '@mui/material';

import { CardExpiryDate } from '@mopla/ui';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	alignItems: 'center',
	gap: '4px 8px',
}));

export const CardExpiryDateStyled = styled(CardExpiryDate)(() => ({
	gridColumnStart: 2,
}));
