import { FC } from 'react';
import { Typography } from '@mui/material';

import { useDefaultPaymentMethod } from '@mopla/business-logic';
import { PaymentIcon, PaypalIcon } from '@mopla/ui';
import { VisaIcon } from '@mopla/ui/icons/Visa';

import { CardExpiryDateStyled, Root } from './PaymentDetails.styles';

const hiddenCardNumber = '****';

export const PaymentDetails: FC = () => {
	const { defaultPaymentMethod } = useDefaultPaymentMethod();

	if (!defaultPaymentMethod) return <Root></Root>;

	const getIcon = () => {
		switch (defaultPaymentMethod.type) {
			case 'card': {
				if (defaultPaymentMethod.card?.brand === 'visa') return <VisaIcon />;
				return <PaymentIcon />; // TODO: if necessary -> replace with MasterCard or any other brand's icons
			}
			case 'paypal':
				return <PaypalIcon />;
			case 'sepa_debit':
			default:
				return <PaymentIcon />;
		}
	};

	const getPaymentText = () => {
		switch (defaultPaymentMethod.type) {
			case 'paypal':
				return defaultPaymentMethod.paypal?.payer_email;

			default:
				return hiddenCardNumber;
		}
	};

	const getLast4 = () => {
		switch (defaultPaymentMethod.type) {
			case 'card': {
				return defaultPaymentMethod.card?.last4;
			}
			case 'sepa_debit': {
				return defaultPaymentMethod.sepa_debit?.last4;
			}
			default:
				return '';
		}
	};

	return (
		<Root>
			{getIcon()}
			<Typography variant="body1" noWrap>
				{getPaymentText()} {getLast4()}
			</Typography>
			{defaultPaymentMethod.card && (
				<CardExpiryDateStyled card={defaultPaymentMethod.card} />
			)}
		</Root>
	);
};
