import { InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputAdornmentWrapper = styled(InputAdornment, {
	shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
	right: '26px',
	'.MuiButtonBase-root': {
		color: error ? theme.palette.error.main : 'inherit',
	},
}));
