import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useObservable, useObservableEagerState } from 'observable-hooks';

import { Itinerary, PassengerDetails } from '@mopla/data-models';

import { InformationIcon } from '../../';

import { ContentWrapper, DropdownBaseStyled } from './styled';
import { getCancellationDataPrepared$ } from './utils';

export interface ICancellationRulesDropdownProps {
	itinerary: Itinerary;
	/** The passengersList might be skipped for the PRODT itineraries (see prepareCancellationData) */
	passengersList?: PassengerDetails[];
	initialOpen?: boolean;
	className?: string;
}

export const CancellationRulesDropdown: FC<ICancellationRulesDropdownProps> = ({
	className,
	itinerary,
	passengersList,
	initialOpen = false,
}) => {
	const { t } = useTranslation('booking');

	const data$ = useObservable(getCancellationDataPrepared$(t), [
		itinerary,
		passengersList,
	]);
	const data = useObservableEagerState(data$);

	if (!data.length) {
		return null;
	}

	return (
		<DropdownBaseStyled
			className={className}
			icon={<InformationIcon />}
			title={
				<Typography variant="caption" fontWeight={700}>
					{t('cancellation_rules.title')}
				</Typography>
			}
			content={
				<ContentWrapper>
					{data.map(([cancellationTerm, cancellationCost], i) => (
						<Fragment key={i}>
							<Typography variant="caption">{cancellationTerm}</Typography>
							<Typography variant="caption">{cancellationCost}</Typography>
						</Fragment>
					))}
				</ContentWrapper>
			}
			initialOpen={initialOpen}
		/>
	);
};
