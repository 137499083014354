import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { IBooking } from '@mopla/data-models';
import {
	checkIsBookingCancelled,
	checkIsPassedBooking,
	isLBTItinerary,
} from '@mopla/utils';

import { ButtonCancel, CancelButtonWrapper } from './ui/styled';

interface CancelButtonProps {
	onClick: () => void;
	bookedItinerary: IBooking;
}

export const CancelButton: React.FC<CancelButtonProps> = ({
	onClick,
	bookedItinerary,
}) => {
	const { t } = useTranslation('tickets');
	const isCancelledTrip = checkIsBookingCancelled(bookedItinerary);
	const isPassedTrip = checkIsPassedBooking(bookedItinerary);
	const isLBTRide = isLBTItinerary(bookedItinerary.itinerary);

	return (
		<CancelButtonWrapper>
			{!isLBTRide && (isCancelledTrip || isPassedTrip) && (
				<Typography variant="body2">{t('text.hide_booking')}</Typography>
			)}
			<ButtonCancel
				variant="outlined"
				onClick={onClick}
				data-testid="savedTicket-cancelBooking-button"
			>
				{isLBTRide || isCancelledTrip || isPassedTrip
					? t('button.remove_ride')
					: t('button.cancel_booking')}
			</ButtonCancel>
		</CancelButtonWrapper>
	);
};
