import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ILocationSuggest } from '@mopla/data-models';

import { CurrentlocationIcon, useCurrentGeoPosition } from '../../';

import { Root } from './CurrentLocationButton.styles';

export interface CurrentLocationButtonProps {
	onLoad: (loc: ILocationSuggest) => void;
}

// TODO: improve styles after clarifications. Provide location service converting lotlang to address
export const CurrentLocationButton: FC<CurrentLocationButtonProps> = ({
	onLoad,
}) => {
	const { t } = useTranslation(['common', 'permissions']);
	const { getCurrentGeoPositionDecoded } = useCurrentGeoPosition();

	const clickHandler = useCallback(async () => {
		const currentLocation = await getCurrentGeoPositionDecoded();

		if (currentLocation) {
			onLoad({
				...currentLocation,
				type: 'address',
			});
		}
	}, [getCurrentGeoPositionDecoded, onLoad]);

	return (
		<Root
			type="button"
			onClick={clickHandler}
			data-testid="search-locationForm-currentLocation-button"
		>
			<CurrentlocationIcon />
			{t('fields.currentLocation')}
		</Root>
	);
};
