import { ComponentProps, FC, JSXElementConstructor, ReactElement } from 'react';
import { Trans } from 'react-i18next';

/* Add any tags you need in your translations */
const DEFAULT_COMPONENTS = {
	b: <b />,
	i: <i />,
	u: <u />,
	s: <s />,
	br: <br />,
} as const;

type TDefaultComponents = {
	readonly [tagName: string]: ReactElement<
		unknown,
		string | JSXElementConstructor<unknown>
	>;
};

interface IMplTransProps extends ComponentProps<typeof Trans> {
	i18nKey: string; // Repeating this prop makes it required
	components?: typeof DEFAULT_COMPONENTS | TDefaultComponents;
}

export const MplTrans: FC<IMplTransProps> = ({
	i18nKey,
	components,
	...otherProps
}) => (
	<Trans
		i18nKey={i18nKey}
		components={{ ...DEFAULT_COMPONENTS, ...components }}
		{...otherProps}
	/>
);
