import H from '@here/maps-api-for-javascript';
import { debounceTime, distinctUntilChanged, fromEvent, map, tap } from 'rxjs';
import invariant from 'tiny-invariant';

import { PaletteColors } from '@mopla/mui';
import { LatLng } from '@mopla/utils';

import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';

import { platform } from './herePlatform';
import { UserFocusControlButton } from './UserFocusControlButton';
import { ease } from './utils';

const tapIcon = new H.map.DomIcon(`
	<svg xmlns="http://www.w3.org/2000/svg" width="25" height="32" fill="none" viewBox="0 0 25 32" style="left:-12px;top:-16px"><g filter="url(#a)"><path fill="#1C308B" d="M20.998 8.48c0-.69-.096-1.355-.25-1.998A8.483 8.483 0 0 0 12.505 0c-.666 0-1.332.083-1.963.238a8.511 8.511 0 0 0-6.28 6.244 8.513 8.513 0 0 0-.25 1.999v.238c-.071 1.01-.071 6.506 7.173 14.701.69.773 1.915.773 2.604 0 7.244-8.195 7.244-13.69 7.173-14.701V8.48h.036Zm-8.493-5.423a5.421 5.421 0 0 1 5.424 5.424 5.422 5.422 0 0 1-5.424 5.424A5.421 5.421 0 0 1 7.08 8.48a5.421 5.421 0 0 1 5.424-5.424Z"/></g><defs><filter id="a" width="24.998" height="32" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11658_7553"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_11658_7553" result="shape"/></filter></defs></svg>
`);

const currentPosIcon = new H.map.DomIcon(`
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 109 109" style="left:-12px;top:-12px"><circle cx="54.5" cy="54.5" r="54.5" fill="#3E8CF9" fill-opacity=".24"/><circle cx="54.5" cy="54.5" r="27.34" fill="#3E8CF9" stroke="#fff" stroke-width="2"/></svg>
`);

const getVehicleIcon = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64" style="left:-32px;top:-32px"><circle cx="32" cy="32" r="32" fill="${color}" opacity=".42"/><circle cx="32" cy="32" r="17" fill="#fff" stroke="${color}" stroke-width="2"/><path fill="${color}" fill-rule="evenodd" d="M41.09 35.52h-1.44a2.39 2.39 0 0 0-3.82 0h-7.66a2.39 2.39 0 0 0-3.82 0H22.9v-3.35H41.1v-.95h-5.26v-4.3h-.96v4.3h-6.7v-4.3h-.95v4.3h-4.3v-4.3h15.33l2.84 3.53v5.07Zm-17.17 1.91h-1a1.91 1.91 0 0 1-1.92-1.9V26.9c0-1.05.86-1.91 1.91-1.91h15.34c.59 0 1.14.26 1.5.72l2.83 3.54c.27.34.42.76.42 1.2v5.06c0 1.06-.86 1.91-1.91 1.91h-1a2.4 2.4 0 0 1-4.7 0H28.6a2.4 2.4 0 0 1-4.68 0Z" clip-rule="evenodd"/></svg>
`;

const vehicleIcon = new H.map.DomIcon(
	getVehicleIcon(PaletteColors.primary.main)
);
const delayedVehicleIcon = new H.map.DomIcon(
	getVehicleIcon(PaletteColors.warning.main)
);

const icons = {
	cursor: tapIcon,
	user: currentPosIcon,
	vehicle: vehicleIcon,
	delayedVehicle: delayedVehicleIcon,
};

export type TIcons = keyof typeof icons;

export class MapController {
	private map: H.Map;
	private ui: H.ui.UI;
	private mapObjects: Partial<Record<TIcons, H.map.DomMarker>> = {};
	public onDragDebounced?: (pos: LatLng) => void;
	public onDrag?: (pos: LatLng) => void;
	private disposeHandlers: VoidFunction[] = [];

	constructor(
		mapDomNode: HTMLDivElement,
		mapCenter: LatLng,
		mapZoom = 17,
		padding?: H.map.ViewPort.Padding
	) {
		const dl = platform.createDefaultLayers();
		this.map = new H.Map(mapDomNode, dl.vector.normal.map, {
			zoom: mapZoom,
			center: mapCenter.coord,
			pixelRatio: window.devicePixelRatio || 1,
			padding,
		});

		this.ui = new H.ui.UI(this.map);

		new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

		this.disposeHandlers.push(() => this.map.dispose());
	}

	private animateMapObjectPos = (
		mapObject: H.map.AbstractMarker,
		pos: LatLng
	) => {
		ease(
			new LatLng(mapObject.getGeometry() as H.geo.Point),
			pos,
			200,
			(nextPos) => mapObject.setGeometry(nextPos.coord)
		);
	};

	private focusOnBounds = (bounds: H.geo.Rect | null) => {
		invariant(
			bounds,
			'[hereMap] trying to focus on a group of points, but no bounds provided'
		);

		const vm = this.map.getViewModel();

		vm.setLookAtData({ bounds });

		setTimeout(() => {
			const vmZoom = vm.getLookAtData().zoom;
			if (vmZoom) {
				vm.setLookAtData({ zoom: Math.min(17, vmZoom) });
			}
		}, 100);
	};

	public initCenterPointer = () => {
		const mapViewModel = this.map.getViewModel();

		/** ViewModel extends EventTarget and has add/removeEventListener functions */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const sub = fromEvent(mapViewModel as any, 'sync')
			.pipe(
				map(() => new LatLng(this.map.getCenter())),
				distinctUntilChanged((prev, cur) => prev.toString() === cur.toString()),
				tap((pos) => {
					this.onDrag && this.onDrag(pos);
				}),
				tap((pos) => this.drawMarker(pos, 'cursor')),
				debounceTime(200),
				tap((pos) => {
					this.onDragDebounced && this.onDragDebounced(pos);
				})
			)
			.subscribe();

		this.disposeHandlers.push(() => sub.unsubscribe());
	};

	public initUserFocusControlButton = () => {
		const control = new UserFocusControlButton(this, this.map, {
			alignment: 'right-bottom',
		});

		this.ui.addControl('userFocusButton', control);
	};

	public resizeHandler = () => {
		this.map.getViewPort().resize();
	};

	public focusOnPos(pos: LatLng) {
		this.map.setCenter(pos.coord);
		this.map.setZoom(17);
	}

	public focusOnUser() {
		const userMarker = this.mapObjects['user'];

		if (userMarker) {
			const point = userMarker.getGeometry() as H.geo.Point;
			this.map.setCenter(point, true);
		}
	}

	public focusOnPoints = (...points: LatLng[]) => {
		const po: H.geo.IPoint[] = points.map((el) => el.coord);

		const p = new H.geo.MultiPoint(po);

		this.focusOnBounds(p.getBoundingBox());
	};

	public drawMarker = (pos: LatLng, name: TIcons, animate?: boolean) => {
		this.map.dispatchEvent(new H.util.Event('marker_draw', { name }));

		let marker = this.mapObjects[name];

		if (marker) {
			if (animate) {
				this.animateMapObjectPos(marker, pos);
			} else {
				marker.setGeometry(pos.coord);
			}

			return;
		}

		marker = new H.map.DomMarker(pos.coord, {
			icon: icons[name],
			data: { iconName: name },
		});
		this.mapObjects[name] = marker;
		this.map.addObject(marker);
	};

	public updateMarkerIcon = (name: TIcons, markerIconName: TIcons) => {
		const marker = this.mapObjects[name];

		if (!marker) {
			return;
		}

		const markerCurrentIconName = marker.getData().iconName;

		/** Just a bit of validation in order to not trigger the change if the icon is already correct */
		if (markerCurrentIconName !== markerIconName) {
			marker.setIcon(icons[markerIconName]);
			marker.setData({ iconName: markerIconName });
		}
	};

	public destroy = () => {
		this.disposeHandlers.forEach((cb) => cb());
	};
}
