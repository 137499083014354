import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FlexColumn, InfoModal } from '@mopla/ui';
import { ValidityWarning } from '@mopla/ui/icons/ValidityWarning';

interface IValidityModal {
	onSubmit: () => void;
	onCancel: () => void;
}

export const isAfterMiddleOfMonth = (monthOfStart: string): boolean => {
	const currentDay = dayjs().date();
	const currentMonth = dayjs().startOf('month');
	const isDayAfterMiddleMonth = currentDay >= 15;
	const selectedMonth = dayjs(monthOfStart);
	const isCurrentSelected = selectedMonth.isSame(currentMonth, 'month');
	return isDayAfterMiddleMonth && isCurrentSelected;
};

export const ValidityModal = ({ onSubmit, onCancel }: IValidityModal) => {
	const { t } = useTranslation(['dticket']);
	const daysToEndOfMonth = dayjs().endOf('month').diff(dayjs(), 'day') + 1;
	const currentMonthTitle = dayjs().format('MMMM');

	return (
		<InfoModal
			icon={
				<FlexColumn className="center">
					<ValidityWarning />
				</FlexColumn>
			}
			title={t('dticket:subscription_management.modal.header.validity_header', {
				count: daysToEndOfMonth,
			})}
			infoText={t('subscription_management.modal.validity_description', {
				currentMonthTitle,
			})}
			submitTitle={t('button.go_back')}
			cancelTitle={t('button.proceed_anyway')}
			onSubmit={onCancel}
			onCancel={onSubmit}
			textAlign="center"
			isButtonReverse
			testId="dticket-subscription-soon-expiration-modal"
		/>
	);
};
