import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';

import { EmailField, SuccessStep } from '@mopla/constants';

import { MplButton } from '../../';
import { Input } from '../../atoms/input/input';
import { ArrowRightIcon } from '../../icons/ArrowRight';
import { AttentionIcon } from '../../icons/Attention';
import { VerficationAnim } from '../../molecules/Animations';
import { EmailSentWarningMessage } from '../EmailSentWarningMessage/EmailSentWarningMessage';
import { ErrorModal } from '../ErrorModal/ErrorModal';

import {
	Description,
	Footer,
	Root,
	SubmitLevel,
	TextFooter,
	TextFooterLink,
	Title,
} from './ForgotPasswordForm.styles';
import ForgotPasswordValidationSchema from './ForgotPasswordValidation';

interface IFormInput {
	[EmailField.email]: string;
}

interface ForgotPasswordProps {
	onSubmit: (values: IFormInput) => void;
	onSuccessCallback: () => void;
	successStep: string;
	error: boolean;
	closeError: VoidFunction;
	setForgotPasswordEmail: Dispatch<SetStateAction<string>>;
	forgotPasswordEmail: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
	onSubmit,
	onSuccessCallback,
	successStep,
	error,
	closeError,
	forgotPasswordEmail,
	setForgotPasswordEmail,
}) => {
	const { t } = useTranslation(['forgotPassword', 'validation']);
	const emailForSignIn = localStorage.getItem('emailForSignIn');
	const [showWarningMessage, setShowWarning] = useState<boolean>(false);

	const methods = useForm<IFormInput>({
		defaultValues: {
			[EmailField.email]: emailForSignIn || '',
		},
		resolver: yupResolver(ForgotPasswordValidationSchema),
		mode: 'onChange',
	});
	const { formState, handleSubmit } = methods;

	const handleFormSubmit = (data: IFormInput) => {
		setForgotPasswordEmail(data[EmailField.email]);
		onSubmit(data);
	};

	const checkResend = () => {
		if (
			new Date().valueOf() - Number(localStorage.getItem('emailSendTime')) <=
			60000
		) {
			return setShowWarning(true);
		}
		return onSuccessCallback();
	};

	if (successStep === SuccessStep.forgotPasswordEmailSent) {
		return (
			<div data-testid="forgot-password-success-screen">
				<EmailSentWarningMessage
					open={showWarningMessage}
					onClose={() => setShowWarning(false)}
					email={forgotPasswordEmail}
				/>
				<Box my={6}>
					<VerficationAnim />
				</Box>

				<Title>
					{t('success_screen.text.start', { email: forgotPasswordEmail })}
				</Title>
				<Title>{t('success_screen.text.description')}</Title>

				<SubmitLevel>
					<Footer>
						<TextFooter>{t('success_screen.text.no_email')}</TextFooter>
						<TextFooterLink onClick={checkResend}>
							{t('success_screen.text.again')}
						</TextFooterLink>
					</Footer>
				</SubmitLevel>
			</div>
		);
	}

	return (
		<>
			<Title>{t('text.title')}</Title>
			<FormProvider {...methods}>
				{error && <ErrorModal onSubmit={closeError} />}
				<form onSubmit={handleSubmit(handleFormSubmit)}>
					<Root>
						<Controller
							name="email"
							render={({ field }) => (
								<Input
									{...field}
									onChange={(e) => {
										e.target.value = e.target.value.trim();
										field.onChange(e);
									}}
									label={t('label.email')}
									ref={null}
									helperText={
										Boolean(formState.errors.email?.message) && (
											<>
												<AttentionIcon width={15} height={15} />
												<span>
													{t(`validation:${formState.errors.email?.message}`)}
												</span>
											</>
										)
									}
									error={Boolean(formState.errors.email?.message)}
									inputProps={{
										'data-testid': 'forgot-password-email-textField',
									}}
								/>
							)}
						/>
						<Description>{t('text.description')}</Description>
						<SubmitLevel>
							<MplButton
								endIcon={<ArrowRightIcon />}
								data-testid="forgot-password-submit-button"
								disabled={!formState.isValid}
								type="submit"
							>
								{t('button.send_email')}
							</MplButton>
						</SubmitLevel>
					</Root>
				</form>
			</FormProvider>
		</>
	);
};
