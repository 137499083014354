import { createContext, useContext } from 'react';
import { Observable } from 'rxjs';

import { ILocationDecoded } from '@mopla/data-models';
import { LatLng } from '@mopla/utils';

export interface ICurrentGeoPositionContext {
	position$: Observable<LatLng | null>;
	position: LatLng | null;
	updatePos: VoidFunction;
	getCurrentGeoPosition(): Promise<LatLng | null>;
	getCurrentGeoPositionDecoded(): Promise<ILocationDecoded | null>;
}

const currentGeoPositionContext = createContext(
	{} as ICurrentGeoPositionContext
);

export const CurrentGeoPositionContextProvider =
	currentGeoPositionContext.Provider;

export const useCurrentGeoPosition = () =>
	useContext(currentGeoPositionContext);
