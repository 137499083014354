import { styled } from '@mui/material/styles';

import { CheckmarkCircleIcon, FlexColumn, MplButton } from '../../../../';

export const Root = styled('div')(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gap: 24,
	boxSizing: 'border-box',
	width: '100%',
	paddingTop: 16,
	paddingBottom: 48,
}));

export const Content = styled('div')(() => ({
	paddingLeft: 24,
	paddingRight: 24,
}));

export const ContentPaper = styled('div')(({ theme }) => ({
	background: theme.palette.neutral[0],
	borderRadius: 8,
	padding: '24px 16px',
	[theme.breakpoints.down('tabletP')]: {
		margin: '0 8px',
	},
}));

export const CancelButtonWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 8,
}));

export const TitleWrapper = styled(Content)(() => ({
	display: 'grid',
	gridTemplateColumns: 'max-content 1fr',
	gridTemplateRows: 'max-content max-content',
	boxSizing: 'border-box',
	marginBottom: 16,
}));

/** TODO button styling is prohibited */
export const ButtonCancel = styled(MplButton)(() => ({
	width: 'auto',
	height: 'max-content',
}));

export const CheapestRideIcon = styled(CheckmarkCircleIcon)(({ theme }) => ({
	color: theme.palette.secondary.main,
	width: 32,
	height: 32,
}));

export const TildaIcon = styled('div')(({ theme }) => ({
	lineHeight: 1,
	'&:after': {
		...theme.typography.body2,
		content: '"≈"',
		lineHeight: 1,
	},
}));

export const LegsWrapper = styled(FlexColumn)(() => ({
	marginLeft: -12,
}));
