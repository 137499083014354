import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { PassengerDetails } from '@mopla/data-models';

import { FlexColumn } from '../../../../';

import { ContentPaper } from './styled';

export interface IPassengerInfoViewProps {
	mainPassenger?: PassengerDetails;
	passengersSummary: ReactElement;
	attendeesCount: number;
}

export const PassengerInfoView: FC<IPassengerInfoViewProps> = ({
	mainPassenger,
	attendeesCount,
	passengersSummary,
}) => {
	const { t } = useTranslation('booking');

	return (
		<ContentPaper>
			<Typography variant="bodyTitle">
				{t('text.ticketFor', {
					count: attendeesCount,
				})}
			</Typography>
			<FlexColumn gap={4} sx={{ mt: 1 }}>
				{passengersSummary}
				<Typography
					variant="body2"
					color="neutral.80"
				>{`${mainPassenger?.phone}`}</Typography>
				<Typography
					variant="body2"
					color="neutral.80"
				>{`${mainPassenger?.email}`}</Typography>
			</FlexColumn>
		</ContentPaper>
	);
};
