import React from 'react';
import { styled } from '@mui/material/styles';

import { DEPRECATED_Button } from '../../atoms/Button/_deprecated/Button';

type ISocialButtonProps = {
	name: 'google' | 'apple' | 'facebook' | 'microsoft';
	onClick: VoidFunction;
	icon: React.ReactNode;
};

export const Social = styled(DEPRECATED_Button)(({ theme }) => ({
	width: 40,
	marginRight: 16,
	background: theme.palette.neutral[0],
	border: `2px solid ${theme.palette.neutral[0]}`,
	'&:disabled': {
		border: `2px solid ${theme.palette.neutral[40]}`,
	},
}));

export const SocialButton: React.FC<ISocialButtonProps> = (props) => {
	const { icon, name, onClick } = props;

	return (
		<Social
			data-testid={`login-with-${name}-button`}
			variant="outlined"
			onClick={onClick}
			// TODO: remove 'disabled' when social reg will be available
			disabled
		>
			{icon}
		</Social>
	);
};
