import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'max-content max-content',
	background: theme.palette.neutral[40],
	padding: 16,
	boxSizing: 'border-box',
	borderRadius: 8,
}));

export const PassengerInfo = styled('div', {
	shouldForwardProp: (prop) => prop !== 'highlighted',
})<{ highlighted: boolean }>(({ theme, highlighted }) => ({
	...theme.typography.body2,
	fontWeight: 700,
	color: highlighted ? theme.palette.primary.dark : theme.palette.neutral[60],
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gridTemplateRows: 'max-content max-content',
	gridGap: 0,
	justifyContent: 'space-between',
	'#more-attendees': {
		...theme.typography.caption,
		color: highlighted ? theme.palette.primary.dark : theme.palette.neutral[60],
		marginTop: 4,
	},

	'&:not(:last-of-type)': {
		marginBottom: 16,
		paddingBottom: 16,
		borderBottom: '1px solid RGBA(14,28,91,0.1)',
	},
}));
