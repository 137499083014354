export const locationSchema = {
	title: 'location schema',
	version: 2,
	primaryKey: 'title',
	type: 'object',
	properties: {
		position: {
			type: 'object',
			properties: {
				lat: {
					type: 'number',
				},
				lng: {
					type: 'number',
				},
			},
		},
		title: {
			type: 'string',
			maxLength: 300,
		},
		address: {
			type: ['object', 'null'],
		},
		timestamp: {
			type: 'number',
		},
		saved: {
			type: 'boolean',
		},
		id: {
			type: 'string',
		},
	},
	required: ['position', 'title'],
};
