import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';

import { IWhoami } from '@mopla/data-models';
import { useBreakpoints } from '@mopla/utils';

import {
	EditIcon,
	Footer,
	MainWithFooterTemplate,
	MplButton,
	TrashIconSmall,
} from '../../';

import {
	DataField,
	MyAccountTitleWithButton,
	MyAccountWrapper,
	SeparatorLine,
} from './ProfileManager.styles';

export interface PersonalScreenProps {
	onEdit: () => void;
	onDeleteAccount: () => void;
	currentUser?: IWhoami;
}

export const PersonalScreen: FC<PersonalScreenProps> = ({
	onEdit,
	onDeleteAccount,
	currentUser,
}) => {
	const { t } = useTranslation('profile');
	const { isAboveMobile } = useBreakpoints();

	const content = (
		<MyAccountWrapper data-testid="personalScreen-myAccountWrapper">
			<div>
				<Typography
					data-testid="personalScreen-userNameData"
					variant="bodyTitle"
					color="neutral.100"
				>
					{currentUser?.firstName} {currentUser?.lastName}
				</Typography>
				<DataField data-testid="personalScreen-userEmail">
					{currentUser?.email}
				</DataField>
			</div>
			<SeparatorLine />
			<MyAccountTitleWithButton>
				<Typography
					data-testid="personalScreen-title"
					variant="bodyTitle"
					color="neutral.100"
				>
					{t('header.personal_data')}
				</Typography>
				<IconButton data-testid="personalScreen-editButton" onClick={onEdit}>
					<EditIcon />
				</IconButton>
			</MyAccountTitleWithButton>
			<div>
				<Typography
					data-testid="personalScreen-addressTitle"
					variant="body2"
					color="neutral.80"
				>
					{t('title.adress')}
				</Typography>
				<DataField data-testid="personalScreen-addressData">
					{`${currentUser?.address?.street} ${currentUser?.address?.streetNumber}`}{' '}
					<br />
					{`${currentUser?.address?.zipCode} ${currentUser?.address?.city}`}
				</DataField>
			</div>
			<div>
				<Typography
					data-testid="personalScreen-phoneText"
					variant="body2"
					color="neutral.80"
				>
					{t('title.cell_phone')}
				</Typography>
				<DataField data-testid="personalScreen-phoneData">
					{currentUser?.phone}
				</DataField>
			</div>
			<div>
				<Typography
					data-testid="personalScreen-birthText"
					variant="body2"
					color="neutral.80"
				>
					{t('title.birth')}
				</Typography>
				<DataField data-testid="personalScreen-birthData">
					{currentUser?.dateOfBirth}
				</DataField>
			</div>
		</MyAccountWrapper>
	);

	if (isAboveMobile) {
		return content;
	}

	return (
		<MainWithFooterTemplate
			content={content}
			footer={
				<Footer>
					<Stack alignItems="center">
						<MplButton
							testId="personalScreen-deleteAccountButton"
							variant="text"
							color="error"
							onClick={onDeleteAccount}
							endIcon={<TrashIconSmall />}
							fullWidth={false}
						>
							{t('button.delete_account')}
						</MplButton>
					</Stack>
				</Footer>
			}
		/>
	);
};
