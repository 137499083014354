import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';

import { loadPassengerBookings } from '@mopla/business-logic';
import { useBusinessLayer } from '@mopla/business-logic';
import { IBooking } from '@mopla/data-models';
import { useTranslation } from '@mopla/i18';
import { calculateCurrentCancellationPrice, formatPrice } from '@mopla/utils';

import { CompleteAnim, ErrorModal, InfoModal } from '../../../';

import { ConfirmationModal } from './ConfirmationModal';
import { ReasonFormStep } from './ReasonFormStep';
import { ICancellationReasonFormState, TCancellationStep } from './types';
import { useCancellationReasonForm } from './useCancellationReasonForm';

interface ICancellationFlowProps {
	bookedItinerary: IBooking;
	onClose: VoidFunction;
}

export const CancellationFlow: FC<ICancellationFlowProps> = ({
	bookedItinerary,
	onClose,
}) => {
	const [step, setStep] = useState<TCancellationStep>(null);
	const [isCancelling, setIsCancelling] = useState(false);
	const navigate = useNavigate();
	const bl = useBusinessLayer();
	const { t } = useTranslation('tickets');
	const [cancellationCost, setCancellationCost] = useState<0 | string | null>(
		null
	);
	const cancellationReasonForm = useCancellationReasonForm();
	const cancellationReasonFormSubmit = cancellationReasonForm.handleSubmit;

	const getCancellationCost = useCallback((bookedTicket: IBooking) => {
		const price = calculateCurrentCancellationPrice(bookedTicket.itinerary);

		return price === 0 ? 0 : formatPrice(price / 100);
	}, []);

	useEffect(
		() => {
			/** Init the flow of the PRODT Cancellation */
			const cancellationCost = getCancellationCost(bookedItinerary);

			setCancellationCost(cancellationCost);
			setStep(cancellationCost === 0 ? 'reason' : 'confirmation');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const processCancelBooking = useCallback(
		async (formState: ICancellationReasonFormState) => {
			setIsCancelling(true);
			try {
				await bl.api.post('/api/command/cancelBooking', {
					bookingId: bookedItinerary.bookingNumber,
					cancellationReason: formState.reason,
					cancellationReasonDescription: formState.otherReasonText || undefined,
				});
				bl.dispatch(loadPassengerBookings());
				setStep('success');
			} catch (e) {
				setStep('error');
				Sentry.captureException(e);
			} finally {
				setIsCancelling(false);
			}
		},
		[bl, bookedItinerary]
	);

	const formSubmitHandler = useMemo(() => {
		return cancellationReasonFormSubmit(processCancelBooking);
	}, [cancellationReasonFormSubmit, processCancelBooking]);

	const finishHandler = useCallback(() => {
		navigate('/home/tickets', { replace: true });
		onClose();
	}, [navigate, onClose]);

	return (
		<FormProvider {...cancellationReasonForm}>
			{step === 'error' && <ErrorModal onSubmit={onClose} />}
			{step === 'confirmation' && (
				<ConfirmationModal
					onClose={onClose}
					onConfirm={() => setStep('reason')}
					isCanceling={isCancelling}
					ticket={bookedItinerary}
					cancellationPrice={cancellationCost as string}
				/>
			)}
			{step === 'reason' && (
				<ReasonFormStep onCancel={onClose} onSubmit={formSubmitHandler} />
			)}
			{step === 'success' && (
				<InfoModal
					icon={<CompleteAnim />}
					title={t('cancellation_flow.success.title')}
					submitTitle={t('common:button.ok')}
					infoText=""
					onSubmit={finishHandler}
				/>
			)}
		</FormProvider>
	);
};
