import React, { ComponentType, FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor, PermissionState } from '@capacitor/core';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';

import { wrapDisplayName } from '@mopla/utils';

import {
	IPermissionsModalRef,
	PermissionsAttentionIcon,
	PermissionsModal,
} from '../../';

import { useOnAppFocus } from './useOnAppFocus';

type TProviderState = Awaited<
	ReturnType<typeof BackgroundGeolocation.getProviderState>
>['status'];

export const withBackgroundLocationPermGiven = <T extends object>(
	Wrapped: ComponentType<T>
) => {
	const Wrapper: FC<T> = (props) => {
		const { t } = useTranslation('permissions');
		const [platform] = useState(Capacitor.getPlatform());
		const [isModalShown, setOpenModal] = useState(platform !== 'web');
		const ref = useRef<IPermissionsModalRef>(null);

		const mapPermState = (
			authorizationStatus: TProviderState
		): {
			location: PermissionState;
		} => {
			const isAlways =
				authorizationStatus ===
				BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS;

			return { location: isAlways ? 'granted' : 'denied' };
		};

		const checkIsPermissionAlways = useCallback(async () => {
			const providerState = await BackgroundGeolocation.getProviderState();

			return mapPermState(providerState.status);
		}, []);

		const askPerm = useCallback(() => {
			return new Promise<{
				location: PermissionState;
			}>((resolve) => {
				BackgroundGeolocation.requestPermission((status) => {
					resolve(mapPermState(status));
				});
			});
		}, []);

		const onAppFocus = useCallback(() => {
			/** If the modal was already shown at this moment,
			 * {reprocess} will trigger checking permissions and may close the modal, if the permissions were granted */
			ref.current?.reprocess();

			setOpenModal(true);
		}, []);

		const handleCloseModal = useCallback(() => setOpenModal(false), []);

		useOnAppFocus(onAppFocus);

		return (
			<>
				{isModalShown && (
					<PermissionsModal
						title={t(`driver_location_always.${platform}.title`)}
						fallbackTitle={t(`driver_location_always.${platform}.title`)}
						firstMessage=" "
						acceptTitle="OK"
						deniedFallbackMessage1={t(
							`driver_location_always.${platform}.description`
						)}
						deniedFallbackMessage2=" "
						animationElement={<PermissionsAttentionIcon />}
						checkPermissionsCallback={checkIsPermissionAlways}
						askPermissionsCallback={askPerm}
						onPermissionsGranted={handleCloseModal}
						onCancel={handleCloseModal}
						showSkipButton={false}
						acceptWithIcon={false}
						ref={ref}
					/>
				)}
				<Wrapped {...props} />
			</>
		);
	};

	Wrapper.displayName = wrapDisplayName(
		Wrapped,
		'withBackgroundLocationPermGiven'
	);

	return Wrapper;
};
