import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));

export const Title = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'bold',
})<{ marginBottom?: string; bold?: boolean }>(
	({ theme, marginBottom, bold }) => ({
		...theme.typography.body1,
		marginBottom: marginBottom || 0,
		fontWeight: bold ? 600 : 400,
	})
);
