import { useCallback } from 'react';

export const usePasswordlessLogin = (baseUrl: string) => {
	return useCallback(
		(email: string, type: 'GERMANYTICKET' | 'NORMAL') => {
			const body = { email, app: 'PASSENGER', type };

			return fetch(`${baseUrl}/api/passwordless-login`, {
				method: 'POST',
				body: JSON.stringify(body),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
			});
		},
		[baseUrl]
	);
};
