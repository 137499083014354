import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: 16,

	'& .MuiFormControlLabel-label': {
		fontSize: 18,
	},
}));
