import { FC, useState } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { MoplaAreaBanner } from './MoplaAreaBanner';
import { MoplaRideTypesBanner } from './MoplaRideTypesBanner';
import { PaginationWrapper, Root, SwiperStyled } from './styled';

export const MoplaPromotionCarousel: FC = () => {
	/** Swiper doesn't work with react ref (doesn't update it's state when ref becomes set), thus useState */
	const [ref, setRef] = useState<HTMLDivElement | null>(null);

	return (
		<Root>
			<SwiperStyled
				slidesPerView={1}
				spaceBetween={30}
				centeredSlides
				autoplay={{
					delay: 3000,
					disableOnInteraction: false,
				}}
				speed={650}
				pagination={{ clickable: true, el: ref }}
				modules={[Autoplay, Pagination]}
				loop
			>
				<SwiperSlide>
					<MoplaAreaBanner />
				</SwiperSlide>
				<SwiperSlide>
					<MoplaRideTypesBanner />
				</SwiperSlide>
			</SwiperStyled>
			<PaginationWrapper ref={setRef} />
		</Root>
	);
};
