import { FC } from 'react';
import { Typography } from '@mui/material';

import { LocationFlagIcon } from '../../..';
import { WalkingLegSvg } from '../assets/WalkingLegSvg';

import { useLegDetailsContext } from './context';
import {
	MiddleCol,
	RightCol,
	TextStyled,
	WalkLegPath,
	WalkLegRoot,
} from './styled';
import { TimeText } from './TimeText';

export type TWalkLegVariant = 'default' | 'disabled';

export interface IWalkLegProps {
	isStart?: boolean;
	isEnd?: boolean;
}

export const WalkLeg: FC<IWalkLegProps> = ({ isStart, isEnd }) => {
	const {
		startTime,
		startPointLabel,
		endPointLabel,
		endTime,
		isTimeNarrow,
		walkLegText,
		disabled,
	} = useLegDetailsContext();

	return (
		<WalkLegRoot ownerState={{ isTimeNarrow }}>
			<WalkLegPath
				className="path"
				ownerState={{ variant: disabled ? 'disabled' : 'default' }}
			>
				{isStart && <LocationFlagIcon />}
				<WalkingLegSvg />
				{isEnd && <LocationFlagIcon />}
			</WalkLegPath>
			{isStart && (
				<>
					<MiddleCol className="startTime">
						<TimeText variant="intermediate">{startTime}</TimeText>
					</MiddleCol>
					<RightCol className="startTitle">
						<TextStyled variant="body1" color="neutral.80" noWrap>
							{startPointLabel}
						</TextStyled>
					</RightCol>
				</>
			)}
			<RightCol className={`distance ${!isStart && !isEnd && 'middle'}`}>
				<Typography variant="body2" color="neutral.80">
					{walkLegText}
				</Typography>
			</RightCol>
			{isEnd && (
				<>
					<MiddleCol className="endTime">
						<TimeText variant="intermediate">{endTime}</TimeText>
					</MiddleCol>
					<RightCol className="endTitle">
						<TextStyled variant="body1" color="neutral.80" noWrap>
							{endPointLabel}
						</TextStyled>
					</RightCol>
				</>
			)}
		</WalkLegRoot>
	);
};
