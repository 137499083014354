import React from 'react';
import { TextFieldProps } from '@mui/material';

import { Root } from './input.styles';

export const Input = ({
	type = 'text',
	InputProps,
	className,
	...props
}: TextFieldProps) => {
	return (
		<Root
			{...props}
			color="primary"
			type={type}
			variant="filled"
			InputProps={{
				disableUnderline: true,
			}}
		/>
	);
};
