import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

import 'dayjs/locale/de';
import 'dayjs/locale/pl';
import 'dayjs/locale/fr';
import 'dayjs/locale/tr';
import 'dayjs/locale/cs';
import 'dayjs/locale/uk';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

//Keep the order because it is used in the LanguageSelector component to this the LanguageSelectorButton component in this order

export const SUPPORTED_LNGS = [
	'de',
	'en',
	'cs_CZ',
	'fr',
	'es',
	'it',
	'pl_PL',
	'uk_UA',
] as const;

export type SupportedLngs = typeof SUPPORTED_LNGS[number];

i18n
	// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init({
		ns: [
			'booking',
			'changePassword',
			'checkIn',
			'common',
			'discardChangesPrompt',
			'docsUpload',
			'dticket',
			'errors',
			'forgotPassword',
			'logIn',
			'navigation',
			'notifications',
			'onboarding',
			'payment',
			'permissions',
			'personalDetails',
			'profile',
			'pushToApp',
			'registration',
			'routeDetails',
			'searchForm',
			'searchResults',
			'specialNeeds',
			'tickets',
			'validation',
			'vehicleCheck',
			'verify',
			'verifyReminder',
			'welcome',
		],
		interpolation: { escapeValue: false },
		nsSeparator: ':',
		debug: true,
		fallbackLng: 'de',
		supportedLngs: SUPPORTED_LNGS,
		defaultNS: 'common',
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
		detection: {
			order: ['querystring', 'cookie', 'localStorage', 'navigator'],
		},
	});

i18n.on('languageChanged', function (lng) {
	dayjs.locale(lng.substring(0, 2));
});

export default i18n;
