export const HeaderSideMenuIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
		>
			<path
				d="M13.5 6H7.5C6.94772 6 6.5 6.44772 6.5 7V13C6.5 13.5523 6.94772 14 7.5 14H13.5C14.0523 14 14.5 13.5523 14.5 13V7C14.5 6.44772 14.0523 6 13.5 6Z"
				fill="#101D56"
			/>
			<path
				d="M27.5 6H21.5C20.9477 6 20.5 6.44772 20.5 7V13C20.5 13.5523 20.9477 14 21.5 14H27.5C28.0523 14 28.5 13.5523 28.5 13V7C28.5 6.44772 28.0523 6 27.5 6Z"
				fill="#101D56"
			/>
			<path
				d="M27.5 20H21.5C20.9477 20 20.5 20.4477 20.5 21V27C20.5 27.5523 20.9477 28 21.5 28H27.5C28.0523 28 28.5 27.5523 28.5 27V21C28.5 20.4477 28.0523 20 27.5 20Z"
				fill="#101D56"
			/>
			<path
				d="M27.5 34H21.5C20.9477 34 20.5 34.4477 20.5 35V41C20.5 41.5523 20.9477 42 21.5 42H27.5C28.0523 42 28.5 41.5523 28.5 41V35C28.5 34.4477 28.0523 34 27.5 34Z"
				fill="#101D56"
			/>
			<path
				d="M41.5 20H35.5C34.9477 20 34.5 20.4477 34.5 21V27C34.5 27.5523 34.9477 28 35.5 28H41.5C42.0523 28 42.5 27.5523 42.5 27V21C42.5 20.4477 42.0523 20 41.5 20Z"
				fill="#101D56"
			/>
			<path
				d="M13.5 20H7.5C6.94772 20 6.5 20.4477 6.5 21V27C6.5 27.5523 6.94772 28 7.5 28H13.5C14.0523 28 14.5 27.5523 14.5 27V21C14.5 20.4477 14.0523 20 13.5 20Z"
				fill="#101D56"
			/>
			<path
				d="M41.5 6H35.5C34.9477 6 34.5 6.44772 34.5 7V13C34.5 13.5523 34.9477 14 35.5 14H41.5C42.0523 14 42.5 13.5523 42.5 13V7C42.5 6.44772 42.0523 6 41.5 6Z"
				fill="#101D56"
			/>
			<path
				d="M41.5 34H35.5C34.9477 34 34.5 34.4477 34.5 35V41C34.5 41.5523 34.9477 42 35.5 42H41.5C42.0523 42 42.5 41.5523 42.5 41V35C42.5 34.4477 42.0523 34 41.5 34Z"
				fill="#101D56"
			/>
			<path
				d="M13.5 34H7.5C6.94772 34 6.5 34.4477 6.5 35V41C6.5 41.5523 6.94772 42 7.5 42H13.5C14.0523 42 14.5 41.5523 14.5 41V35C14.5 34.4477 14.0523 34 13.5 34Z"
				fill="#101D56"
			/>
		</svg>
	);
};
