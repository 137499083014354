import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(() => ({
	boxSizing: 'border-box',
	padding: 24,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
}));

export const PaperBox = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: 24,
	backgroundColor: theme.palette.neutral[40],
	color: theme.palette.primary.dark,
	display: 'flex',
	flexDirection: 'column',
	gap: 16,
	marginTop: 35,
}));

export const MailLink = styled('a')(({ theme }) => ({
	...theme.typography.body1,
	textDecoration: 'underline',
	color: theme.palette.primary.dark,
}));

export const DescriptionLink = styled('button')(({ theme }) => ({
	...theme.typography.body1,
	background: 'none',
	border: 'none',
	padding: 0,
	textDecoration: 'underline',
	color: theme.palette.neutral[100],
	cursor: 'pointer',
}));
