import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IOwnerState {
	open: boolean;
	withHeader: boolean;
}

export const Root = styled('div')<{ ownerState: IOwnerState }>(
	({ theme, ownerState: { open } }) => ({
		background: theme.palette.neutral[0],
		position: 'absolute',
		width: '100%',
		bottom: 0,
		left: 0,
		height: open ? 'calc(100% - var(--mopla-safe-area-top))' : 0,
		transition: 'height .2s, width .2s, left .2s',
		zIndex: open ? 10 : 20,
	})
);

export const ContentWrapper = styled(Paper)<{ ownerState: IOwnerState }>(
	({ ownerState: { withHeader } }) => ({
		display: 'grid',
		gridTemplateRows: withHeader ? 'max-content 1fr' : '1fr',
		maxWidth: 600,
		margin: '0 auto',
		height: '100%',
		borderRadius: '8px 8px 0 0',
		overflow: 'hidden',
	})
);

export const BaseSurfaceBlock = styled('div')<{ ownerState: IOwnerState }>(
	({ theme, ownerState: { open } }) => ({
		position: 'absolute',
		background: theme.palette.neutral[0],
		width: '100%',
		bottom: 0,
		left: 0,
		height: open ? '100%' : 0,
		transition: open ? 'height .2s' : '',
		overflow: 'hidden',
		zIndex: 4,
	})
);

export const ChildrenWrapper = styled('div')(() => ({
	overflowY: 'auto',
}));
