import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ISubscriptionOfferVoucher } from '@mopla/data-models';
import { FlexContainer } from '@mopla/ui';
import { formatPrice } from '@mopla/utils';

import { BASIC_TICKET_COST } from './constants';

interface IProps {
	regularPrice?: number;
	voucher?: ISubscriptionOfferVoucher;
	isJobticket: boolean;
}

export const PriceInfoBlock: FC<IProps> = (props) => {
	const { regularPrice, voucher, isJobticket } = props;
	const { t } = useTranslation(['dticket']);

	const ticketPrice = regularPrice ?? BASIC_TICKET_COST;
	const priceFontStyle = isJobticket ? 'body1' : 'body2';

	return (
		<>
			{voucher && !isJobticket && (
				<FlexContainer className="space-between">
					<Typography
						data-testid="dticket-subscription-summary-discount-amount"
						variant="body1"
						fontWeight={600}
						color="neutral.0"
					>
						{t('label.price_per_month_with_discount', {
							discount: formatPrice(voucher.voucherDeductionAmount),
						})}
					</Typography>
					<Typography
						data-testid="dticket-subscription-summary-price-with-discount"
						variant="body1"
						fontWeight={600}
						color="neutral.0"
					>
						{t('text.price_per_month_with_discount', {
							price: formatPrice(ticketPrice - voucher.voucherDeductionAmount),
						})}
					</Typography>
				</FlexContainer>
			)}
			<FlexContainer className="space-between">
				<Typography variant={priceFontStyle} fontWeight={600} color="neutral.0">
					{t('label.price_per_month')}
				</Typography>
				<Typography
					data-testid="dticket-subscription-summary-price-regular"
					variant={priceFontStyle}
					fontWeight={600}
					color="neutral.0"
				>
					{t('text.price_per_month', {
						price: formatPrice(ticketPrice),
					})}
				</Typography>
			</FlexContainer>
		</>
	);
};
