import { BottomNavigationAction } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBottomNavigationAction = styled(BottomNavigationAction)(
	({ theme }) => ({
		color: theme.palette.primary.main,
		opacity: 0.4,

		'&.Mui-selected': {
			color: theme.palette.primary.main,
			opacity: 1,
		},
	})
);
